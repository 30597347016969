<template>
	<div>
		<!-- 留言按钮 -->
		<Comment class="commentWrap"/>
		<!-- 置顶组件 -->
		<gotop></gotop>
		
		<div class="casebannertop">
			
			<!-- 顶部导航栏 -->
			<Headernew :navshow.async="navshow"/> 
			
			<!-- 如果用户没有登录信息，且在微信中打开，那么显示一个白色遮罩 -->
			<div class="wechatnologin" v-if="wechatnologin">
				<div class="nomessage">您还没有登录，请登录后查看！</div>
				<div class="gotologBtn" @click="geturlrandom">授权微信登录</div>
			</div>
			
			
			<div class="casebanner-center" v-if="videomessage.code_style==1">
				<div class="casebanner-centertitle">{{videomessage.main}}</div>
				<!-- 横屏视频 -->
				<div class="casebanner-img">
					<!-- 观看码 -->
					<div class="password" v-if="videopassword">
					<!-- <div class="password"> -->
						<div class="password-center">
							<div class="password-centertop">
								<input type="text" placeholder="请输入观看码" ref="getValue" @input="changeinput" />
								<div v-if="lookerror">观看码错误!</div>
							</div>
							<div class="password-bottom" @click="requestpassword">确定</div>
						</div>
					</div>
					
					<img class="videopcimg" src="../assets/img/pc.png" />
					<video class="topvideoheng" controls="controls" :src="videomessage.open" :poster="videomessage.simg" controlslist="nodownload"></video>
				</div>
			</div>
			<div class="shoujicasebanner-center" v-if="videomessage.code_style==2">
				<div class="casebanner-centertitle">{{videomessage.main}}</div>
				<!-- 竖屏视频 -->
				<div class="shoujicasebanner-img">
					<!-- 观看码 -->
					<div class="shoujipassword" v-if="videopassword">
					<!-- <div class="shoujipassword"> -->
						<div class="password-center">
							<div class="password-centertop">
								<input type="text" placeholder="请输入观看码" ref="getValue" @input="changeinput" />
								<div v-if="lookerror">观看码错误!</div>
							</div>
							<div class="password-bottom" @click="requestpassword">确定</div>
						</div>
					</div>
					
					<img class="videoshoujiimg" src="../assets/img/topvideoshoujimob.png"/>
					<video class="topvideoshu" controls="controls" :src="videomessage.open" :poster="videomessage.simg" controlslist="nodownload"></video>
				</div>
			</div>
		</div>
		
		<!-- 公司介绍 -->
<!-- 		<div class="companymessage">
			<div class="companymessage-left">
				<div class="companymessage-top">
					<div>KEYWORD</div>
					<div>信息科技网站 / 科技公司网站 / 信息公司网站</div>
				</div>
				<div class="companymessage-bottom">
					<div>三禾一科技</div>
					<div>
						“人大宣教网”由中国民主法制出版社人大图书出版分社主管，北京中民法智文化发展有限公司承办和运营，是一家以宣传我国人民代表大会制度、普及人民代表大会知识、提高人大工作水平和代表履职水平为己任的一家综合性门户网站
					</div>
				</div>
			</div>
			
			<div class="companymessage-right">
				<div class="ercodeimg">
					<img src="../assets/img/ercodeimg.png" />
				</div>
				<div class="ercodetext">手机扫一扫继续浏览</div>
			</div>
		</div> -->
		
		<!-- 更多详情 -->
		<!-- <div class="wow fadeInUp detailcontent">
			<div class="detailcontent-center" v-html="videocontent">
				{{videocontent}}
			</div>
		</div> -->
		
		<!-- <div class="vrdetail">
			<iframe id="vrdetailif" src="https://htkja.htkjbd.com/threejs/house.html"></iframe>
		</div> -->
		
		<!-- 精心打造 -->
		<div class="wow fadeInUp englishtext">
			<div class="englishtext-center">
				<div class="huienglishi">PROCESS</div>
				<div class="englishtext-word">
					<span>专业团队+专业的定制服务流程</span>
					<span>互联网营销趋势的敏锐洞察 确保产品高端的视觉体验</span>
				</div>
			</div>
		</div>
		<!-- 合作流程 -->
		<div class="wow fadeInUp service-show-process">
			 <div class="service-process-box">
			        <ul class="process-list clearfix">
			            <li class="pro-item">
			                <div>
			                    <img class="baxk" src="../assets/img/aboutPage_service_icon11.png">
			                    <em>前期沟通</em>
			                    <div class="work-box">
			                        <p>当面洽谈，了解客户意图</p>
			                    </div>
			                </div>
			            </li>
			            <li class="pro-item">
			                <div>
								<img class="baxk" src="../assets/img/aboutPage_service_icon22.png">
			                    <em>方案 / 报价</em>
			                    <div class="work-box">
			                        <p>诉求对接沟通，核算工作量提交预算方案</p>
			                    </div>
			                </div>
			            </li>
			            <li class="pro-item">
			                <div>
								<img class="baxk" src="../assets/img/aboutPage_service_icon33.png">
			                    <em>合同签订</em>
			                    <div class="work-box">
			                        <p>签订正式合同，支付预付款(合同款50%)</p>
			                    </div>
			                </div>
			            </li>
			            <li class="pro-item">
			                <div>
								<img class="baxk" src="../assets/img/aboutPage_service_icon55.png">
			                    <em>项目方案</em>
			                    <div class="work-box">
			                        <p>根据客户诉求形成脚本及配音方案/功能开发、执行方案</p>
			                    </div>
			                </div>
			            </li>
			            <li class="pro-item">
							<div>
			                <img class="baxk" src="../assets/img/aboutPage_service_icon66.png">
			                    <em>客户确认</em>
			                    <div class="work-box">
			                        <p>制作执行 客户签字确认方案后，依据方案开展服务（拍摄制作/软件编程）</p>
			                    </div>
			                </div>
			            </li>
			            <li class="pro-item">
			               <div>
			                <img class="baxk" src="../assets/img/aboutPage_service_icon88.png">
			                    <em>客户审核结算</em>
			                    <div class="work-box">
			                        <p>客户审阅样片/测试软件通过后，结算尾款，成品交付</p>
			                    </div>
			                </div>
			            </li>
			        </ul>
			    </div>
		</div>
		
		
		<!-- 我们的优势 -->
		<div class="wow fadeInUp advantage-wrap">
			
			<div class="advantagetext">
				<div class="advantagetext-center">
					<div class="advantagelishi"></div>
					<div class="advantagetext-word">
						<span>我们的优势</span>
						<span>多种平台、多个渠道，一站式服务</span>
					</div>
				</div>
			</div>
			
			<div class="advantage-center">
				<div class="advantage-left">
					<div class="dvantage-list">
						<div class="dvantage-listtop">
							<img src="../assets/img/wzjs1.jpg" />
							<div class="dvantage-listtoptitle">
								<div>以智取胜</div>
								<div>WISDOM</div>
							</div>
						</div>
						
						<div class="dvantage-listmiddle">网络科技+交互(视频)</div>
						<div class="dvantage-listbottom">
							“网络科技+交互(视频)”为企业集团和地产开发商量身定制；策划+宣传+营销+推广；全面利用科技
							大数据手段掌握市场最新动态及企业站位，帮助企业和地产做出更贴合市场情景的有效的指导规划。
							让每一个决策都产生价值，让智慧引领未来。
						</div>
					</div>
					<div class="dvantage-list">
						<div class="dvantage-listtop">
							<img src="../assets/img/wzjs2.jpg" />
							<div class="dvantage-listtoptitle">
								<div>技高一筹</div>
								<div>HIGH-TECH</div>
							</div>
						</div>
						
						<div class="dvantage-listmiddle">为企业打造完整科技服务闭环</div>
						<div class="dvantage-listbottom">
							高科技网络服务平台+全智能化服务中断(o2o线上+线下营销新模式)，
							为企业/开发商打造完整科技服务闭环。技术支持：720智能VR全景+建筑动画
							(30人三维团队)+园林景观漫游+3D户型+宣传/广告视频制作+VI设计+三维/实景
							体感交互+大数据精准人群投放。
						</div>
					</div>
				</div>
				<div class="advantage-middle"></div>
				<div class="advantage-right">
					<div class="dvantage-list">
						<div class="dvantage-listtop">
							<img src="../assets/img/wzjs3.jpg" />
							<div class="dvantage-listtoptitle">
								<div>坐享其成</div>
								<div>THE-CITY</div>
							</div>
						</div>
						
						<div class="dvantage-listmiddle">智能科技展厅</div>
						<div class="dvantage-listbottom">
							后天科技打破传统展厅展示的局限性，运用高科技互动多媒体技术、打造全新互动式/沉浸式/体验式展厅，为客户提供研发、策划、设计、多媒体交互一站式服务。
							将多媒体展馆引入高端数字创意，在设计师巧妙的运作下完美融入展厅之中，全面拥抱科技所创造出的新感知形式。
						</div>
					</div>
					<div class="dvantage-list">
						<div class="dvantage-listtop">
							<img src="../assets/img/wzjs4.jpg" />
							<div class="dvantage-listtoptitle">
								<div>神来之笔</div>
								<div>MAGIC PEN</div>
							</div>
						</div>
						
						<div class="dvantage-listmiddle">实践践行理论，理论指导实践</div>
						<div class="dvantage-listbottom">
							余年企业/地产项目合作经验，频繁密度接触各行各业，从面到点深入对接，不辜负
							每一份期待，为行业的每一位客户提供物超所值的服务。结合市场导向，总结10余
							年运营服务理念，携手保定、雄安新区，在媒体科技力量支持下助力企业数字化转
							型，向更大更广阔的天地奔去，共创未来数字科技企业
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 更多案例 -->
		<div class="wow fadeInUp morecase-title">更多案例_more</div>
		<div class="wow fadeInUp morecase-wrap">
			<div class="swiper-container">
			    <div class="swiper-wrapper">
					
			     <div class="swiper-slide" v-for="(item,i) in lubomessage">
					  <div>
						  <img :src="item.simg" />
					  </div>
					  <div>{{item.main}}</div>
				  </div>
				  
				<!-- <div class="swiper-slide">
					  <img src="../assets/img/videolist1.png" />
					  <div>宣传片</div>
				  </div>
				  <div class="swiper-slide">
					  <img src="../assets/img/videolist1.png" />
					  <div>宣传片</div>
				  </div>
				  <div class="swiper-slide">
					  <img src="../assets/img/videolist1.png" />
					  <div>宣传片</div>
				  </div>
				  <div class="swiper-slide">
					  <img src="../assets/img/videolist1.png" />
					  <div>宣传片</div>
				  </div>
				  <div class="swiper-slide">
					  <img src="../assets/img/videolist1.png" />
					  <div>宣传片</div>
				  </div> -->
			   
			    </div>
			    <!-- Add Pagination -->
			    <div class="swiper-pagination"></div>
			  </div>
		</div>
		
		<Footernew/>
	</div>
</template>

<script>
	import Swiper from "swiper"
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import {WOW} from 'wowjs'  //导入wow
	import Comment from '@/components/comment.vue'
	import gotop from '@/components/gotop.vue'
	
	//在需要使用的路由里进行引用   也可以进行全局引用
	import wx from 'weixin-js-sdk'
	export default {
		name: '',
		components: {
		  Headernew,
		  Footernew,
		  Comment,
		  gotop
		},
		data() {
			return {
				wechatnologin:false,//控制微信打开页面时，未登录，显示的遮罩
				videoId:'',//接收视频id
				videomessage:'',//项目信息
				videopassword:'',//观看码
				navshow:true,//控制顶部导航栏显示和隐藏
				screenWidth: document.body.clientWidth,     // 屏幕宽
				lookerror:false,//观看码错误文本显示
				showpassword:true,//观看码显示和隐藏
				videocontent:'',//接收的富文本
				lubomessage:'',//底部轮播信息
				randomnum:'',//
			}
		},
		watch:{
			// 检测屏幕宽度变化
			 screenWidth(val) {
					this.screenWidth = val;
					console.log(this.screenWidth)
					if(this.screenWidth > 320 && this.screenWidth <= 1024){
						this.navshow = false
					}
			   }
			
		},
		created(){
		   var that = this
		  //获取路由参数
		  this.videoId = this.$route.query.id
		  
		  that.getvideodetail()
		
		  //页面一打开就先检测地址栏上有没有random_str，有就存起来
		  that.geturlrandom()
		},

		mounted() {
			const that = this
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
				
				// 将转发放在这里而不是created中，防止页面没加载完就获取不到random_str
				that.share()
			})
			
			//获取轮播信息
			that.getlunbo()
			// 根据屏幕宽度，控制底部轮播图样式
			// this.changelunbo()
			setTimeout(function(){
				that.changelunbo()
			},1000)
			
			// 监控页面滚动
			// window.addEventListener('scroll',this.handleScroll)
			
			// <!--把window.onresize事件挂在到mounted函数上-->
			// 这里是用来获取屏幕宽度的，当时移动端的时候，就隐藏顶部导航
            window.onresize= () => {
                return (() => {
                    window.screenWidth = document.body.clientWidth;
                    that.screenWidth = window.screenWidth;
                })();
            }
			
			// 当页面直接打开的时候，也关闭顶部导航
			that.closenavtop()
			// 当页面直接打开的时候，禁止右键和F12
			// that.prohibitClick()
		},
		methods:{
			geturlrandom:function(){
				var that = this
				var url = window.location.href
				// 只要是判断此时是在微信浏览器打开的这个页面，就执行登录
				 var ua = navigator.userAgent.toLowerCase();
				 var usersmessage = localStorage.getItem('usermessage');
				 // localStorage.setItem('geotourl',url)
				 document.cookie = 'retrunurl' +"="+ url
				  if(ua.match(/MicroMessenger/i) == 'micromessenger' && !usersmessage){
					  // 如果用户没有登录，且是在微信上打开的页面，就显示遮罩
					  this.wechatnologin = true
					  // 移动端用的是公众号的appid，pc端用的是公众平台的appid
					  var appid = 'wx799c2495cc0d2a30'
					  var urlx = 'http%3A%2F%2Fxjaikj.htkjbd.com%2Floginsucess%3Fappid%3Dwx799c2495cc0d2a30'
					  // let redirect_uri = encodeURIComponent(url)
					  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlx}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
					  // 跳转到loginsucess.vue页面，在域名上得到code
				  }else{
					  // 如果用户登录了，就隐藏遮罩
					  this.wechatnologin = false
					  
				  }
				// 页面一打开，先检查一下地址栏上有没有random_str，有的话，就是分享进来的，存在缓存中
				//然后当用户在任意页面登录了，就可以确定上下级关系
				var index = window.location.href.indexOf('random_str')
				var parent_id = ''
				var randomnum
				if(index>=0){
					var urlright = url.split("&random_str=")[1] // 9-10 改动
					var newurl = urlright.split("&parent_id=")[0] // 9-10 改动
					// var newurl = url.substring(index + 10, url.length) // 9-10 改动
					localStorage.setItem('randomstr',newurl) //随机字符串

					if(usersmessage){
						// 如果用户登录了，就向后端提交用户id和random字符串
						// 这是查看的接口
						var userid = JSON.parse(usersmessage).userid
						var randomstr = newurl.split("=")[1]
						var newdata = {
							user_id:userid,
							random_str:randomstr
						}
						 this.$axios.post('https://htkja.htkjbd.com/api/save/brows',newdata)
						.then(function (res) {
							 console.log(res)
						 }).catch(function (err) {
							 console.log(err)
						 })  
						 
						 
						 // 原来转发记录的保存逻辑，放在这了，为了出现浏览记录树  12.1
						 // 随机字符串存在，说明是分享进来的，就不需要再生成随机字符串
						 // 再从url中取到parent_id,作为参数提交给后端
						 // https://xjaikj.htkjbd.com/casecontent?id=47&random_str=103713579827849710&parent_id=28
						 randomnum = urlright.split("&parent_id=")[0]
						 parent_id = urlright.split("&parent_id=")[1]
						 
						 let savaurl = "https://htkja.htkjbd.com/api/saveBrowse2" // 9-10 改动
						 that.$axios.post(savaurl,{
						 	 user_id:userid,
						 	 random_str:randomnum,
						 	 project_id:that.videoId,
						 	 parent_id:parent_id // 9-10 改动
						 })
						 .then((res) => {
						 	console.log(res)			  
						 })
						 .catch((err) => {
						 	console.log(err)
						 })
						 
						 
					}

				}
				
			},
			
			// 获取视频详情
			getvideodetail:function(){
				var that = this
				 //带参数的get请求
				 // let url = "https://htkja.htkjbd.com/api/get/detail/video/" + this.videoId
				 
				 let newurl = this.$api + "/get/detail/video/" + this.videoId
				 this.$axios.get(newurl)
				 .then((res) => {
					 // console.log('我',res)
					 
					 this.videomessage = res.data.result
					 this.videopassword = res.data.result.password
					 this.videocontent = res.data.result.content
					 
				 })
				 .catch((err) => {
					 console.log(err) //错误信息
				 })
			},
			
			// 获取底部轮播图信息
			getlunbo:function(){
				var that = this
				let newurl = this.$api + "/get/randomVideos"
				this.$axios.get(newurl)
				.then((res) => {
					console.log(res.data.result)
					this.lubomessage = 	res.data.result
				})
				.catch((err) => {
					console.log(err) //错误信息
				})
			},
			
			// 微信分享功能
			share:function(){
			        var that=this
					var usersmessage = localStorage.getItem('usermessage');
					var userid = JSON.parse(usersmessage).userid
					var randomnum
					var shareUrl = window.location.href
					var parent_id = '' // 9-10 改动
					var urlright = '' //9-13改动
					
					var index = window.location.href.indexOf('random_str')
					
					// 12.1
					if(index>=0){
						// 随机字符串存在，说明是分享进来的，就不需要再生成随机字符串
						// 再从url中取到parent_id,作为参数提交给后端
						// https://xjaikj.htkjbd.com/casecontent?id=47&random_str=103713579827849710&parent_id=28
						urlright = shareUrl.split("&random_str=")[1]
						randomnum = urlright.split("&parent_id=")[0]
						parent_id = urlright.split("&parent_id=")[1]
					}else{
							//12.1
							//如果地址栏上没有随机字符串，说明不是分享进来的，那么随机生成字符串
							//再生成一个父级id，并存入到url中，传递给下一个人用，注意不要作为参数
							randomnum = JSON.stringify(Math.ceil(Math.random()*125895123628588596))
							
							// 说明不是分享进来的话，那么此时请求保存浏览记录接口，去获取parent_id
							let savaurl = "https://htkja.htkjbd.com/api/saveBrowse2" // 9-10 改动
							that.$axios.post(savaurl,{
								 user_id:userid,
								 random_str:randomnum,
								 project_id:that.videoId
								 //parent_id:parent_id // 9-10 改动
							})
							.then((res) => {
								console.log(res.data.result.id)	
								parent_id = res.data.result.id				  
							})
							.catch((err) => {
								console.log(err)
							})
					
					 
				    }
					

					var newshareurl = shareUrl.split("&random_str=")[0]
					 that.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
					.then(function (res) {
					   wx.config({
						 debug: false,// true开启调试模式,pc端会console.log手机端会alert
						 appId: res.data.result.appId,// 必填，公众号的唯一标识，填自己的！
						 timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
						 nonceStr: res.data.result.nonceStr,   // 必填，生成签名的随机串
						 signature: res.data.result.signature, // 必填，签名，见附录1
						 jsApiList: [
						   'onMenuShareTimeline',
						   'onMenuShareAppMessage'
						 ]
					   })
						   
						   //分享配置
						   wx.ready(function () {
							   //分享到朋友圈
							   wx.onMenuShareTimeline({
								 title: that.videomessage.main,
								 link: newshareurl + "&random_str=" + randomnum + "&parent_id=" + parent_id, // 9-10 改动
								 imgUrl: that.videomessage.simg,
								 success: function () {
									 
								 },
								 cancel: function () {
								   // alert("取消分享");
								 }
							   });
							   //分享给朋友
							   wx.onMenuShareAppMessage({
								 title: that.videomessage.main,
								 desc: that.videomessage.main, 
								 link: newshareurl + "&random_str=" + randomnum + "&parent_id=" + parent_id, // 9-10 改动
								 imgUrl: that.videomessage.simg,
								 success: function () {
						
								 },
								 cancel: function () {
								   // alert("取消分享");
								 }
							   });
						    })
								
					   }).catch(function (err) {
										// alert(err)
						//        console.log(err)
					   })  
					
						

			    },
			
			
			
			// 禁止右键和F12
			prohibitClick:function(){
				
				//禁用右键
				document.oncontextmenu = function () { return false; };
				//禁用开发者工具F12
				  document.onkeydown = document.onkeyup = document.onkeypress = function(event) {
					let e = event || window.event || arguments.callee.caller.arguments[0];
					if (e && e.keyCode == 123) {
					  e.returnValue = false;
					  return false;
					}
				  }
			},
			
			// 根据屏幕宽度，控制底部轮播图样式
			changelunbo:function(){
				var that = this
				
				// 每次进入页面，都默认让页面滚动到顶部
				if(document.documentElement&&document.documentElement.scrollTop){  
						document.documentElement.scrollTop = 0
				}else if(document.body){  
					document.body.scrollTop = 0
				}  
				
				
				if(this.screenWidth > 320 && this.screenWidth < 768){
					var swiper = new Swiper('.swiper-container', {
						  loop: true,
						  pagination: {
							el: '.swiper-pagination',
							clickable: true,
						  },
						  autoplay:true, 
						})
						
				}else {
					var swiper = new Swiper('.swiper-container', {
						  slidesPerView: 3,
						  spaceBetween: 30,
						  centeredSlides: true,
						  loop: true,
						  pagination: {
							el: '.swiper-pagination',
							clickable: true,
						  },
						  autoplay:true, 
						})
				}
				
			},
			
			
			// 控制顶部导航栏显示和隐藏的
			closenavtop:function(){
				if(this.screenWidth > 320 && this.screenWidth <= 1024){
					this.navshow = false
				}
			},
			
			 handleScroll:function(){
			 	var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				
				if(scrollTop>=2600 && this.screenWidth>414){
					// 执行合作流程位置动画
					$('.englishtext-center').each(function(){
						$(this).animate({
							left: 20+'%',
							easing:"linear"
						}, 1000);
					})
					$('.service-process-box').each(function(){
						$(this).animate({
							right: 5+'%',
							easing:"linear"
						}, 1000);
					})
					
				}
				
				if(scrollTop>=3100 && this.screenWidth>414){
					// 执行我们的优势位置动画
					$('.advantagetext-center').each(function(){
						$(this).animate({
							top:0,
							easing:"linear"
						}, 1000);
					})
				}
			 },
			 
			 // 发送验证码请求
			 requestpassword:function(){
				 var passnum = this.$refs.getValue.value
				 
				 if(this.videopassword == passnum){
					 this.showpassword = false
				 }else{
					 this.lookerror = true
				 }
				 
				 //带参数的get请求
				 // let url = "https://lubanhui.xjaikj.com/api/test2"
				 // this.$axios.get(url, {
				 //     params: {
				 //         number: passnum
				 //     }
				 // })
				 // .then((res) => {
					//  if(res.data.success == true){
					// 	 this.showpassword = false
					//  }else if(res.data.success == false){
					// 	 this.lookerror = true
					//  }
				 // })
				 // .catch((err) => {
				 //     console.log(err)
				 // })
			 },
			 
			 // 观看码input输入事件
			 changeinput:function(){
				 this.lookerror = false
			 },

			 
		}
	}
</script>

<style scoped="scoped">
	ul,li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	body{
		min-width: 1200px;
	}
	video::-webkit-media-controls-enclosure {overflow:hidden;}
	video::-webkit-media-controls-panel {width: calc(100% + 30px);}
	video{
	object-fit:fill;
	}
	/* 评论组件 */
	.commentWrap{
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 100;
	 }
	.casebannertop{
		width: 100%;
		height: 100vh;
		background-color: #fafafa;
	}
	/* 微信中打开，没有登录的话，显示的遮罩 */
	.wechatnologin{
		width: 100vw;
		height: 100vh;
		background-color: #fff;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.gotologBtn{
		width: 130px;
		height: 40px;
		color: #fff;
		line-height: 40px;
		background-color: #007400;
		margin-top: 20px;
		border-radius: 5px;
	}
	
	.casebanner-center,.shoujicasebanner-center{
		width: 100%;
		height: 800px;
		background-image: url(../assets/img/detailbg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}
	.casebanner-centertitle{
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 35px;
		font-weight: bold;
		line-height: 70px;
		position: absolute;
		top: 110px;
		left: 0;
		letter-spacing: 2px;
	}
	.casebanner-img,.shoujicasebanner-img{
		position: absolute;
		width: 75%;
		height: 85%;
		bottom: -12%;
		left: 9%;
	}
	/* 观看码区 */
	.password{
		/* width: 1100px;
		height: 615px;
		position: absolute;
		left: 170px;
		top: 10px;
		border-radius: 20px;
		z-index: 7; */
		
		width: 80%;
		height: 92.2%;
		position: absolute;
		left: 15.1%;
		top: 7%;
		border-radius: 20px;
		z-index: 7;
		background-color: rgba(0,0,0,0.5);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.password-center{
		display: flex;
		justify-content: center;
	}
	.password-centertop{
		width: 160px;
		height: 70px;
		text-align: center;
	}
	.password-centertop input{
		width: 140px;
		height: 35px;
		padding-left: 10px;
		box-sizing: border-box;
		border: 0;
		outline: 0;
	}
	.password-centertop div{
		font-size: 14px;
		line-height: 30px;
		color: red;
	}
	.password-bottom{
		width: 80px;
		height: 35px;
		background-color: #DACFCF;
		color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		letter-spacing: 2px;
	}
	.password-bottom:hover{
		cursor: pointer;
	}
	/* 观看码区结束 */
	.vrdetail{
		width: 70%;
		height: 700px;
		margin: 30px auto 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	#vrdetailif{
		width: 100%;
		height: 700px;
	}
	.videopcimg{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 5%;
		top: 5%;
		z-index: 5;
	}
	.topvideoheng{
		width: 80%;
		height: 92.2%;
		position: absolute;
		left: 15.1%;
		top: 7%;
		border-radius: 20px;
		z-index: 6;
	}
	/* 手机和pad端新样式 */
	.shoujicasebanner-center{
		width: 100%;
		height: 850px;
		background-image: url(../assets/img/detailbg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}
	.shoujicasebanner-img{
		position: absolute;
		width: 95%;
		height: auto;
		top: 200px;
		left: 2%;
		border-radius: 0px;
		margin-top: 0px;
	}
	.shoujipassword{
		width: 310px;
		height: 550px;
		position: absolute;
		left: 41.5%;
		top: 6%;
		z-index: 6;
		border-radius: 10px;
		z-index: 7;
		background-color: rgba(0,0,0,0.3);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.videoshoujiimg{
		width: 335px;
		height: 600px;
		position: absolute;
		left: 40.8%;
		top: 0%;
		border-radius: 20px;
		z-index: 6;
	}
	.topvideoshu{
		width: 310px;
		height: 550px;
		position: absolute;
		left: 41.5%;
		top: 4.8%;
		z-index: 6;
		border-radius: 10px;
	}
	
	
	/* 公司介绍区 */
	.companymessage{
		width: 75%;
		height: 300px;
		margin: 20px auto 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.companymessage-left{
		width: 70%;
	}
	.companymessage-top{
		width: 400px;
		text-align: left;
		border-bottom: #f4f4f4 1px solid;
		padding-bottom: 10px;
	}
	.companymessage-top div:nth-of-type(1){
		font-size: 20px;
		color: #545454;
		line-height: 34px;
		font-weight: bold;
	}
	.companymessage-top div:nth-of-type(2){
		font-size: 14px;
		color: #535353;
	}
	.companymessage-bottom{
		width: 600px;
		text-align: left;
		border-bottom: #f4f4f4 1px solid;
		padding-bottom: 10px;
		margin-top: 20px;
	}
	.companymessage-bottom div:nth-of-type(1){
		font-size: 22px;
		color: #545454;
		line-height: 34px;
		font-weight: bold;
	}
	.companymessage-bottom div:nth-of-type(2){
		font-size: 14px;
		color: #535353;
		line-height: 24px;
	}
	.companymessage-right{
		width: 200px;
		
	}
	.ercodeimg{
		width: 180px;
		height: 180px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: #f4f4f4 1px solid;
		border-radius: 15px;
	}
	.ercodeimg img{
		width: 170px;
		height: 170px;
	}
	.ercodetext{
		width: 180px;
		text-align: center;
		font-size: 15px;
		line-height: 35px;
	}
	/* 合作详情区 */
	.detailcontent{
		/* width: 75%; */
		width: 100%;
		margin: 30px auto ;
	}
	.detailcontent-center{
		width: 100%;
	}
	.detailcontent-center img{
		width: 100%;
	}
	/* 精心打造 */
	.englishtext{
		width: 100%;
		height: 160px;
		position: relative;
		margin: 60px auto 0;
	}
	.englishtext-center{
		width: 60%;
		margin: 0 auto;
/* 		position: absolute;
		z-index: 5;
		left: -500px;
		top: 0; */
	}
	.huienglishi{
		width: 100%;
		text-align: center;
		margin-top: 50px;
		height: 70px;
		font-size: 90px;
		line-height: 70px;
		text-transform: uppercase;
		color: #f7f7f7;
		font-weight: bold;
	}
	.englishtext-word{
		width: 100%;
		text-align: center;
	}
	.englishtext-word span:nth-of-type(1){
		display: block;
		font-size: 30px;
		margin-top: -30px;
	}
	.englishtext-word span:nth-of-type(2){
		font-size: 20px;
		color: #888;
		margin-top: 10px;
		line-height: 30px;
	}
	/* 合作流程 */
	.service-show-process{
		width: 75%;
		margin: 30px auto 0;
		height: 350px;
		position: relative;
	}
	.service-process-box {
	    max-width: 1240px;
	    margin: 0 auto;
	    text-align: center;
/* 		position: absolute;
		right: -100px;
		top: 0; */
	}
	.pro-item > div:hover {
	    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}
	.clearfix {
	    zoom: 1;
	}
	.clearfix:after, .clearfix:before {
	    content: "";
	    display: block;
	    height: 0;
	    overflow: hidden;
		visibility: hidden;
		width: 0;
		clear: both;
	}
	.pro-item {
	    float: left;
	    width: 16.66666667%;
	    position: relative;
	}
	.service-show-process .pro-item::before {
	    content: "";
	    display: block;
	    width: 16px;
	    height: 16px;
	    background: #e23433;
	    border: 3px solid #fff;
	    -webkit-border-radius: 50%;
	    -moz-border-radius: 50%;
	    border-radius: 50%;
	    margin: 0 auto;
	    position: absolute;
	    bottom: 115px;
	    left: 0;
	    right: 0;
	    z-index: 2;
	}
	.service-show-process .pro-item::after {
	    content: "";
	    border-top: 1px solid #eae9e9;
	    position: absolute;
	    bottom: 125px;
	    left: 0;
	    right: 0;
		clear: both;
		display: block;
	}
	.service-show-process .pro-item > div {
	    height: 260px;
	    padding: 30px 10px;
		-webkit-transition: all .3s ease-out;
		-moz-transition: all .3s ease-out;
		-ms-transition: all .3s ease-out;
		-o-transition: all .3s ease-out;
	    transition: all .3s ease-out;
	    background-repeat: no-repeat;
	    background-position: 55px 20px;
	}
	.pro-item img{
		vertical-align: middle;
		display: inline-block;
	}
	.service-show-process .pro-item em {
	    display: block;
	    font-size: 20px;
	    margin-top: 10px;
		font-style: normal;
	}
	.work-box {
	    margin-top: 30px;
	    color: #888;
	    line-height: 25px;
	    width: 80%;
	    text-align: left;
	    display: inline-block;
	}
	/* 我们的优势标题 */
	.advantagetext{
		width: 100%;
		height: 160px;
		position: relative;
	}
	.advantagetext-center{
		width: 60%;
		margin: 0 auto;
/* 		position: absolute;
		top: -100px;
		left: 20%;
		z-index: 5; */
	}
	.advantagelishi{
		width: 100%;
		text-align: center;
		margin-top: 20px;
		height: 50px;
		font-size: 90px;
		line-height: 70px;
		text-transform: uppercase;
		color: #f7f7f7;
		font-weight: bold;
	}
	.advantagetext-word{
		width: 100%;
		text-align: center;
	}
	.advantagetext-word span:nth-of-type(1){
		display: block;
		font-size: 30px;
		margin-top: -30px;
	}
	.advantagetext-word span:nth-of-type(2){
		font-size: 20px;
		color: #888;
		margin-top: 10px;
		line-height: 30px;
	}
	/* 我们的优势 */
	.advantage-wrap{
		width: 100%;
		margin: 30px auto 0;
		height: 800px;
		background-image: url(../assets/img/bolan.jpg);
	}
	.advantage-center{
		width: 65%;
		height: 520px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}
	.advantage-left{
		width: 32%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.dvantage-list{
		width: 100%;
		height: 250px;
		background-color: #fff;
		border-radius: 15px;
	}
	.dvantage-listtop{
		width: 92%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		padding-top: 20px;
		box-sizing: border-box;
	}
	.dvantage-listtop img{
		width: 40px;
		height: 40px;
	}
	.dvantage-listtoptitle{
		margin-left: 20px;
	}
	.dvantage-listtoptitle div:nth-of-type(1){
		font-size: 18px;
		line-height: 25px;
	}
	.dvantage-listtoptitle div:nth-of-type(2){
		font-size: 14px;
		line-height: 20px;
		color: #cccfda;
	}
	.dvantage-listmiddle{
		margin-top: 10px;
		font-size: 16px;
		line-height: 40px;
		color: #01aeec;
		font-weight: bold;
	}
	.dvantage-listbottom{
		width: 92%;
		margin: 0 auto;
		text-align: left;
		font-size: 14px;
		line-height: 20px;
		color: #999999;
	}
	
	
	.advantage-middle{
		width: 25%;
		height: 520px;
		background-color: green;
		border-radius: 10px;
		background-image: url(../assets/img/casemiddle.jpg);
		background-size: 100% 100%;
	}
	.advantage-right{
		width: 32%;
		height: 520px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	
	/* 更多案例区 */
	.morecase-title{
		width: 75%;
		margin: 60px auto 0;
		text-align: left;
		font-size: 20px;
		color: #545454;
		line-height: 34px;
		font-weight: bold;
		border-bottom: #f4f4f4 1px solid;
		padding-bottom: 10px;
	}
	.morecase-wrap{
		width: 80%;
		height: 280px;
		margin: 30px auto 40px;
		padding-bottom: 50px;
	}
	 .swiper-container {
	      width: 100%;
	      height: 100%;
	   }
	.swiper-slide {
	  text-align: center;
	  font-size: 18px;
	  position: relative;
	  overflow: hidden;
	  
	  /* Center slide text vertically */
	  transition: 300ms;
	  transform: scale(0.8);
	}
	.swiper-slide-active,.swiper-slide-duplicate-active{
	  transform: scale(1);
	}
	.swiper-slide div:nth-of-type(1){
		width: 100%;
		height: 230px;
		overflow: hidden;
	}
	.swiper-slide img{
		width: 100%;
		height: auto;
		/* position: absolute;
		left: 0;
		top: 0; */
		z-index: 1;
		vertical-align: bottom;
	}
	.swiper-slide div:nth-of-type(2){
		width: 100%;
		height: 50px;
		/* background: rgba(0,0,0,0.5); */
		background-color: #000000;
		z-index: 2;
		color: #fff;
		font-size: 17px;
		line-height: 50px;
		letter-spacing: 2px;
	}
	
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
	
	/* @media (min-width: 320px){ */
		.casebannertop{
			width: 100%;
			height: auto;
		}
		.casebanner-center{
			width: 100%;
			height: 300px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}
		.casebanner-centertitle{
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			line-height: 30px;
			position: absolute;
			top: 30px;
			left: 0;
			letter-spacing: 2px;
		}
		.casebanner-img{
			position: absolute;
			width: 95%;
			height: 220px;
			top: 70px;
			left: 2%;
			border-radius: 10px;
			margin-top: 20px;
		}
		
		/* 观看码区 */
		.password{
			width: 78%;
			height: 73%;
			position: absolute;
			left: 12%;
			top: 0;
			z-index: 7;
			background-color: rgba(0,0,0,0.3);
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
		}
		.password-center{
			display: flex;
			justify-content: center;
		}
		.password-centertop{
			width: 120px;
			height: 50px;
			text-align: center;
		}
		.password-centertop input{
			width: 100px;
			height: 30px;
			padding-left: 5px;
			box-sizing: border-box;
			border: 0;
			outline: 0;
			font-size: 12px;
		}
		.password-centertop div{
			font-size: 12px;
			line-height: 24px;
			color: red;
		}
		.password-bottom{
			width: 60px;
			height: 30px;
			background-color: #DACFCF;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			font-size: 14px;
			letter-spacing: 2px;
		}
		/* 观看码区结束 */
		.vrdetail{
			width: 70%;
			height: 400px;
			margin: 30px auto 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		#vrdetailif{
			width: 100%;
			height: 500px;
		}
		/* 手机和pad端新样式 */
		.shoujicasebanner-center{
			width: 100%;
			height: 480px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
			overflow: hidden;
		}
		.shoujicasebanner-img{
			position: absolute;
			width: 95%;
			height: auto;
			top: 70px;
			left: 2%;
			border-radius: 10px;
			margin-top: 20px;
		}
		.shoujipassword{
			width: 47%;
			height: 65%;
			border-radius: 10px;
			position: absolute;
			left: 26.5%;
			top: 4%;
			z-index: 7;
			background-color: rgba(0,0,0,0.3);
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.videopcimg{
			/* width: 100%;
			height: 100%; */
			width: 99%;
			height: auto;
			position: absolute;
			left: 1.3%;
			top: 0;
			z-index: 5;
		}
		.topvideoheng{
			/* width: 80%;
			height: 90%; */
			width: 78%;
			height: auto;
			border-radius: 5px;
			position: absolute;
			left: 11.8%;
			top: 1.5%;
			z-index: 6;
		}
		.videoshoujiimg{
			width: 50%;
			height: auto;
			position: absolute;
			left: 25%;
			top: 0%;
			border-radius: 20px;
			z-index: 6;
		}
		.topvideoshu{
			width: 45%;
			height: auto;
			position: absolute;
			left: 27.7%;
			top: 4%;
			z-index: 6;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-radius: 0 0 10px 10px;
		}
		
		/* 公司介绍区 */
		.companymessage{
			width: 90%;
			height: 250px;
			margin: 20px auto 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.companymessage-left{
			width: 100%;
		}
		.companymessage-top{
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}
		.companymessage-top div:nth-of-type(1){
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}
		.companymessage-top div:nth-of-type(2){
			font-size: 12px;
			color: #535353;
		}
		.companymessage-bottom{
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
			margin-top: 20px;
		}
		.companymessage-bottom div:nth-of-type(1){
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}
		.companymessage-bottom div:nth-of-type(2){
			font-size: 12px;
			color: #535353;
			line-height: 24px;
		}
		.companymessage-right{
			display: none;
			width: 200px;
		}

		/* 合作详情区 */
		.detailcontent{
			width: 100%;
			margin: 10px auto ;
		}
		.detailcontent-center{
			width: 100%;
		}
		.detailcontent-center img{
			width: 100%;
		}
		/* 精心打造 */
		.englishtext{
			width: 90%;
			height: 100px;
			position: relative;
			margin: 0px auto 0;
		}
		.englishtext-center{
			width: 100%;
			margin: 0 auto;
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
		}
		.huienglishi{
			width: 100%;
			text-align: center;
			margin-top: 10px;
			height: 70px;
			font-size: 50px;
			line-height: 70px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}
		.englishtext-word{
			width: 100%;
			text-align: center;
		}
		.englishtext-word span:nth-of-type(1){
			display: block;
			font-size: 18px;
			margin-top: -30px;
		}
		.englishtext-word span:nth-of-type(2){
			font-size: 14px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}
		/* 合作流程 */
		.service-show-process{
			width: 90%;
			margin: 20px auto 0;
			height: 320px;
			position: relative;
		}
		.service-process-box {
		    max-width: 1240px;
		    margin: 0 auto;
		    text-align: center;
			position: absolute;
			right: 0;
			top: 0;
		}
		.pro-item > div:hover {
		    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}
		.clearfix {
		    zoom: 1;
		}
		.clearfix:after, .clearfix:before {
		    content: "";
		    display: block;
		    height: 0;
		    overflow: hidden;
			visibility: hidden;
			width: 0;
			clear: both;
		}
		.pro-item {
		    float: left;
		    width: 100%;
		    position: relative;
			display: flex;
			justify-content: center;
		}
		.service-show-process .pro-item::before {
		    content: "";
		    display: none;
		    width: 0;
		    height: 0;
		    background: #e23433;
		    border: 3px solid #fff;
		    -webkit-border-radius: 50%;
		    -moz-border-radius: 50%;
		    border-radius: 50%;
		    margin: 0 auto;
		    position: absolute;
		    bottom: 115px;
		    left: 0;
		    right: 0;
		    z-index: 2;
		}
		.service-show-process .pro-item::after {
		    content: "";
		    border-top: none;
			border-bottom: none;
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
			clear: both;
			display: block;
		}
		.service-show-process .pro-item > div {
			width: 100%;
		    height: 52px;
		    padding: 0;
			-webkit-transition: all .3s ease-out;
			-moz-transition: all .3s ease-out;
			-ms-transition: all .3s ease-out;
			-o-transition: all .3s ease-out;
		    transition: all .3s ease-out;
		    background-repeat: no-repeat;
		    background-position: 55px 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
		}
		.pro-item img{
			width: 40px;
			height: 40px;
			vertical-align: middle;
			display: inline-block;
			margin-left: 5px;
		}
		.service-show-process .pro-item em {
		    display: block;
		    font-size: 8px;
		    margin-top: 7px;
			font-style: normal;
		}
		.work-box {
		    margin-top: 0;
		    color: #888;
			font-size: 10px;
		    line-height: 16px;
		    width: 60%;
		    text-align: left;
		    display: inline-block;
		}
		/* 我们的优势标题 */
		.advantagetext{
			width: 100%;
			height: 100px;
			position: relative;
		}
		.advantagetext-center{
			width: 60%;
			margin: 0 auto;
			position: absolute;
			top: 0;
			left: 20%;
			z-index: 5;
		}
		.advantagelishi{
			display: none;
			width: 100%;
			text-align: center;
			margin-top: 20px;
			height: 50px;
			font-size: 40px;
			line-height: 60px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}
		.advantagetext-word{
			width: 100%;
			text-align: center;
		}
		.advantagetext-word span:nth-of-type(1){
			display: block;
			font-size: 20px;
			margin-top: 20px;
		}
		.advantagetext-word span:nth-of-type(2){
			font-size: 16px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}
		/* 我们的优势 */
		.advantage-wrap{
			width: 100%;
			margin: 30px auto 0;
			height: 950px;
			background-image: url(../assets/img/bolan.jpg);
		}
		.advantage-center{
			width: 90%;
			height: 520px;
			margin: 0 auto;
			display: block;
		}
		.advantage-left{
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.dvantage-list{
			width: 100%;
			height: auto;
			background-color: #fff;
			border-radius: 15px;
			margin-bottom: 15px;
		}
		.dvantage-listtop{
			width: 92%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 20px;
			box-sizing: border-box;
		}
		.dvantage-listtop img{
			width: 30px;
			height: 30px;
		}
		.dvantage-listtoptitle{
			margin-left: 15px;
		}
		.dvantage-listtoptitle div:nth-of-type(1){
			font-size: 14px;
			line-height: 20px;
		}
		.dvantage-listtoptitle div:nth-of-type(2){
			font-size: 12px;
			line-height: 18px;
			color: #cccfda;
		}
		.dvantage-listmiddle{
			margin-top: 8px;
			font-size: 14px;
			line-height: 30px;
			color: #01aeec;
			font-weight: bold;
		}
		.dvantage-listbottom{
			width: 92%;
			margin: 0 auto;
			text-align: left;
			font-size: 12px;
			line-height: 18px;
			color: #999999;
			padding-bottom: 15px;
		}
		
		
		.advantage-middle{
			display: none;
			width: 25%;
			height: 520px;
			background-color: green;
			border-radius: 10px;
			background-image: url(../assets/img/casemiddle.jpg);
			background-size: 100% 100%;
		}
		.advantage-right{
			width: 100%;
			height: 400px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		
		/* 更多案例区 */
		.morecase-title{
			width: 90%;
			margin: 20px auto 0;
			text-align: left;
			font-size: 16px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}
		.morecase-wrap{
			width: 90%;
			height: 250px;
			margin: 30px auto 10px;
			padding-bottom: 20px;
		}
		 .swiper-container {
		      width: 100%;
		      height: 100%;
		   }
		.swiper-slide {
		  text-align: center;
		  font-size: 16px;
		  position: relative;
		  overflow: hidden;
		  
		  transition: 300ms;
		  transform: scale(0.8);
		}
		.swiper-slide-active,.swiper-slide-duplicate-active{
		  /* transform: scale(1); */
		}
		.swiper-slide div:nth-of-type(1){
			width: 100%;
			height: 210px;
			overflow: hidden;
		}
		.swiper-slide img{
			width: 100%;
			height: auto;
			z-index: 1;
		}
		.swiper-slide div{
			width: 100%;
			height: 40px;
			/* background: rgba(0,0,0,0.5); */
			background-color: #000000;
			z-index: 2;
			color: #fff;
			font-size: 15px;
			line-height: 40px;
			letter-spacing: 2px;
		}
	}
	
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
	
	/* @media (min-width: 320px){ */
		.casebannertop{
			width: 100%;
			height: auto;
		}
		.casebanner-center{
			width: 100%;
			height: 480px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}
		.casebanner-centertitle{
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 19px;
			font-weight: bold;
			line-height: 30px;
			position: absolute;
			top: 60px;
			left: 0;
			letter-spacing: 2px;
		}
		
		/* 观看码区 */
		.password{
			width: 78%;
			height: 310px;
			border-radius: 5px;
			position: absolute;
			left: 11%;
			top: 2.4%;
			z-index: 7;
			background-color: rgba(0,0,0,0.3);
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.password-center{
			display: flex;
			justify-content: center;
		}
		.password-centertop{
			width: 120px;
			height: 50px;
			text-align: center;
		}
		.password-centertop input{
			width: 100px;
			height: 30px;
			padding-left: 5px;
			box-sizing: border-box;
			border: 0;
			outline: 0;
			font-size: 12px;
		}
		.password-centertop div{
			font-size: 12px;
			line-height: 24px;
			color: red;
		}
		.password-bottom{
			width: 60px;
			height: 30px;
			background-color: #DACFCF;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			font-size: 14px;
			letter-spacing: 2px;
		}
		/* 观看码区结束 */
		/* 手机和pad端新样式 */
		.casebanner-img{
			position: absolute;
			width: 90%;
			height: 350px;
			top: 110px;
			left: 5%;
			border-radius: 0;
			z-index: 2;
		}
		.shoujicasebanner-center{
			width: 100%;
			height: 550px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}
		.shoujicasebanner-img{
			position: absolute;
			width: 95%;
			height: auto;
			top: 120px;
			left: 2%;
			border-radius: 0px;
			margin-top: 0px;
		}
		.shoujipassword{
			width: 202px;
			height: 360px;
			border-radius: 10px;
			position: absolute;
			left: 36.5%;
			top: 4%;
			z-index: 7;
			background-color: rgba(0,0,0,0.3);
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.videopcimg{
			width: 690px;
			height: 340px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
		}
		.topvideoheng{
			width: 540px;
			height: 303px;
			border-radius: 5px;
			position: absolute;
			left: 11%;
			top: 2.4%;
			z-index: 6;
		}
		.videoshoujiimg{
			width: 220px;
			height: 388px;
			position: absolute;
			left: 35.3%;
			top: 0%;
			border-radius: 20px;
			z-index: 6;
		}
		.topvideoshu{
			width: 202px;
			height: 360px;
			position: absolute;
			left: 36.5%;
			top: 4%;
			z-index: 6;
			border-radius: 10px;
		}
		
		/* 公司介绍区 */
		.companymessage{
			width: 90%;
			height: 250px;
			margin: 20px auto 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.companymessage-left{
			width: 100%;
		}
		.companymessage-top{
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}
		.companymessage-top div:nth-of-type(1){
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}
		.companymessage-top div:nth-of-type(2){
			font-size: 12px;
			color: #535353;
		}
		.companymessage-bottom{
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
			margin-top: 20px;
		}
		.companymessage-bottom div:nth-of-type(1){
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}
		.companymessage-bottom div:nth-of-type(2){
			font-size: 12px;
			color: #535353;
			line-height: 24px;
		}
		.companymessage-right{
			display: none;
			width: 200px;
		}
	
		/* 合作详情区 */
		.detailcontent{
			width: 100%;
			margin: 10px auto ;
		}
		.detailcontent-center{
			width: 100%;
		}
		.detailcontent-center img{
			width: 100%;
		}
		/* 精心打造 */
		.englishtext{
			width: 90%;
			height: 100px;
			position: relative;
			margin: 0 auto;
		}
		.englishtext-center{
			width: 100%;
			margin: 0 auto;
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
		}
		.huienglishi{
			width: 100%;
			text-align: center;
			margin-top: 10px;
			height: 70px;
			font-size: 50px;
			line-height: 70px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}
		.englishtext-word{
			width: 100%;
			text-align: center;
		}
		.englishtext-word span:nth-of-type(1){
			display: block;
			font-size: 18px;
			margin-top: -30px;
		}
		.englishtext-word span:nth-of-type(2){
			font-size: 14px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}
		/* 合作流程 */
		.service-show-process{
			width: 90%;
			margin: 20px auto 0;
			height: 300px;
			position: relative;
		}
		.service-process-box {
		    max-width: 1240px;
		    margin: 0 auto;
		    text-align: center;
			position: absolute;
			right: 0;
			top: 0;
		}
		.pro-item > div:hover {
		    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}
		.clearfix {
		    zoom: 1;
		}
		.clearfix:after, .clearfix:before {
		    content: "";
		    display: block;
		    height: 0;
		    overflow: hidden;
			visibility: hidden;
			width: 0;
			clear: both;
		}
		.pro-item {
		    float: left;
		    width: 100%;
		    position: relative;
			display: flex;
			justify-content: center;
		}
		.service-show-process .pro-item::before {
		    content: "";
		    display: none;
		    width: 0;
		    height: 0;
		    background: #e23433;
		    border: 3px solid #fff;
		    -webkit-border-radius: 50%;
		    -moz-border-radius: 50%;
		    border-radius: 50%;
		    margin: 0 auto;
		    position: absolute;
		    bottom: 115px;
		    left: 0;
		    right: 0;
		    z-index: 2;
		}
		.service-show-process .pro-item::after {
		    content: "";
		    border-top: none;
			border-bottom: none;
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
			clear: both;
			display: block;
		}
		.service-show-process .pro-item > div {
			width: 100%;
		    height: 52px;
		    padding: 0;
			-webkit-transition: all .3s ease-out;
			-moz-transition: all .3s ease-out;
			-ms-transition: all .3s ease-out;
			-o-transition: all .3s ease-out;
		    transition: all .3s ease-out;
		    background-repeat: no-repeat;
		    background-position: 55px 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
		}
		.pro-item img{
			width: 40px;
			height: 40px;
			vertical-align: middle;
			display: inline-block;
			margin-left: 5px;
		}
		.service-show-process .pro-item em {
		    display: block;
		    font-size: 14px;
		    margin-top: 7px;
			font-style: normal;
		}
		.work-box {
		    margin-top: 0;
		    color: #888;
			font-size: 12px;
		    line-height: 16px;
		    width: 60%;
		    text-align: left;
		    display: inline-block;
		}
		.work-box p{
			font-size: 12px;
			line-height: 16px;
		}
		/* 我们的优势标题 */
		.advantagetext{
			width: 100%;
			height: 100px;
			position: relative;
		}
		.advantagetext-center{
			width: 60%;
			margin: 0 auto;
			position: absolute;
			top: 0;
			left: 20%;
			z-index: 5;
		}
		.advantagelishi{
			display: none;
			width: 100%;
			text-align: center;
			margin-top: 20px;
			height: 50px;
			font-size: 40px;
			line-height: 60px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}
		.advantagetext-word{
			width: 100%;
			text-align: center;
		}
		.advantagetext-word span:nth-of-type(1){
			display: block;
			font-size: 20px;
			margin-top: 20px;
		}
		.advantagetext-word span:nth-of-type(2){
			font-size: 16px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}
		/* 我们的优势 */
		.advantage-wrap{
			width: 100%;
			margin: 30px auto 0;
			height: 680px;
			background-image: url(../assets/img/bolan.jpg);
		}
		.advantage-center{
			width: 90%;
			height: 520px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
		}
		.advantage-left{
			width: 32%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.dvantage-list{
			width: 100%;
			height: 250px;
			background-color: #fff;
			border-radius: 15px;
		}
		.dvantage-listtop{
			width: 92%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 20px;
			box-sizing: border-box;
		}
		.dvantage-listtop img{
			width: 40px;
			height: 40px;
		}
		.dvantage-listtoptitle{
			margin-left: 20px;
		}
		.dvantage-listtoptitle div:nth-of-type(1){
			font-size: 16px;
			line-height: 25px;
		}
		.dvantage-listtoptitle div:nth-of-type(2){
			font-size: 12px;
			line-height: 20px;
			color: #cccfda;
		}
		.dvantage-listmiddle{
			margin-top: 10px;
			font-size: 10px;
			line-height: 40px;
			color: #01aeec;
			font-weight: bold;
		}
		.dvantage-listbottom{
			width: 92%;
			margin: 0 auto;
			text-align: left;
			font-size: 10px;
			line-height: 16px;
			color: #999999;
		}
		
		
		.advantage-middle{
			width: 30%;
			height: 520px;
			background-color: green;
			border-radius: 10px;
			background-image: url(../assets/img/casemiddle.jpg);
			background-size: 100% 100%;
		}
		.advantage-right{
			width: 32%;
			height: 520px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		
		/* 更多案例区 */
		.morecase-title{
			width: 90%;
			margin: 20px auto 0;
			text-align: left;
			font-size: 16px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}
		.morecase-wrap{
			width: 90%;
			height: 140px;
			margin: 30px auto 40px;
			padding-bottom: 20px;
		}
		 .swiper-container {
		      width: 100%;
		      height: 100%;
		   }
		.swiper-slide {
		  text-align: center;
		  font-size: 14px;
		  position: relative;
		  overflow: hidden;
		  
		  transition: 300ms;
		  transform: scale(0.8);
		}
		.swiper-slide-active,.swiper-slide-duplicate-active{
		  transform: scale(1);
		}
		.swiper-slide div:nth-of-type(1){
			width: 100%;
			height: 100px;
			overflow: hidden;
		}
		.swiper-slide img{
			width: 100%;
			height: auto;
			z-index: 1;
			vertical-align: bottom;
		}
		.swiper-slide div{
			width: 100%;
			height: 40px;
			background: rgba(0,0,0,0.5);
			z-index: 2;
			color: #fff;
			font-size: 14px;
			line-height: 40px;
			letter-spacing: 2px;
		}
	}
	
	/* 老板仓定制机的样式 */
	@media (min-width: 1025px) and (max-width: 1300px){
		   .casebannertop{
				width: 100%;
				height: auto;
				background-color: #fafafa;
			}
			/* 微信中打开，没有登录的话，显示的遮罩 */
			.wechatnologin{
				width: 100vw;
				height: 500px;
				background-color: #fff;
				position: fixed;
				left: 0;
				top: 0;
				z-index: 1000;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
			.gotologBtn{
				width: 130px;
				height: 40px;
				color: #fff;
				line-height: 40px;
				background-color: #007400;
				margin-top: 20px;
				border-radius: 5px;
			}
			.casebanner-center{
				width: 100%;
				height: 550px;
				background-image: url(../assets/img/detailbg.jpg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				position: relative;
			}
			.shoujicasebanner-center{
				width: 100%;
				height: 500px;
				background-image: url(../assets/img/detailbg.jpg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				position: relative;
			}
			.casebanner-centertitle{
				width: 100%;
				text-align: center;
				color: #fff;
				font-size: 22px;
				font-weight: bold;
				line-height: 30px;
				position: absolute;
				top: 100px;
				left: 0;
				letter-spacing: 2px;
			}
			.casebanner-img{
				position: absolute;
				width: 75%;
				height: 75%;
				bottom: 2%;
				left: 9%;
			}
			.shoujicasebanner-img{
				position: absolute;
				width: 75%;
				height: 85%;
				bottom: -12%;
				left: 9%;
			}
			/* 观看码区 */
			.password{
				/* width: 1100px;
				height: 615px;
				position: absolute;
				left: 170px;
				top: 10px;
				border-radius: 20px;
				z-index: 7; */
				
				width: 80%;
				height: 92.2%;
				position: absolute;
				left: 15.1%;
				top: 7%;
				border-radius: 20px;
				z-index: 7;
				background-color: rgba(0,0,0,0.5);
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.password-center{
				display: flex;
				justify-content: center;
			}
			.password-centertop{
				width: 160px;
				height: 70px;
				text-align: center;
			}
			.password-centertop input{
				width: 140px;
				height: 35px;
				padding-left: 10px;
				box-sizing: border-box;
				border: 0;
				outline: 0;
			}
			.password-centertop div{
				font-size: 14px;
				line-height: 30px;
				color: red;
			}
			.password-bottom{
				width: 80px;
				height: 35px;
				background-color: #DACFCF;
				color: #000;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				letter-spacing: 2px;
			}
			.password-bottom:hover{
				cursor: pointer;
			}
			/* 观看码区结束 */
			.vrdetail{
				width: 70%;
				height: 700px;
				margin: 30px auto 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			#vrdetailif{
				width: 100%;
				height: 700px;
			}
			.videopcimg{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 5%;
				top: 5%;
				z-index: 5;
			}
			.topvideoheng{
				width: 80%;
				height: 92.2%;
				position: absolute;
				left: 15.1%;
				top: 7%;
				border-radius: 20px;
				z-index: 6;
			}
			/* 手机和pad端新样式 */
			.shoujicasebanner-center{
				width: 100%;
				height: 650px;
				background-image: url(../assets/img/detailbg.jpg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				position: relative;
			}
			.shoujicasebanner-img{
				position: absolute;
				width: 80%;
				height: auto;
				top: 150px;
				left: 2%;
				border-radius: 0px;
				margin-top: 0px;
			}
			.shoujipassword{
				width: 252px;
				height: 450px;
				position: absolute;
				left: 44.4%;
				top: 4.8%;
				z-index: 6;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				border-bottom-left-radius: 25px;
				border-bottom-right-radius: 25px;
				z-index: 7;
				background-color: rgba(0,0,0,0.3);
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.videoshoujiimg{
				/* width: 335px; */
				width: 280px;
				height: 490px;
				position: absolute;
				left: 42.8%;
				top: 0%;
				border-radius: 20px;
				z-index: 6;
			}
			.topvideoshu{
				width: 252px;
				height: 450px;
				position: absolute;
				left: 44.4%;
				top: 4.8%;
				z-index: 6;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				border-bottom-left-radius: 25px;
				border-bottom-right-radius: 25px;
			}
			
			
			/* 公司介绍区 */
			.companymessage{
				width: 75%;
				height: 300px;
				margin: 20px auto 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.companymessage-left{
				width: 70%;
			}
			.companymessage-top{
				width: 400px;
				text-align: left;
				border-bottom: #f4f4f4 1px solid;
				padding-bottom: 10px;
			}
			.companymessage-top div:nth-of-type(1){
				font-size: 20px;
				color: #545454;
				line-height: 34px;
				font-weight: bold;
			}
			.companymessage-top div:nth-of-type(2){
				font-size: 14px;
				color: #535353;
			}
			.companymessage-bottom{
				width: 600px;
				text-align: left;
				border-bottom: #f4f4f4 1px solid;
				padding-bottom: 10px;
				margin-top: 20px;
			}
			.companymessage-bottom div:nth-of-type(1){
				font-size: 22px;
				color: #545454;
				line-height: 34px;
				font-weight: bold;
			}
			.companymessage-bottom div:nth-of-type(2){
				font-size: 14px;
				color: #535353;
				line-height: 24px;
			}
			.companymessage-right{
				width: 200px;
				
			}
			.ercodeimg{
				width: 180px;
				height: 180px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: #f4f4f4 1px solid;
				border-radius: 15px;
			}
			.ercodeimg img{
				width: 170px;
				height: 170px;
			}
			.ercodetext{
				width: 180px;
				text-align: center;
				font-size: 15px;
				line-height: 35px;
			}
			/* 合作详情区 */
			.detailcontent{
				/* width: 75%; */
				width: 100%;
				margin: 30px auto ;
			}
			.detailcontent-center{
				width: 100%;
			}
			.detailcontent-center img{
				width: 100%;
			}
			/* 精心打造 */
			.englishtext{
				width: 100%;
				height: 110px;
				position: relative;
				margin: 0px auto 0;
			}
			.englishtext-center{
				width: 60%;
				margin: 0 auto;
		/* 		position: absolute;
				z-index: 5;
				left: -500px;
				top: 0; */
			}
			.huienglishi{
				width: 100%;
				text-align: center;
				margin-top: 50px;
				height: 70px;
				font-size: 70px;
				line-height: 70px;
				text-transform: uppercase;
				color: #f7f7f7;
				font-weight: bold;
			}
			.englishtext-word{
				width: 100%;
				text-align: center;
			}
			.englishtext-word span:nth-of-type(1){
				display: block;
				font-size: 25px;
				margin-top: -40px;
			}
			.englishtext-word span:nth-of-type(2){
				font-size: 20px;
				color: #888;
				margin-top: 10px;
				line-height: 30px;
			}
			/* 合作流程 */
			.service-show-process{
				width: 85%;
				margin: 0px auto 0;
				height: 370px;
				position: relative;
			}
			.service-process-box {
			    max-width: 1240px;
			    margin: 0 auto;
			    text-align: center;
		/* 		position: absolute;
				right: -100px;
				top: 0; */
			}
			.pro-item > div:hover {
			    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			}
			.clearfix {
			    zoom: 1;
			}
			.clearfix:after, .clearfix:before {
			    content: "";
			    display: block;
			    height: 0;
			    overflow: hidden;
				visibility: hidden;
				width: 0;
				clear: both;
			}
			.pro-item {
			    float: left;
			    width: 16.66666667%;
			    position: relative;
			}
			.service-show-process .pro-item::before {
			    content: "";
			    display: block;
			    width: 16px;
			    height: 16px;
			    background: #e23433;
			    border: 3px solid #fff;
			    -webkit-border-radius: 50%;
			    -moz-border-radius: 50%;
			    border-radius: 50%;
			    margin: 0 auto;
			    position: absolute;
			    bottom: 125px;
			    left: 0;
			    right: 0;
			    z-index: 2;
			}
			.service-show-process .pro-item::after {
			    content: "";
			    border-top: 1px solid #eae9e9;
			    position: absolute;
			    bottom: 135px;
			    left: 0;
			    right: 0;
				clear: both;
				display: block;
			}
			.service-show-process .pro-item > div {
			    height: 260px;
			    padding: 30px 10px;
				-webkit-transition: all .3s ease-out;
				-moz-transition: all .3s ease-out;
				-ms-transition: all .3s ease-out;
				-o-transition: all .3s ease-out;
			    transition: all .3s ease-out;
			    background-repeat: no-repeat;
			    background-position: 55px 20px;
			}
			.pro-item img{
				vertical-align: middle;
				display: inline-block;
			}
			.service-show-process .pro-item em {
			    display: block;
			    font-size: 16px;
			    margin-top: 10px;
				font-style: normal;
			}
			.work-box {
			    margin-top: 30px;
			    color: #888;
			    line-height: 25px;
			    width: 80%;
			    text-align: left;
			    display: inline-block;
			}
			.work-box p{
				font-size: 14px;
			}
			/* 我们的优势标题 */
			.advantagetext{
				width: 100%;
				height: 120px;
				position: relative;
			}
			.advantagetext-center{
				width: 60%;
				margin: 0 auto;
		/* 		position: absolute;
				top: -100px;
				left: 20%;
				z-index: 5; */
			}
			.advantagelishi{
				width: 100%;
				text-align: center;
				margin-top: 20px;
				height: 50px;
				font-size: 90px;
				line-height: 70px;
				text-transform: uppercase;
				color: #f7f7f7;
				font-weight: bold;
			}
			.advantagetext-word{
				width: 100%;
				text-align: center;
			}
			.advantagetext-word span:nth-of-type(1){
				display: block;
				font-size: 25px;
				line-height: 40px;
				margin-top: -30px;
			}
			.advantagetext-word span:nth-of-type(2){
				font-size: 20px;
				color: #888;
				margin-top: 10px;
				line-height: 30px;
			}
			/* 我们的优势 */
			.advantage-wrap{
				width: 100%;
				margin: 30px auto 0;
				height: 700px;
				background-image: url(../assets/img/bolan.jpg);
			}
			.advantage-center{
				width: 84%;
				height: 520px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
			}
			.advantage-left{
				width: 34%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
			.dvantage-list{
				width: 100%;
				height: 250px;
				background-color: #fff;
				border-radius: 15px;
			}
			.dvantage-listtop{
				width: 92%;
				margin: 0 auto;
				display: flex;
				justify-content: center;
				padding-top: 20px;
				box-sizing: border-box;
			}
			.dvantage-listtop img{
				width: 40px;
				height: 40px;
			}
			.dvantage-listtoptitle{
				margin-left: 20px;
			}
			.dvantage-listtoptitle div:nth-of-type(1){
				font-size: 18px;
				line-height: 25px;
			}
			.dvantage-listtoptitle div:nth-of-type(2){
				font-size: 14px;
				line-height: 20px;
				color: #cccfda;
			}
			.dvantage-listmiddle{
				margin-top: 10px;
				font-size: 16px;
				line-height: 25px;
				color: #01aeec;
				font-weight: bold;
			}
			.dvantage-listbottom{
				width: 92%;
				margin: 0 auto;
				text-align: left;
				font-size: 13px;
				line-height: 20px;
				color: #999999;
			}
			
			
			.advantage-middle{
				width: 29%;
				height: 520px;
				background-color: green;
				border-radius: 10px;
				background-image: url(../assets/img/casemiddle.jpg);
				background-size: 100% 100%;
			}
			.advantage-right{
				width: 34%;
				height: 520px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
			
			/* 更多案例区 */
			.morecase-title{
				width: 90%;
				margin: 40px auto 0;
				text-align: left;
				font-size: 20px;
				color: #545454;
				line-height: 34px;
				font-weight: bold;
				border-bottom: #f4f4f4 1px solid;
				padding-bottom: 10px;
			}
			.morecase-wrap{
				width: 90%;
				height: 200px;
				margin: 30px auto 30px;
				padding-bottom: 50px;
			}
			 .swiper-container {
			      width: 100%;
			      height: 100%;
			   }
			.swiper-slide {
			  text-align: center;
			  font-size: 18px;
			  position: relative;
			  overflow: hidden;
			  
			  /* Center slide text vertically */
			  transition: 300ms;
			  transform: scale(0.8);
			}
			.swiper-slide-active,.swiper-slide-duplicate-active{
			  transform: scale(1);
			}
			.swiper-slide div:nth-of-type(1){
				width: 100%;
				height: 148px;
				overflow: hidden;
			}
			.swiper-slide img{
				width: 100%;
				height: auto;
				/* position: absolute;
				left: 0;
				top: 0; */
				z-index: 1;
				vertical-align: bottom;
			}
			.swiper-slide div:nth-of-type(2){
				width: 100%;
				height: 50px;
				/* background: rgba(0,0,0,0.5); */
				background-color: #000000;
				z-index: 2;
				color: #fff;
				font-size: 17px;
				line-height: 50px;
				letter-spacing: 2px;
			}
	}
</style>
