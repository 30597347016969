<template>
	<div class="all-wrap">
		<div id="map-container" style="width:60%;height:500px;"></div>
	</div>
</template>

<script>
	import loadBMap from '../quote/loadBMap.js'
	export default {
		name: '',
		data() {
			return {
				 form: { 
					 address: '', //详细地址 
					 addrPoint: { //详细地址经纬度 
						 lng: 0, 
						 lat: 0 
					 } }, 
					 map: '', //地图实例 
					 mk: '' //Marker实例
			}	
		},
		components: {
		},
		async mounted(){
			//这里运用async/await 进行异步处理，保证BMap加载进来后才执行后面的操作 
			await loadBMap('6u9kGeBlGCBo0XomI0u7pVPbFihEioPD') //加载引入BMap
			this.initMap()
		},
		methods: { 
			initMap() { 
				var that = this; 
				this.map = new BMap.Map("map-container", {enableMapClick:false}) 
				//新建地图实例，enableMapClick:false ：禁用地图默认点击弹框 
				var point = new BMap.Point(115.509543,38.907392); 
				this.map.centerAndZoom(point,19)
				// 创建点标记
				var marker1 = new BMap.Marker(new BMap.Point(115.509543,38.907392));
				// 在地图上添加点标记
				this.map.addOverlay(marker1);
				// 创建缩放
				this.map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
				var scaleCtrl = new BMap.ScaleControl();  // 添加比例尺控件
				this.map.addControl(scaleCtrl);
			},

		}, 
	}
</script>

<style scoped>

</style>
