<template>
	<div class="camerwrap">

		<div class="signwrap">
			<div class="signtop">
				<div class="signtopleft">
					<img src="../../assets/img/logo2.png" />
				</div>
				<router-link to="/" class="signtopright">https://xjaikj.htkjbd.com/</router-link>
			</div>
			
			<div class="signcenter">
				<div class="signtitle">配音文稿确认单</div>
			    <ul class="signul">
			    	<!-- 表头 -->
			    	<li class="lione">
			    		<div class="lionediv1">项目名称</div>
			    		<div class="litwodiv1">{{datamessage.project_name}}</div>
						<div class="lionediv2">项目编号</div>
						<div class="litwodiv2">{{datamessage.number}}</div>
			    	</li>

					<!-- 项目验收 -->
					<li class="lione2 mobilelione">
						<div class="lionediv">文稿确认</div>
						<div class="litwodiv" style="justify-content: center;">
							<el-button :size="btnsize" v-show="!check" class="litcontbutton" type="primary">点击确认</el-button>
							<!-- <el-button :size="btnsize" type="danger" plain disabled v-show="userid">已验收</el-button> -->
							<!-- 已签收印章 -->
							<div class="yanshoudivs" v-show="check">
								<img  class="yiqianshou" src="../../assets/img/queren.png" />
							</div>
						</div>
					</li>
					<!-- 负责人 -->
					<li class="lione">
						<div class="lionediv1">负责人</div>
						<div class="litwodiv1">
							<div class="yanshoudiv" v-if="check">
								<div class="wechart">
									<img :src="datamessage.check_user.headimgurl" />
									<span>{{datamessage.check_user.nickname}}</span>
									<!-- <img src="../../assets/img/weixin.png" />
									<span>张先生</span> -->
								</div>
								<div class="yanshotext">已签收</div>
							</div>
						</div>
						<div class="lionediv2">日期</div>
						<div class="litwodiv2">{{datamessage.date}}</div>
					</li>

				</ul>
				
				<div class="botomdes">
					<div class="botomdesleft">北京后天世纪文化传媒有限公司保定分公司</div>
				</div>
			</div>
		</div>
		
		<div ref="test" class="iframewrap">
			<div class="iframecont" id="index">
				
				<img :src="datamessage.remarks" @click="showimgs">
				
				<!-- <div class="floatright"></div>
				<div class="floatbot"></div> -->
				<!-- <div class="floatdiv" :style="{width: newidth,height:newheight,backgroundImage:`url(${orgBackground})`}"></div> -->
			</div>
		</div>
		
		<!-- 加减符号 -->
		<!-- <div class="icon">
			<div class="iconcenter iconfirst">
				<div class="widthlow" @click="changsmall">-</div>
				<div class="iconnum">宽度 {{newidth}}</div>
				<div class="widthadd" @click="changbig">+</div>
			</div>
			
			<div class="iconcenter">
				<div class="widthlow" @click="changdi">-</div>
				<div class="iconnum">高度 {{newheight}}</div>
				<div class="widthadd" @click="changgao">+</div>
			</div>
		</div> -->

	</div>
</template>

<script>
	import $ from "jquery"
	import wx from 'weixin-js-sdk'
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	//import watermark from '../../quote/plus.js'  //添加水印 全页面加
	import tool from '../../quote/marktwo.js'  //添加水印  局部位置
	//import zooms from '../../quote/zoom.js'  //移动端手势缩放插件
	import Hammer from 'hammerjs'//引用hammerjs
	import {zoomElement} from '../../quote/zoom.js'
	
	export default {
	  name: 'camerdetail',
	  components: {
	    Headernew,
	    Footernew,
	  },
	  data() {
		return {
			user_id:'',//用户id
			check:'',//用户是否签收过
			uid:'',//方案id
			signend:false,//控制签收头像，签收图片的显示
			datamessage:'',//签收单数据
			btnsize:'medium',//按钮尺寸
			screenWidth: document.body.clientWidth, //屏幕宽

			newidth:'100%',
			newheight:'100%',
			oldwidth: 100,
			oldheight: 100,
			currentScale:1,//是放大还是缩小，大于1是放大，小于1是缩小

			srcList: [], //底部图片的路径，用来做预览
		}
	  },
	  mounted() {
		  var that = this
		  this.uid =this.$route.query.id
		  
		  //watermark.set('后天科技');
		  //局部添加水印
		  localStorage.setItem("loginUserName", "后天科技");
		  this.orgBackground = tool.addWaterMark();
		  
		  // 监控手指缩放事件
		  // this.$nextTick(() => {
			 //  document.addEventListener("touchstart", function(event) {
				//   that.zommfu()
			 //  })
		  // })
		  
		  // 这里是用来获取屏幕宽度的
		  window.onresize= () => {
		      return (() => {
		          window.screenWidth = document.body.clientWidth;
		          that.screenWidth = window.screenWidth;
		      })();
		  }
		  
		  this.getdata() //获取数据
		  //this.share() //微信转发分享，放在这里有异步问题
	  },
	  methods:{
		  // 点击图片出现预览
		  showimgs:function(){
			   this.$imagePreview({
					images: this.srcList,
					index: index,
			   })
		  },
		  
		  getdata:function(){
			  var that = this
			  // 确认按钮的尺寸
			  if(this.screenWidth > 300 && this.screenWidth < 800){
				  this.btnsize = 'mini'
			  }else{
				  this.btnsize = 'medium'
			  }

			  // 从地址上获取项目userid和checkid
			  // https://xjaikj.htkjbd.com/printdub?userid=5&checkid=39
			  let checkid = this.$route.query.checkid
			  let userid = this.$route.query.userid
			  console.log(checkid,userid)
			  
			  //获取确认单数据
			  let uid =this.$route.query.id
			  let url = this.$api + "/getAudioById/" + checkid
			  this.$axios.get(url,{
					params:{
						user_id:userid
					}
			  })
			  .then((res) => {
				  console.log(res)
				  that.datamessage = res.data.result
				  that.check = res.data.result.on_check
				  that.userid = res.data.result.user_id
				  that.srcList[0] = res.data.result.remarks
				  
				  // 微信转发分享
				  // 这里是为了避免异步问题，所以将微信转发分享放在这个位置上
				  that.share(res.data.result.project_name)
			  })
			  .catch((err) => {
			  	console.log(err)
			  })
			  
		  },
		  // 确认签收
		  suresign:function(){
			  this.windowshow = false
			  this.signend = true
			  
			  var that = this
			  var usersmessage = localStorage.getItem('usermessage');
			  var userid = JSON.parse(usersmessage).userid 
			  let url = this.$api + "/saveAudio/" + that.uid
			  this.$axios.post(url,{
				user_id:userid
			  })
			  .then((res) => {
				  console.log(res)
				  that.check = true
				  that.datamessage.check_user = res.data.result
				  // console.log(that.datamessage)
				  // that.userid = res.data.result.user_id
			  })
			  .catch((err) => {
				console.log(err)
			  })
		  },
		  // 微信分享功能
		  share:function(newname){
		        var that=this
				let uid = this.$route.query.checkid
				let userid = this.$route.query.userid
				// var name = that.datamessage.project_name
		  	 // console.log(that.uid)
		  		var shareUrl = window.location.href
		  		this.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
		  		.then(function (res) {
		  		   wx.config({
		  			 debug: false,// true开启调试模式,pc端会console.log手机端会alert
		  			 appId: res.data.result.appId,// 必填，公众号的唯一标识，填自己的！
		  			 timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
		  			 nonceStr: res.data.result.nonceStr,   // 必填，生成签名的随机串
		  			 signature: res.data.result.signature, // 必填，签名，见附录1
		  			 jsApiList: [
		  			   'onMenuShareTimeline',
		  			   'onMenuShareAppMessage'
		  			 ]
		  		   })
		  		
		  		   //分享配置
		  		   wx.ready(function () {
		  			   //分享到朋友圈
		  			   wx.onMenuShareTimeline({
		  				 // title: that.datamessage.content+'验收单',
		  				 title: '配音文稿确认单打印单',
		  				 link: 'https://xjaikj.htkjbd.com/printdub?userid=' + userid + '&checkid=' + uid,
		  				 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
		  				 success: function () {
		  					  
		  				 },
		  				 cancel: function () {
		  				   // alert("取消分享");
		  				 }
		  			   });
		  			   //分享给朋友
		  			   wx.onMenuShareAppMessage({
		  				 // title: that.datamessage.content+'验收单',
		  				 title: '配音文稿确认单打印单',
		  				 desc: newname, 
		  				 link: 'https://xjaikj.htkjbd.com/printdub?userid=' + userid + '&checkid=' + uid,
		  				 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
		  				 success: function () {
		  					 // alert(newname)
		  				 },
		  				 cancel: function (err) {
		  				   // alert("取消分享");
		  				   alert(err)
		  				 }
		  			   });
		  		   })
		  		
		  		}).catch(function (err) {
						// alert(err)
						// console.log(err)
		  		})  
		  		
		   },
		   
		   // 缩放宽度
		   changbig:function(){
			  this.oldwidth = this.oldwidth + 30
			  this.newidth = this.oldwidth + '%'
		   },
		   changsmall:function(){
			  if(this.oldwidth>100){
				  this.oldwidth = this.oldwidth - 30
				  this.newidth = this.oldwidth + '%'
				  // 宽度缩小的时候，如果处于放大状态，就一直缩小
				  if(that.currentScale>1){
					 normalel.style.transform="scale(0.3)"
					 that.currentScale = that.currentScale - 0.7
				  }
			  }
		   },
		   // 缩放高度
		   changgao:function(){
			  this.oldheight = this.oldheight + 30
			  this.newheight = this.oldheight + '%'
		   },
		   changdi:function(){
			  if(this.oldheight>100){
				 this.oldheight = this.oldheight - 30
				 this.newheight = this.oldheight + '%'
				 
				 // 高度缩小的时候，如果处于放大状态，就一直缩小
				 if(that.currentScale>1){
					 normalel.style.transform="scale(0.3)"
					 that.currentScale = that.currentScale - 0.7
				 }
			  }
		   },
		   // 手指缩放
		   zommfu:function(){
			  var that = this
			  let zoomEl = this.$refs.test;
			  let normalel = this.$refs.normalel;
			  var newidth = this.newidth
			  var newheight = this.newheight
			  // zoomElement(zoomEl,newidth,newheight,normalel)
			  
			  var lastScale = 1;//上次缩放值
			  var currentScale = 1;//当前缩放值
			  var center;//双指中心点
			  
			  //初始化hammer
			  var hammer = new Hammer(zoomEl);
			  //缩放事件默认是关闭的，需要设置启用
			  hammer.get('pinch').set({ enable: true });
			  
			  //监听缩放事件
			  hammer.on("pinchmove pinchstart pinchin pinchout",e => {
			  	//缩放开始时获取上一次缩放值与双指中心点
			      if(e.type == "pinchstart"){
			        lastScale = currentScale || 1;
			  		center = e.center;
			      }
			  	//当前缩放值 = 上一次缩放值 * 缩放比例
			      currentScale = lastScale * e.scale;

			  	// 双指移动的时候，触发缩放
			  	if(e.type == "pinchmove"){
			  		// 如果缩放值小于1了，就是缩小
			  		if (currentScale < 1){
			  			// 缩小
			  			if(parseFloat(newidth)>=100){
			  				normalel.style.width = parseFloat(newidth) - Math.round(currentScale)*30 + '%'
			  				normalel.style.height = parseFloat(newheight) - Math.round(currentScale)*30 + '%'
			  			}
			  			
			  		}else{
			  			// 缩放值大于1了，就是放大
			  			normalel.style.width = parseFloat(newidth) + Math.round(currentScale)*30 + '%'
			  			normalel.style.height = parseFloat(newheight) + Math.round(currentScale)*30 + '%'
			  		}
			  		
			  		normalel.style.transform="scale(" + (currentScale)+ ")"
			  		that.newidth = normalel.style.width
			  		that.newheight = normalel.style.height
			  		that.oldwidth = parseFloat(normalel.style.width)
			  		that.oldheight = parseFloat(normalel.style.height)
			  		that.currentScale = currentScale
			  		
			  	}

				// that.newidth = normalel.style.width
				// that.newheight = normalel.style.height
				// that.oldwidth = parseFloat(normalel.style.width)
				// that.oldheight = parseFloat(normalel.style.height)
				// that.currentScale = currentScale
				

			  });
		   }
	  }
	}
	
</script>

<style scoped="scoped">
	router-link,a{
		text-decoration: none;
	}
	/* #markers{
		position: absolute;
		left: 120px !important;
		top: 100px !important;
	} */
	ul,li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	/* 微信中打开，没有登录的话，显示的遮罩 */
	.wechatnologin{
		width: 100vw;
		height: 100vh;
		background-color: #fff;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.gotologBtn{
		width: 130px;
		height: 40px;
		color: #fff;
		line-height: 40px;
		background-color: #007400;
		margin-top: 20px;
		border-radius: 5px;
	}
	.gotologBtn:hover{
		cursor: pointer;
	}
	/* 微信中 */
	
	.iframewrap{
		width: 1200px;
		height: 1000px;
		margin: 0 auto 50px;
		border: #9A9999 3px solid;
		border-top: 0;
		box-sizing: border-box;
		position: relative;
		
		/* -moz-transform: none !important;
		-webkit-transform: none !important;
		-o-transform: none !important;
		-ms-transform: none !important;
		transform: none !important; */
	}
	.iframecont{
		width: 100%;
		height: 100%;
		margin: 0 !important;
		overflow: auto;
		position: relative;
	}
	.iframecont img{
		width: 100%;
	}
	.floatdiv{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 30;
	}
	iframe {
		width: 100%;
		height: 700px;
		margin: 0 !important;
		border: none !important;
	}
	.signwrap{
		width: 1200px;
		height: auto;
		margin: 80px auto 0;
	}
	/* 右侧和下侧遮挡内层滚动条的div */
	.floatright{
		width: 17px;
		height: 100%;
		background-color: #808080;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 29;
	}
	.floatbot{
		width: 100%;
		height: 55px;
		background-color: #808080;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 29;
	}
	
	/* 水印行的加减号 */
	.icon{
		/* position: absolute;
		right: 10px;
		bottom: 30px;
		z-index: 999; */
		width: 1200px;
		margin: 20px auto 100px;
		display: flex;
		justify-content: flex-end;
	}
	
	.icontop{
		width: 100%;
		margin-bottom: 10px;
	}
	.icontop div{
		font-size: 14px;
	}
	.icon button{
		width: 80px;
		height: 30px;
		margin-left: 10px;
	}
	.iconcenter{
		width: 160px;
		display: flex;
		align-items: center;
		border: #333333 1px solid;
		border-radius: 3px;
		margin-bottom: 15px;
		/* background-color: rgba(252,117,0,0.7); */
		background-color: #409eff;
		color: #fff;
	}
	.iconfirst{
		margin-right: 30px;
	}
	.widthadd{
		width: 40px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		border-left: #333333 1px solid;
	}
	.widthadd:hover{
		cursor: pointer;
	}
	.iconnum{
		width: 90px;
		font-size: 13px;
	}
	.widthlow:hover{
		cursor: pointer;
	}
	.widthlow{
		width: 40px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		border-right: #333333 1px solid;
	}
	
	/* 顶部横线 */
	.signtop{
		width: 100%;
		height: 50px;
		/* border-bottom: #9A9999 1px solid; */
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	.signtopleft img{
		width: 130px;
		height: auto;
	}
	.signtopright{
		font-size: 14px;
		color: #949494;
		line-height: 23px;
	}
	/* 表格 */
	.signcenter{
		width: 100%;
		height: auto;
		margin-top: 20px;
		padding-bottom: 0px !important;
		position: relative;
	}
	.signtitle{
		width: 100%;
		font-size: 26px;
		font-weight: bold;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		border-bottom: none;
		height: 80px;
		line-height: 80px;
	}
	.signul{
		width: 100%;
		margin-top: 0;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		/* border-radius: 5px; */
	}
	
	/* 表格 */
	.signcenter{
		width: 100%;
		height: auto;
		margin-top: 20px;
		padding-bottom: 150px;
		position: relative;
	}
	.signtitle{
		width: 100%;
		font-size: 26px;
		font-weight: bold;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		border-bottom: none;
		height: 80px;
		line-height: 80px;
	}
	.signul{
		width: 100%;
		margin-top: 0;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		/* border-radius: 5px; */
	}
	/* 项目名称 */
	.lione{
		display: flex;
		height: 70px;
		align-items: center;
		/* background-color: #ECEBEB; */
	}
	.lionediv1{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.lionediv2{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-left: #9A9999 3px solid;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.litwodiv1{
		width: 40%;
		height: 100%;
		text-align: center;
		line-height: 25px;
		display: flex;
		align-items: center;
		text-align: left;
		padding: 0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	.litwodiv2{
		width: 30%;
		height: 100%;
		text-align: center;
		line-height: 70px;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	/* 项目情况 */
	.lione2{
		display: flex;
		height: 130px;
		align-items: center;
		border-bottom: #9A9999 3px solid;
		box-sizing: border-box;
	}
	.lione3{
		display: flex;
		height: 100px;
		align-items: center;
	}
	.lionediv{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top: #9A9999 3px solid;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.litwodiv{
		width: 85%;
		height: 100%;
		text-align: center;
		border-top: #9A9999 3px solid;
		line-height: 25px;
		display: flex;
		align-items: center;
		text-align: left;
		padding: 0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	/* 已签收印章 */
	.yanshoudivs{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.yiqianshou{
		width: 260px;
		height: auto;
		/* position: absolute;
		right: 420px;
		bottom: 190px; */
		transform:rotate(-25deg)
	}
	/* 已验收信息 */
	.yanshoudiv{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.wechart{
		display: flex;
		align-items: center;
	}
	.wechart img{
		width: 25px;
		border-radius: 50%;
	}
	.wechart span{
		font-size: 16px;
		font-weight: bold;
		margin-left: 5px;
		color: orange;
	}
	.yanshotext{
		font-size: 16px;
		font-weight: bold;
		margin-left: 10px;
	}
	/* 底部公司名称 */
	.botomdes{
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 0px;
		height: 60px;
		border: #9A9999 3px solid;
		border-top: none;
		box-sizing: border-box;
	}
	.botomdesleft{
		width: 100%;
		font-size: 18px;
		/* font-weight: bold; */
		display: flex;
		justify-content: center;
		align-items: center;
		/* color: #8A8888; */
		letter-spacing: 1px;
	}
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		.navtop{
			width: 100%;
			height: 90px;
			background-color: #000;
		}
		.signwrap{
			width: 93%;
			height: auto;
			margin: 20px auto 0;
		}
		/* 顶部横线 */
		.signtop{
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.signtopleft img{
			width: 100px;
			height: auto;
		}
		.signtopright{
			font-size: 13px;
			line-height: 20px;
			color: #949494;
		}
		/* 表格 */
		.signcenter{
			width: 100%;
			margin-top: 10px;
			padding-bottom: 50px;
			position: relative;
		}
		.signtitle{
			font-size: 17px;
			font-weight: bold;
			width: 100%;
			border: #9A9999 2px solid;
			border-bottom: none;
			box-sizing: border-box;
			height: 60px;
			line-height: 60px;
		}
		.signul{
			width: 100%;
			margin-top: 0px;
			border: #9A9999 2px solid;
			box-sizing: border-box;
			/* border-radius: 5px; */
		}
		/* 项目名称 */
		.lione{
			display: flex;
			height: 90px;
			align-items: center;
			/* background-color: #ECEBEB; */
		}
		.lionediv1{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-right: #9A9999 2px solid;
			box-sizing: border-box;
			font-weight: bold;
		}
		.lionediv2{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: left;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			box-sizing: border-box;
			font-weight: bold;
		}
		.litwodiv1{
			width: 41%;
			font-size: 13px;
			height: 100%;
			text-align: left;
			line-height: 0px;
			display: flex;
			justify-content: center;
			line-height: 20px;
			align-items: center;
			padding: 0 5px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		.litwodiv2{
			width: 21%;
			font-size: 13px;
			height: 100%;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
			box-sizing: border-box;
		}
		/* 项目情况 */
		.lione2{
			display: flex;
			height: 120px;
			align-items: center;
			border-bottom: #9A9999 2px solid;
			box-sizing: border-box;
		}
		
		.mobilelione{
			height: 80px;
		}
		.lionediv{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
			box-sizing: border-box;
		}
		.litwodiv{
			width: 81%;
			font-size: 13px;
			height: 100%;
			text-align: left;
			line-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
			padding: 0 10px;
			border-top: #9A9999 2px solid;
			box-sizing: border-box;
		}
		/* 已签收印章 */
		.yiqianshou{
			/* width: 100px;
			height: auto;
			position: absolute;
			right: 0;
			top: 0px; */
			width: 170px;
			height: auto;
			/* position: absolute;
			right: 90px;
			bottom: 130px; */
			transform:rotate(-25deg)
		}
		/* 已验收信息 */
		.yanshoudiv{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.wechart{
			display: flex;
			align-items: center;
		}
		.wechart img{
			width: 20px;
		}
		.wechart span{
			font-size: 14px;
			font-weight: bold;
			margin-left: 5px;
			color: orange;
		}
		.yanshotext{
			font-size: 14px;
			font-weight: bold;
			margin-left: 10px;
		}
		/* 底部公司名称 */
		.botomdes{
			width: 100%;
			display: block;
			height: 60px;
			border: #9A9999 2px solid;
			border-top: none;
			box-sizing: border-box;
		}
		.botomdesleft,.botomdesright{
			font-size: 14px;
			/* font-weight: bold; */
		}
		/* 底部公司名称 */
		.botomdes{
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 0px;
			height: 40px;
			/* border: #9A9999 3px solid; */
			border-top: none;
			box-sizing: border-box;
		}
		.botomdesleft{
			width: 100%;
			font-size: 13px;
			/* font-weight: bold; */
			display: flex;
			justify-content: center;
			align-items: center;
			/* color: #8A8888; */
			letter-spacing: 0px;
		}
		/* 表格信息 */
		.iframewrap{
			width: 93%;
			height: 500px;
			margin: 0 auto 50px;
			border: #9A9999 2px solid;
			border-top: 0;
			box-sizing: border-box;
			
		}
		iframe {
			width: 100%;
			height: 700px;
			margin: 0 !important;
			border: none !important;
		}
		/* 水印行的加减号 */
		.icon{
			/* position: absolute;
			right: 10px;
			bottom: 30px;
			z-index: 999; */
			width: 93%;
			margin: 15px auto 50px;
			display: flex;
			justify-content: space-between;
		}
		
		.icontop{
			width: 100%;
			margin-bottom: 10px;
		}
		.icontop div{
			font-size: 14px;
		}
		.icon button{
			width: 80px;
			height: 30px;
			margin-left: 10px;
		}
		.iconcenter{
			display: flex;
			align-items: center;
			border: #333333 1px solid;
			border-radius: 3px;
			margin-bottom: 10px;
			background-color: #409eff;
			/* background-color: rgba(252,117,0,0.7); */
			color: #fff;
		}
		.iconfirst{
			margin-right: 0px;
		}
		.widthadd{
			width: 50px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			border-left: #333333 1px solid;
		}
		.iconnum{
			width: 90px;
			font-size: 11px;
		}
		.widthlow{
			width: 50px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			border-right: #333333 1px solid;
		}
	}
	
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		.navtop{
			width: 100%;
			height: 90px;
			background-color: #000;
		}
		.signwrap{
			width: 97%;
			height: auto;
			margin: 30px auto 0;
		}
		/* 顶部横线 */
		.signtop{
			width: 100%;
			height: 50px;
			/* border-bottom: #9A9999 1px solid; */
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.signtopleft img{
			width: 130px;
			height: auto;
		}
		.signtopright{
			font-size: 14px;
			color: #949494;
			line-height: 23px;
		}
		/* 表格 */
		.signcenter{
			width: 100%;
			margin-top: 15px;
			padding-bottom: 100px;
			position: relative;
		}
		.signtitle{
			width: 100%;
			font-size: 20px;
			font-weight: bold;
			border: #9A9999 2px solid;
			border-bottom: none;
			height: 90px;
			line-height: 90px;
		}
		.signul{
			width: 100%;
			margin-top: 0;
			border: #9A9999 2px solid;
			/* border-radius: 5px; */
		}
		/* 项目名称 */
		.lione{
			display: flex;
			height: 90px;
			align-items: center;
			/* background-color: #ECEBEB; */
		}
		.lionediv1{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.lionediv2{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			border-left: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.litwodiv1{
			width: 40%;
			height: 100%;
			line-height: 22px;
			display: flex;
			text-align: left;
			align-items: center;
			padding: 0 10px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		.litwodiv2{
			width: 30%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			/* font-weight: bold; */
		}
		/* 项目情况 */
		.lione2{
			display: flex;
			height: 160px;
			align-items: center;
			border-bottom: #9A9999 2px solid;
			box-sizing: border-box;
		}
		.mobilelione{
			height: 100px;
		}
		.lionediv{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.litwodiv{
			width: 85%;
			height: 100%;
			text-align: left;
			border-top: #9A9999 2px solid;
			line-height: 22px;
			display: flex;
			align-items: center;
			padding: 0 10px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		/* 已签收印章 */
		.yiqianshou{
			width: 210px;
			height: auto;
			/* position: absolute;
			right: 240px;
			bottom: 150px; */
			transform:rotate(-25deg)
		}
		/* 已验收信息 */
		.yanshoudiv{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.wechart{
			display: flex;
			align-items: center;
		}
		.wechart img{
			width: 25px;
			border-radius: 50%;
		}
		.wechart span{
			font-size: 16px;
			font-weight: bold;
			margin-left: 5px;
			color: orange;
		}
		.yanshotext{
			font-size: 16px;
			font-weight: bold;
			margin-left: 10px;
		}
		/* 底部公司名称 */
		.botomdes{
			width: 100%;
			display: flex;
			justify-content: space-between;
			height: 60px;
			border: #9A9999 2px solid;
			border-top: none;
			box-sizing: border-box;
		}
		.botomdesleft{
			font-size: 18px;
			/* font-weight: bold; */
		}
		/* 表格信息 */
		.iframewrap{
			width: 97%;
			height: 600px;
			margin: 0 auto 50px;
			border: #9A9999 2px solid;
			border-top: 0;
			box-sizing: border-box;
			
			-moz-transform: none !important;
			-webkit-transform: none !important;
			-o-transform: none !important;
			-ms-transform: none !important;
			transform: none !important;
		}
		iframe {
			width: 100%;
			height: 700px;
			margin: 0 !important;
			border: none !important;
		}
		/* 水印行的加减号 */
		.icon{
			/* position: absolute;
			right: 10px;
			bottom: 30px;
			z-index: 999; */
			width: 97%;
			margin: 20px auto 100px;
			display: flex;
			justify-content: flex-end;
		}
		
		.icontop{
			width: 100%;
			margin-bottom: 10px;
		}
		.icontop div{
			font-size: 14px;
		}
		.icon button{
			width: 80px;
			height: 30px;
			margin-left: 10px;
		}
		.iconcenter{
			display: flex;
			align-items: center;
			border: #333333 1px solid;
			border-radius: 3px;
			margin-bottom: 15px;
			/* background-color: rgba(252,117,0,0.7); */
			background-color: #409eff;
			color: #fff;
		}
		.widthadd{
			width: 40px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			border-left: #333333 1px solid;
		}
		.widthadd:hover{
			cursor: pointer;
		}
		.iconnum{
			width: 80px;
			font-size: 13px;
		}
		.widthlow:hover{
			cursor: pointer;
		}
		.widthlow{
			width: 40px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			border-right: #333333 1px solid;
		}
	}
</style>
