<template>
	<div class="all-wrap">
		<!-- 顶部导航栏和顶部图片 -->
		<div class="navtop">
			<Headernew/> 
		</div>
		
		<!-- 列表区 -->
		<div class="projectwrap">
			<div class="projecttop">
				<div>分镜确认单列表</div>
			</div>
			<div class="searchwai">
				<div class="projectchose">
					 <input class="searchinput" ref="search" type="text" placeholder="请输入方案名称" />
					 <img src="../../assets/img/search.png" @click="tosearch" />
				</div>
			</div>
			<div class="projecttable">
				<el-table
				      :data="tableData"
				      style="width: 100%;font-size: 15px;">
				      <el-table-column
				        prop="date"
				        label="日期"
						align="center"
						:width='tablewidth'
				       >
				      </el-table-column>
				      <el-table-column
				        prop="project_name"
						:width='projectwidth'
						align="center"
				        label="项目名称">
				      </el-table-column>
					  <el-table-column
					    :width='tablewidth'
						align="center"
					    label="查看详情">
						<template slot-scope="scope">
							<el-button size="mini" type="primary" @click="jumppage(scope.id, scope.row)">查看详情</el-button>
						</template>
					  </el-table-column>
				    </el-table>
			</div>
			
			<!-- 分页 -->
			<div class="fenye">
				<el-pagination
				  :pager-count='fenyenum'
				  background
				  :small='fenyesize'
				  layout="prev, pager, next"
				  :page-size='limit'
				  :total="totalnum"
				  @current-change="handleCurrentChange"
				  >
				</el-pagination>
			</div>
			
			<!-- 上拉加载更多的图标-->
			<!-- <div class="loadicon" v-if="showmoreicon">
				<div class="jiazaidone" v-if="showload == 1">
					<span class="iconfont icon-dengdai"></span>
					<div>加载中...</div>
				</div>
				<div class="jiazaidone" v-if="showload == 2">
					<span class="iconfont icon-xiaolian"></span>
					<div>没有更多了!</div>
				</div>
			</div> -->
			
		</div>
		
		
		
		<div class="footers">
			<Footernew ref="getheight"/>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	import wx from 'weixin-js-sdk'
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	
	export default {
	  name: 'signlist',
	  components: {
	    Headernew,
	    Footernew,
	  },
	  data() {
		return {
			tablewidth:'150',//日期和按钮表格尺寸，根据浏览器宽度不同，表格尺寸改变
			projectwidth:'700', //项目名称尺寸
			footerheight:'',//计算footer高度
			fullWidth:document.documentElement.clientWidth,//浏览器可视区宽度
			tableData: [{
					date: '2016-05-02',
					content: '卓正项目'
				  }],
			totalnum:0,//总页数
			page:1, //第几页
			limit:10, //每页几个
			fenyenum:7,//分页器的页码数显示几个，移动端显示5个，电脑端显示7个
			searchtext:'',//搜索词
			showmoreicon:true,//展示或者隐藏下拉刷新更多的图标
			showload:1,//上拉加载更多的图标
			fenyesize:false,//分页器大小，移动端是samll
		}
	  },
	  mounted() {
		  // 页面加载完成后，计算出footer的高度，防止错误出现
		  this.footerheight= this.$refs.getheight.$el.offsetHeight;
		  
		  this.handleResize() //获取计算器宽度
		  this.getlist() //请求列表数据
		   // 执行上滑加载更多函数
		  // window.addEventListener('scroll',this.sildeupmore)
	  },
	  methods:{
		  // 计算浏览器宽度
		   handleResize (event) {
		  	 var that = this
		        that.fullWidth = document.documentElement.clientWidth
		  	 if(that.fullWidth>1200){
		  		 // 说明是电脑端
		  		 that.tablewidth = 150
				 that.projectwidth = 700
				 that.fenyenum = 7 //分页器的数字数量
				 that.fenyesize = false
		  	 }else if(that.fullWidth>300 && that.fullWidth<400){
		  		 // 说明是在窄屏移动端 比如iphoneX
		  		 that.tablewidth = 110
				 that.projectwidth = 150
				 that.fenyenum = 5 //分页器的数字数量
				 that.fenyesize = true
		  	 }else if(that.fullWidth>400 && that.fullWidth<500){
		  		 // 说明是在宽屏移动端 比如iphone 6s
		  		 that.tablewidth = 110
				 that.projectwidth = 180
				 that.fenyenum = 5 //分页器的数字数量
				 that.fenyesize = true
		  	 }else if(that.fullWidth>500 && that.fullWidth<800){
		  		 // 说明是在pad端
		  		 that.tablewidth = 140
				 that.projectwidth = 460
				 that.fenyenum = 7 //分页器的数字数量
				 that.fenyesize = false
		  	 }
		   },
		  // 上滑加载更多
		  sildeupmore:function(){
		  	this.showmoreicon = true
		  	var newparam
		  	 // 滚动条滚动高度
		  	 let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
		  	 //windowHeight是可视区的高度
		  	 var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
		  	 //scrollHeight是滚动条的总高度
		  	 var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
		  	 // 获取footer的高度
		  	 let height= this.footerheight;
		  	 //滚动条到底部的条件
		  	 if(scrollTop+windowHeight >= scrollHeight){
		  	 } 			 
		  },
		  // 请求数据获取列表
		  getlist:function(){
			  var that = this
			  var newparam = {
				  page:that.page,
				  limit:that.limit,
				  search_name:that.searchtext
			  }
			  let url = this.$api + "/receipts"
			  this.$axios.get(url,{
			  	params:newparam
			  })
			  .then((res) => {
				  that.totalnum = res.data.result.count
				  that.tableData = res.data.result.data
				  
				  // console.log(res.data.result.data)
			  })
			  .catch((err) => {
			  	console.log(err)
			  })
		  },
		  // 切换下一页的事件
		  handleCurrentChange:function(e){
			  this.page = e
			  this.getlist()
		  },
		  // 进入页面详情
		  jumppage:function(id,row){
			  var id = row.id //获取到点击的这一行的id
			  this.$router.push({
				   name:'camerdetail',
				   query:{id:id}
			  })
			  // this.$router.push({path: '/signdetail?id='+id})

		  },
		  // 点击搜索的时候
		  tosearch:function(){
			 var that = this
			 that.page = 1
			 that.searchtext = that.$refs.search.value
			 this.getlist()
		  }
	  }
	}
	
</script>

<style>
	.all-wrap{
		/* position: relative;
		width: 100%; */
	}
	.navtop{
		width: 100%;
		height: 90px;
		background-color: #000;
	}
	.projectwrap{
		width: 1000px;
		height: 850px;
		padding-bottom: 90px;
		margin: 100px auto 0;
	}
	.projecttop{
		font-size: 26px;
		font-weight: bold;
		margin-bottom: 40px;
		letter-spacing: 2px;
	}
	/* 顶部搜索框 */
	.searchwai{
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.projectchose{
		width: 200px;
		height: 40px;
		margin-left: 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: #dcdfe6 1px solid;
		border-radius: 5px;
		padding: 0 8px;
		box-sizing: border-box;
	}
	.searchinput{
		width: 70%;
		height: 80%;
		border: none;
		outline: none;
		color: #000;
	}
	.projectchose img{
		width: 9%;
	}
	/* 表格 */
	.projecttable{
		margin-top: 20px;
		/* border-top: #dcdfe6 1px solid; */
	}
	.el-pagination.is-background .el-pager li {
	    margin: 0 5px;
	    background-color: #f4f4f5;
	    color: #606266;
	    width: 40px;
		height: 40px;
		line-height: 40px;
	    /* min-width: 30px; */
	    border-radius: 2px;
	}
	.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
		width: 40px;
		height: 40px;
		line-height: 40px;
	}
	
	.fenye{
		width: 100%;
		margin-top: 40px;
	}
	
	/* 加载中图标 */
	/* .loadicon{
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 40px 0 50px 0;
	}
	.jiazaidone{
		display: flex;
		align-items: center;
	}
	.icon-xiaolian{
		font-size: 22px;
		color: #999;
	}
	.icon-dengdai{
		font-size: 22px;
		color: #999;
	}
	.jiazaidone div{
		font-size: 18px;
		color: #999;
		margin-left: 10px;
	} */
	/* .footers{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #000;
		z-index: 100;
	} */
	
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		.navtop{
			width: 100%;
			height: 60px;
			background-color: #000;
		}
		.projectwrap{
			width: 97%;
			height: 800px;
			margin: 40px auto 0;
			padding-bottom: 60px !important;
		}
		.projecttop{
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 30px;
			letter-spacing: 2px;
		}
		/* 顶部搜索框 */
		.searchwai{
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
		.projectchose{
			width: 170px;
			height: 36px;
			margin-left: 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: #dcdfe6 1px solid;
			border-radius: 5px;
			padding: 0 8px;
			box-sizing: border-box;
		}
		.searchinput{
			width: 70%;
			height: 80%;
			border: none;
			outline: none;
			color: #000;
		}
		.projectchose img{
			width: 12%;
		}
		/* 表格 */
		.projecttable{
			width: 100%;
			margin-top: 10px;
			/* border-top: #dcdfe6 1px solid; */
		}
		/* 分页 */
		.fenye{
			width: 100%;
			margin-top: 20px;
		}
		.el-pagination.is-background .el-pager li {
		    margin: 0 5px;
		    background-color: #f4f4f5;
		    color: #606266;
		    width: 30px;
			height: 30px;
			line-height: 30px;
		    /* min-width: 30px; */
		    border-radius: 2px;
		}
		.el-pagination.is-background .btn-prev{
			margin-left: 0;
		}
		.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
			width: 30px;
			height: 30px;
			line-height: 30px;
		}
		/* .el-button--primary {
			width: 80px !important;
			height: 30px !important;
		    color: #FFF;
		    background-color: #409EFF;
		    border-color: #409EFF;
		}
		.el-button--primary span{
			font-size: 12px;
			width: 80px !important;
			height: 30px !important;
			line-height: 0 !important;
			margin: 0;
		} */
		.el-table td.el-table__cell div {
		    font-size: 14px;
			line-height: 20px;
		}
		
		/* 加载中图标 */
		.loadicon{
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 20px 0 0px 0;
		}
		.jiazaidone{
			display: flex;
			align-items: center;
		}
		.icon-xiaolian{
			font-size: 22px;
			color: #999;
		}
		.icon-dengdai{
			font-size: 22px;
			color: #999;
		}
		.jiazaidone div{
			font-size: 18px;
			color: #999;
			margin-left: 10px;
		}
	}
	
	/* 手机端样式 窄屏比如iphonex */
	@media (min-width: 300px) and (max-width: 400px){
		.el-pagination.is-background .el-pager li {
		    margin: 0 5px;
		    background-color: #f4f4f5;
		    color: #606266;
		    width: 30px;
			height: 30px;
			line-height: 30px;
		    /* min-width: 30px; */
		    border-radius: 2px;
		}
		.el-pagination.is-background .btn-prev{
			margin-left: 0;
		}
		.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
			width: 30px;
			height: 30px;
			line-height: 30px;
		}
	}
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		.navtop{
			width: 100%;
			height: 80px;
			background-color: #000;
		}
		.projectwrap{
			width: 97%;
			height: 800px;
			/* padding-bottom: 30px; */
			margin: 40px auto 0;
		}
		.projecttop{
			font-size: 26px;
			font-weight: bold;
			margin-bottom: 40px;
			letter-spacing: 2px;
		}
		/* 顶部搜索框 */
		.searchwai{
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
		.projectchose{
			width: 200px;
			height: 40px;
			margin-left: 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: #dcdfe6 1px solid;
			border-radius: 5px;
			padding: 0 8px;
			box-sizing: border-box;
		}
		.searchinput{
			width: 70%;
			height: 80%;
			border: none;
			outline: none;
			color: #000;
		}
		.projectchose img{
			width: 9%;
		}
		/* 表格 */
		.projecttable{
			margin-top: 20px;
			/* border-top: #dcdfe6 1px solid; */
		}
		.el-pagination.is-background .el-pager li {
		    margin: 0 5px;
		    background-color: #f4f4f5;
		    color: #606266;
		    width: 40px;
			height: 40px;
			line-height: 40px;
		    /* min-width: 30px; */
		    border-radius: 2px;
		}
		.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
			width: 40px;
			height: 40px;
			line-height: 40px;
		}
		
		.fenye{
			width: 100%;
			margin-top: 40px;
		}
	}
</style>
