<template>
	<div class="all-wrap">
		<div class="topnav">
			<Headernew/>
		</div>
		
		<!-- 返回区域 -->
		<div class="goback" @click="gojumpback"><span class="el-icon-arrow-left"></span></div>

		<div class="formzuiwai">
			
			<!-- 表格区域 -->
			<div class="formwrap22">
				  <div class="formtitle">{{proname}}项目签收单--浏览记录表</div>
				  <div class="explain">
					 <!-- <div class="timeselect">
						  <el-date-picker
								@change="changetime"
								v-model="timechose"
								style="width: 100%;"
								type="date"
								placeholder="选择日期">
						   </el-date-picker>
					  </div> -->
					   
					   <!-- <div class="projectchose">
							 <input class="searchinput" ref="search" type="text" placeholder="请输入项目名称" />
							 <img src="../../assets/img/search.png" @click="tosearch" />
					   </div> -->
				  </div>
				  <div class="exclewrap">
					  <el-table
						  :data="tableData"
						  height="100%"
						  border
						  style="width: 100%;"
						  row-key="id"
						  default-expand-all
						  :header-cell-style='{background: "#f4f4f4"}'
						  >
						  <!-- <el-table-column
							prop="date"
							label="日期"
							fixed="left"
							width="200"
							sortable
							column-key="date"
							:filters="filtertime"
							:filter-method="filterHandler"
							>
						  </el-table-column> -->
						  <el-table-column
							prop="created_at"
							:width='tablewidth'
							label="时间">
						  </el-table-column>
						  </el-table-column>
						  <el-table-column
							prop="nickname"
							label="浏览者"
							:width='tablewidth'
							>
						  </el-table-column>
						</el-table>
					</div>
					
					<!-- 分页器 -->
					<div class="fenye">
						<el-pagination
						  :pager-count='fenyenum'
						  background
						  :small='fenyesize'
						  layout="prev, pager, next"
						  :page-size='limit'
						  :total="totalnum"
						  @current-change="handleCurrentChange"
						  >
						</el-pagination>
					</div>
					
					
			</div>
			
		</div>
		
		<!-- 表格结束 -->
		<Footernew/>
		
	</div>
</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import {WOW} from 'wowjs'  //导入wow
	
	export default {
		name: '',
		data() {
			return {
				tablewidth:'125',//表格尺寸，根据浏览器宽度不同，表格尺寸改变
				fullWidth:document.documentElement.clientWidth,//浏览器可视区宽度
				timechose: '', //选择的时间
				proname:'', //项目名
				//筛选时间 
				tableData: [],
				totalnum:0,//总页数
				page:1, //第几页
				limit:12, //每页几个,移动端12个，电脑端15个
				fenyenum:7,//分页器的页码数显示几个，移动端显示5个，电脑端显示7个
				fenyesize:false,//分页器大小，移动端是samll
			}	
		},
		components: {
		  Headernew,
		  Footernew,
		},
		async mounted() {
			// 页面打开的时候滚到顶部
			document.documentElement.scrollTop = document.body.scrollTop = 0
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
			})
			
			this.proname =this.$route.query.proname
			
			// this.getday()
			this.handleResize() //获取计算器宽度
			// this.loopdata() //循环日期
			this.getdata() //获取数据
			
		},
		methods: {
			// 计算浏览器宽度
			 handleResize (event) {
				 var that = this
			      that.fullWidth = document.documentElement.clientWidth
				 if(that.fullWidth>1200){
					 // 说明是电脑端
					 that.tablewidth = 400
					 that.limit = 15
					 that.fenyesize = false
				 }else if(that.fullWidth>300 && that.fullWidth<500){
					 // 说明是在移动端
					 that.tablewidth = 200
					 that.limit = 12
					 that.fenyesize = true
				 }
			 },
			 getdata:function(){
				 var that = this
				 
				 let newurl = this.$api + "/getConfirmsBrows"
				 this.$axios.get(newurl,{
					 params:{
						 content:that.proname,
						 page:that.page,
						 limit:that.limit,
					 }
				 })
				 .then((res) => {
					 console.log(res)
					 that.tableData = res.data.data
					 that.totalnum = res.data.count
				 })
				 .catch((err) => {
				 	console.log(err) //错误信息
				 })
			 },
			 // 切换下一页的事件
			 handleCurrentChange:function(e){
				  this.page = e
				  this.getdata()
			 },
			 // 点击返回上一页
			 gojumpback:function(){
			 	 this.$router.go(-1)
			 }
		}, 
	}
</script>

<style>
	/* 顶部返回按钮 */
	.goback{
		display: none;
	}
	.topnav{
		width: 100%;
		height: 96px;
		background-color: #000000;
	}
	
	.formzuiwai{
		width: 100%;
		height: 1200px;
		position: relative;
	}
	.formwrap22{
		width: 800px;
		height: 700px;
		position: absolute;
		left: 50%;
		top: 39%;
		margin-left: -22%;
		margin-top: -20%;
		/* box-shadow:0 0 10px rgba(153, 153,153, 0.3); */
		
	}
	.fenye{
		width: 100%;
		margin-top: 40px;
	}
	.formtitle{
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 2px;
	}
	.explain{
		width: 100%;
		display: flex;
		margin-top: 25px;
		margin-bottom: 20px;
	}
	.projectchose{
		width: 17%;
		margin-left: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: #dcdfe6 1px solid;
		border-radius: 5px;
		padding: 0 8px;
		box-sizing: border-box;
	}
	.searchinput{
		width: 70%;
		height: 80%;
		border: none;
		outline: none;
		color: #000;
	}
	.projectchose img{
		width: 9%;
	}
	.exclewrap{
		height: 800px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}
	.el-table--border:after,.el-table--group:after,.el-table:before {
	    /* background-color: black; */
	}
	.el-table--border,.el-table--group {
	    /* border-color: black;	 */
	}
	.el-table th > .cell {
		  text-align: center;
		}
	.el-table .cell {
		  text-align: center;
	}
	.el-table .warning-row {
	    background: oldlace;
	  }
	.el-table .success-row {
	    background: #f0f9eb;
	}
	
	
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		body{
			background-color: #fff;
		}
		.topnav{
			display: none;
		}
		/* 顶部返回按钮 */
		.goback{
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			margin: 0 auto 30px;
			display: flex;
			justify-content: flex-start;
			background-color: black;
		}
		.goback span{
			font-size: 18px;
			font-weight: bold;
			margin-left: 10px;
			color: #fff;
		}
		.topnav{
			width: 100%;
			height: 60px;
			background-color: #000000;
		}
		
		.formzuiwai{
			width: 97%;
			margin: 0 auto;
			height: auto;
			padding-bottom: 20px;
			position: relative;
		}
		.formwrap22{
			width: 100%;
			height: auto;
			position: relative;
			left: 0;
			top: 0;
			z-index: 1 !important;
			margin: 0 auto !important;
			/* box-shadow:0 0 10px rgba(153, 153,153, 0.3); */
			/* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
			border-radius: 5px;
			/* transform: rotate(90deg); */
		}
		.formtitle{
			font-size: 18px;
			margin-top: 20px;
			font-weight: bold;
			letter-spacing: 2px;
		}
		.explain{
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.timeselect{
			width: 49%;
		}
		.projectchose{
			width: 49%;
			margin-left: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: #dcdfe6 1px solid;
			border-radius: 5px;
			padding: 0 8px;
			box-sizing: border-box;
		}
		.searchinput{
			width: 70%;
			height: 80%;
			border: none;
			outline: none;
			color: #dcdfe6;
		}
		input::-webkit-input-placeholder{
			font-size: 14px;
		}
		.projectchose img{
			width: 12%;
		}
		
		.exclewrap{
			height: 80vh;
			/* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
			border-radius: 5px;
		}
		
		
	}
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.topnav{
			display: none;
		}
		/* 顶部返回按钮 */
		.goback{
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			margin: 0 auto 30px;
			display: flex;
			justify-content: flex-start;
			background-color: black;
		}
		.goback span{
			font-size: 18px;
			font-weight: bold;
			margin-left: 10px;
			color: #fff;
		}
		.topnav{
			width: 100%;
			height: 96px;
			background-color: #000000;
		}
		
		.formzuiwai{
			width: 100%;
			height: 930px;
			position: relative;
		}
		.formwrap22{
			width: 98%;
			height: 700px;
			position: absolute;
			left: 1%;
			top: 5%;
			margin-left: 0%;
			margin-top: 0%;
			z-index: 1 !important;
			/* box-shadow:0 0 10px rgba(153, 153,153, 0.3); */
		}
		.formtitle{
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 2px;
		}
		.explain{
			width: 100%;
			display: flex;
			margin-top: 25px;
			margin-bottom: 20px;
		}
		.projectchose{
			width: 30%;
			margin-left: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: #dcdfe6 1px solid;
			border-radius: 5px;
			padding: 0 8px;
			box-sizing: border-box;
		}
		.searchinput{
			width: 70%;
			height: 80%;
			border: none;
			outline: none;
			color: #000;
		}
		.projectchose img{
			width: 9%;
		}
		.exclewrap{
			height: 700px;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}
	}
</style>
