/** * 动态加载百度地图api函数 * @param {String} ak 百度地图AK，必传 */
export default function loadBMap(ak) {
	return new Promise(function(resolve, reject) {
		if (typeof window.BMap !== 'undefined') {
			resolve(window.BMap) 
			return true
		}
		window.onBMapCallback = function() {
			resolve(window.BMap)
		}
		let script = document.createElement('script') 
		script.type = 'text/javascript'
		script.src = 'http://api.map.baidu.com/api?v=2.0&ak=' + ak + '&callback=onBMapCallback'
		script.onerror = reject 
		document.head.appendChild(script)
	})
}
