<template>
	<div class="vipallwrap">
		<!-- 留言按钮 -->
		<Comment class="commentWrap"/>
		<!-- 置顶组件 -->
		<gotop></gotop>
		
		<div class="videolist-banner">
			<!-- 顶部导航栏 -->
			<Headernew/> 
			
			<div class="wow fadeInUp bannertop-title">
				<div>用品质面对挑战，用艺术重塑企业</div>
				<div>——后天科技</div>
			</div>
			<!-- 搜索框 -->
			<div class="wow fadeInUp searchWrap">
				<input class="searchInput" type="text" placeholder="请输入要搜索的内容" v-model="searchinput" />
				<div>|</div>
				<div class="searchBtn"  @click="sendInput">搜索</div>
			</div>
		</div>
		
		
		<!-- 中间位置固定尺寸 -->
		<div class="fixedwidth">
			
			<!-- 小标题区 -->
			<div class="wow fadeInUp littleword">
				<div v-if="searchshow">搜索结果</div>
				<a class="littlelist alllist" @click="tabchangelist(0)" v-if="!searchshow">
					<span class="iconfont icon-shipinbofangyingpian"></span>
					<div>所有分类</div>
				</a>
				<a class="littlelist" v-for="(item,i) in secondvideolist" @click="tabchangelist(item.id)" v-if="!searchshow">
					<span class="iconfont icon-shoushimima" v-if="item.name == '智能交互'"></span>
					<span class="iconfont icon-Dmoxingx" v-if="item.name == '3D漫游动画'"></span>
					<span class="iconfont icon-dianying" v-if="item.name == '数字影片'"></span>
					<span class="iconfont icon-shipinbofangyingpian" v-if="item.name!='智能交互' && item.name!='3D漫游动画' && item.name !='数字影片'"></span>
					<div>{{item.name}}</div>
				</a>
			
			</div>
			
			<!-- 列表区 -->
			<div class="wow fadeInUp videolist-wrap">
				<!--不分类的瀑布流布局 -->
				<div class="allvideo" v-if="!searchemptyshow">
				  <waterfall :col="col" :data="allvideoarr">
				  <template>
				   <div v-for="(item,index) in allvideoarr">
					   <!-- 宽屏的视频 -->
					   <div class="wow fadeInUp allvideoli" style="visibility: visible; animation-name: fadeInUp;" v-if="item.code_style==1" @click="remourl">
							<div class="box">
								<div class="img slow">
									<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class='preview'>
									<img :src="item.simg + '?x-oss-process=style/avatar_375'" />
									<i class="videolist-tips" v-if="item.code_name">{{item.code_name}}</i>
									<span class='red-bg'></span>
									<span class='line line_1'></span>
									<span class='line line_2'></span>
									</router-link>
								</div>
								<div class="con">
									<h5>
										<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="title">
										<span>{{item.main}}</span>
										<i><img src="../assets/img/listlian.png" title=""></i>
										</router-link to="/casecontent">
									</h5>
									<span class="hengxian"></span>
									<h6>
										<router-link class="conh6" target="_blank" :to="{path:'/casecontent',query:{id:item.id}}">项目时间</router-link>
										  ： {{item.time | showDate}}
									</h6>
								</div>
							</div>
						</div>
						
						<!-- 竖屏视频 -->
						<div class="newcentertab-list" v-if="item.code_style==2" @click="remourl">
							<div class="newcentertab-listtop">
								<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="newcentertab-listtopl">
									<img :src="item.simg + '?x-oss-process=style/avatar_375'">
									
									<div class="newcentertab-listline">
										<span class='line line_1'></span>
										<span class='line line_2'></span>
									</div>
								</router-link>
							</div>
							<div class="newcentertab-listword">
								<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="newcentertab-listwordl">
									<div class="newcentertab-listname">{{item.main}}</div>
									<div class="newcentertab-time">{{item.time | showDate}}</div>
								</router-link>
							</div>
						</div>
				   </div>
				   </template>
				   </waterfall>
					
					<!-- 竖屏视频 -->
					<!-- <div class="newcentertab-list">
						<div class="newcentertab-listtop">
							<img src="https://tanpanxia.tanpanxia.xjaikj.com/storage/xjaikj/public/2021-03-02/K3AhnYZlqYSXXgWiVXAoDFRIyNF91mLFlsvN0BOK.jpeg">
							
							<div class="newcentertab-listline">
								<div class="newcentertab-black"></div>
							</div>
						</div>
						<div class="newcentertab-listword">
							<div class="newcentertab-listname">长城灯具动画样片</div>
							<div class="newcentertab-time">2021-03-15</div>
						</div>
					</div> -->
					
					<!-- 宽屏的视频 -->
					<!-- <div class="wow fadeInUp allvideoli" style="visibility: visible; animation-name: fadeInUp;">
						<div class="box">
							<div class="img slow">
								<router-link to="/casecontent" class='preview'>
								<img src='../assets/img/videolist1.png' />
								<span class='red-bg'></span>
								<span class='line line_1'></span>
								<span class='line line_2'></span>
								</router-link>
							</div>
							<div class="con">
								<h5>
									<router-link to="/casecontent" class="title" title="大湾区国际金科城">
									<span>大湾区国际金科城</span>
									<i><img src="../assets/img/listlian.png" title=""></i>
									</router-link to="/casecontent">
								</h5>
								<hr>
								<h6>
									<router-link to="/casecontent">政府机构</router-link>
									 / 响应式官网制作  定制开发
								</h6>
							</div>
						</div>
					</div> -->
					
				</div>
				
				<!-- 如果搜索出的内容什么都没有 -->
				<div class="searchempty" v-if="searchemptyshow">
					<span class="iconfont icon-empty"></span>
					<div>没有内容</div>
				</div>
				
				<!-- 上拉加载更多的图标 -->
				<div class="loadicon" v-if="showmoreicon">
					<div class="jiazaidone" v-if="showload == 1">
						<span class="iconfont icon-dengdai"></span>
						<div>加载中...</div>
					</div>
					<div class="jiazaidone" v-if="showload == 2">
						<span class="iconfont icon-xiaolian"></span>
						<div>没有更多了!</div>
					</div>
				</div>
			</div>
			
		</div>
		
		<!-- 顶部导航栏 -->
		<Footernew ref="getheight"/> 
	</div>
</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import pagination from '@/components/pagination.vue'
	import { formatDate } from '../quote/utils.js' //导入时间戳处理
	import {WOW} from 'wowjs'  //导入wow
	import Comment from '@/components/comment.vue'
	import gotop from '@/components/gotop.vue'
	export default {
		name: '',
		data() {
			return {
				secondvideolist:[],
				col:4,//瀑布流一行有几个
				allvideoarr:[],//不分类的视频列表
				page:1, //当前是第几页
				limit:8,//分页-每页显示的条数
				topcodeid:0,//小标题id，默认是0
				showmoreicon:false,//展示或者隐藏下拉刷新更多的图标
				showload:1,//上拉加载更多的图标
				searchshow:false,//点击了搜索按钮
				searchemptyshow:false,//控制搜索结果为空的图标显示和隐藏
				searchinput:'',//搜索内容
				screenWidth: document.body.clientWidth,     // 屏幕宽
			}
		},
		components: {
		  Headernew,
		  Footernew,
		  Comment,
		  gotop
		},
		watch:{
			// 检测屏幕宽度变化
			 screenWidth(val) {
					this.screenWidth = val;
					console.log(this.screenWidth)
					if(this.screenWidth > 320 && this.screenWidth <= 800){
						this.navshow = false
					}
			   }
			
		},
		mounted() {
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
				// 页面加载完成后，计算出footer的高度，防止错误出现
				this.footerheight= this.$refs.getheight.$el.offsetHeight;
			})
			var that = this
			
			// 每次进入页面，都让页面置顶
			document.body.scrollTop = document.documentElement.scrollTop= 0
			
			// 这里是用来获取屏幕宽度的，当时移动端的时候，就让瀑布流的行数发生变化
			window.onresize= () => {
			    return (() => {
			        window.screenWidth = document.body.clientWidth;
			        that.screenWidth = window.screenWidth;
			    })();
			}
			that.changelunbo() //执行判断是不是移动端函数
			
			this.requesttitlelist() //请求小标题列表
			this.requestvideolist() //请求视频列表
			// 执行上滑加载更多函数
			window.addEventListener('scroll',this.sildeupmore)
		

		},
		filters: {
		  // 评论时间格式化
		  showDate(value){
			let date = new Date(value * 1000)
			return formatDate(date, 'yyyy-MM-dd')
		  }
		},
		methods:{
			// 根据屏幕宽度，控制瀑布流一行展示的数量
			changelunbo:function(){
				var that = this
				if(this.screenWidth > 320 && this.screenWidth <= 500){
					this.col = 2
				}else if(this.screenWidth > 500 && this.screenWidth < 800){
					this.col = 3
				}else if(this.screenWidth > 1000 && this.screenWidth < 1300){
					this.col = 3
				}else{
					this.col = 4
				}
				
			},
			// 如果用户在微信浏览器中点击了视频列表，那么就检测一下本地是不是有url缓存，有就删掉
			remourl:function(){
				var ua = navigator.userAgent.toLowerCase();
				 if(ua.match(/MicroMessenger/i) == 'micromessenger'){
					 var backurl = localStorage.getItem('geotourl')
					 if(backurl){
						 localStorage.removeItem('geotourl')
					 }
				 }
			},
			// 请求小标题列表
			requesttitlelist:function(){
				 //https://htkja.htkjbd.com/api/getCodes  小标题的地址
				 let url = this.$api + "/getCodes"
				 this.$axios.get(url)
				 .then((res) => {
					 this.secondvideolist = res.data.result
				 })
				 .catch((err) => {
				 	console.log(err)
				 })
			},
			// 请求视频列表
			requestvideolist:function(){
				 //https://htkja.htkjbd.com/api/getVideoByCode  视频列表地址
				 var newparam
				 if(this.topcodeid == 0){
					 newparam = {
						 page:this.page,
						 limit:this.limit,
					 }
				 }else{
					 newparam = {
						 page:this.page,
						 limit:this.limit,
						 code_id:this.topcodeid
					 }
				 }
				 let url = this.$api + "/getVideoByCode"
				 this.$axios.get(url,{
					 params:newparam
				 })
				 .then((res) => {
					 this.allvideoarr = res.data.result.data
				 })
				 .catch((err) => {
				 	console.log(err)
				 })
			},
			tabchangelist(id){
				// 点击顶部的小标题，切换视频列表内容
				this.page = 1
				this.topcodeid = JSON.stringify(id)
				this.requestvideolist()
				this.showmoreicon = false
				this.showload = 1
				this.searchemptyshow = false
			},
			// 上滑加载更多
			sildeupmore:function(){
				this.showmoreicon = true
				var newparam
				 // 滚动条滚动高度
				 let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
				 //windowHeight是可视区的高度
				 var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
				 //scrollHeight是滚动条的总高度
				 var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
				 // 获取footer的高度
				 let height= this.footerheight;
				 //滚动条到底部的条件
				 if(scrollTop+windowHeight >= scrollHeight){
					 // 只有当搜索到了一次列表内容后，才可以执行上滑刷新
					 if(this.allvideoarr.length>0){
						 // 如果搜索数据有内容的话，说明用户想搜索内容
						 // var searchtext = this.$refs.searchtext.value
						 // if(searchtext){
							//  newparam.name = searchtext
						 // }
						 
						 // 滚动到底部后，请求数据，并且每次触底，都让page+1
						 this.page++
						 this.showload=1
						
						 if(this.topcodeid == 0){
							 newparam = {
								 page:this.page,
								 limit:this.limit,
							 }
						 }else{
							 newparam = {
								 page:this.page,
								 limit:this.limit,
								 code_id:this.topcodeid
							 }
						 }
						 
						 if(this.searchinput){
							 newparam.name = this.searchinput
						 }
						 let url = this.$api + "/getVideoByCode"
						 this.$axios.get(url,{
							 params:newparam
						 })
						 .then((res) => {
							 if(res.data.result.data.length==0){
							 	this.showload=2
								return
							 }else{
							 	this.allvideoarr.push(...res.data.result.data)
								console.log(this.allvideoarr)
							 }
						 })
						 .catch((err) => {
						 	console.log(err)
						 })
					 }
				 } 			 
			},
			// 获取搜索框的内容，并进行列表搜索
			sendInput:function(){
				 // var searchtext = this.$refs.searchtext.value
				 this.page=1
				 var searchtext = this.searchinput
				 this.searchshow = true
				 this.showmoreicon = false
				 var newparam
				 if(searchtext){
					 if(this.topcodeid == 0){
						 newparam = {
							 page:this.page,
							 limit:this.limit,
							 name:searchtext
						 }
					 }else{
						 newparam = {
							 page:this.page,
							 limit:this.limit,
							 code_id:this.topcodeid,
							 name:searchtext
						 }
					 }
					 let url = this.$api + "/getVideoByCode"
					 this.$axios.get(url,{
					 	params:newparam
					 })
					 .then((res) => {
						 if(res.data.result.data.length==0){
							 this.searchemptyshow = true
						 }else{
							 this.allvideoarr = res.data.result.data
						 }
					 	
					 })
					 .catch((err) => {
					 	console.log(err)
					 })
				 }else{
					 alert('请输入搜索内容')
				 }
			},

		}
	}
</script>

<style scoped="scoped">
	ul,li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	/* 评论组件 */
	.commentWrap{
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100;
	 }
    /* 顶部banner区 */
    .videolist-banner{
    	width: 100%;
    	height: 700px;
    	/* background-image: url(../assets/img/videolistbanner.jpg); */
    	background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/viplistbanner.jpg);
    	background-size: 100% 100%;
    	position: relative;
    	
    }
    .bannertop-title{
    	width: 100%;
    	position: absolute;
    	left: 0;
    	top: 30%;
    }
    .bannertop-title div:nth-of-type(1){
    	width: 80%;
    	margin: 0 auto;
    	font-size: 61px;
    	color: #fff;
    	font-weight: bold;
    }
    .bannertop-title div:nth-of-type(2){
    	width: 48%;
    	margin: 35px auto 0;
    	font-size: 31px;
    	color: #fff;
    	text-align: right;
    	letter-spacing: 2px;
    }
    /* 搜索框 */
    .searchWrap{
    	width: 30%;
    	height: 50px;
    	position: absolute;
    	left: 50%;
    	margin-left: -15%;
    	bottom: 200px;
    	background-color: #fff;
    	display: flex;
    	justify-content: space-between;
    	align-items: center;
    	padding: 0 15px;
    	border-radius: 5px;
    	/* border-radius: 25px; */
    }
    .searchInput{
    	width: 90%;
    	height: 80%;
    	border: none;
    	outline: none;
    	font-size: 15px;
    	letter-spacing: 2px;
    }
    .searchBtn:hover{
    	cursor: pointer;
    }
	/* 外层固定宽度 */
	.fixedwidth{
		width: 1650px;
		margin: 0 auto;
	}
	
	/* 小标题区 */
		.littleword{
			width: 88%;
			margin: 90px auto 70px;
			display: flex;
			flex-wrap: wrap;
		}
		.littleword div{
			font-size: 20px;
			letter-spacing: 2px;
			font-weight: bold;
		}
		.littlelist{
			/* width: 150px; */
			height: 40px;
			padding: 0 15px;
			background-color: #fbfbfb;
			border-radius: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 50px;
			margin-top: 30px;
		}
		.littlelist:hover{
			cursor: pointer;
			background-color: #f26e33;
			color: #fff !important;
		}
		.littlelist span{
			font-size: 26px;
			font-weight: bold;
		}
		.littlelist div{
			font-size: 18px;
			margin-left: 10px;
			font-weight: bold;
		}
		/* 列表标题 */
		.videolist-h1{
			width: 88%;
			height: 50px;
			margin: 50px auto 10px;
		}
		.videolist-h1title{
			text-align: left;
			font-size: 20px;
			font-weight: bold;
		}
		/* 列表区 */
		.videolist-wrap{
			width: 100%;
		}
			.allvideo{
				width: 90%;
				margin: 0 auto 0;
				/* display: flex;
				flex-wrap: wrap; */
				/* column-count: 4;
				column-gap: 0; */
			}
			.allvideoli{
				/* width: 25%; */
				padding: 0 1.09375rem;
				break-inside: avoid;
				margin-bottom: 2.4375rem;
				box-sizing: border-box;
				page-break-inside: avoid;
			}
		.fadeInUp {
		    -webkit-animation-name: fadeInUp;
		    animation-name: fadeInUp;
		}
		.animated {
		    -webkit-animation-duration: 1s;
		    animation-duration: 1s;
		    -webkit-animation-fill-mode: both;
		    animation-fill-mode: both;
		}
		.img{
			overflow: hidden;
			width: 100%;
			height: 168px;
			position: relative;
		}
		a{
			color: #333;
			text-decoration: none;
		}
		.img img{
			overflow: hidden;
			width: 100%;
			position: relative;
			height: auto;
			vertical-align: bottom;
		}
		.videolist-tips{
			padding: 4px;
			background-color: #EF590F;
			font-size: 12px !important;
			font-style: normal;
			color: #fff !important;
			text-align: center;
			position: absolute;
			right: 10px;
			top: 10px;
			border-radius: 4px;
		}
		.red-bg{
			background: rgba(226, 52, 51, 0.8);
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			transition: 300ms;
			-webkit-transition: 300ms;
		}
		.img:hover .red-bg {
		    opacity: 1;
		    visibility: visible;
		}
		.box .img:hover .line {
		    transform: translate(0);
		    -webkit-transform: translate(0);
		    opacity: 1;
		    visibility: visible;
		}
		.img .line_1 {
		    width: 52px;
		    height: 1px;
		    margin: -1px 0px 0px -26px;
			transform: translateX(-55px);
			-webkit-transform: translateX(-55px);
		}
		.img .line_2 {
		    width: 1px;
		    height: 52px;
		    margin: -26px 0px 0px -1px;
		    transform: translateY(55px);
		    -webkit-transform: translateY(55px);
		}
		.img .line {
		    display: block;
		    background: #fff;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    opacity: 0;
		    visibility: hidden;
		    transition: 400ms;
		    -webkit-transition: 400ms;
		}
		.con {
		    background: #f5f5f5;
		    padding: 0px 15px;
		}
		.con h5{
		    font-size:18px;
		    height: 30px;
		    line-height: 30px;
		    color: #4d4c51;
			margin: 0 !important;
		}
		/* .con h5 a {
		    font-size: 1.375rem;
		    height: 1.5rem;
		    line-height: 1.5rem;
		    color: #4d4c51;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		} */
		.con h5 a span {
		    display: inline-block;
		    max-width: 85%;
		    font-weight: bold;
		    font-size: 16px;
		    height: 30px;
		    line-height: 30px;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		}
		.con h5 i {
		    float: right;
		}
		.con h5 i img {
		    max-height: 1.5rem;
		}
		.con h6 {
		    font-size: 0.875rem;
		    color: #4d4c51;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
			margin: 0 !important;
		}
		.hengxian{
			width: 100%;
			display: block;
			border-top: #999 1px solid;
		}
		/* 竖屏视频 */
		.newcentertab-list{
			/* width: 20%; */
			height: 600px;
			/* width: 25%; */
			padding: 0 1.09375rem;
			/* padding: 0 10px 0px 10px; */
			box-sizing: border-box;
			margin-bottom: 0px;
			break-inside: avoid;
		}
		.newcentertab-list:hover{
			cursor: pointer;
		}
		.newcentertab-list:hover .newcentertab-listline{
			display: block;
		}

		.newcentertab-listtop{
			width: 100%;
			height: 85%;
			overflow: hidden;
			position: relative;
			overflow: hidden;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
		.newcentertab-listtop img{
			width: 100%;
			height: auto;
			z-index: 1;
			vertical-align: top;
		}
		.newcentertab-listline{
			display: none;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			background-color: rgba(0,0,0,0.5);
		}
		.newcentertab-listline:hover .line {
		    transform: translate(0);
		    -webkit-transform: translate(0);
		    opacity: 1;
		    visibility: visible;
		}
		.newcentertab-listline .line_1 {
		    width: 52px;
		    height: 1px;
		    margin: -1px 0px 0px -26px;
			transform: translateX(-55px);
			-webkit-transform: translateX(-55px);
		}
		.newcentertab-listline .line_2 {
		    width: 1px;
		    height: 52px;
		    margin: -26px 0px 0px -1px;
		    transform: translateY(55px);
		    -webkit-transform: translateY(55px);
		}
		.newcentertab-listline .line {
		    display: block;
		    background: #fff;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    opacity: 0;
		    visibility: hidden;
		    transition: 400ms;
		    -webkit-transition: 400ms;
		}
		
		.newcentertab-listword{
			width: 100%;
			height: 8%;
			margin: 0 auto;
			padding: 4px 10px 0 10px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #f5f5f5;
		}
		.newcentertab-listwordl{
			width: 95%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.newcentertab-listname{
			width: 65%;
			font-size: 16px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: bold;
			text-align: left;
			padding-left: 3px;
			box-sizing: border-box;
		}
		.newcentertab-time{
			width: 35%;
			font-size: 14px;
			color: #9E9B9B;
			font-weight: bold;
			text-align: right;
		}
		
		/* 加载中图标 */
		.loadicon{
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 20px 0 50px 0;
		}
		.jiazaidone{
			display: flex;
			align-items: center;
		}
		.icon-xiaolian{
			font-size: 22px;
			color: #999;
		}
		.icon-dengdai{
			font-size: 22px;
			color: #999;
		}
		.jiazaidone div{
			font-size: 18px;
			color: #999;
			margin-left: 10px;
		}
		/* 搜索结果为空 */
		.searchempty{
			width: 100%;
			height: 300px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.searchempty span{
			font-size: 80px;
			line-height: 60px;
			color: #494848;
		}
		.searchempty div{
			font-size: 18px;
			margin-top: 20px;
			color: #494848;
		}
		/* 手机端自适应 */
		@media (min-width: 300px) and (max-width: 500px){
			/* 顶部banner区 */
			.videolist-banner{
				width: 100%;
				height: 250px;
			}
			.bannertop-title{
				width: 100%;
				position: absolute;
				left: 0;
				top: 35%;
			}
			.bannertop-title div:nth-of-type(1){
				width: 90%;
				margin: 0 auto;
				font-size: 22px;
				color: #fff;
				font-weight: bold;
			}
			.bannertop-title div:nth-of-type(2){
				width: 80%;
				margin: 15px auto 0;
				font-size: 16px;
				color: #fff;
				text-align: right;
				letter-spacing: 2px;
			}
			/* 搜索框 */
			.searchWrap{
				width: 70%;
				height: 35px;
				position: absolute;
				left: 13%;
				margin-left: 0;
				bottom: 30px;
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 10px;
				border-radius: 5px;
			}
			.searchInput{
				width: 80%;
				height: 80%;
				border: none;
				outline: none;
				font-size: 12px;
				letter-spacing: 2px;
			}
			.searchWrap div:nth-of-type(1){
				font-size: 12px;
				line-height: 35px;
			}
			.searchWrap div:nth-of-type(2){
				font-size: 14px;
				line-height: 35px;
			}
			.searchBtn:hover{
				cursor: pointer;
			}
			/* 外层固定宽度 */
			.fixedwidth{
				width: 100%;
				margin: 0 auto;
			}
			/* 小标题区 */
			.littleword{
				width: 92%;
				margin: 15px auto 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			.littlelist{
				width: 40%;
				height: 40px;
				background-color: #fbfbfb;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 0px;
				margin-bottom: 10px;
				margin-top: 10px;
			}
			/* .alllist{
				width: 100% !important;
			} */
			.littlelist span{
				font-size: 20px;
				font-weight: bold;
			}
			.littlelist div{
				font-size: 14px;
				margin-left: 10px;
				font-weight: bold;
			}
			
			/* 列表区 */
			.videolist-wrap{
				width: 100%;
			}
			.videolist-h1{
				width: 92%;
				height: 40px;
				margin: 20px auto 0px;
			}
			.videolist-h1title{
				text-align: left;
				font-size: 16px;
				font-weight: bold;
			}
			.allvideo{
				width: 93%;
				margin: 0 auto;
				/* display: flex; */
				/* justify-content: space-between; */
				/* flex-wrap: wrap; */
				/* column-count: 2;
				column-gap: 0; */
			}
			.allvideoli{
				/* width: 50%; */
				padding: 0 0.5rem;
				margin-bottom: 1rem;
				box-sizing: border-box;
			}
			.fadeInUp {
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			.animated {
				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;
			}
			.img{
				overflow: hidden;
				width: 100%;
				height: 94px;
				position: relative;
			}
			a{
				color: #333;
				text-decoration: none;
			}
			.img img{
				overflow: hidden;
				width: 100%;
				position: relative;
				height: auto;
				vertical-align: bottom;
			}
			.videolist-tips{
				padding: 2px;
				background-color: #EF590F;
				font-size: 8px !important;
				font-style: normal;
				color: #fff !important;
				text-align: center;
				position: absolute;
				right: 5px;
				top: 5px;
				border-radius: 2px;
			}
			.red-bg{
				background: rgba(226, 52, 51, 0.8);
				position: absolute;
				left: 0px;
				top: 0px;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transition: 300ms;
				-webkit-transition: 300ms;
			}
			.img:hover .red-bg {
				opacity: 1;
				visibility: visible;
			}
			.box .img:hover .line {
				transform: translate(0);
				-webkit-transform: translate(0);
				opacity: 1;
				visibility: visible;
			}
			.img .line_1 {
				width: 52px;
				height: 1px;
				margin: -1px 0px 0px -26px;
				transform: translateX(-55px);
				-webkit-transform: translateX(-55px);
			}
			.img .line_2 {
				width: 1px;
				height: 40px;
				margin: -26px 0px 0px -1px;
				transform: translateY(55px);
				-webkit-transform: translateY(55px);
			}
			.img .line {
				display: block;
				background: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				opacity: 0;
				visibility: hidden;
				transition: 400ms;
				-webkit-transition: 400ms;
			}
			.con {
				background: #f5f5f5;
				padding: 0px 5px;
			}
			.con h5{
				font-size:20px;
				height: 25px;
				line-height: 25px;
				color: #4d4c51;
				margin: 0 !important;
			}
			.con h5 a {
				font-size: 0.7rem;
				height: 1rem;
				line-height: 1rem;
				color: #4d4c51;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.con h5 a span {
				display: inline-block;
				max-width: 85%;
				font-weight: bold;
				font-size: 14px;
				height: 1rem;
				line-height: 1rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.con h5 i {
				float: right;
			}
			.con h5 i img {
				max-height: 1.5rem;
			}
			.con div {
				font-size: 11px;
				color: #4d4c51;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 0 !important;
			}
			.conh6{
				font-size: 11px !important;
			}
			/* 竖屏视频 */
			.newcentertab-list{
				/* width: 49%; */
				height: 350px;
				padding-bottom: 1%;
				padding: 0 0.5rem;
				box-sizing: border-box;
				margin-bottom: 20px;
			}
			.newcentertab-list:hover{
				cursor: pointer;
			}
			.newcentertab-list:hover .newcentertab-black{
				width: 90%;
				height: 80%;
				border: #E1DDDD 0.5px solid;
				opacity: 0.3;
			}
			.newcentertab-listtop{
				width: 100%;
				height: 85%;
				position: relative;
				overflow: hidden;
			}
			.newcentertab-listtop img{
				width: 100%;
				height: auto;
				z-index: 1;
				vertical-align: top;
			}
			.newcentertab-listline{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				background-color: rgba(0,0,0,0.5);
				display: flex;
				justify-content: center;
				align-items: center;
			}
		/* 	.newcentertab-black{
				width: 90%;
				height: 90%;
				border: #E1DDDD 0.5px solid;
				opacity: 0.3;
			} */
			
			.newcentertab-listword{
				width: 100%;
				height: 10%;
				margin: 0px auto;
				display: flex;
				justify-content: space-between;
			}
			.newcentertab-listname{
				width: 50%;
				font-size: 12px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: bold;
				text-align: left;
			}
			.newcentertab-time{
				width: 50%;
				font-size: 10px;
				color: #9E9B9B;
				font-weight: bold;
			}
			/* 搜索结果为空 */
			.searchempty{
				width: 100%;
				height: 300px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.searchempty span{
				font-size: 60px;
				line-height: 60px;
				color: #494848;
			}
			.searchempty div{
				font-size: 16px;
				margin-top: 10px;
				color: #494848;
			}
		}
		/* pad端 */
		@media (min-width: 500px) and (max-width: 800px){
			/* 顶部banner区 */
			.videolist-banner{
				width: 100%;
				height: 380px;
			}
			.bannertop-title{
				width: 100%;
				position: absolute;
				left: 0;
				top: 40%;
			}
			.bannertop-title div:nth-of-type(1){
				width: 80%;
				margin: 0 auto;
				font-size: 30px;
				color: #fff;
				font-weight: bold;
			}
			.bannertop-title div:nth-of-type(2){
				width: 59%;
				margin: 20px auto 0;
				font-size: 20px;
				color: #fff;
				text-align: right;
				letter-spacing: 2px;
			}
			/* 搜索框 */
			.searchWrap{
				width: 50%;
				height: 35px;
				position: absolute;
				left: 24%;
				margin-left: 0;
				bottom: 50px;
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 10px;
				border-radius: 5px;
			}
			.searchInput{
				width: 88%;
				height: 80%;
				border: none;
				outline: none;
				font-size: 12px;
				letter-spacing: 2px;
			}
			.searchWrap div:nth-of-type(1){
				font-size: 12px;
				line-height: 35px;
			}
			.searchWrap div:nth-of-type(2){
				font-size: 14px;
				line-height: 35px;
			}
			.searchBtn:hover{
				cursor: pointer;
			}
			/* 外层固定宽度 */
			.fixedwidth{
				width: 100%;
				margin: 0 auto;
			}
			/* 小标题区 */
			.littleword{
				width: 92%;
				margin: 15px auto 20px;
				display: flex;
				flex-wrap: wrap;
				/* justify-content: space-between; */
			}
			.littlelist{
				width: 25%;
				height: 40px;
				background-color: #fbfbfb;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 20px;
				margin-bottom: 0px;
			}
			.littlelist span{
				font-size: 20px;
				font-weight: bold;
			}
			.littlelist div{
				font-size: 14px;
				margin-left: 10px;
				font-weight: bold;
			}
			
			/* 列表区 */
			.videolist-wrap{
				width: 100%;
				margin-top: 50px;
			}
			.videolist-h1{
				width: 92%;
				height: 40px;
				margin: 20px auto 0px;
			}
			.videolist-h1title{
				text-align: left;
				font-size: 16px;
				font-weight: bold;
			}
			.allvideo{
				width: 93%;
				margin: 0 auto;
				/* column-count: 3;
				column-gap: 0; */
			}
			.allvideoli{
				/* width: 33.333%; */
				padding: 0 1rem;
				margin-bottom: 1.7rem;
				box-sizing: border-box;
			}
			.fadeInUp {
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			.animated {
				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;
			}
			.img{
				overflow: hidden;
				width: 100%;
				height: 92px;
				position: relative;
			}
			a{
				color: #333;
				text-decoration: none;
			}
			.img img{
				overflow: hidden;
				width: 100%;
				position: relative;
				height: auto;
				vertical-align: bottom;
			}
			.videolist-tips{
				padding: 2px;
				background-color: #EF590F;
				font-size: 8px !important;
				font-style: normal;
				color: #fff !important;
				text-align: center;
				position: absolute;
				right: 5px;
				top: 5px;
				border-radius: 2px;
			}
			.red-bg{
				background: rgba(226, 52, 51, 0.8);
				position: absolute;
				left: 0px;
				top: 0px;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transition: 300ms;
				-webkit-transition: 300ms;
			}
			.img:hover .red-bg {
				opacity: 1;
				visibility: visible;
			}
			.box .img:hover .line {
				transform: translate(0);
				-webkit-transform: translate(0);
				opacity: 1;
				visibility: visible;
			}
			.img .line_1 {
				width: 52px;
				height: 1px;
				margin: -1px 0px 0px -26px;
				transform: translateX(-55px);
				-webkit-transform: translateX(-55px);
			}
			.img .line_2 {
				width: 1px;
				height: 40px;
				margin: -26px 0px 0px -1px;
				transform: translateY(55px);
				-webkit-transform: translateY(55px);
			}
			.img .line {
				display: block;
				background: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				opacity: 0;
				visibility: hidden;
				transition: 400ms;
				-webkit-transition: 400ms;
			}
			.con {
				background: #f5f5f5;
				padding: 0px 5px;
			}
			.con h5{
				font-size:22px;
				height: 30px;
				line-height: 30px;
				color: #4d4c51;
				margin: 0 !important;
			}
			.con h5 a {
				font-size: 0.8rem;
				height: 1rem;
				line-height: 1rem;
				color: #4d4c51;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.con h5 a span {
				display: inline-block;
				max-width: 85%;
				font-weight: bold;
				font-size: 16px;
				height: 1rem;
				line-height: 1rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.con h5 i {
				float: right;
			}
			.con h5 i img {
				max-height: 1.5rem;
			}
			.con div {
				font-size: 13px;
				color: #4d4c51;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 0 !important;
			}
			.conh6{
				font-size: 11px !important;
			}
			/* 轮播分类区 */
			.newcentertab-list{
				/* width: 33.3333%; */
				height: 440px;
				/* padding-bottom: 1%; */
				padding: 0 0.5rem 0px 0.5rem;
				box-sizing: border-box;
				margin-bottom: 20px;
			}
			.newcentertab-list:hover{
				cursor: pointer;
			}
			.newcentertab-list:hover .newcentertab-black{
				width: 90%;
				height: 80%;
				border: #E1DDDD 0.5px solid;
				opacity: 0.3;
			}
			.newcentertab-listtop{
				width: 100%;
				height: 85%;
				position: relative;
				overflow: hidden;
			}
			.newcentertab-listtop img{
				width: 100%;
				height: auto;
				z-index: 1;
				vertical-align: top;
			}
			.newcentertab-listline{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				background-color: rgba(0,0,0,0.5);
				display: flex;
				justify-content: center;
				align-items: center;
			}
		/* 	.newcentertab-black{
				width: 90%;
				height: 90%;
				border: #E1DDDD 0.5px solid;
				opacity: 0.3;
			} */
			
			.newcentertab-listword{
				width: 100%;
				height: 10%;
				margin: 0px auto;
				display: flex;
				justify-content: space-between;
			}
			.newcentertab-listname{
				width: 50%;
				font-size: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: bold;
				text-align: left;
			}
			.newcentertab-time{
				width: 50%;
				font-size: 12px;
				color: #9E9B9B;
				font-weight: bold;
			}
			/* 搜索结果为空 */
			.searchempty{
				width: 100%;
				height: 600px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.searchempty span{
				font-size: 65px;
				line-height: 65px;
				color: #494848;
			}
			.searchempty div{
				font-size: 17px;
				margin-top: 15px;
				color: #494848;
			}
		}
		
		/* 老板仓定制机的样式 */
		@media (min-width: 1000px) and (max-width: 1300px){
			/* 评论组件 */
			.commentWrap{
				position: fixed;
				left: 0;
				top: 0;
				z-index: 100;
			 }
			/* 顶部banner区 */
			.videolist-banner{
				width: 100%;
				height: 450px;
				/* background-image: url(../assets/img/videolistbanner.jpg); */
				background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/viplistbanner.jpg);
				background-size: 100% 100%;
				position: relative;
				
			}
			.bannertop-title{
				width: 100%;
				position: absolute;
				left: 0;
				top: 35%;
			}
			.bannertop-title div:nth-of-type(1){
				width: 80%;
				margin: 0 auto;
				font-size: 40px;
				color: #fff;
				font-weight: bold;
			}
			.bannertop-title div:nth-of-type(2){
				width: 48%;
				margin: 20px auto 0;
				font-size: 20px;
				color: #fff;
				text-align: right;
				letter-spacing: 2px;
			}
			/* 搜索框 */
			.searchWrap{
				width: 30%;
				height: 50px;
				position: absolute;
				left: 50%;
				margin-left: -15%;
				bottom: 70px;
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 15px;
				border-radius: 5px;
				/* border-radius: 25px; */
			}
			.searchInput{
				width: 85%;
				height: 80%;
				border: none;
				outline: none;
				font-size: 15px;
				letter-spacing: 2px;
			}
			.searchBtn:hover{
				cursor: pointer;
			}
			/* 外层固定宽度 */
			.fixedwidth{
				width: 100%;
				margin: 0 auto;
			}
			
			/* 小标题区 */
				.littleword{
					width: 88%;
					margin: 30px auto 70px;
					display: flex;
					flex-wrap: wrap;
				}
				.littleword div{
					font-size: 20px;
					letter-spacing: 2px;
					font-weight: bold;
				}
				.littlelist{
					/* width: 150px; */
					height: 40px;
					padding: 0 15px;
					background-color: #fbfbfb;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 40px;
					margin-top: 20px;
				}
				.littlelist:hover{
					cursor: pointer;
					background-color: #f26e33;
					color: #fff !important;
				}
				.littlelist span{
					font-size: 26px;
					font-weight: bold;
				}
				.littlelist div{
					font-size: 18px;
					margin-left: 10px;
					font-weight: bold;
				}
				/* 列表标题 */
				.videolist-h1{
					width: 88%;
					height: 50px;
					margin: 50px auto 10px;
				}
				.videolist-h1title{
					text-align: left;
					font-size: 20px;
					font-weight: bold;
				}
				/* 列表区 */
				.videolist-wrap{
					width: 100%;
				}
					.allvideo{
						width: 90%;
						margin: 0 auto 0;
						/* display: flex;
						flex-wrap: wrap; */
						/* column-count: 4;
						column-gap: 0; */
					}
					.allvideoli{
						/* width: 25%; */
						padding: 0 1.09375rem;
						break-inside: avoid;
						margin-bottom: 2.4375rem;
						box-sizing: border-box;
						page-break-inside: avoid;
					}
				.fadeInUp {
				    -webkit-animation-name: fadeInUp;
				    animation-name: fadeInUp;
				}
				.animated {
				    -webkit-animation-duration: 1s;
				    animation-duration: 1s;
				    -webkit-animation-fill-mode: both;
				    animation-fill-mode: both;
				}
				.img{
					overflow: hidden;
					width: 100%;
					height: 152px;
					position: relative;
				}
				a{
					color: #333;
					text-decoration: none;
				}
				.img img{
					overflow: hidden;
					width: 100%;
					position: relative;
					height: auto;
					vertical-align: bottom;
				}
				.videolist-tips{
					padding: 4px;
					background-color: #EF590F;
					font-size: 12px !important;
					font-style: normal;
					color: #fff !important;
					text-align: center;
					position: absolute;
					right: 10px;
					top: 10px;
					border-radius: 4px;
				}
				.red-bg{
					background: rgba(226, 52, 51, 0.8);
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					opacity: 0;
					visibility: hidden;
					transition: 300ms;
					-webkit-transition: 300ms;
				}
				.img:hover .red-bg {
				    opacity: 1;
				    visibility: visible;
				}
				.box .img:hover .line {
				    transform: translate(0);
				    -webkit-transform: translate(0);
				    opacity: 1;
				    visibility: visible;
				}
				.img .line_1 {
				    width: 52px;
				    height: 1px;
				    margin: -1px 0px 0px -26px;
					transform: translateX(-55px);
					-webkit-transform: translateX(-55px);
				}
				.img .line_2 {
				    width: 1px;
				    height: 52px;
				    margin: -26px 0px 0px -1px;
				    transform: translateY(55px);
				    -webkit-transform: translateY(55px);
				}
				.img .line {
				    display: block;
				    background: #fff;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    opacity: 0;
				    visibility: hidden;
				    transition: 400ms;
				    -webkit-transition: 400ms;
				}
				.con {
				    background: #f5f5f5;
				    padding: 0px 15px;
				}
				.con h5{
				    font-size:18px;
				    height: 30px;
				    line-height: 30px;
				    color: #4d4c51;
					margin: 0 !important;
				}
				/* .con h5 a {
				    font-size: 1.375rem;
				    height: 1.5rem;
				    line-height: 1.5rem;
				    color: #4d4c51;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				} */
				.con h5 a span {
				    display: inline-block;
				    max-width: 85%;
				    font-weight: bold;
				    font-size: 16px;
				    height: 30px;
				    line-height: 30px;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				}
				.con h5 i {
				    float: right;
				}
				.con h5 i img {
				    max-height: 1.5rem;
				}
				.con h6 {
				    font-size: 0.875rem;
				    color: #4d4c51;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
					margin: 0 !important;
				}
				.hengxian{
					width: 100%;
					display: block;
					border-top: #999 1px solid;
				}
				/* 竖屏视频 */
				.newcentertab-list{
					/* width: 20%; */
					height: 520px;
					/* width: 25%; */
					padding: 0 1.09375rem;
					/* padding: 0 10px 0px 10px; */
					box-sizing: border-box;
					margin-bottom: 0px;
					break-inside: avoid;
				}
				.newcentertab-list:hover{
					cursor: pointer;
				}
				.newcentertab-list:hover .newcentertab-listline{
					display: block;
				}
			
				.newcentertab-listtop{
					width: 100%;
					height: 85%;
					overflow: hidden;
					position: relative;
					overflow: hidden;
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
				}
				.newcentertab-listtop img{
					width: 100%;
					height: auto;
					z-index: 1;
					vertical-align: top;
				}
				.newcentertab-listline{
					display: none;
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 2;
					background-color: rgba(0,0,0,0.5);
				}
				.newcentertab-listline:hover .line {
				    transform: translate(0);
				    -webkit-transform: translate(0);
				    opacity: 1;
				    visibility: visible;
				}
				.newcentertab-listline .line_1 {
				    width: 52px;
				    height: 1px;
				    margin: -1px 0px 0px -26px;
					transform: translateX(-55px);
					-webkit-transform: translateX(-55px);
				}
				.newcentertab-listline .line_2 {
				    width: 1px;
				    height: 52px;
				    margin: -26px 0px 0px -1px;
				    transform: translateY(55px);
				    -webkit-transform: translateY(55px);
				}
				.newcentertab-listline .line {
				    display: block;
				    background: #fff;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    opacity: 0;
				    visibility: hidden;
				    transition: 400ms;
				    -webkit-transition: 400ms;
				}
				
				.newcentertab-listword{
					width: 100%;
					height: 8%;
					margin: 0 auto;
					padding: 4px 10px 0 10px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: #f5f5f5;
				}
				.newcentertab-listwordl{
					width: 95%;
					margin: 0 auto;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.newcentertab-listname{
					width: 65%;
					font-size: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-weight: bold;
					text-align: left;
					padding-left: 3px;
					box-sizing: border-box;
				}
				.newcentertab-time{
					width: 35%;
					font-size: 14px;
					color: #9E9B9B;
					font-weight: bold;
					text-align: right;
				}
				
				/* 加载中图标 */
				.loadicon{
					width: 100%;
					display: flex;
					justify-content: center;
					margin: 20px 0 50px 0;
				}
				.jiazaidone{
					display: flex;
					align-items: center;
				}
				.icon-xiaolian{
					font-size: 22px;
					color: #999;
				}
				.icon-dengdai{
					font-size: 22px;
					color: #999;
				}
				.jiazaidone div{
					font-size: 18px;
					color: #999;
					margin-left: 10px;
				}
				/* 搜索结果为空 */
				.searchempty{
					width: 100%;
					height: 300px;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				.searchempty span{
					font-size: 80px;
					line-height: 60px;
					color: #494848;
				}
				.searchempty div{
					font-size: 18px;
					margin-top: 20px;
					color: #494848;
				}
		}
</style>
