<template>
	<!-- 根据屏幕高度，自适应背景图大小 -->
	<div id="wai" class="all-wrap" :style="{height:(noheight?'100vh':'')}">
		
		<!-- 如果用户不是在微信浏览器打开的话，就提示需要在微信中打开 -->
		<div class="weixintip" v-show="wechatnologin">请在微信中打开！</div>
		
		<!-- 用户没有授权登录 -->
		<div class="nodenglu" v-show="nodenglushow">
			<div class="nodenglucont">
				<div class="nodenglutitle">微信授权</div>
				<div class="nodengludes">检测到您尚未进行微信登录,无法参与活动</div>
				<div class="nodengluimg">
					<img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/share.png" >
					<div>红包盲盒申请获取以下权限</div>
				</div>
				<div class="nodengluname">获取你的公开信息(昵称、头像等)</div>
				<div class="nodenglubtn">
					<div @click="againlogion">登录授权</div>
				</div>
			</div>
			<div class="nodengluyin"></div>
		</div>
		
		<!-- 加载中动画 -->
		<div class="loadwai" v-show="loadshow">
			<img v-show='iconloadshow' src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/1.gif" />
			<div class="loadyin"></div>
		</div>
		
		<!-- 活动结束 -->
		<div class="suitip" v-show="huodongend">
			  <div class="onlytip">
				  <div class="huodong">本次红包活动已经结束!</div>
			  </div>
			  <div class="huodongyin"></div>
		</div>

		<!-- 主体 -->
		<div class="centercont">
			<!-- 钱包背景挂件 -->
			<div class="qianbao"></div>
			
			<!-- 虎头图片 -->
		    <div class="titlewai">
		      <div class="titleimg"></div>
			  <!-- <div class="peoplenum">领取红包总人数为342人</div> -->
		    </div>
			
			<!-- 领取者的信息滚动 -->
			<div class="page-section">
				<div class="section-swiper swiper mySwiper">
					<div class="swiper-wrap swiper-wrapper">
						
						<div class="swiper-slide" v-for="(item,index) in scrollarr">
							<div class="swiper-view">
								<img :src="item.headimgurl" />
								<div class="swipername">
									<span class="swiper-text">{{item.nickname}}</span>
								</div>
								<span class="swiper-time">领取小红包{{item.payment_number}}个</span>
								<span class="swiper-time">领取大红包{{item.video_number}}个</span>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		 
		 <!-- 没有登录情况下-的登录区域 -->
		 <div class="chouzuiwai" v-show="!choujiangshow">
		   <div class="chouwai">
		     <ul class="choucent">
		       <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
		       </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
			   <li class="choulist" @click="showdenglu">
				  <div class="choulist1">
					  <img class="choulistimg2" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
			   </li>
		     </ul>
		   </div>
		 </div>
		 
		 <!--真实的- 抽奖区域 -->
		  <div class="chouzuiwai" v-show="choujiangshow">
		    <div class="chouwai">
		      <ul class="choucent">
		        <li class="choulist" v-for="(item,index) in arrdata" @click="clickli(item)">
				  <!-- 让不可点击的背景变色  这里需要修改-->
				  <!-- <div class="choulist1" :class="[item.type=='video'?'noclick':'']"> -->
				  <div class="choulist1">
					  <img class="choulistimg1" v-if="item.finish==1" src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/open.png" />
					  <img class="choulistimg2" v-else src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/close.png" />
				  </div>
		        </li>
		      </ul>
		    </div>
		  </div>
		  
		  <!-- 文字提示区 -->
		  <div class="texttip" @click="showrole">
		    <div class="textcont">
		      <div class="texticon"></div>
		      <div>红包领取规则</div>
		    </div>
		  </div>
		  
		  <!-- 底部按钮 -->
		  <div class="choubtnwrap">
			  <div class="choubtn">欢迎大家参与虎福迎春互动抽奖</div>
		  </div>
		  
		  <!-- 公司logo区域 -->
		  <div class="logowai">
			  <div class="logocont"></div>
			  <div class="logotext">沉浸数字交互倡导者联合祝愿</div>
			  <div class="botomabout">@后天科技提供技术支持</div>
		  </div>
		  
		  <!-- 视频弹出层 -->
		  <div class="videowindow" v-show="videowindowshow">
		    <video webkit-playsinline="true" playsinline="true" controls="controls" id='video' class="videos" :src="videolink" :poster="videopost"></video>
		    <!-- <video controls="controls" id='video' class="videos" src="../../assets/1.mp4"></video> -->
			<div class="videoyin" @click="closevideo"></div>
		  </div>
		  
		  <!-- 弹窗提示只有10次点击机会 -->
		  <div class="suitip" v-show="onlyshow">
			  <div class="onlytip">
				  <div class="suiconttop">每个用户只有10次打开盲盒的机会!</div>
				  <div class="onlytipbtn">
					  <div @click="closeonly">确定</div>
				  </div>
			  </div>
			  <div class="suiyin"></div>
		  </div>
		  
		  <!-- 提示用户已经没有点击机会了 -->
		  <div class="suitip" v-show="clicknumshow">
		  	  <div class="onlytip">
		  		  <div class="suiconttop" style="text-align: center;">抱歉,您的10次抽奖机会已经用完!</div>
		  		  <div class="onlytipbtn">
		  			  <div @click="closeclick">确定</div>
		  		  </div>
		  	  </div>
		  	  <div class="suiyin"></div>
		  </div>
		  
		  <!-- 提示用户，此时退出视频，将无法获取到拼图碎片 -->
		 <div class="suitip" v-show="videndshow">
			  <div class="suicont">
				  <!-- <div class="suiconttop">如果此时退出，将无法获取红包是否继续？</div> -->
				  <div class="suiconttop">如果此时退出，将无法获取拼图碎片是否继续？</div>
				  <div class="suicontbtn">
					  <div @click="closewindow">确定</div>
					  <div @click="quxiao">取消</div>
				  </div>
			  </div>
			  <div class="suiyin"></div>
		  </div>
		  
		  <!-- 碎片展示 -->
		  <div class="imgfragwai" v-show="imgfragshow">
			  <div class="imgfragcenter">
				  <!-- <span class="iconfont icon-close closeimg" @click="closeimgfrag"></span>
				  <em class="gongxi">恭喜您获得新的拼图碎片!</em> -->
				  <div class="imgfrag">
					  <div class="imgfragcont">
						  <div class="fraglist1">
							  <img :src="pindi1" />
						  </div>
						  <div class="fraglist2">
							  <img :src="pindi2" />
						  </div>
						  <div class="fraglist3">
							  <img :src="pindi3" />
						  </div>
						  <div class="fraglist4">
							  <img :src="pindi4" />
						  </div>
						  <div class="fraglist5">
							  <img :src="pindi5" />
						  </div>
						  <div class="fraglist6">
							  <img :src="pindi6" />
						  </div>
					  </div>
				  </div>
				  <!-- 拼图底部按钮 -->
				  <div class="imgfragbot">
					  <div class="imgfragbottop">
						  <div class="imgfragbotbtn" @click="closeimgfrag">继续游戏</div>
					  </div>
					  <div class="imgfragbottext">剩余抽奖机会{{shengnum}}次</div>
				  </div>
			  </div>
			  <div class="imgfragyin"></div>
		  </div>
		  
		  <!-- 拼图先加载出来并隐藏，防止出现一点点加载的问题 -->
		  <div class="pintuyinul" v-show="false">
			  <div class="pintuyinlist" v-for="(item,index) in arrdata" v-show="item.type=='video'">
				  <img :src="item.image_url" >
			  </div>
			  <img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/redget.png" />
		  </div>
		  
		  
		  <!--正常的 红包弹出层 -->
		  <div class="rolewindow" v-if="redboxshow">
			  
			   <div class="redcenter" v-show="showmoney">
				  <span class="iconfont icon-close closespan" @click="closered"></span>
				  <div class="redtoptext">
					  <div class="redtoptext1">祝您虎年吉祥行大运</div>
					  <div class="redtoptext2">已有{{totalnum}}位朋友参与游戏</div>
				  </div>
				  <div class="redbto">
					  <div class="lingqubtn" @click="getmoney">点击抽取</div>
					  <div class="redbottitle">温馨提示:</div>
					  <div class="redbottxt">抽取盲盒可获得现金红包或观看视频赢取拼图碎片集齐6个碎片可获得一个新年大红包哟！</div>
					  <div class="redbotgongsi">沉浸数字交互服务商联合祝愿</div>
				  </div>
				</div>
				
				<div class="redsucess" v-show="!showmoney">
				  <div class="redsucesstop">
					  <div class="redsucesstoptext">福到啦!</div>
					  <div class="pricewai">
						  <div class="priceicon">￥</div>
						  <div class="price">{{paynum}}</div>
					  </div>
				  </div>
				  <span class="iconfont icon-close closespan" @click="closered"></span>
				  <div class="redsucessbto">
					  <div class="redsucessbtotitle">恭喜您获得现金红包</div>
					  <div class="redsucessbottxt">请在【微信服务通知】内点击消息领取</div>
					  <div class="lingqubtn" @click="closered">继续游戏</div>
				  </div>
				  
				  <div class="redsucesstext">剩余抽奖机会{{shengnum}}次</div>
				</div>
				<div class="roleyin"></div>
		  </div>
		  
		  <!-- 拼图拼好之后，出现的红包封面图 -->
		  <div class="rolewindow" v-if="sixredboxshow">
		  			  
				<div class="pincheng" v-show="sixshowmoney">
					<div class="pinchengcont">
						<div class="pinchengbtn" @click="sixgetmoney"></div>
					</div>
				</div>	  
			   <!-- <div class="redcenter" v-show="sixshowmoney">
				   
				  <span class="iconfont icon-close closespan" @click="sixclosered"></span>
				  <div class="redtoptext">
					  <div class="redtoptext1">祝您虎年吉祥行大运</div>
					  <div class="redtoptext2">已有{{totalnum}}位朋友参与游戏</div>
				  </div>
				  <div class="redbto">
					  <div class="lingqubtn" @click="sixgetmoney">点击抽取</div>
					  <div class="redbottitle">温馨提示:</div>
					  <div class="redbottxt">抽取盲盒可获得现金红包或观看视频赢取拼图碎片集齐6个碎片可获得一个新年大红包哟！</div>
					  <div class="redbotgongsi">沉浸数字交互服务商联合祝愿</div>
				  </div>
				</div> -->
				
				<div class="redsucess" v-show="!sixshowmoney">
				  <div class="redsucesstop">
					  <div class="redsucesstoptext">福到啦!</div>
					  <div class="pricewai">
						  <div class="priceicon">￥</div>
						  <div class="price">{{paynum}}</div>
					  </div>
				  </div>
				  <span class="iconfont icon-close closespan" @click="sixclosered"></span>
				  <div class="redsucessbto">
					  <div class="redsucessbtotitle">恭喜您获得现金红包</div>
					  <div class="redsucessbottxt">请在【微信服务通知】内点击消息领取</div>
					  <div class="lingqubtn" @click="sixclosered">继续游戏</div>
				  </div>
				  
				  <div class="redsucesstext">剩余抽奖机会{{shengnum}}次</div>
				</div>
				<div class="roleyin"></div>
		  </div>
		  
		  
		
		  <!-- 如果红包领取人数已经到达上限，或有前台错误的时候，需要弹窗提醒 -->
		  <div class="suitip" v-show="noredbox">
			  <div class="onlytip">
				  <div class="suiconttop" style="text-align: center;">系统错误,请重试!</div>
				  <div class="onlytipbtn">
					  <div @click="closenored">确定</div>
				  </div>
			  </div>
			  <div class="suiyin"></div>
		  </div>
		
		  <!-- 规则解释弹窗 -->
		  <div class="rolewindow" v-if="roleshow">
		    <!-- 活动规则内容区 -->
			<div class="rolecontent wow bounceInDown">
				<div class="rolecenter">
				   <img src="https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/rolexiaosui.png" />
				  <!-- <span class="roletitle">活动规则</span>
				  <div class="rolecont">
					<div class="roletext">
						<div class="title1">玩法说明：</div>
						<div class="text1">1.点开任意盲盒均有惊喜！支持点开多个盲盒！</div>
						<div class="text1">2.恭喜抽到视频祝福的朋友们，每收下一支新年祝福都会赢得一块拼图碎片。集齐6个拼图碎片即可额外获得新春大红包哦。</div>
					</div>
				    <div class="roletext">
						<div class="title1">如何领取红包：</div>
						<div class="text1">1.确认不再继续游戏后，退出当前界面。</div>
						<div class="text1">2.系统会以微信服务通知的方式，点击进入消息自动发放红包，直接领取到卡包。</div>
						<div class="text1">温馨提醒：一条消息对应一个红包，不要忘记领取哦~~~</div>
					</div>
					<div class="roletext">
						<div class="title1">活动说明：</div>
						<div class="text1">本活动春节期间开展，红包发放完成为止。</div>
					</div>
				
				  </div> -->
				</div>
				<!-- 活动规则按钮 -->
				<div class="rolebtnwai"><div class="rolebtn" @click="closerole"></div></div>
			</div>
			<!-- 活动规则半透明背景 -->
		    <div class="roleyin"></div>
		  </div>
		
		
		</div>

		
		<!-- <div class="footers">
			<Footernew ref="getheight"/>
		</div> -->
	</div>
</template>

<script>
	import $ from "jquery"
	import wx from 'weixin-js-sdk'
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import Swiper from "swiper"
	import {WOW} from 'wowjs'  //导入wow
	
	export default {
	  name: 'redbox',
	  components: {
	    Headernew,
	    Footernew,
	  },
	  data() {
		return {
			nodenglushow:false,//没有授权登录弹出的弹窗
			loadshow:false,//加载中图标
			noheight:false,//控制最外层高度的
			choujiangshow:false,//控制真实和未登录情况下的盲盒列表的显示和隐藏
			arrdata:[
				{id:1,finish:0,type:''},
				{id:2,finish:0,type:''},
				{id:3,finish:0,type:''},
				{id:4,finish:0,type:''},
				{id:5,finish:0,type:''},
				{id:6,finish:0,type:''},
				{id:7,finish:0,type:''},
				{id:8,finish:0,type:''},
				{id:9,finish:0,type:''},
				{id:10,finish:0,type:''},
				{id:11,finish:0,type:''},
				{id:12,finish:0,type:''},
			],//请求接口获得的盲盒列表数据
			scrollarr:[],//滚动列表的数据
			clicknumshow:false,//用户已经没有点击机会的弹窗
			clicknum:'',//用户还能点击的次数

			videolink:'',//视频弹出层中的视频链接
			videopost:'',//播放视频的封面
			videowindowshow:false, //控制视频弹出层的显示和隐藏
			videndshow:false,//视频没有播放完成时，用户点击关闭视频的弹窗提示
			imgfragshow:false,//控制碎片弹窗的显示和隐藏
			redboxshow:false,//控制红包弹窗的显示和隐藏
			noredbox:false,//控制提醒红包领取已经到达上限的显示和隐藏
			showmoney:true,//控制领取红包成功的显示和隐藏
			roleshow:true,//控制活动规则弹窗的显示和隐藏
			wechatnologin:false,//控制提示需要在微信端打开的遮罩的出现  这里需要修改
			onlyshow:false,//弹窗提示只有10次点击机会
			projectid:'602',//盲盒项目的id
			paynum:'',//红包的钱数
			userid:'',//用户id
			
			huodongend:false,//活动结束的提示
			//拼图弹窗的背景和图片
			pindi1:'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pindi1.png',
			pindi2:'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pindi2.png',
			pindi3:'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pindi3.png',
			pindi4:'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pindi4.png',
			pindi5:'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pindi5.png',
			pindi6:'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pindi6.png',
			
			clickid:'',//点击选中的盲盒id
			shengnum:'',//剩余点击次数
			totalnum:'',//总参与人数
			pinpayid:'',//拼图完成后的paymentid
			
			resdata:'',//点击的盲盒的数据
			cilicke:'',//点击选中的盲盒
			finshed:false,//当用户点击时，盲盒是打开过的，直接显示打开过，或只放视频
			sixredboxshow:false, //拼图专用红包封面外部显示和隐藏  这里需要修改
			sixshowmoney:true, //
			payment:'',//当6个拼图都出现的时候，大红包的payment才会有值
			alreadywindowshow:false,//提示用户已经打开过这个盲盒了
			iconloadshow:true,//加载中的gif小图标的显示和隐藏
		}
	  },
	  watch: {
	      
	  },
	  created:function(){
	       document.title = '虎福迎春互动抽奖活动'
	  },
	  mounted() {
		  var that = this
		  // 这里需要删
		  // var name = '土不先生'
		  // var id = 5
		  
		  // var name = 'w'
		  // var id = 6
		  
		  // var name = 'cbl'
		  // var id = 688
		  
		  // var usermessage = {
		  // 	username:name,
		  // 	userid:id,
		  // }
		  // localStorage.setItem('usermessage',JSON.stringify(usermessage))
		  
		  // 在项目加载完成之后初始化wow
		  this.$nextTick(() => {
		  	let wow = new WOW({
		  		live:false
		  	})
		  	wow.init()
		  	
		  })
		  
		  this.handleResize()
		  this.getlogin()  //用户登录
		  that.getscroll() //获取用户滚动信息
	  },
	  methods:{
		    // 计算页面高度
		    handleResize (event) {
		        var screenHeigh = document.documentElement.clientHeight
				var allwrapHeight = document.getElementById("wai").offsetHeight
				if(screenHeigh>allwrapHeight){
					// 此时说明，页面底部会有白边
					this.noheight = true
				}else{
					this.noheight = false
				}
		    },
		    //用户信息，获取盲盒数据
		    getdata(id){
				var that =this
				that.loadshow = true
				that.iconloadshow=true
				let newurl = this.$api + "/saveBox"
				this.$axios.post(newurl,{
					user_id:id
				})
				.then((res) => {
					console.log(res)
					var arrdata = that.arrdata
					var data = res.data.result
					
					console.log(arrdata)
					if(res.data.code==20024){
						// 活动结束
						that.huodongend = true
						that.roleshow=false
					}else{
						if(res.data.result.length>0){
							// 说明之前点击打开过盲盒了
							data.map(item=>{
								arrdata.[item-1].finish = 1
							})
						}
					}
					// else{
					// 	that.arrdata = res.data.result.array
					// 	that.clicknum = res.data.result.click
					// }
					that.loadshow = false

				})
				.catch((err) => {
					console.log(err) //错误信息
				})
			},
			// 获取用户滚动信息
			getscroll(){
				var that =this
				let newurl = this.$api + "/getBrowes"
				this.$axios.get(newurl)
				.then((res) => {	
					that.scrollarr = res.data.result
					this.$nextTick(() => { // 下一个UI帧再初始化swiper
					
					   var swiper = new Swiper(".mySwiper", {
							  direction: "vertical",
							  speed: 0,
							  autoplay: {
								delay: 6000,
								disableOnInteraction: false,
							  },
							  loop : true,
							  observer: true, //修改swiper自己或子元素时，自动初始化swiper
							  observeParents: true, //修改swiper的父元素时，自动初始化swiper 
					   })
					
					});
				})
				.catch((err) => {
					console.log(err) //错误信息
				})
			},
		    // li的点击事件
		    clickli:function(e){
				  var that = this
				  var num = e
				  // console.log(e)
				  that.clickid = e.id
				  that.cilicke = e
				  var arrdata = this.arrdata
				  // 打开盲盒--
				  var index = e.id-1
				  // arrdata.[index].finish = 1
				  
				 // 请求数据，告知后台点击了哪个
				  that.loadshow = true
				  that.iconloadshow=true
				  let newurl = this.$api + "/getClick"
				  this.$axios.post(newurl,{
					user_id:that.userid,
					box_number:e.id
				  })
				  .then((res) => {
					  console.log(res.data.result.type)
					  console.log(res.data)
					  if(res.data.code==20021){
						  // 说明点击次数到了
						  that.clicknumshow = true
					  }else{
						  that.redboxshow = true
						  that.shengnum = res.data.result.count
						  that.totalnum = res.data.result.user_count
						  that.resdata = res
						  arrdata.[index].type = res.data.result.type
					  }
					// console.log(res)
					// 关闭加载中
					that.loadshow = false
				  
				  })
				  .catch((err) => {
					console.log(err) //错误信息
				  })


		    },
			// 点击【领取红包按钮】
			getmoney(){
				var that = this
				that.loadshow = true
				
				var res = that.resdata
				var type = res.data.result.type
				if( type =='video'){
					that.redboxshow = false
					that.loadshow = false
					that.videowindowshow = true
					that.videolink = res.data.result.video_url //视频地址
					that.videopost = res.data.result.thumbnail //视频封面
					
					// 如果是视频的话，就让视频显示的时候，从0播放
					var vide=document.getElementById('video')
					vide.currentTime=0
					vide.volume = 1
					vide.oncanplay=function(){
						vide.play()
					}
					that.watchvideo()
				}else if(type =='payment'){
					that.iconloadshow = false
					that.paynum = res.data.result.amount //将金额赋值到变量
					that.takemoney() //获取到钱
					that.showmoney=false
				}
				
			},
			// 获取红包碎片
			takemoney(){
				var that = this
				// 先确定一下，盲盒是不是被打开过了
				if(that.cilicke.finish==1){
					// 提示用户之前已经领取过红包
					that.loadshow = false
					
					// 如果用户的点击次数已经用完，并且又点击的话，提示10次机会已经用完
					// if(that.shengnum==0){
					// 	that.clicknumshow = true
					// }
				}else{
					// 判断有没有拼图完成后的paymentid，有就是拼图完成的id
					// 没有的话，就是小红包了
					if(that.pinpayid==''){
						// 真正拿到钱
						let newurl = this.$api + "/saveFinish"
						this.$axios.post(newurl,{
							user_id:that.userid,
							box_number:that.clickid
						})
						.then((res) => {
							console.log(res)
							var payid = res.data.result.payment.id
							that.getqian(payid)
							
							// 红包领取完成，让finish为1
							var index = that.clickid-1
							that.arrdata[index].finish=1
							
						})
						.catch((err) => {
								console.log(err) //错误信息
						})
						
					}else{
						that.getqian(that.pinpayid)
					}
				}
				
				
			},
			// 获取红包碎片，换现金红包
			getqian(id){
				var that = this
				that.iconloadshow = false
				let newurl = this.$api + "/boxPayment"
				this.$axios.post(newurl,{
					user_id:that.userid,
					payment_id:id
				})
				.then((res) => {
					console.log(res)
					that.loadshow=false
				})
				.catch((err) => {
						console.log(err) //错误信息
				})
			},
			
			watchvideo(){
				var that = this
				var e = that.cilicke
				var vide=document.getElementById('video')
				vide.onended=function(){
					if(vide.ended){
						// 检测到视频播放完成，退出全屏，出拼图
						vide.pause()
						
						// if(that.shengnum==0){
						// 	that.clicknumshow = true
						// }
						
						// 如果盲盒没有打开过，就可以出拼图
						if(that.cilicke.finish!=1){
							// 视频播放完成，让finish为1
							var index = that.clickid-1
							that.arrdata[index].finish=1
							
							// 视频播放完成后，获取红包碎片，出拼图碎片
							that.loadshow = true //加载中动画出现
							let newurl = that.$api + "/saveFinish"
							that.$axios.post(newurl,{
								user_id:that.userid,
								box_number:e.id
							})
							.then((res) => {	
								  console.log(res)
								  
								  if(res.data.success==true){
										vide.pause()
										that.showimgs(res.data.code,res.data.result.imgs,res.data.result.payment)
								  }else{
									  // 有可能是出错了，也有可能是金额到达上限了
									  that.noredbox = true
								  }
								  //加载中图表隐藏
								  that.loadshow = false
							})
							.catch((err) => {
									console.log(err) //错误信息
							})
						}else{
							if(that.shengnum==0){
								that.clicknumshow = true
							}
						}
						
						// 视频播放完成，提示用户，10次抽奖机会已经用完
						// if(that.shengnum==0){
						// 	that.clicknumshow = true
						// }
						
					 }
				}
				

			},
			// 视频播放完成之后，展示图片
			showimgs(code,suiarr,payment){
				var that = this
				this.imgfragshow = true //拼图界面出现
				var vide=document.getElementById('video')
				vide.pause()
				
				// 说明还没全部获取到
				suiarr.map(item=>{
					if(item.video_number == '1'){
						that.pindi1 = item.image_url
					}else if(item.video_number == '2'){
						that.pindi2 = item.image_url
					}else if(item.video_number == '3'){
						that.pindi3 = item.image_url
					}else if(item.video_number == '4'){
						that.pindi4 = item.image_url
					}else if(item.video_number == '5'){
						that.pindi5 = item.image_url
					}else if(item.video_number == '6'){
						that.pindi6 = item.image_url
					}
				})
				
				console.log(payment)
				
				if(payment){
					that.paynum = payment.amount
					that.payment = payment
					
					// 说明拼完了，可以请求碎片了
					setTimeout(function(){
						that.loadshow = true //加载中动画出现
						that.iconloadshow = false
					},600)
					
					setTimeout(function(){
						that.loadshow = false //加载中动画隐藏
						that.iconloadshow = true
						// 关闭视频
						that.videowindowshow = false
						// 说明6个碎片全都获取到了，就出大红包
						that.imgfragshow=false //关闭拼图
						that.pinpayid = payment.id
						
						// 此时，出现拼图专用红包弹窗
						that.sixredboxshow=true
						that.sixshowmoney = true
					},1000)
				}

			},
			// 获取拼图弹窗，的大红包
			sixgetmoney(){
				var that = this
				this.sixshowmoney=false
				this.getqian(that.pinpayid)
			},
			// 关闭拼图完成后专用的红包弹窗
			sixclosered(){
				var that = this
				this.sixredboxshow=false
				if(that.shengnum==0){
					that.clicknumshow = true
				}
			},
			//点击碎片小叉号，关闭碎片弹窗，并且关闭视频窗口
			closeimgfrag(){
				var that = this
				var payment = that.payment

				if(payment==''){
					// 说明是还没有集齐6个拼图
					this.imgfragshow = false
					this.videowindowshow = false
					
					if(that.shengnum==0){
						that.clicknumshow = true
					}
				}else{
					this.imgfragshow = false
					this.videowindowshow = false
					// 说明拼完了，可以请求碎片了
					that.loadshow = true //加载中动画出现
					
					setTimeout(function(){
						that.loadshow = false //加载中动画隐藏
						// 说明6个碎片全都获取到了，就出大红包
						that.imgfragshow=false //关闭拼图
						that.pinpayid = payment.id
						// 此时，
						that.sixredboxshow=true //出现拼图专用红包弹窗
						that.sixshowmoney = true
					},1000)
				}
				
				
			},
			// 点击关闭视频，并将视频播放时间重置和暂停
			closevideo(){
				var that = this
				var videos=document.getElementById('video')
				if(!videos.ended){
					// 假如视频没播放完成，就点击退出，需要提醒用户，是否真的退出
					that.videndshow = true
				}else{
					that.videowindowshow = false
					var videos = document.getElementsByClassName('videos')[0]
					videos.pause()
				}
				
			},
			// 视频没有播放完成时，用户点击关闭视频的弹窗提示,点击了确定
			closewindow(){
				this.videndshow = false
				this.videowindowshow = false
				var videos = document.getElementsByClassName('videos')[0]
				videos.pause()
			},
			// 视频没有播放完成时，用户点击关闭视频的弹窗提示，点击了取消
			quxiao(){
				this.videndshow = false
			},
			// 显示规则弹窗
		    showrole:function(){
				this.roleshow = true
		    },
			// 关闭规则弹窗
		    closerole:function(){
		        this.roleshow = false
		    },
			// 关闭红包弹窗
			closered:function(){
				var that = this
				if(that.cilicke.finish==1){
					that.alreadywindowshow = true
				}
				
				if(that.payment == '' && that.shengnum==0){
					that.clicknumshow = true
				}
				
				this.videowindowshow = false
			    this.redboxshow = false
				this.showmoney = true
			},
			// 关闭提示只有点击机会的弹窗
			closeonly(){
				this.onlyshow = false
			},
			// 关闭提示用户已经没有点击机会了
			closeclick(){
				this.clicknumshow = false
			},
			//未登录状态下，点击盲盒列表，出登录授权
			showdenglu(){
				this.nodenglushow = true
			},
			// 重新登录
			againlogion(){
				var that = this
				var url = window.location.href
				// 判断是否是在微信浏览器打开的页面，是就执行登录
				var ua = navigator.userAgent.toLowerCase();
				if(ua.match(/MicroMessenger/i) != 'micromessenger'){
					//如果用户不是在微信中打开的，就显示遮罩
					// that.wechatnologin = true
				}else{
					  // 如果用户是在微信中打开的，就隐藏遮罩
					  that.wechatnologin = false
					
					  // 移动端用的是公众号的appid，pc端用的是公众平台的appid
					  var appid = 'wx799c2495cc0d2a30'
					  var urlx = 'http%3A%2F%2Fxjaikj.htkjbd.com%2Fredbox'
					  // let redirect_uri = encodeURIComponent(url)
					  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlx}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
					  // 跳转到loginsucess.vue页面，在域名上得到code
					  that.getlogin()
				}
			},
			//登录函数
			getlogin(){
				var that = this
				var url = window.location.href
				// 判断是否是在微信浏览器打开的页面，是就执行登录
				var ua = navigator.userAgent.toLowerCase();
				var usersmessage = localStorage.getItem('usermessage');
				var urlcode = that.getQueryVariable("code")

				if(ua.match(/MicroMessenger/i) != 'micromessenger'){
					//如果用户不是在微信中打开的，就显示遮罩
					// that.wechatnologin = true
				}else{
					// 如果用户是在微信中打开的，就隐藏遮罩
					that.wechatnologin = false
					
					if(!usersmessage && !urlcode){
					
						  // 移动端用的是公众号的appid，pc端用的是公众平台的appid
						  var appid = 'wx799c2495cc0d2a30'
						  var urlx = 'http%3A%2F%2Fxjaikj.htkjbd.com%2Fredbox'
						  // let redirect_uri = encodeURIComponent(url)
						  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlx}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
						  // 跳转到loginsucess.vue页面，在域名上得到code
					}else if(!usersmessage && urlcode){
						// 说明是跳转登录进来的，此时获取code和state，去换取user信息
						// console.log(urlcode)
						that.getuserinfo(urlcode)
					}
				}
				
				
				if(usersmessage){
					that.choujiangshow = true
					//关闭微信登录授权弹窗
					that.nodenglushow = false
					that.userid = JSON.parse(usersmessage).userid
					var userid = JSON.parse(usersmessage).userid
					that.getdata(userid)
					// if(that.arrdata.length==0){
					// 	that.getdata(userid)
					// }
					// 这里需要改
					that.share()
				}else{
					that.loadshow = false
				}
				
				// 页面一打开，先检查一下地址栏上有没有random_str，有的话，就是分享进来的，存在缓存中
				//然后当用户在任意页面登录了，就可以确定上下级关系
				var index = window.location.href.indexOf('random_str')
				var parent_id = ''
				var randomnum
				if(index>=0){
					var urlright = url.split("?random_str=")[1] 
					var newurl = urlright.split("&parent_id=")[0]
					localStorage.setItem('randomstr',newurl) //随机字符串
				
					if(usersmessage){
						
						// 如果用户登录了，就向后端提交用户id和random字符串
						// 这是查看的接口
						var userid = JSON.parse(usersmessage).userid
						var randomstr = newurl.split("=")[1]
						var newdata = {
							user_id:userid,
							random_str:randomstr
						}
						
						// console.log(usersmessage)
						
						//  保存浏览记录
						let savaurl = "https://htkja.htkjbd.com/api/save/brows" // 9-10 改动
						that.$axios.post(savaurl,newdata)
						.then((res) => {
							console.log(res)			  
						})
						.catch((err) => {
							console.log(err)
						})
						
						
						 
						 // 原来转发记录的保存逻辑，放在这了，为了出现浏览记录树  12.1
						 // 随机字符串存在，说明是分享进来的，就不需要再生成随机字符串
						 // 再从url中取到parent_id,作为参数提交给后端
						 // http://xjaikj.htkjbd.com/casecontent?id=47&random_str=103713579827849710&parent_id=28
						 randomnum = urlright.split("&parent_id=")[0]
						 parent_id = urlright.split("&parent_id=")[1]
						 
						 let nsurl = "https://htkja.htkjbd.com/api/saveBrowse2"
						 that.$axios.post(nsurl,{
								 user_id:userid,
								 random_str:randomnum,
								 project_id:that.projectid,   //这个项目id
								 parent_id:parent_id 
							 })
						 .then((res) => {
						 	console.log(res)			  
						 })
						 .catch((err) => {
						 	console.log(err)
						 })
						 
					}
				}
			},
			// 获取地址栏上的参数的函数
			getQueryVariable(variable){
			       var query = window.location.search.substring(1);
			       var vars = query.split("&");
			       for (var i=0;i<vars.length;i++) {
					   var pair = vars[i].split("=");
					   if(pair[0] == variable){return pair[1];}
			       }
			       return(false);
			},
			getuserinfo(code){
				var newdata
				var that = this
				//查看本地存储中有没有随机字符串，如果有，说明是从分享进来的
				var newrandomstr = localStorage.getItem('randomstr');
				if(newrandomstr){
					var randomstr = newrandomstr.split("=")[1]  
				}
				
				// 假如是移动端的登录，请求的参数的appid就是在缓存中，缓存中没有，就用公众平台的
				// 如果本地有随机字符串，说明是分享进来的，那么参数中也带上这个随机字符串
					if(randomstr){
						newdata = {
							params:{
								appid:'wx799c2495cc0d2a30',
								random_str:randomstr,
							}
						}
			
					}else{
						
						newdata = {
							params:{
								appid:'wx799c2495cc0d2a30',
							}
						}
					}
					
					var urlcookie = document.cookie
					
					
					//调取接口返回验证码
					let url = 'http://xuanchuanpian.xjaikj.com/api/get/AuthCode/'+ code
					this.$axios.get(url, newdata)
					.then((res) => {
						console.log(res)
						// 将假的盲盒列表隐藏，真的列表显示
						that.choujiangshow = true
						//关闭微信登录授权弹窗
						that.nodenglushow = false
						that.userid = res.data.result.id
						
						that.getdata(res.data.result.id)
						var name = res.data.result.nickname
						var id = res.data.result.id
						var adminid = res.data.result.is_admin
						var usermessage = {
							username:name,
							userid:id,
							adminid:adminid
						}
						localStorage.setItem('usermessage',JSON.stringify(usermessage));
					})
					.catch((err) => {
					    console.log(err)
						// 用户点击了取消授权，在页面中间或底部，增加重新授权接口，并执行getdata
						that.loadshow = false
						that.nodenglushow = true
					})
					
			},
			// 如果红包领取人数已经到达上限，需要弹窗提醒
			closenored(){
				this.noredbox=false
			},
			// 分享函数
			share(){
				var that = this
				var shareUrl  = window.location.href;
				var usersmessage = localStorage.getItem('usermessage');
				var userid = JSON.parse(usersmessage).userid
				var username = JSON.parse(usersmessage).username
				var randomnum
				var parent_id = '' // 9-10 改动
				var urlright = '' //9-13改动
				
				var index = window.location.href.indexOf('random_str')
				
				if(index>=0){
					// 随机字符串存在，说明是分享进来的，就不需要再生成随机字符串
					// 再从url中取到parent_id,作为参数提交给后端
					// http://xjaikj.htkjbd.com/casecontent?id=47&random_str=103713579827849710&parent_id=28
					urlright = shareUrl.split("?random_str=")[1]
					randomnum = urlright.split("&parent_id=")[0]
					parent_id = urlright.split("&parent_id=")[1]

				}else{
					
					//12.1
					//如果地址栏上没有随机字符串，说明不是分享进来的，那么随机生成字符串
					//再生成一个父级id，并存入到url中，传递给下一个人用，注意不要作为参数
					randomnum = JSON.stringify(Math.ceil(Math.random()*125895123628588596))
					
					// 说明不是分享进来的话，那么此时请求保存浏览记录接口，去获取parent_id
					this.$axios.post('https://htkja.htkjbd.com/api/saveBrowse2',{
						 user_id:userid,
						 random_str:randomnum,
						 project_id:that.projectid,   //这个项目id
					 })
					.then(function (res) {
						 // console.log(res)
						 parent_id = res.data.result.id	
						 
					 }).catch(function (err) {
						 console.log(err)
					 })  
					
				}
				
				// 防止上面的接口请求异步
				setTimeout(function(){
					
					var newshareurl = shareUrl.split("&random_str=")[0]
					//微信分享功能实现
					that.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
					.then(function(res) {
							//分享链接
							// console.log(res)
							wx.config({
								debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
								appId: res.data.result.appId, // 必填，公众号的唯一标识
								timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳
								nonceStr: res.data.result.nonceStr, // 必填，生成签名的随机串
								signature: res.data.result.signature,// 必填，签名，见附录1
								jsApiList: [
									'onMenuShareAppMessage',//分享给朋友
									'onMenuShareTimeline', //分享到朋友圈
								] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
							});
							wx.ready(function(){
								wx.onMenuShareAppMessage({
									title: username +'给您拜年送红包啦!', // 分享标题
									desc: '欢迎参与虎福迎春互动抽奖活动，点击收取我们的新年祝福吧 ——沉浸数字交互服务商', // 分享描述
									link: newshareurl + "?random_str=" + randomnum + "&parent_id=" + parent_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
									imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/share.png', // 分享图标
									success: function () {
										// alert('33')
									},
									cancel: function () {
										// alert('false');
									}
								});
								wx.onMenuShareTimeline({
									title: username +'给您拜年送红包啦!', // 分享标题
									link: newshareurl + "?random_str=" + randomnum + "&parent_id=" + parent_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
									imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/share.png', // 分享图标
									success: function () {
										// alert('33')
									},
									cancel: function () {
										// alert('false');
									}
								});
											
							});
						 
					})
					.catch((err) => {
						console.log(err) //错误信息
					})
					
					
				},500)


			}
			// 分享结束
			
	  }
	}
	
</script>

<style>
	 ul,li{
	   list-style: none;
	 }
	 /* 加载中动画 */
	 .loadwai{
		 width: 100%;
		 height: 100vh;
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 999;
		 display: flex;
		 justify-content: center;
		 align-items: center;
	 }
	 .loadwai img{
		 width: 200%;
		 height: auto;
		 z-index: 2;
	 }
	 .loadyin{
		 width: 100%;
		 height: 100vh;
		 position: absolute;
		 left: 0;
		 top: 0;
		 z-index: 1;
		 background-color: rgba(0,0,0,0.2);
	 }
	 /* 用户未授权登录出现的界面 */
	 .nodenglu{
		 width: 100%;
		 height: 100vh;
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 999;
		 display: flex;
		 justify-content: center;
		 align-items: center;
	 }
	 .nodengluyin{
		 width: 100%;
		 height: 100vh;
		 position: absolute;
		 left: 0;
		 top: 0;
		 z-index: 1;
		 background-color: rgba(0,0,0,0.2);
	 }
	 .nodenglucont{
		 width: 80%;
		 height: 300px;
		 background-color: #fff;
		 z-index: 2;
		 border-radius: 5px;
		 padding: 15px;
		 box-sizing: border-box;
	 }
	 .nodenglutitle{
		 width: 100%;
		 text-align: left;
		 font-size: 18px;
		 font-weight: bold;
	 }
	 .nodengludes{
		 width: 100%;
		 text-align: left;
		 font-size: 15px;
		 margin-top: 10px;
	 }
	 .nodengluimg{
		 width: 100%;
		 display: flex;
		 align-items: center;
		 margin-top: 30px;
	 }
	 .nodengluimg img{
		 width: 40px;
		 height: 40px;
		 border-radius: 50%;
	 }
	 .nodengluimg div{
		 font-size: 15px;
		 margin-left: 10px;
		 color: #757070;
	 }
	 .nodengluname{
		 width: 100%;
		 margin-top: 25px;
		 border-top: #d7d7d7 1px solid;
		 text-align: left;
		 font-size: 14px;
		 padding-top: 10px;
		 color: #999999;
	 }
	 .nodenglubtn{
		 width: 100%;
		 display: flex;
		 justify-content: flex-end;
		 height: 30px;
		 margin-top: 20px;
	 }
	 .nodenglubtn div{
		 width: 50%;
		 color: #32A91D;
		 font-weight: bold;
		 text-align: right;
	 }
	 /* 提示请在微信中打开 */
	 .weixintip{
		 width: 100vw;
		 height: 100vh;
		 background-color: #fff;
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 999;
		 display: flex;
		 justify-content: center;
		 align-items: center;
	 }
	 .centercont{
	   width: 100%;
	   height: 100%;
	   padding-top: 20px;
	   padding-bottom: 100px;
	   background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/newbg.png);
	   background-size: 100% 100%;
	   box-sizing: border-box;
	   /* padding-top: 440px; */
	   position: relative;
	 }
	 .qianbao{
		 width: 100%;
		 height: 200px;
		 position: absolute;
		 left: 0px;
		 top: 50px;
		 z-index: 6;
		 background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/qianbao.png);
		 background-size: 100% auto;
		 background-repeat: no-repeat;
	 }
	 .titlewai{
	   width: 100%;
	   height: 230px;
	   overflow: hidden;
	   display: flex;
	   justify-content: center;
	   flex-wrap: wrap;
	 }
	 .titleimg{
	    width: 80%;
	    height: 230px;
	    background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/tiger.png);
	    background-size: 100% auto;
	    background-repeat: no-repeat;
	 }
	 .peoplenum{
		 width: 100%;
		 display: flex;
		 justify-content: center;
		 margin-top: 5px;
		 color: #fff;
		 letter-spacing: 1px;
	 }
	 /* 领取者信息滚动区域 */
	 .page-section{
	   margin: 0 auto;
	   width: 100%;
	   height: 90px;
	   box-shadow:0 0 6px rgba(153, 153,153, 0.2);
	   position: fixed;
	   top: 20px;
	   left: 5px;
	   z-index: 10;
	   /* display: flex;
	   justify-content: center; */
	 }
	 
	 .section-swiper{
	   height: 90px;
	   overflow: hidden;
	  /* background-color: aqua;
	   border: red 1px solid;
	   box-sizing: border-box; */
	 }
	 .swiper-wrap {
	   width: 100%;
	   height: 90px;
	   position: relative;
	  }
	  .swiper-slide{
		  /* height: 90px; */
		 /* display: flex;
		  justify-content: center;
		  align-items: center; */
	  }
	  
	  
	  .swiper-view{
	   width: 92%;
	   height: 30px !important;
	   display: flex;
	   align-items: center;
	   /* justify-content: space-between; */
	   padding-left: 8px;
	   padding-right: 8px;
	   /* background-color: black; */
	   background-color: rgba(0,0,0,0.7);
	   opacity: 0.7;
	   border-radius: 30px;
	   position: absolute;
	   bottom: 0;
	   animation: dropdown 6s linear infinite;
	 }
	  @keyframes dropdown {
	      0% {
	        transform: translateY(0);
	        opacity: 0;
	      }
	      15% {
	       transform: translateY(0);
	       opacity: 0;
	      }
	      20% {
	       transform: translateY(-15px);
	       opacity: 0.3;
	      }
	      30% {
	       transform: translateY(-30px);
	       opacity: 1;
	      }
	      /* 悬停阶段 */
	      70% {
	       transform: translateY(-30px);
	       opacity: 1;
	      }
	      85% {
	       transform: translateY(-45px);
	       opacity: 0;
	      }
	      100% {
	       transform: translateY(-60px);
	       opacity: 0;
	      }
	  }
	 
	 .swipername{
		 width: 14%;
		 height: 30px;
		/* display: flex;
		 align-items: center; */
		 word-break: break-all;
		 text-overflow: ellipsis;
		 display: -webkit-box;
		 -webkit-box-orient: vertical;
		 -webkit-line-clamp: 1; /* 超出几行省略 */
		 overflow: hidden;
		 margin-left: 15px;
	 }
	 .swiper-time{
		 margin-left: 12px;
	 }
	 .swiper-text{
		 display: block;
		 width: 100%;
		 text-align: left;
	 }
	 .swiper-view img{
	   width: 23px;
	   height: 23px;
	   border-radius: 50% 50%;
	 }
	 .swiper-view span{
	   color: #9b9a9a;
	   font-size: 13px;
	   line-height: 30px;
	   text-align: left;
	 }
	 
	 /* 盲盒区 */
	 .chouzuiwai{
	   width: 100%;
	   height: 360px;
	   margin-top: -20px;
	 }
	 .chouwai{
	   width: 90%;
	   height: 360px;
	   background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/centerbg.png);
	   background-size: 100% 100%;
	   margin: 0 auto;
	   position: relative;
	 }
	 .choucent{
	   width: 90.5%;
	   height: 93%;
	   /* background-color: rgba(0,0,0,0.6); */
	   position: absolute;
	   left: 18px;
	   /* top:0px; */
	   /* 这里 */
	   top:15px;
	   border-radius: 17px;
	   box-sizing: border-box;
	   display: flex;
	   flex-wrap: wrap;
	   padding:  0;
	 }
	 .choulist{
	   width: 33.33333%;
	   height: 23%;
	   margin-top: 5px;
	   display: flex;
	   justify-content: center;
	 }
	 .choulist1{
	   width: 90%;
	   height: 90%;
	   background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/listother.png);
	   background-size: 100% 100%;
	   display: flex;
	   justify-content: center;
	   align-items: center;
	 }
	 .choulistimg1{
		 width: 53%;
		 height: auto;
	 }
	 .choulistimg2{
		 width: 58%;
		 height: auto;
	 }
	 .choulistimg3{
		 width: 53%;
		 height: auto;
	 }
	 .noclick{
	    background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/no.png) !important;
	    background-size: 100% 100%;
	 }
	 
	 /* 抽奖底部按钮 */
	 .choubtnwrap{
		 width: 100%;
		 display: flex;
		 justify-content: center;
		 height: 50px;
	 }
	 .choubtn{
		 width: 90%;
		 height: 40px;
		 background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/btn.png);
		 background-size: 100% 100%;
		 display: flex;
		 justify-content: center;
		 /* align-items: center; */
		 color: #fff;
		 font-weight: bold;
		 line-height: 36px;
		 font-size: 15px;
		 letter-spacing: 1px;
	 }
	 /* 公司logo区域 */
	 .logowai{
		 width: 100%;
		 /* height: 80px; */
		 display: flex;
		 justify-content: center;
		 align-items: flex-start;
		 flex-wrap: wrap;
		 margin-top: 10px;
	 }
	 .logocont{
		 width: 90%;
		 height: 43px;
		 background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/gongsi.png);
		 background-size: 100% auto;
		 background-repeat: no-repeat;
		 display: flex;
		 justify-content: center;
		 align-items: center;
	 }
	.logotext{
		width: 65%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #EEE8E8;
		font-weight: bold;
		line-height: 30px;
		font-size: 14px;
		letter-spacing: 1px;
	}
	.botomabout{
		width: 65%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #EEE8E8;
		/* font-weight: bold; */
		line-height: 20px;
		font-size: 9px;
		letter-spacing: 1px;
		/* margin-top: 10px; */
	}
	 /* 视频弹出层 */
	 .videowindow{
	   width: 100vw;
	   height: 100vh;
	   position: fixed;
	   left: 0;
	   top: 0;
	   z-index: 100;
	   display: flex;
	   justify-content: center;
	   align-items: center;
	 }
	 .videoyin{
	  width: 100vw;
	  height: 100vh;
	  position: absolute;
	  left: 0;
	  top: 0; 
	  background-color: rgba(0,0,0,0.5);
	  z-index: 1;
	 }
	 .videos{
	   /* width: 80%;
	   height: 80%; */
	   width: 290px;
	   height: 515px;
	   z-index: 2;
	 }
	 /* 提示用户，此时退出视频，将无法获取到拼图碎片 */
	 .suitip{
		 width: 100%;
		 height: 100vh;
		 position: fixed;
		 left: 0;
		 top: 0;
		 display: flex;
		 justify-content: center;
		 align-items: center;
		 z-index: 999;
	 }
	 .suiyin{
		 width: 100%;
		 height: 100vh;
		 position: absolute;
		 left: 0;
		 top: 0;
		 background-color: rgba(0,0,0,0.5);
		 z-index: 1;
	 }
	 .suicont{
		 width: 70%;
		 height: 150px;
		 background-color: #fff;
		 z-index: 2;
		 border-radius: 5px;
	 }
	 /* 活动结束 */
	 .huodongyin{
		 width: 100%;
		 height: 100vh;
		 position: absolute;
		 left: 0;
		 top: 0;
		 background-color: rgba(0,0,0,0.7);
		 z-index: 1;
	 }
	 .huodong{
		 width: 100%;
		 height: 100%;
		 display: flex;
		 justify-content: center;
		 align-items: center;
		 font-size: 15px;
		 font-weight: bold;
	 }
	 /* 活动结束 */
	 .suiconttop{
		 width: 92%;
		 margin: 0 auto;
		 padding-top: 20px;
		 font-size: 14px;
		 font-weight: bold;
		 text-align: left;
	 }
	 .suicontbtn{
		 display: flex;
		 justify-content: center;
		 margin-top: 45px;
	 }
	 .suicontbtn div{
		 width: 80px;
		 height: 30px;
		 background-color: orange;
		 text-align: center;
		 line-height: 30px;
		 color: #fff;
		 border-radius: 5px;
		 font-size: 14px;
	 }
	 .suicontbtn div:nth-of-type(1){
		 background-color: #f56c6c;
	 }
	 .suicontbtn div:nth-of-type(2){
		 margin-left: 20px;
	 }
	 /* 弹窗提示只有10次点击机会 */
	 .onlytip{
		 width: 70%;
		 height: 120px;
		 background-color: #fff;
		 z-index: 2;
		 border-radius: 5px;
	 }
	 .onlytipbtn{
		 display: flex;
		 justify-content: center;
		 margin-top: 30px;
	 }
	 .onlytipbtn div{
		 width: 80px;
		 height: 30px;
		 background-color: orange;
		 text-align: center;
		 line-height: 30px;
		 color: #fff;
		 border-radius: 5px;
		 font-size: 14px;
	 }
	 /* 碎片展示区 */
	 .imgfragwai{
		 width: 100%;
		 height: 100vh;
		 position:fixed;
		 left: 0;
		 top: 0;
		 z-index: 999;
		 display: flex;
		 justify-content: center;
		 align-items: center;
	 }
	 .imgfragyin{
		 width: 100%;
		 height: 100vh;
		 position:absolute;
		 left: 0;
		 top: 0;
		 z-index: 1;
		 background-color: rgba(0,0,0,0.6);
	 }
	 .imgfragcenter{
		 width: 310px;
		 height: 500px;
		 z-index: 2;
		 background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pintubgs.png');
		 background-repeat: no-repeat;
		 background-size: 100% 100%;
		 position: relative;
	 }
	 .closeimg{
		 position: absolute;
		 z-index: 3;
		 top: -50px;
		 right: 0px;
		 color: #fff;
		 font-size: 30px !important;
	 }
	 .imgfrag{
		 /* width: 90%; */
		 width: 270px;
		 height: 180px;
		 background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/nepinbg.png?x-oss-process=style/avatar_375');
		 background-repeat: no-repeat;
		 background-size: 100% 100%;
		 z-index: 2;
		 display: flex;
		 justify-content: center;
		 align-items: center;
		 position: absolute;
		 left: 20px;
		 bottom: 100px;
	 }
	 .gongxi{
		 position: absolute;
		 top: -40px;
		 left: 0;
		 width: 100%;
		 text-align: center;
		 color: #fff;
		 font-family: '微软雅黑';
		 font-style: normal;
	 }
	 .imgfragcont{
		 width: 95%;
		 height: 92%;
		 display: flex;
		 justify-content: space-between;
		 flex-wrap: wrap;
		 position: relative;
	 }
	 .imgfragcont div{
		 width: 32%;
		 height: 48%;
		 position: relative;
	 }
	 .fraglist4,.fraglist5,.fraglist6{
		 margin-top: 6px;
	 }
	 .fraglist1 img{
		 width: 100%;
		 height: 100%;
	 }
	 .fraglist2 img{
		 width: auto;
		 height: 100%;
		 position: absolute;
		 left:-18px;
	 }
	 .fraglist3 img{
	 	 width: 100%;
	 	 height: 100%;
	 }
	 .fraglist4 img{
	 	 width: 100%;
	 	 height: 100%;
	 }
	 .fraglist5 img{
		 width: auto;
		 height: 124%;
		 position: absolute;
		 top: -20px;
		 left:-19px;
	 }
	 .fraglist6 img{
	 	 width: 100%;
	 	 height: 100%;
	 }
	 
	 .imgfragbot{
		 width: 100%;
		 position: absolute;
		 left: 0;
		 bottom: 15px;
	 }
	 .imgfragbottop{
		 width: 100%;
		 display: flex;
		 justify-content: center;
		 margin-bottom: 10px;
	 }
	 .imgfragbotbtn{
		 width: 150px;
		 height: 40px;
		 background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/getbtn.png');
		 background-repeat: no-repeat;
		 background-size: 100% 100%;
		 text-align: center;
		 line-height: 40px;
		 color: #fff;
		 font-weight: bold;
		 font-size: 16px;
	 }
	 .imgfragbottext{
		 width: 100%;
		 text-align: center;
		 color: #fce3a6;
		 font-size: 14px;
	 }
	 
	 /* 活动规则文字 */
	 .texttip{
	   width: 100%;
	   /* height: 30px; */
	 }
	 .textcont{
	  width: 88%;
	  margin: 0 auto;
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	 }
	 .textcont div{
	   color: #fff;
	   font-size: 13px;
	   line-height: 16px;
	 }
	 .texticon{
		 width: 30px;
		 height: 30px;
		 background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/guizedian.png);
		 background-size: 100% 100%;
	 }
	 /* 阿里巴巴 */
	 @font-face {
	   font-family: "iconfont"; /* Project id 3054141 */
	   src: url('//at.alicdn.com/t/font_3054141_pnealybbduk.woff2?t=1641287824603') format('woff2'),
	        url('//at.alicdn.com/t/font_3054141_pnealybbduk.woff?t=1641287824603') format('woff'),
	        url('//at.alicdn.com/t/font_3054141_pnealybbduk.ttf?t=1641287824603') format('truetype');
	 }
	 
	 .iconfont {
	   font-family: "iconfont" !important;
	   font-size: 16px;
	   font-style: normal;
	   -webkit-font-smoothing: antialiased;
	   -moz-osx-font-smoothing: grayscale;
	 }
	 
	 .icon-close:before {
	   content: "\e60a";
	 }
	 
	 .icon-fengexian:before {
	   content: "\e64f";
	 }
	 
	 .icon-chouti:before {
	   content: "\e63e";
	 }
	 
	 .icon-bofang:before {
	   content: "\e624";
	 }
	 
	 .icon-zanting:before {
	   content: "\e629";
	 }

	/* 阿里巴巴结束 */
	/* 红包弹出层 */
	.closespan{
		font-size: 30px;
		position: absolute;
		right: -20px;
		top: -40px;
		color: #fff;
	}
	.redcenter{
	  width: 300px;
	  height: 500px;
	  /* background-color: #fff; */
	  background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/redget.png');
	  background-size: 100% 100%;
	  z-index: 2;
	  border-radius: 15px;
	  padding-top: 15px;
	  box-sizing: border-box;
	  position: relative;
	}
	.redcont{
	  width: 90%;
	  margin: 10px auto 0;
	  font-size: 15px;
	  line-height: 20px;
	  text-align: left;
	}
	.redtoptext{
		width: 100%;
		text-align: center;
		position: absolute;
		left: 0;
		top: 70px;
	}
	.redtoptext1{
		font-size: 18px;
		font-weight: bold;
		color: #fce3a6;
	}
	.redtoptext2{
		font-size: 14px;
		color: #fce3a6;
		margin-top: 3px;
	}
	.redbto{
		width: 100%;
		/* height: 48%; */
		/* background-color: antiquewhite; */
		/* opacity: 0.4; */
		position: absolute;
		left: 0;
		bottom: 15px;
		z-index: 999;
	}
	.redbottitle{
		width: 90%;
		font-size: 8px;
		line-height: 16px;
		text-align: left;
		color: #fff;
		letter-spacing: 1px;
		margin: 3px auto 0;
	}
	.redbottxt{
		width: 90%;
		font-size: 8px;
		line-height: 16px;
		font-weight: bold;
		text-align: left;
		color: #ffc682;
		letter-spacing: 1px;
		margin: 3px auto 0;
	}
	.redbotgongsi{
		width: 90%;
		font-size: 8px;
		line-height: 16px;
		font-weight: bold;
		text-align: center;
		color: #ffc682;
		letter-spacing: 1px;
		margin: 5px auto 0;
	}
	.lingqubtn{
		width: 60%;
		height: 40px;
		background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/getbtn.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		margin: 0px auto 0;
		font-size: 17px;
		line-height: 36px;
		font-weight: bold;
		text-align: left;
		color: #fff;
		text-align: center;
		letter-spacing: 1px;
	}
	/* 拼图完成后，出的红包独立封面 */
	.pincheng{
		width: 320px;
		height: 370px;
		background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pincheng.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		z-index: 2;
		position: relative;
	}
	.pinchengcont{
		width: 100%;
		display: flex;
		justify-content: center;
		position: absolute;
		left: 0;
		bottom: 30px;
	}
	.pinchengbtn{
		width: 200px;
		height: 80px;
		background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/pinchengbtn.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
	}
	

	/* 获取的红包钱数 */
	.redsucess{
		width: 280px;
		height: 350px;
		/* background-color: #fff; */
		background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/getmoney.png');
		background-size: 100% 100%;
		z-index: 2;
		border-radius: 15px;
		padding-top: 15px;
		box-sizing: border-box;
		position: relative;
	}
	.redsucesstop{
		width: 100%;
		height: 45%;
		/* background-color: green; */
		position: absolute;
		left: 0;
		top: 20px;
		z-index: 999;
	}
	.redsucesstoptext{
		width: 85%;
		height: 24px;
		margin: 20px auto 15px;
		font-size: 18px;
		line-height: 36px;
		font-weight: bold;
		text-align: left;
		color: #505050;
		text-align: center;
		letter-spacing: 1px;
	}
	.redsucesstext{
		color: #fce3a6;
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 10px;
		width: 100%;
		text-align: center;
		font-size: 13px;
	}
	.pricewai{
		width: 50%;
		margin: 0px auto 0;
		display: flex;
		align-items: flex-end;
	}
	.priceicon{
		font-size: 30px;
		font-weight: bold;
		color: #dd414c;
		/* background-color: yellow; */
		line-height: 40px;
	}
	.price{
		font-size: 50px;
		font-weight: bold;
		color: #dd414c;
		height: 50px;
		/* background-color: green; */
		line-height: 50px;
		margin-left: 10px;
	}
	.redsucessbto{
		width: 100%;
		height: 40%;
		/* background-color: antiquewhite; */
		/* opacity: 0.4; */
		position: absolute;
		left: 0;
		bottom: 0px;
		z-index: 999;
	}
	.redsucessbtotitle{
		width: 85%;
		margin: 10px auto 5px;
		font-size: 18px;
		line-height: 24px;
		font-weight: bold;
		text-align: left;
		color: #ffc682;
		text-align: center;
		letter-spacing: 1px;
	}
	.redsucessbottxt{
		width: 85%;
		margin: 5px auto 10px;
		font-size: 13px;
		line-height: 14px;
		font-weight: bold;
		text-align: left;
		color: #ffc682;
		text-align: center;
		letter-spacing: 1px;
	}
	/* 底部规则横线 */
	/* .leftheng{
	  color: #fff;
	  font-size: 60px;
	  margin-right: 10px;
	  line-height: 60px;
	}
	.rightheng{
	  color: #fff;
	  font-size: 60px;
	  margin-left: 10px;
	} */
	/* 规则提示弹窗 */
	.rolewindow{
	  width: 100vw;
	  height: 100vh;
	  position: fixed;
	  left: 0;
	  top: 0;
	  z-index: 100;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}
	.roleyin{
	  width: 100vw;
	  height: 100vh;
	  position: absolute;
	  left: 0;
	  top: 0; 
	  background-color: rgba(0,0,0,0.5);
	}
	.rolecontent{
	  width: 310px;
	  height: 550px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  position: relative;
	  z-index: 2;
	}
	.rolebtnwai{
		width: 100%;
		display: flex;
		justify-content: center;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 2;
	}
	.rolebtn{
		 width: 150px;
		 height: 40px;
		 background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/rolebtn.png');
		 background-repeat: no-repeat;
		 background-size: 100% 100%;
		 text-align: center;
		 line-height: 40px;
		 color: #fff;
		 font-weight: bold;
		 font-size: 16px;
	}
	.rolecenter{
	  width: 310px;
	  height: 450px;
	  background-image: url('https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/newmanghe/rolebg.png');
	  background-repeat: no-repeat;
	  background-size: 100% 100%;
	  /* background-color: #fff; */
	  z-index: 2;
	  border-radius: 15px;
	  padding-top: 15px;
	  box-sizing: border-box;
	  position: relative;
	}
	.rolecenter img{
		width: 100%;
		position: absolute;
		top: -10px;
		left: 15px;
	}
	.roletitle{
	  width: 100%;
	  text-align: center;
	  display: block;
	  letter-spacing: 1px;
	  font-size: 16px;
	  font-weight: bold;
	}
	.rolecont{
	  width: 90%;
	  margin: 10px auto 0;
	  font-size: 15px;
	  line-height: 20px;
	  text-align: left;
	}
	.roletext{
		margin-top: 10px;
	}
	.title1{
		font-size: 15px;
		line-height: 20px;
		text-align: left;
		color: red;
		font-weight: bold;
	}
	.text1{
		font-size: 14px;
		line-height: 20px;
		font-weight: bold;
	}
	.text1 span{
		font-size: 14px;
		line-height: 20px;
		color: blue;
		font-weight: bold;
	}


</style>
