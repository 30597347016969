<template>
	<div>
		<div class="bannertop">
			<!-- 顶部导航栏 -->
			<Headernew/> 
			<div class="bannertop-black">
				<div class="wow fadeInUp bannertop-title" >
					<div>新闻动态</div>
				</div>
			</div>
		</div>
		
		<!-- 新闻列表区 -->
		<div class="articleWrap">
			<div class="articlelist" v-for="(item,index) in articlelist" @click="gotoarticle(item.id)">
				<div>{{item.name}}</div>
				<div>{{item.updated_at}}</div>
			</div>
		</div>
		
		<!-- 分页器 -->
		<div class="paginationWrap">
			<v-pagination :total="total" :current-page='current' :display='display' @pagechange="pagechange"></v-pagination>
		</div>
		
		
		<Footernew/>
	</div>

</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	
	import pagination from '@/components/pagination.vue'
	export default {
		components: {
		  Headernew,
		  Footernew,
		  'v-pagination': pagination,
		},
	    data () {
	      return {
			  articlelist:[],
			  total: 150,     // 记录总条数
			  display: 20,   // 每页显示条数
			  current: 1,   // 当前的页数
			}
	    },
	    created(){
	      this.getParams()
	    },
	    methods:{
	      getParams() {
				let newurl = this.$api + "/get/Articles"
				this.$axios.get(newurl,{
					params:{
						page:1,
						limit:20
					}
				})
				.then((res) => {
					console.log(res)
					this.articlelist = res.data.result.data
					this.total = res.data.result.count
				})
				.catch((err) => {
					console.log(err)
				})
	      },
		  pagechange:function(currentPage){
			 console.log(currentPage);//该参数就是当前点击的页码数
			 // ajax请求, 向后台发送 currentPage, 来获取对应的数据
			 let newurl = this.$api + "/get/Articles"
			 this.$axios.get(newurl,{
			 	params:{
			 		page:currentPage,
			 		limit:1
			 	}
			 })
			 .then((res) => {
			 	console.log(res)
			 	this.articlelist = res.data.result.data
			 	this.total = res.data.result.count
			 })
			 .catch((err) => {
			 	console.log(err)
			 })
		   },
		   gotoarticle:function(id){
			   // 点击文章列表，进入文章详情页面
			   this.$router.replace({name: "articledetail", query:{id: id}})
		   }

	    }
	  }
</script>

<style>
	body{
		margin: 0;
		padding: 0;
	}
	.bannertop{
		width: 100%;
		height: 500px;
		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/banner-144.jpg);
		background-size: 100% auto;
		background-repeat: no-repeat;
	}
	.bannertop-black{
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.3);
		position: relative;
	}
	.bannertop-title{
		width: 100%;
		position: absolute;
		left: 0;
		top: 40%;
	}
	.bannertop-title div:nth-of-type(1){
		font-size: 61px;
		color: #fff;
		font-weight: bold;
	}
	.bannertop-title div:nth-of-type(2){
		font-size: 31px;
		color: #fff;
		margin-top: 35px;
		letter-spacing: 2px;
	}
	/* 文章列表 */
	.articleWrap{
		width: 60%;
		margin: 50px auto 0;
	}
	.articlelist{
		width: 100%;
		height: 60px;
		background-color: #eeeeee;
		margin-bottom: 20px;
		padding: 0 15px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 5px;
	}
	.articlelist:hover{
		cursor: pointer;
	}
	.articlelist div:nth-of-type(1){
		width: 70%;
		font-size: 18px;
		letter-spacing: 1px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap; 
		text-align: left;
	}
	.articlelist div:nth-of-type(2){
		width: 20%;
		text-align: right;
		font-size: 18px;
		letter-spacing: 1px;
	}
	/* 分页器组件引用 */
	.paginationWrap{
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 30px 0 50px 0;
	}

	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		.bannertop{
			width: 100%;
			height: 250px;
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/banner-144.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
		.bannertop-title{
			width: 90%;
			position: absolute;
			left: 5%;
			top: 40%;
		}
		.bannertop-title div:nth-of-type(1){
			font-size: 20px;
			color: #fff;
			font-weight: bold;
		}
		.bannertop-title div:nth-of-type(2){
			font-size: 14px;
			color: #fff;
			margin-top: 10px;
			letter-spacing: 2px;
		}
		/* 文章列表 */
		.articleWrap{
			width: 90%;
			margin: 30px auto 0;
		}
		.articlelist{
			width: 100%;
			height: 40px;
			background-color: #eeeeee;
			margin-bottom: 20px;
			padding: 0 15px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 5px;
		}
		.articlelist:hover{
			cursor: pointer;
		}
		.articlelist div:nth-of-type(1){
			width: 50%;
			font-size: 15px;
			letter-spacing: 1px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space:nowrap; 
			text-align: left;
		}
		.articlelist div:nth-of-type(2){
			width: 50%;
			text-align: right;
			font-size: 14px;
			letter-spacing: 1px;
		}
		/* 分页器组件引用 */
		.paginationWrap{
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 30px 0 50px 0;
		}
	}
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.bannertop{
			width: 100%;
			height: 350px;
		}
		.bannertop-title{
			width: 80%;
			position: absolute;
			left: 10%;
			top: 40%;
		}
		.bannertop-title div:nth-of-type(1){
			font-size: 24px;
			color: #fff;
			font-weight: bold;
		}
		.bannertop-title div:nth-of-type(2){
			font-size: 16px;
			color: #fff;
			margin-top: 20px;
			letter-spacing: 2px;
		}
		/* 文章列表 */
		.articleWrap{
			width: 70%;
			margin: 30px auto 0;
		}
		.articlelist{
			width: 100%;
			height: 50px;
			background-color: #eeeeee;
			margin-bottom: 20px;
			padding: 0 15px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 5px;
		}
		.articlelist:hover{
			cursor: pointer;
		}
		.articlelist div:nth-of-type(1){
			width: 50%;
			font-size: 15px;
			letter-spacing: 1px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space:nowrap; 
			text-align: left;
		}
		.articlelist div:nth-of-type(2){
			width: 50%;
			text-align: right;
			font-size: 14px;
			letter-spacing: 1px;
		}
		/* 分页器组件引用 */
		.paginationWrap{
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 30px 0 50px 0;
		}
	}
</style>
