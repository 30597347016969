import { render, staticRenderFns } from "./signdetailadd.vue?vue&type=template&id=473249f8&scoped=true&"
import script from "./signdetailadd.vue?vue&type=script&lang=js&"
export * from "./signdetailadd.vue?vue&type=script&lang=js&"
import style0 from "./signdetailadd.vue?vue&type=style&index=0&id=473249f8&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473249f8",
  null
  
)

export default component.exports