<template>
	<div>
		<!-- 留言按钮 -->
		<Comment class="commentWrap"/>
		<!-- 置顶组件 -->
		<gotop></gotop>
		
		<div class="bannertop">
			<!-- 顶部导航栏 -->
			<Headernew/> 
			<div class="bannertop-black">
				<div class="wow fadeInUp bannertop-title">
					<div>数字影片 | 3D漫游动画 | 交互视频</div>
					<div>影片策划-央视影音-拍摄/3D建模-剪辑包装-合成渲染 一站式影视服务</div>
				</div>
				
				<div class="dotojump" @click="jumppage">
					<div class="wow fadeInUp jumpbtn">
						查看案例
					</div>
				</div>
			</div>
		</div>
		
		<div class="maincontent" v-html="contentmessage">
			{{contentmessage}}
		</div>
		
		<Footernew/> 
	</div>
</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import {WOW} from 'wowjs'  //导入wow
	import Comment from '@/components/comment.vue'
	import gotop from '@/components/gotop.vue'
	export default {
		name: '',
		data() {
			return {
				contentmessage:'',//富文本信息
				jumpid:'',//要跳转的地址id
			}
		},
		components: {
		  Headernew,
		  Footernew,
		  Comment,
		  gotop
		},
		mounted() {
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
			})
			
			this.requestcontent()
		},
		methods:{
			// 请求富文本信息
			requestcontent:function(){
							 
				 //带参数的get请求
				 // let url = "https://htkja.htkjbd.com/api"
				 let newurl = this.$api + "/get/view2"
				 this.$axios.get(newurl)
				 .then((res) => {
					// var newres = JSON.stringify(res)
					console.log(res)
					this.contentmessage = res.data.result.value
					this.jumpid = res.data.result.type
				 })
				 .catch((err) => {
					 console.log(err) //错误信息
				 })
			},
			
			// 跳转到案例列表页面的锚文本
			jumppage:function(){
				var select = this.jumpid
				localStorage.setItem('id',select)
				console.log(select,localStorage)
				this.$router.push({name:'videolist'})
			},
		}
	}
</script>

<style scoped="scoped">
	body{
		margin: 0;
		padding: 0;
	}
	/* 评论组件 */
	.commentWrap{
		 position: absolute;
		 left: 0;
		 top: 0;
	 }
	.bannertop{
		width: 100%;
		height: 800px;
		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/quality-bg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.bannertop-black{
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.3);
		position: relative;
	}
	.bannertop-title{
		width: 100%;
		position: absolute;
		left: 0;
		top: 40%;
	}
	.bannertop-title div:nth-of-type(1){
		font-size: 61px;
		color: #fff;
		font-weight: bold;
	}
	.bannertop-title div:nth-of-type(2){
		font-size: 31px;
		color: #fff;
		margin-top: 35px;
		letter-spacing: 2px;
	}
	.dotojump{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		bottom: 200px;
	}
	.jumpbtn:hover{
		cursor: pointer;
	}
	.jumpbtn{
		width: 140px;
		height: 40px;
		background-color: #fff;
		border-radius: 20px;
		text-align: center;
		line-height: 40px;
		letter-spacing: 1px;
		font-weight: bold;
	}
	
	/* 富文本信息 */
	.maincontent{
		/* width: 90%; */
		width: 100%;
		margin: 0px auto 0;
	}
	
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		.bannertop{
			width: 100%;
			height: 250px;
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/quality-bg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
		.bannertop-title{
			width: 90%;
			position: absolute;
			left: 5%;
			top: 40%;
		}
		.bannertop-title div:nth-of-type(1){
			font-size: 20px;
			color: #fff;
			font-weight: bold;
		}
		.bannertop-title div:nth-of-type(2){
			font-size: 14px;
			color: #fff;
			margin-top: 10px;
			letter-spacing: 2px;
		}
		/* 富文本信息 */
		.maincontent{
			/* width: 90%; */
			width: 100%;
			margin: 5px auto 0;
		}
		.dotojump{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0;
			bottom: 20px;
		}
		.jumpbtn:hover{
			cursor: pointer;
		}
		.jumpbtn{
			width: 100px;
			height: 25px;
			background-color: #fff;
			border-radius: 15px;
			text-align: center;
			line-height: 25px;
			letter-spacing: 1px;
			font-weight: bold;
			font-size: 12px;
			font-family: '微软雅黑';
		}
		
	}
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.bannertop{
			width: 100%;
			height: 400px;
		}
		.bannertop-title{
			width: 80%;
			position: absolute;
			left: 10%;
			top: 40%;
		}
		.bannertop-title div:nth-of-type(1){
			font-size: 24px;
			color: #fff;
			font-weight: bold;
		}
		.bannertop-title div:nth-of-type(2){
			font-size: 16px;
			color: #fff;
			margin-top: 20px;
			letter-spacing: 2px;
		}
		/* 富文本信息 */
		.maincontent{
			/* width: 90%; */
			width: 100%;
			margin: 5px auto 0;
		}
		.dotojump{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0;
			bottom: 100px;
		}
		.jumpbtn:hover{
			cursor: pointer;
		}
		.jumpbtn{
			width: 100px;
			height: 25px;
			background-color: #fff;
			border-radius: 15px;
			text-align: center;
			line-height: 25px;
			letter-spacing: 1px;
			font-weight: bold;
			font-size: 12px;
		}
		
	}
	
	/* 老板仓定制机的样式 */
	@media (min-width: 1000px) and (max-width: 1300px){
		/* 评论组件 */
		.commentWrap{
			 position: absolute;
			 left: 0;
			 top: 0;
		 }
		.bannertop{
			width: 100%;
			height: 450px;
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/quality-bg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
		.bannertop-black{
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.3);
			position: relative;
		}
		.bannertop-title{
			width: 100%;
			position: absolute;
			left: 0;
			top: 30%;
		}
		.bannertop-title div:nth-of-type(1){
			font-size: 40px;
			color: #fff;
			font-weight: bold;
		}
		.bannertop-title div:nth-of-type(2){
			font-size: 20px;
			color: #fff;
			margin-top: 25px;
			letter-spacing: 2px;
		}
		.dotojump{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0;
			bottom: 100px;
		}
		.jumpbtn:hover{
			cursor: pointer;
		}
		.jumpbtn{
			width: 140px;
			height: 40px;
			background-color: #fff;
			border-radius: 20px;
			text-align: center;
			line-height: 40px;
			letter-spacing: 1px;
			font-weight: bold;
		}
		
		/* 富文本信息 */
		.maincontent{
			/* width: 90%; */
			width: 100%;
			margin: 0px auto 0;
		}
	}
</style>
