<template>
	<div>
		<button bindtap="getred">领取红包</button>
	</div>
</template>

<script>
import $ from "jquery"
	import wx from 'weixin-js-sdk'
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	
	export default {
	  name: 'redboxceshi',
	  components: {
	    Headernew,
	    Footernew,
	  },
	  data() {
		return {

		}
	  },
	  mounted() {

	  },
	  methods:{
		  // 获取地址栏上的参数的函数
		  getQueryVariable(){
			  var query = window.location.search.substring(1);
			  var vars = query.split("&");
			  for (var i=0;i<vars.length;i++) {
			          var pair = vars[i].split("=");
			          if(pair[0] == variable){return pair[1];}
			  }
			  return(false);
		  },
		  // 用户登录函数
		  getuser(){
			  var that = this
			  var url = window.location.href
			  // 判断是否是在微信浏览器打开的页面，是就执行登录
			  var ua = navigator.userAgent.toLowerCase();
			  var usersmessage = localStorage.getItem('usermessage');
			  var urlcode = that.getQueryVariable("code")
			  // if(ua.match(/MicroMessenger/i) != 'micromessenger'){
			  // 	// 当页面不在微信中打开的时候，显示提示层
			  // 	console.log(1)
			  // }
			  if(ua.match(/MicroMessenger/i) == 'micromessenger' && !usersmessage && !urlcode){
			  	  // 如果用户没有登录，且是在微信上打开的页面，就显示遮罩
			  	  this.wechatnologin = true
			  	  // 移动端用的是公众号的appid，pc端用的是公众平台的appid
			  	  var appid = 'wx799c2495cc0d2a30'
			  	  var urlx = 'http%3A%2F%2Fxjaikj.htkjbd.com%2Fyongwu%2Fyongwu.html'
			  	  // let redirect_uri = encodeURIComponent(url)
			  	  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlx}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
			  	  // 跳转到loginsucess.vue页面，在域名上得到code
			  }else if(!usersmessage && urlcode){
			  	// 说明是跳转登录进来的，此时获取code和state，去换取user信息
			  	
			  	that.getuserinfo(urlcode)
			  }

		  },
		  getuserinfo(code){
			  var newdata
			  
			  newdata = {
			  	appid:'wx799c2495cc0d2a30'
			  }
			  	
			  	var urlcookie = document.cookie
			  	
			  	$.ajax({//调取接口返回验证码
			  	    type:"get",
			  	    url: 'http://xuanchuanpian.xjaikj.com/api/get/AuthCode/'+ code,
			  	    data: newdata,
			  	    success:function(res){
			  			console.log(res)
			  			var name = res.result.nickname
			  			var id = res.result.id
			  			var adminid = res.result.is_admin
			  			var usermessage = {
			  				username:name,
			  				userid:id,
			  				adminid:adminid
			  			}
			  			localStorage.setItem('usermessage',JSON.stringify(usermessage));
			  	    },
			  	    error:function(){
			  	        console.log("error");
			  	    }
			  	});
		  },
		  getred(){
			  let url = "https://api.mch.weixin.qq.com/mmpaymkttransfers/sendredpack"
			  this.$axios.post(url,{
			  	nonce_str:'',//随机字符串，不长于32位
				sign:'',//签名
				mch_billno:'',//商户订单号
				mch_id:'',//微信支付分配的商户号
				wxappid:'',//公众号id
				send_name:'',//红包发送者名称
				re_openid:'',//用户openid
				total_amount:'',//付款金额
				total_num:'',//红包发放总人数
				wishing:'后天科技恭祝您新年快乐！',//红包祝福语
				client_ip:'',//调用接口的机器ip地址
				act_name:'盲盒',//活动名称
				remark:'',//备注信息
				scene_id:'',//场景id，红包金额大于200或者小于1元时必传，PRODUCT_2:抽奖
				risk_info:'',//活动信息 
						// posttime:用户操作的时间戳
						// mobile:业务系统账号的手机号，国家代码-手机号。不需要+号
						// deviceid :mac 地址或者设备唯一标识 
						// clientversion :用户操作的客户端版本
						// 把值为非空的信息用key=value进行拼接，再进行urlencode
						// urlencode(posttime=xx& mobile =xx&deviceid=xx)
			  })
			  .then((res) => {
				  console.log(res)
			  })
			  .catch((err) => {
			  	console.log(err)
			  })
		  }
	  }
	}
	
</script>

<style>


</style>
