<template>
	<div>
		<!-- 案例合集 -->
		<Cases :type="type"></Cases>
		<!-- 留言按钮 -->
		<Comment class="commentWrap"></Comment>
		<!-- 置顶组件 -->
		<gotop></gotop>
		
		<div style="width: 100%; height: 100%;">
			<div style="width: 100%; height: 100%;" v-show="type == 0">
				<img src="../assets/pdf/htkj/htkj_00.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_01.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_02.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_03.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_04.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_05.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_06.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_07.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_08.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_09.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_10.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_11.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_12.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_13.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_14.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_15.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_16.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_17.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_18.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htkj/htkj_19.jpg" style="width: 100%;" preview/>
			</div>
			<div style="width: 100%; height: 100%;" v-show="type == 1">
				<img src="../assets/pdf/ssyx/ssyx_00.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_01.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_02.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_03.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_04.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_05.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_06.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_07.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_08.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_09.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_10.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_11.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_12.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_13.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_14.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_15.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_16.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_17.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_18.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_19.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_20.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_21.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_22.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_23.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_24.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_25.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_26.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_27.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_28.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_29.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_30.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_31.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_32.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_33.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_34.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_35.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_36.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_37.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_38.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_39.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_40.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_41.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_42.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/ssyx/ssyx_43.jpg" style="width: 100%;" preview/>
			</div>
			
			<div style="width: 100%; height: 100%;" v-show="type == 2">
				<img src="../assets/pdf/htcm/htcm_00.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_01.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_02.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_03.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_04.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_05.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_06.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_07.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_08.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_09.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_10.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_11.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_12.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_13.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_14.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_15.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_16.jpg" style="width: 100%;" preview/>
				<img src="../assets/pdf/htcm/htcm_17.jpg" style="width: 100%;" preview/>
			</div>
		</div>
		
		<Footernew />
	</div>
</template>

<script>
	import Swiper from "swiper"
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import {
		WOW
	} from 'wowjs' //导入wow
	import Comment from '@/components/comment.vue'
	import Cases from '@/components/Cases.vue'
	import gotop from '@/components/gotop.vue'

	//在需要使用的路由里进行引用   也可以进行全局引用
	import wx from 'weixin-js-sdk'
	export default {
		name: '',
		components: {
			Headernew,
			Footernew,
			Comment,
			Cases,
			gotop
		},
		data() {
			return {
				isWx: false,
				isPhone: false,
				type: 0, // 0 1 2
				names: [
					"北京后天科技宣传册",
					"盛世永信集团保定办事处宣传册",
					"后天传媒画册"
				]
			}
		},
		created() {
			this.type = this.getParamsByName("t").substr(0, 1);
			document.getElementsByTagName('title')[0].innerText = this.names[this.type];
			let ua = navigator.userAgent.toLowerCase();
			this.isPhone = ua.match(/iphone/i) == 'iphone' || ua.match(/android/i) == 'android';
			this.isWx = ua.match(/MicroMessenger/i) == 'micromessenger';
			// if (!this.isPhone) {
			// 	alert("请在手机端打开");
			// 	this.$router.replace("/");
			// }
		},
		mounted() {
			var that = this
			this.$nextTick(() => {
				// 将转发放在这里而不是created中，防止页面没加载完就获取不到random_str
				that.share()
			})
		},
		methods: {
			getParamsByName(name) {
				let search = location.search;
				search = search.replaceAll("?", "");
				let params = search.split("&");
				let res = "0";
				params.forEach(item => {
					let param = item.split("=");
					let key = param[0];
					let value = param[1];
					if (key == name) {
						res = value;
					}
				});
				return res;
			},
			// 微信分享功能
			share: function() {
				var that = this;
				var shareUrl = window.location.href;
				that.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
					.then(function(res) {
						wx.config({
							debug: false, // true开启调试模式,pc端会console.log手机端会alert
							appId: res.data.result.appId, // 必填，公众号的唯一标识，填自己的！
							timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
							nonceStr: res.data.result.nonceStr, // 必填，生成签名的随机串
							signature: res.data.result.signature, // 必填，签名，见附录1
							jsApiList: [
								'onMenuShareTimeline',
								'onMenuShareAppMessage'
							]
						})

						//分享配置
						wx.ready(function() {
							//分享到朋友圈
							wx.onMenuShareTimeline({
								// title: that.videomessage.main,
								title: that.names[that.type],
								link: shareUrl + "?t=" + that.type, // 9-10 改动
								imgUrl: "https://xjaikj.htkjbd.com/logo.png",
								success: function() {

								},
								cancel: function() {
									// alert("取消分享");
								}
							});
							//分享给朋友
							wx.onMenuShareAppMessage({
								// title: that.videomessage.main,
								// desc: that.videomessage.main,
								title: that.names[that.type],
								desc: that.names[that.type],
								link: shareUrl + "?t=" + that.type, // 9-10 改动
								imgUrl: "https://xjaikj.htkjbd.com/logo.png",
								success: function() {

								},
								cancel: function() {
									// alert("取消分享");
								}
							});
						})

					}).catch(function(err) {
						// alert(err)
						//        console.log(err)
					})
			},


			// 禁止右键和F12
			prohibitClick: function() {

				//禁用右键
				document.oncontextmenu = function() {
					return false;
				};
				//禁用开发者工具F12
				document.onkeydown = document.onkeyup = document.onkeypress = function(event) {
					let e = event || window.event || arguments.callee.caller.arguments[0];
					if (e && e.keyCode == 123) {
						e.returnValue = false;
						return false;
					}
				}
			},
		}
	}
</script>

<style scoped="scoped">
	.alhjBtn {
		right: 10px;
		z-index: 100;
		width: 198px;
		bottom: 220px;
		height: 207px;
		position: fixed;
		transform: scale(0.35);
		transform-origin: 100% 0;
		background-image: url("../assets/img/alhj_btn.png");
	}
	
	ul,
	li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	body {
		min-width: 1200px;
	}

	video::-webkit-media-controls-enclosure {
		overflow: hidden;
	}

	video::-webkit-media-controls-panel {
		width: calc(100% + 30px);
	}

	video {
		object-fit: fill;
	}
	
	/* 案例合集 */
	.cases {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100;
	}

	/* 评论组件 */
	.commentWrap {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100;
	}

	.casebannertop {
		width: 100%;
		height: 100vh;
		background-color: #fafafa;
	}

	/* 微信中打开，没有登录的话，显示的遮罩 */
	.wechatnologin {
		width: 100vw;
		height: 100vh;
		background-color: #fff;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.gotologBtn {
		width: 130px;
		height: 40px;
		color: #fff;
		line-height: 40px;
		background-color: #007400;
		margin-top: 20px;
		border-radius: 5px;
	}

	.casebanner-center,
	.shoujicasebanner-center {
		width: 100%;
		height: 800px;
		background-image: url(../assets/img/detailbg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}

	.casebanner-centertitle {
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 35px;
		font-weight: bold;
		line-height: 70px;
		position: absolute;
		top: 110px;
		left: 0;
		letter-spacing: 2px;
	}

	.casebanner-img,
	.shoujicasebanner-img {
		position: absolute;
		width: 75%;
		height: 85%;
		bottom: -12%;
		left: 9%;
	}

	/* 观看码区 */
	.password {
		/* width: 1100px;
		height: 615px;
		position: absolute;
		left: 170px;
		top: 10px;
		border-radius: 20px;
		z-index: 7; */

		width: 80%;
		height: 92.2%;
		position: absolute;
		left: 15.1%;
		top: 7%;
		border-radius: 20px;
		z-index: 7;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.password-center {
		display: flex;
		justify-content: center;
	}

	.password-centertop {
		width: 160px;
		height: 70px;
		text-align: center;
	}

	.password-centertop input {
		width: 140px;
		height: 35px;
		padding-left: 10px;
		box-sizing: border-box;
		border: 0;
		outline: 0;
	}

	.password-centertop div {
		font-size: 14px;
		line-height: 30px;
		color: red;
	}

	.password-bottom {
		width: 80px;
		height: 35px;
		background-color: #DACFCF;
		color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		letter-spacing: 2px;
	}

	.password-bottom:hover {
		cursor: pointer;
	}

	/* 观看码区结束 */
	.vrdetail {
		width: 70%;
		height: 700px;
		margin: 30px auto 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#vrdetailif {
		width: 100%;
		height: 700px;
	}

	.videopcimg {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 5%;
		top: 5%;
		z-index: 5;
	}

	.topvideoheng {
		width: 80%;
		height: 92.2%;
		position: absolute;
		left: 15.1%;
		top: 7%;
		border-radius: 20px;
		z-index: 6;
	}

	/* 手机和pad端新样式 */
	.shoujicasebanner-center {
		width: 100%;
		height: 850px;
		background-image: url(../assets/img/detailbg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}

	.shoujicasebanner-img {
		position: absolute;
		width: 95%;
		height: auto;
		top: 200px;
		left: 2%;
		border-radius: 0px;
		margin-top: 0px;
	}

	.shoujipassword {
		width: 310px;
		height: 550px;
		position: absolute;
		left: 41.5%;
		top: 6%;
		z-index: 6;
		border-radius: 10px;
		z-index: 7;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.videoshoujiimg {
		width: 335px;
		height: 600px;
		position: absolute;
		left: 40.8%;
		top: 0%;
		border-radius: 20px;
		z-index: 6;
	}

	.topvideoshu {
		width: 310px;
		height: 550px;
		position: absolute;
		left: 41.5%;
		top: 4.8%;
		z-index: 6;
		border-radius: 10px;
	}


	/* 公司介绍区 */
	.companymessage {
		width: 75%;
		height: 300px;
		margin: 20px auto 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.companymessage-left {
		width: 70%;
	}

	.companymessage-top {
		width: 400px;
		text-align: left;
		border-bottom: #f4f4f4 1px solid;
		padding-bottom: 10px;
	}

	.companymessage-top div:nth-of-type(1) {
		font-size: 20px;
		color: #545454;
		line-height: 34px;
		font-weight: bold;
	}

	.companymessage-top div:nth-of-type(2) {
		font-size: 14px;
		color: #535353;
	}

	.companymessage-bottom {
		width: 600px;
		text-align: left;
		border-bottom: #f4f4f4 1px solid;
		padding-bottom: 10px;
		margin-top: 20px;
	}

	.companymessage-bottom div:nth-of-type(1) {
		font-size: 22px;
		color: #545454;
		line-height: 34px;
		font-weight: bold;
	}

	.companymessage-bottom div:nth-of-type(2) {
		font-size: 14px;
		color: #535353;
		line-height: 24px;
	}

	.companymessage-right {
		width: 200px;

	}

	.ercodeimg {
		width: 180px;
		height: 180px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: #f4f4f4 1px solid;
		border-radius: 15px;
	}

	.ercodeimg img {
		width: 170px;
		height: 170px;
	}

	.ercodetext {
		width: 180px;
		text-align: center;
		font-size: 15px;
		line-height: 35px;
	}

	/* 合作详情区 */
	.detailcontent {
		/* width: 75%; */
		width: 100%;
		margin: 30px auto;
	}

	.detailcontent-center {
		width: 100%;
	}

	.detailcontent-center img {
		width: 100%;
	}

	/* 精心打造 */
	.englishtext {
		width: 100%;
		height: 160px;
		position: relative;
		margin: 60px auto 0;
	}

	.englishtext-center {
		width: 60%;
		margin: 0 auto;
		/* 		position: absolute;
		z-index: 5;
		left: -500px;
		top: 0; */
	}

	.huienglishi {
		width: 100%;
		text-align: center;
		margin-top: 50px;
		height: 70px;
		font-size: 90px;
		line-height: 70px;
		text-transform: uppercase;
		color: #f7f7f7;
		font-weight: bold;
	}

	.englishtext-word {
		width: 100%;
		text-align: center;
	}

	.englishtext-word span:nth-of-type(1) {
		display: block;
		font-size: 30px;
		margin-top: -30px;
	}

	.englishtext-word span:nth-of-type(2) {
		font-size: 20px;
		color: #888;
		margin-top: 10px;
		line-height: 30px;
	}

	/* 合作流程 */
	.service-show-process {
		width: 75%;
		margin: 30px auto 0;
		height: 350px;
		position: relative;
	}

	.service-process-box {
		max-width: 1240px;
		margin: 0 auto;
		text-align: center;
		/* 		position: absolute;
		right: -100px;
		top: 0; */
	}

	.pro-item>div:hover {
		-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}

	.clearfix {
		zoom: 1;
	}

	.clearfix:after,
	.clearfix:before {
		content: "";
		display: block;
		height: 0;
		overflow: hidden;
		visibility: hidden;
		width: 0;
		clear: both;
	}

	.pro-item {
		float: left;
		width: 16.66666667%;
		position: relative;
	}

	.service-show-process .pro-item::before {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		background: #e23433;
		border: 3px solid #fff;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		margin: 0 auto;
		position: absolute;
		bottom: 115px;
		left: 0;
		right: 0;
		z-index: 2;
	}

	.service-show-process .pro-item::after {
		content: "";
		border-top: 1px solid #eae9e9;
		position: absolute;
		bottom: 125px;
		left: 0;
		right: 0;
		clear: both;
		display: block;
	}

	.service-show-process .pro-item>div {
		height: 260px;
		padding: 30px 10px;
		-webkit-transition: all .3s ease-out;
		-moz-transition: all .3s ease-out;
		-ms-transition: all .3s ease-out;
		-o-transition: all .3s ease-out;
		transition: all .3s ease-out;
		background-repeat: no-repeat;
		background-position: 55px 20px;
	}

	.pro-item img {
		vertical-align: middle;
		display: inline-block;
	}

	.service-show-process .pro-item em {
		display: block;
		font-size: 20px;
		margin-top: 10px;
		font-style: normal;
	}

	.work-box {
		margin-top: 30px;
		color: #888;
		line-height: 25px;
		width: 80%;
		text-align: left;
		display: inline-block;
	}

	/* 我们的优势标题 */
	.advantagetext {
		width: 100%;
		height: 160px;
		position: relative;
	}

	.advantagetext-center {
		width: 60%;
		margin: 0 auto;
		/* 		position: absolute;
		top: -100px;
		left: 20%;
		z-index: 5; */
	}

	.advantagelishi {
		width: 100%;
		text-align: center;
		margin-top: 20px;
		height: 50px;
		font-size: 90px;
		line-height: 70px;
		text-transform: uppercase;
		color: #f7f7f7;
		font-weight: bold;
	}

	.advantagetext-word {
		width: 100%;
		text-align: center;
	}

	.advantagetext-word span:nth-of-type(1) {
		display: block;
		font-size: 30px;
		margin-top: -30px;
	}

	.advantagetext-word span:nth-of-type(2) {
		font-size: 20px;
		color: #888;
		margin-top: 10px;
		line-height: 30px;
	}

	/* 我们的优势 */
	.advantage-wrap {
		width: 100%;
		margin: 30px auto 0;
		height: 800px;
		background-image: url(../assets/img/bolan.jpg);
	}

	.advantage-center {
		width: 65%;
		height: 520px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.advantage-left {
		width: 32%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.dvantage-list {
		width: 100%;
		height: 250px;
		background-color: #fff;
		border-radius: 15px;
	}

	.dvantage-listtop {
		width: 92%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		padding-top: 20px;
		box-sizing: border-box;
	}

	.dvantage-listtop img {
		width: 40px;
		height: 40px;
	}

	.dvantage-listtoptitle {
		margin-left: 20px;
	}

	.dvantage-listtoptitle div:nth-of-type(1) {
		font-size: 18px;
		line-height: 25px;
	}

	.dvantage-listtoptitle div:nth-of-type(2) {
		font-size: 14px;
		line-height: 20px;
		color: #cccfda;
	}

	.dvantage-listmiddle {
		margin-top: 10px;
		font-size: 16px;
		line-height: 40px;
		color: #01aeec;
		font-weight: bold;
	}

	.dvantage-listbottom {
		width: 92%;
		margin: 0 auto;
		text-align: left;
		font-size: 14px;
		line-height: 20px;
		color: #999999;
	}


	.advantage-middle {
		width: 25%;
		height: 520px;
		background-color: green;
		border-radius: 10px;
		background-image: url(../assets/img/casemiddle.jpg);
		background-size: 100% 100%;
	}

	.advantage-right {
		width: 32%;
		height: 520px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	/* 更多案例区 */
	.morecase-title {
		width: 75%;
		margin: 60px auto 0;
		text-align: left;
		font-size: 20px;
		color: #545454;
		line-height: 34px;
		font-weight: bold;
		border-bottom: #f4f4f4 1px solid;
		padding-bottom: 10px;
	}

	.morecase-wrap {
		width: 80%;
		height: 280px;
		margin: 30px auto 40px;
		padding-bottom: 50px;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		position: relative;
		overflow: hidden;

		/* Center slide text vertically */
		transition: 300ms;
		transform: scale(0.8);
	}

	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}

	.swiper-slide div:nth-of-type(1) {
		width: 100%;
		height: 230px;
		overflow: hidden;
	}

	.swiper-slide img {
		width: 100%;
		height: auto;
		/* position: absolute;
		left: 0;
		top: 0; */
		z-index: 1;
		vertical-align: bottom;
	}

	.swiper-slide div:nth-of-type(2) {
		width: 100%;
		height: 50px;
		/* background: rgba(0,0,0,0.5); */
		background-color: #000000;
		z-index: 2;
		color: #fff;
		font-size: 17px;
		line-height: 50px;
		letter-spacing: 2px;
	}

	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px) {

		/* @media (min-width: 320px){ */
		.casebannertop {
			width: 100%;
			height: auto;
		}

		.casebanner-center {
			width: 100%;
			height: 300px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}

		.casebanner-centertitle {
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			line-height: 30px;
			position: absolute;
			top: 30px;
			left: 0;
			letter-spacing: 2px;
		}

		.casebanner-img {
			position: absolute;
			width: 95%;
			height: 220px;
			top: 70px;
			left: 2%;
			border-radius: 10px;
			margin-top: 20px;
		}

		/* 观看码区 */
		.password {
			width: 78%;
			height: 73%;
			position: absolute;
			left: 12%;
			top: 0;
			z-index: 7;
			background-color: rgba(0, 0, 0, 0.3);
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
		}

		.password-center {
			display: flex;
			justify-content: center;
		}

		.password-centertop {
			width: 120px;
			height: 50px;
			text-align: center;
		}

		.password-centertop input {
			width: 100px;
			height: 30px;
			padding-left: 5px;
			box-sizing: border-box;
			border: 0;
			outline: 0;
			font-size: 12px;
		}

		.password-centertop div {
			font-size: 12px;
			line-height: 24px;
			color: red;
		}

		.password-bottom {
			width: 60px;
			height: 30px;
			background-color: #DACFCF;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			font-size: 14px;
			letter-spacing: 2px;
		}

		/* 观看码区结束 */
		.vrdetail {
			width: 70%;
			height: 400px;
			margin: 30px auto 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		#vrdetailif {
			width: 100%;
			height: 500px;
		}

		/* 手机和pad端新样式 */
		.shoujicasebanner-center {
			width: 100%;
			height: 480px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
			overflow: hidden;
		}

		.shoujicasebanner-img {
			position: absolute;
			width: 95%;
			height: auto;
			top: 70px;
			left: 2%;
			border-radius: 10px;
			margin-top: 20px;
		}

		.shoujipassword {
			width: 47%;
			height: 65%;
			border-radius: 10px;
			position: absolute;
			left: 26.5%;
			top: 4%;
			z-index: 7;
			background-color: rgba(0, 0, 0, 0.3);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.videopcimg {
			/* width: 100%;
			height: 100%; */
			width: 99%;
			height: auto;
			position: absolute;
			left: 1.3%;
			top: 0;
			z-index: 5;
		}

		.topvideoheng {
			/* width: 80%;
			height: 90%; */
			width: 78%;
			height: auto;
			border-radius: 5px;
			position: absolute;
			left: 11.8%;
			top: 1.5%;
			z-index: 6;
		}

		.videoshoujiimg {
			width: 50%;
			height: auto;
			position: absolute;
			left: 25%;
			top: 0%;
			border-radius: 20px;
			z-index: 6;
		}

		.topvideoshu {
			width: 45%;
			height: auto;
			position: absolute;
			left: 27.7%;
			top: 4%;
			z-index: 6;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-radius: 0 0 10px 10px;
		}

		/* 公司介绍区 */
		.companymessage {
			width: 90%;
			height: 250px;
			margin: 20px auto 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.companymessage-left {
			width: 100%;
		}

		.companymessage-top {
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}

		.companymessage-top div:nth-of-type(1) {
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}

		.companymessage-top div:nth-of-type(2) {
			font-size: 12px;
			color: #535353;
		}

		.companymessage-bottom {
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
			margin-top: 20px;
		}

		.companymessage-bottom div:nth-of-type(1) {
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}

		.companymessage-bottom div:nth-of-type(2) {
			font-size: 12px;
			color: #535353;
			line-height: 24px;
		}

		.companymessage-right {
			display: none;
			width: 200px;
		}

		/* 合作详情区 */
		.detailcontent {
			width: 100%;
			margin: 10px auto;
		}

		.detailcontent-center {
			width: 100%;
		}

		.detailcontent-center img {
			width: 100%;
		}

		/* 精心打造 */
		.englishtext {
			width: 90%;
			height: 100px;
			position: relative;
			margin: 0px auto 0;
		}

		.englishtext-center {
			width: 100%;
			margin: 0 auto;
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
		}

		.huienglishi {
			width: 100%;
			text-align: center;
			margin-top: 10px;
			height: 70px;
			font-size: 50px;
			line-height: 70px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}

		.englishtext-word {
			width: 100%;
			text-align: center;
		}

		.englishtext-word span:nth-of-type(1) {
			display: block;
			font-size: 18px;
			margin-top: -30px;
		}

		.englishtext-word span:nth-of-type(2) {
			font-size: 14px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}

		/* 合作流程 */
		.service-show-process {
			width: 90%;
			margin: 20px auto 0;
			height: 320px;
			position: relative;
		}

		.service-process-box {
			max-width: 1240px;
			margin: 0 auto;
			text-align: center;
			position: absolute;
			right: 0;
			top: 0;
		}

		.pro-item>div:hover {
			-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}

		.clearfix {
			zoom: 1;
		}

		.clearfix:after,
		.clearfix:before {
			content: "";
			display: block;
			height: 0;
			overflow: hidden;
			visibility: hidden;
			width: 0;
			clear: both;
		}

		.pro-item {
			float: left;
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
		}

		.service-show-process .pro-item::before {
			content: "";
			display: none;
			width: 0;
			height: 0;
			background: #e23433;
			border: 3px solid #fff;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			margin: 0 auto;
			position: absolute;
			bottom: 115px;
			left: 0;
			right: 0;
			z-index: 2;
		}

		.service-show-process .pro-item::after {
			content: "";
			border-top: none;
			border-bottom: none;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			clear: both;
			display: block;
		}

		.service-show-process .pro-item>div {
			width: 100%;
			height: 52px;
			padding: 0;
			-webkit-transition: all .3s ease-out;
			-moz-transition: all .3s ease-out;
			-ms-transition: all .3s ease-out;
			-o-transition: all .3s ease-out;
			transition: all .3s ease-out;
			background-repeat: no-repeat;
			background-position: 55px 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
		}

		.pro-item img {
			width: 40px;
			height: 40px;
			vertical-align: middle;
			display: inline-block;
			margin-left: 5px;
		}

		.service-show-process .pro-item em {
			display: block;
			font-size: 8px;
			margin-top: 7px;
			font-style: normal;
		}

		.work-box {
			margin-top: 0;
			color: #888;
			font-size: 10px;
			line-height: 16px;
			width: 60%;
			text-align: left;
			display: inline-block;
		}

		/* 我们的优势标题 */
		.advantagetext {
			width: 100%;
			height: 100px;
			position: relative;
		}

		.advantagetext-center {
			width: 60%;
			margin: 0 auto;
			position: absolute;
			top: 0;
			left: 20%;
			z-index: 5;
		}

		.advantagelishi {
			display: none;
			width: 100%;
			text-align: center;
			margin-top: 20px;
			height: 50px;
			font-size: 40px;
			line-height: 60px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}

		.advantagetext-word {
			width: 100%;
			text-align: center;
		}

		.advantagetext-word span:nth-of-type(1) {
			display: block;
			font-size: 20px;
			margin-top: 20px;
		}

		.advantagetext-word span:nth-of-type(2) {
			font-size: 16px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}

		/* 我们的优势 */
		.advantage-wrap {
			width: 100%;
			margin: 30px auto 0;
			height: 950px;
			background-image: url(../assets/img/bolan.jpg);
		}

		.advantage-center {
			width: 90%;
			height: 520px;
			margin: 0 auto;
			display: block;
		}

		.advantage-left {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.dvantage-list {
			width: 100%;
			height: auto;
			background-color: #fff;
			border-radius: 15px;
			margin-bottom: 15px;
		}

		.dvantage-listtop {
			width: 92%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 20px;
			box-sizing: border-box;
		}

		.dvantage-listtop img {
			width: 30px;
			height: 30px;
		}

		.dvantage-listtoptitle {
			margin-left: 15px;
		}

		.dvantage-listtoptitle div:nth-of-type(1) {
			font-size: 14px;
			line-height: 20px;
		}

		.dvantage-listtoptitle div:nth-of-type(2) {
			font-size: 12px;
			line-height: 18px;
			color: #cccfda;
		}

		.dvantage-listmiddle {
			margin-top: 8px;
			font-size: 14px;
			line-height: 30px;
			color: #01aeec;
			font-weight: bold;
		}

		.dvantage-listbottom {
			width: 92%;
			margin: 0 auto;
			text-align: left;
			font-size: 12px;
			line-height: 18px;
			color: #999999;
			padding-bottom: 15px;
		}


		.advantage-middle {
			display: none;
			width: 25%;
			height: 520px;
			background-color: green;
			border-radius: 10px;
			background-image: url(../assets/img/casemiddle.jpg);
			background-size: 100% 100%;
		}

		.advantage-right {
			width: 100%;
			height: 400px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		/* 更多案例区 */
		.morecase-title {
			width: 90%;
			margin: 20px auto 0;
			text-align: left;
			font-size: 16px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}

		.morecase-wrap {
			width: 90%;
			height: 250px;
			margin: 30px auto 10px;
			padding-bottom: 20px;
		}

		.swiper-container {
			width: 100%;
			height: 100%;
		}

		.swiper-slide {
			text-align: center;
			font-size: 16px;
			position: relative;
			overflow: hidden;

			transition: 300ms;
			transform: scale(0.8);
		}

		.swiper-slide-active,
		.swiper-slide-duplicate-active {
			/* transform: scale(1); */
		}

		.swiper-slide div:nth-of-type(1) {
			width: 100%;
			height: 210px;
			overflow: hidden;
		}

		.swiper-slide img {
			width: 100%;
			height: auto;
			z-index: 1;
		}

		.swiper-slide div {
			width: 100%;
			height: 40px;
			/* background: rgba(0,0,0,0.5); */
			background-color: #000000;
			z-index: 2;
			color: #fff;
			font-size: 15px;
			line-height: 40px;
			letter-spacing: 2px;
		}
	}


	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px) {

		/* @media (min-width: 320px){ */
		.casebannertop {
			width: 100%;
			height: auto;
		}

		.casebanner-center {
			width: 100%;
			height: 480px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}

		.casebanner-centertitle {
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 19px;
			font-weight: bold;
			line-height: 30px;
			position: absolute;
			top: 60px;
			left: 0;
			letter-spacing: 2px;
		}

		/* 观看码区 */
		.password {
			width: 78%;
			height: 310px;
			border-radius: 5px;
			position: absolute;
			left: 11%;
			top: 2.4%;
			z-index: 7;
			background-color: rgba(0, 0, 0, 0.3);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.password-center {
			display: flex;
			justify-content: center;
		}

		.password-centertop {
			width: 120px;
			height: 50px;
			text-align: center;
		}

		.password-centertop input {
			width: 100px;
			height: 30px;
			padding-left: 5px;
			box-sizing: border-box;
			border: 0;
			outline: 0;
			font-size: 12px;
		}

		.password-centertop div {
			font-size: 12px;
			line-height: 24px;
			color: red;
		}

		.password-bottom {
			width: 60px;
			height: 30px;
			background-color: #DACFCF;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			font-size: 14px;
			letter-spacing: 2px;
		}

		/* 观看码区结束 */
		/* 手机和pad端新样式 */
		.casebanner-img {
			position: absolute;
			width: 90%;
			height: 350px;
			top: 110px;
			left: 5%;
			border-radius: 0;
			z-index: 2;
		}

		.shoujicasebanner-center {
			width: 100%;
			height: 550px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}

		.shoujicasebanner-img {
			position: absolute;
			width: 95%;
			height: auto;
			top: 120px;
			left: 2%;
			border-radius: 0px;
			margin-top: 0px;
		}

		.shoujipassword {
			width: 202px;
			height: 360px;
			border-radius: 10px;
			position: absolute;
			left: 36.5%;
			top: 4%;
			z-index: 7;
			background-color: rgba(0, 0, 0, 0.3);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.videopcimg {
			width: 690px;
			height: 340px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
		}

		.topvideoheng {
			width: 540px;
			height: 303px;
			border-radius: 5px;
			position: absolute;
			left: 11%;
			top: 2.4%;
			z-index: 6;
		}

		.videoshoujiimg {
			width: 220px;
			height: 388px;
			position: absolute;
			left: 35.3%;
			top: 0%;
			border-radius: 20px;
			z-index: 6;
		}

		.topvideoshu {
			width: 202px;
			height: 360px;
			position: absolute;
			left: 36.5%;
			top: 4%;
			z-index: 6;
			border-radius: 10px;
		}

		/* 公司介绍区 */
		.companymessage {
			width: 90%;
			height: 250px;
			margin: 20px auto 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.companymessage-left {
			width: 100%;
		}

		.companymessage-top {
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}

		.companymessage-top div:nth-of-type(1) {
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}

		.companymessage-top div:nth-of-type(2) {
			font-size: 12px;
			color: #535353;
		}

		.companymessage-bottom {
			width: 100%;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
			margin-top: 20px;
		}

		.companymessage-bottom div:nth-of-type(1) {
			font-size: 14px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
		}

		.companymessage-bottom div:nth-of-type(2) {
			font-size: 12px;
			color: #535353;
			line-height: 24px;
		}

		.companymessage-right {
			display: none;
			width: 200px;
		}

		/* 合作详情区 */
		.detailcontent {
			width: 100%;
			margin: 10px auto;
		}

		.detailcontent-center {
			width: 100%;
		}

		.detailcontent-center img {
			width: 100%;
		}

		/* 精心打造 */
		.englishtext {
			width: 90%;
			height: 100px;
			position: relative;
			margin: 0 auto;
		}

		.englishtext-center {
			width: 100%;
			margin: 0 auto;
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
		}

		.huienglishi {
			width: 100%;
			text-align: center;
			margin-top: 10px;
			height: 70px;
			font-size: 50px;
			line-height: 70px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}

		.englishtext-word {
			width: 100%;
			text-align: center;
		}

		.englishtext-word span:nth-of-type(1) {
			display: block;
			font-size: 18px;
			margin-top: -30px;
		}

		.englishtext-word span:nth-of-type(2) {
			font-size: 14px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}

		/* 合作流程 */
		.service-show-process {
			width: 90%;
			margin: 20px auto 0;
			height: 300px;
			position: relative;
		}

		.service-process-box {
			max-width: 1240px;
			margin: 0 auto;
			text-align: center;
			position: absolute;
			right: 0;
			top: 0;
		}

		.pro-item>div:hover {
			-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}

		.clearfix {
			zoom: 1;
		}

		.clearfix:after,
		.clearfix:before {
			content: "";
			display: block;
			height: 0;
			overflow: hidden;
			visibility: hidden;
			width: 0;
			clear: both;
		}

		.pro-item {
			float: left;
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
		}

		.service-show-process .pro-item::before {
			content: "";
			display: none;
			width: 0;
			height: 0;
			background: #e23433;
			border: 3px solid #fff;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			margin: 0 auto;
			position: absolute;
			bottom: 115px;
			left: 0;
			right: 0;
			z-index: 2;
		}

		.service-show-process .pro-item::after {
			content: "";
			border-top: none;
			border-bottom: none;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			clear: both;
			display: block;
		}

		.service-show-process .pro-item>div {
			width: 100%;
			height: 52px;
			padding: 0;
			-webkit-transition: all .3s ease-out;
			-moz-transition: all .3s ease-out;
			-ms-transition: all .3s ease-out;
			-o-transition: all .3s ease-out;
			transition: all .3s ease-out;
			background-repeat: no-repeat;
			background-position: 55px 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
		}

		.pro-item img {
			width: 40px;
			height: 40px;
			vertical-align: middle;
			display: inline-block;
			margin-left: 5px;
		}

		.service-show-process .pro-item em {
			display: block;
			font-size: 14px;
			margin-top: 7px;
			font-style: normal;
		}

		.work-box {
			margin-top: 0;
			color: #888;
			font-size: 12px;
			line-height: 16px;
			width: 60%;
			text-align: left;
			display: inline-block;
		}

		.work-box p {
			font-size: 12px;
			line-height: 16px;
		}

		/* 我们的优势标题 */
		.advantagetext {
			width: 100%;
			height: 100px;
			position: relative;
		}

		.advantagetext-center {
			width: 60%;
			margin: 0 auto;
			position: absolute;
			top: 0;
			left: 20%;
			z-index: 5;
		}

		.advantagelishi {
			display: none;
			width: 100%;
			text-align: center;
			margin-top: 20px;
			height: 50px;
			font-size: 40px;
			line-height: 60px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}

		.advantagetext-word {
			width: 100%;
			text-align: center;
		}

		.advantagetext-word span:nth-of-type(1) {
			display: block;
			font-size: 20px;
			margin-top: 20px;
		}

		.advantagetext-word span:nth-of-type(2) {
			font-size: 16px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}

		/* 我们的优势 */
		.advantage-wrap {
			width: 100%;
			margin: 30px auto 0;
			height: 680px;
			background-image: url(../assets/img/bolan.jpg);
		}

		.advantage-center {
			width: 90%;
			height: 520px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
		}

		.advantage-left {
			width: 32%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.dvantage-list {
			width: 100%;
			height: 250px;
			background-color: #fff;
			border-radius: 15px;
		}

		.dvantage-listtop {
			width: 92%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 20px;
			box-sizing: border-box;
		}

		.dvantage-listtop img {
			width: 40px;
			height: 40px;
		}

		.dvantage-listtoptitle {
			margin-left: 20px;
		}

		.dvantage-listtoptitle div:nth-of-type(1) {
			font-size: 16px;
			line-height: 25px;
		}

		.dvantage-listtoptitle div:nth-of-type(2) {
			font-size: 12px;
			line-height: 20px;
			color: #cccfda;
		}

		.dvantage-listmiddle {
			margin-top: 10px;
			font-size: 10px;
			line-height: 40px;
			color: #01aeec;
			font-weight: bold;
		}

		.dvantage-listbottom {
			width: 92%;
			margin: 0 auto;
			text-align: left;
			font-size: 10px;
			line-height: 16px;
			color: #999999;
		}


		.advantage-middle {
			width: 30%;
			height: 520px;
			background-color: green;
			border-radius: 10px;
			background-image: url(../assets/img/casemiddle.jpg);
			background-size: 100% 100%;
		}

		.advantage-right {
			width: 32%;
			height: 520px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		/* 更多案例区 */
		.morecase-title {
			width: 90%;
			margin: 20px auto 0;
			text-align: left;
			font-size: 16px;
			color: #545454;
			line-height: 30px;
			font-weight: bold;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}

		.morecase-wrap {
			width: 90%;
			height: 140px;
			margin: 30px auto 40px;
			padding-bottom: 20px;
		}

		.swiper-container {
			width: 100%;
			height: 100%;
		}

		.swiper-slide {
			text-align: center;
			font-size: 14px;
			position: relative;
			overflow: hidden;

			transition: 300ms;
			transform: scale(0.8);
		}

		.swiper-slide-active,
		.swiper-slide-duplicate-active {
			transform: scale(1);
		}

		.swiper-slide div:nth-of-type(1) {
			width: 100%;
			height: 100px;
			overflow: hidden;
		}

		.swiper-slide img {
			width: 100%;
			height: auto;
			z-index: 1;
			vertical-align: bottom;
		}

		.swiper-slide div {
			width: 100%;
			height: 40px;
			background: rgba(0, 0, 0, 0.5);
			z-index: 2;
			color: #fff;
			font-size: 14px;
			line-height: 40px;
			letter-spacing: 2px;
		}
	}

	/* 老板仓定制机的样式 */
	@media (min-width: 1025px) and (max-width: 1300px) {
		.casebannertop {
			width: 100%;
			height: auto;
			background-color: #fafafa;
		}

		/* 微信中打开，没有登录的话，显示的遮罩 */
		.wechatnologin {
			width: 100vw;
			height: 500px;
			background-color: #fff;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 1000;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.gotologBtn {
			width: 130px;
			height: 40px;
			color: #fff;
			line-height: 40px;
			background-color: #007400;
			margin-top: 20px;
			border-radius: 5px;
		}

		.casebanner-center {
			width: 100%;
			height: 550px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}

		.shoujicasebanner-center {
			width: 100%;
			height: 500px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}

		.casebanner-centertitle {
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 22px;
			font-weight: bold;
			line-height: 30px;
			position: absolute;
			top: 100px;
			left: 0;
			letter-spacing: 2px;
		}

		.casebanner-img {
			position: absolute;
			width: 75%;
			height: 75%;
			bottom: 2%;
			left: 9%;
		}

		.shoujicasebanner-img {
			position: absolute;
			width: 75%;
			height: 85%;
			bottom: -12%;
			left: 9%;
		}

		/* 观看码区 */
		.password {
			/* width: 1100px;
				height: 615px;
				position: absolute;
				left: 170px;
				top: 10px;
				border-radius: 20px;
				z-index: 7; */

			width: 80%;
			height: 92.2%;
			position: absolute;
			left: 15.1%;
			top: 7%;
			border-radius: 20px;
			z-index: 7;
			background-color: rgba(0, 0, 0, 0.5);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.password-center {
			display: flex;
			justify-content: center;
		}

		.password-centertop {
			width: 160px;
			height: 70px;
			text-align: center;
		}

		.password-centertop input {
			width: 140px;
			height: 35px;
			padding-left: 10px;
			box-sizing: border-box;
			border: 0;
			outline: 0;
		}

		.password-centertop div {
			font-size: 14px;
			line-height: 30px;
			color: red;
		}

		.password-bottom {
			width: 80px;
			height: 35px;
			background-color: #DACFCF;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			letter-spacing: 2px;
		}

		.password-bottom:hover {
			cursor: pointer;
		}

		/* 观看码区结束 */
		.vrdetail {
			width: 70%;
			height: 700px;
			margin: 30px auto 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		#vrdetailif {
			width: 100%;
			height: 700px;
		}

		.videopcimg {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 5%;
			top: 5%;
			z-index: 5;
		}

		.topvideoheng {
			width: 80%;
			height: 92.2%;
			position: absolute;
			left: 15.1%;
			top: 7%;
			border-radius: 20px;
			z-index: 6;
		}

		/* 手机和pad端新样式 */
		.shoujicasebanner-center {
			width: 100%;
			height: 650px;
			background-image: url(../assets/img/detailbg.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
		}

		.shoujicasebanner-img {
			position: absolute;
			width: 80%;
			height: auto;
			top: 150px;
			left: 2%;
			border-radius: 0px;
			margin-top: 0px;
		}

		.shoujipassword {
			width: 252px;
			height: 450px;
			position: absolute;
			left: 44.4%;
			top: 4.8%;
			z-index: 6;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
			z-index: 7;
			background-color: rgba(0, 0, 0, 0.3);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.videoshoujiimg {
			/* width: 335px; */
			width: 280px;
			height: 490px;
			position: absolute;
			left: 42.8%;
			top: 0%;
			border-radius: 20px;
			z-index: 6;
		}

		.topvideoshu {
			width: 252px;
			height: 450px;
			position: absolute;
			left: 44.4%;
			top: 4.8%;
			z-index: 6;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
		}


		/* 公司介绍区 */
		.companymessage {
			width: 75%;
			height: 300px;
			margin: 20px auto 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.companymessage-left {
			width: 70%;
		}

		.companymessage-top {
			width: 400px;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}

		.companymessage-top div:nth-of-type(1) {
			font-size: 20px;
			color: #545454;
			line-height: 34px;
			font-weight: bold;
		}

		.companymessage-top div:nth-of-type(2) {
			font-size: 14px;
			color: #535353;
		}

		.companymessage-bottom {
			width: 600px;
			text-align: left;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
			margin-top: 20px;
		}

		.companymessage-bottom div:nth-of-type(1) {
			font-size: 22px;
			color: #545454;
			line-height: 34px;
			font-weight: bold;
		}

		.companymessage-bottom div:nth-of-type(2) {
			font-size: 14px;
			color: #535353;
			line-height: 24px;
		}

		.companymessage-right {
			width: 200px;

		}

		.ercodeimg {
			width: 180px;
			height: 180px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: #f4f4f4 1px solid;
			border-radius: 15px;
		}

		.ercodeimg img {
			width: 170px;
			height: 170px;
		}

		.ercodetext {
			width: 180px;
			text-align: center;
			font-size: 15px;
			line-height: 35px;
		}

		/* 合作详情区 */
		.detailcontent {
			/* width: 75%; */
			width: 100%;
			margin: 30px auto;
		}

		.detailcontent-center {
			width: 100%;
		}

		.detailcontent-center img {
			width: 100%;
		}

		/* 精心打造 */
		.englishtext {
			width: 100%;
			height: 110px;
			position: relative;
			margin: 0px auto 0;
		}

		.englishtext-center {
			width: 60%;
			margin: 0 auto;
			/* 		position: absolute;
				z-index: 5;
				left: -500px;
				top: 0; */
		}

		.huienglishi {
			width: 100%;
			text-align: center;
			margin-top: 50px;
			height: 70px;
			font-size: 70px;
			line-height: 70px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}

		.englishtext-word {
			width: 100%;
			text-align: center;
		}

		.englishtext-word span:nth-of-type(1) {
			display: block;
			font-size: 25px;
			margin-top: -40px;
		}

		.englishtext-word span:nth-of-type(2) {
			font-size: 20px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}

		/* 合作流程 */
		.service-show-process {
			width: 85%;
			margin: 0px auto 0;
			height: 370px;
			position: relative;
		}

		.service-process-box {
			max-width: 1240px;
			margin: 0 auto;
			text-align: center;
			/* 		position: absolute;
				right: -100px;
				top: 0; */
		}

		.pro-item>div:hover {
			-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}

		.clearfix {
			zoom: 1;
		}

		.clearfix:after,
		.clearfix:before {
			content: "";
			display: block;
			height: 0;
			overflow: hidden;
			visibility: hidden;
			width: 0;
			clear: both;
		}

		.pro-item {
			float: left;
			width: 16.66666667%;
			position: relative;
		}

		.service-show-process .pro-item::before {
			content: "";
			display: block;
			width: 16px;
			height: 16px;
			background: #e23433;
			border: 3px solid #fff;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			margin: 0 auto;
			position: absolute;
			bottom: 125px;
			left: 0;
			right: 0;
			z-index: 2;
		}

		.service-show-process .pro-item::after {
			content: "";
			border-top: 1px solid #eae9e9;
			position: absolute;
			bottom: 135px;
			left: 0;
			right: 0;
			clear: both;
			display: block;
		}

		.service-show-process .pro-item>div {
			height: 260px;
			padding: 30px 10px;
			-webkit-transition: all .3s ease-out;
			-moz-transition: all .3s ease-out;
			-ms-transition: all .3s ease-out;
			-o-transition: all .3s ease-out;
			transition: all .3s ease-out;
			background-repeat: no-repeat;
			background-position: 55px 20px;
		}

		.pro-item img {
			vertical-align: middle;
			display: inline-block;
		}

		.service-show-process .pro-item em {
			display: block;
			font-size: 16px;
			margin-top: 10px;
			font-style: normal;
		}

		.work-box {
			margin-top: 30px;
			color: #888;
			line-height: 25px;
			width: 80%;
			text-align: left;
			display: inline-block;
		}

		.work-box p {
			font-size: 14px;
		}

		/* 我们的优势标题 */
		.advantagetext {
			width: 100%;
			height: 120px;
			position: relative;
		}

		.advantagetext-center {
			width: 60%;
			margin: 0 auto;
			/* 		position: absolute;
				top: -100px;
				left: 20%;
				z-index: 5; */
		}

		.advantagelishi {
			width: 100%;
			text-align: center;
			margin-top: 20px;
			height: 50px;
			font-size: 90px;
			line-height: 70px;
			text-transform: uppercase;
			color: #f7f7f7;
			font-weight: bold;
		}

		.advantagetext-word {
			width: 100%;
			text-align: center;
		}

		.advantagetext-word span:nth-of-type(1) {
			display: block;
			font-size: 25px;
			line-height: 40px;
			margin-top: -30px;
		}

		.advantagetext-word span:nth-of-type(2) {
			font-size: 20px;
			color: #888;
			margin-top: 10px;
			line-height: 30px;
		}

		/* 我们的优势 */
		.advantage-wrap {
			width: 100%;
			margin: 30px auto 0;
			height: 700px;
			background-image: url(../assets/img/bolan.jpg);
		}

		.advantage-center {
			width: 84%;
			height: 520px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
		}

		.advantage-left {
			width: 34%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.dvantage-list {
			width: 100%;
			height: 250px;
			background-color: #fff;
			border-radius: 15px;
		}

		.dvantage-listtop {
			width: 92%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 20px;
			box-sizing: border-box;
		}

		.dvantage-listtop img {
			width: 40px;
			height: 40px;
		}

		.dvantage-listtoptitle {
			margin-left: 20px;
		}

		.dvantage-listtoptitle div:nth-of-type(1) {
			font-size: 18px;
			line-height: 25px;
		}

		.dvantage-listtoptitle div:nth-of-type(2) {
			font-size: 14px;
			line-height: 20px;
			color: #cccfda;
		}

		.dvantage-listmiddle {
			margin-top: 10px;
			font-size: 16px;
			line-height: 25px;
			color: #01aeec;
			font-weight: bold;
		}

		.dvantage-listbottom {
			width: 92%;
			margin: 0 auto;
			text-align: left;
			font-size: 13px;
			line-height: 20px;
			color: #999999;
		}


		.advantage-middle {
			width: 29%;
			height: 520px;
			background-color: green;
			border-radius: 10px;
			background-image: url(../assets/img/casemiddle.jpg);
			background-size: 100% 100%;
		}

		.advantage-right {
			width: 34%;
			height: 520px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		/* 更多案例区 */
		.morecase-title {
			width: 90%;
			margin: 40px auto 0;
			text-align: left;
			font-size: 20px;
			color: #545454;
			line-height: 34px;
			font-weight: bold;
			border-bottom: #f4f4f4 1px solid;
			padding-bottom: 10px;
		}

		.morecase-wrap {
			width: 90%;
			height: 200px;
			margin: 30px auto 30px;
			padding-bottom: 50px;
		}

		.swiper-container {
			width: 100%;
			height: 100%;
		}

		.swiper-slide {
			text-align: center;
			font-size: 18px;
			position: relative;
			overflow: hidden;

			/* Center slide text vertically */
			transition: 300ms;
			transform: scale(0.8);
		}

		.swiper-slide-active,
		.swiper-slide-duplicate-active {
			transform: scale(1);
		}

		.swiper-slide div:nth-of-type(1) {
			width: 100%;
			height: 148px;
			overflow: hidden;
		}

		.swiper-slide img {
			width: 100%;
			height: auto;
			/* position: absolute;
				left: 0;
				top: 0; */
			z-index: 1;
			vertical-align: bottom;
		}

		.swiper-slide div:nth-of-type(2) {
			width: 100%;
			height: 50px;
			/* background: rgba(0,0,0,0.5); */
			background-color: #000000;
			z-index: 2;
			color: #fff;
			font-size: 17px;
			line-height: 50px;
			letter-spacing: 2px;
		}
	}
</style>