<template>
	<div>
		<div class="topnav">
			<Headernew/>
		</div>
		
		<!-- 树形区域 -->
		<div class="wrap">
			  
			  <div class="nametip">
				  <div>{{peoplename}}</div>
				  <div>{{zhuanfadate}} 转发</div>
			  </div>
			  <div id="main" style="width: 100%;height:100%;"></div>
			  
		</div>
		
		
		<Footernew/>
	</div>

</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import {WOW} from 'wowjs'  //导入wow
	
	// 引入 ECharts 主模块
	var echarts = require('echarts/lib/echarts');
	// 引入柱状图
	require('echarts/lib/chart/bar');
	// 引入提示框和标题组件
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/title');
	
	export default {
		  name: 'formalechart',
		  components: {
		    Headernew,
		    Footernew,
		  },
		  data() {
				return {
				  peoplename:'',//转发人
				  zhuanfadate:'',//转发日期
				}
			  },
		  mounted() {
			  // 页面打开的时候滚到顶部
			  document.documentElement.scrollTop = document.body.scrollTop = 0
			  
			  // 在项目加载完成之后初始化wow
			  this.$nextTick(() => {
			  	let wow = new WOW({
			  		live:false
			  	})
			  	wow.init()
			  })

			  this.myEcharts()
		  },
		  methods: {

			  myEcharts(){
				  // 基于准备好的dom，初始化echarts实例
				  var myChart = echarts.init(document.getElementById('main'));
				  
				  let uid = this.$route.query.id;
				  let time = this.$route.query.time
				  
				  var that = this
				  let newurl = 'https://htkja.htkjbd.com/api/browsTable'
				  this.$axios.get(newurl,{
						 params:{
							 id:uid,
							 date:time
						 }
				  })
				  .then((res) => {
					 console.log(res.data.result.data)
				  	 var data = res.data.result.data[0]
					 that.peoplename = data.name
					 that.zhuanfadate = data.created_at
					 // console.log(data)
				  	 // console.log(res.data.result)
					 // 指定图表的配置项和数据
					 var option = {
						  tooltip: {
								  trigger: 'item',
								  triggerOn: 'mousemove'
							  },
							  series:[
								  {
									  type: 'tree',
									  name: '张先生',
									  data: [data],
									  top: '4%',
									  left: '18%',
									  bottom: '5%',
									  right: '25%',
									  // symbol:'rect',
									  symbolSize: 15, //圆圈大小
									  
									  itemStyle: {
										  color: '#00ced1' //圆圈颜色
									  },
									  lineStyle: {
										  "color": "#00ced1",//线段颜色
										  "width": 2 ,//线段粗细
										  'curveness': 0.4,//曲线弯曲度
									   },
									  label: {
										  position: 'left',
										  verticalAlign: 'middle',
										  align: 'right',
										  fontSize: 13 //标签文字大小
									  },
						  
									  leaves: {
										  label: {
											  position: 'right',
											  verticalAlign: 'middle',
											  align: 'left'
										  }
									  },
						  
									  emphasis: {
										  focus: 'descendant'
									  },
						  
									  expandAndCollapse: true,
						  
									  animationDuration: 550,
									  animationDurationUpdate: 750
						  
								  }
							  ]
					 };
					 		  
					 // 使用刚指定的配置项和数据显示图表。
					 myChart.setOption(option);
						  
				  })
				  .catch((err) => {
				  	console.log(err) //错误信息
				  })
				  
				  // var data = {
					 //  "name": "张先生",
					 //  "value": 9.12,
					 //  "children": [
						//   {
						// 	  "name": "郑经理",
						// 	  "children": [
						// 		  {
						// 			   "name": "王经理",
						// 			   "children": [
						// 				{"name": "李先生", 
						// 				 "value": 8.31,
						// 				 "children": [
						// 						{"name": "李先生", "value": 8.31},
						// 						{"name": "韩先生", "value": 9.12}
						// 				 ]
						// 				},
						// 				{"name": "韩先生", "value": 9.12}
						// 			   ]
						// 		  },
						// 		  {
						// 			  "name": "孙先生",
						// 			  "value": 9.02
						// 		  }
						// 	  ]
						//   },
						//   {
						// 	  "name": "王五",
						//   },
						//   {
						// 	  "name": "刘先生",
						// 	  "children": [
						// 		  {"name": "刘先生1", "value": 8.30}
						// 	  ]
						//   },
						//   {
						// 	 "name": "孙正",
						// 	 "children": [
						// 	  {"name": "孙正1", "value": 8.30},
						// 	  {"name": "孙正2", "value": 9.2},
						// 	  {
						// 	   "name": "孙正3",
						// 	   "children": [
						// 		{"name": "孙正4", "value": 8.30},
						// 		{"name": "孙正5", "value": 8.30},
						// 		{"name": "孙正6", "value": 8.30},
						// 		{"name": "孙正7", "value": 8.30},
						// 	   ]
						// 	  },
						// 	  {"name": "孙正8", "value": 8.30},
						// 	  {"name": "孙正9", "value": 8.30},
						// 	  {"name": "孙正10", "value": 8.30},
						// 	  {"name": "孙正11", "value": 8.30},
						// 	 ]
						// 	},
						//   {
						// 	 "name": "向左",
						// 	 "children": [
						// 	  {"name": "向左1", "value": 9.2},
						// 	  {"name": "向左2", "value": 9.2},
						// 	  {"name": "向左3", "value": 9.2},
						// 	  {"name": "向左4", "value": 9.2},
						// 	 ]
						//   }
					 //  ]
				  // };
				  
				  
				  
			  }
			  
		  }
	}
</script>

<style>
	body{
		background-image: none;
		margin: 0;
		padding: 0;
	}
	.topnav{
		width: 100%;
		height: 96px;
		background-color: #000000;
		/* background-image: url(../assets/img/zhuanfabg.jpg);
		background-size: 100% auto; */
	}
	.wrap{
		width: 1400px;
		height: 100vh;
		margin: 0;
		position: relative;
	}
	.nametip{
		display: flex;
		align-items: center;
		position: absolute;
		left: 20px;
		top: 30px;
		font-size: 16px;
		line-height: 16px;
		font-weight: bold;
	}
	.nametip div:nth-of-type(2){
		margin-left: 10px;
	}
	@media (min-width: 300px) and (max-width: 500px){
		.topnav{
			width: 100%;
			height: 60px;
			background-color: #000000;
		}
		.wrap{
			width: 100%;
			height: 100vh;
			margin: 0;
			overflow: hidden;
			position: relative;
		}
		.nametip{
			width: 100%;
			display: flex;
			align-items: center;
			position: absolute;
			left: 10px;
			top: 15px;
			font-size: 16px;
			line-height: 16px;
			font-weight: bold;
		}
		.nametip div:nth-of-type(2){
			margin-left: 10px;
		}
	}
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.topnav{
			width: 100%;
			height: 80px;
			background-color: #000000;
		}
		.wrap{
			width: 100%;
			height: 100vh;
			margin: 0;
			/* overflow: hidden; */
			position: relative;
		}
		.nametip{
			width: 100%;
			display: flex;
			align-items: center;
			position: absolute;
			padding-left: 10px;
			box-sizing: border-box;
			left: 0px;
			top: 15px;
			font-size: 16px;
			line-height: 16px;
			font-weight: bold;
		}
		.nametip div:nth-of-type(2){
			margin-left: 10px;
		}
	}
</style>
