<template>
	<div class="loginWrap">
		<div class="loginsucess">登录成功,</div>
		<div class="miao">{{daotime}}</div>
		<div v-if="pctext">秒后跳转至首页....</div>
		<div v-if="!pctext">秒后进入页面....</div>
	</div>
</template>

<script>
	import $ from "jquery"
	import wx from 'weixin-js-sdk'
	export default {
	  data() {
		return {
			daotime:2,
			moubeilappid:'',//从地址栏上截取的appid
			pctext:true,//默认展示非微信端的文字提示
		}
	  },
	  watch: {
	  	$route(){
	          if(this.$route.query.code!=undefined){
	               this.getWeixin(this.$route.query.code,this.$route.query.state);
	          }
	      }
	  },
	 // js代码
	 created () {
	 	let code=this.getUrlKey("code");
	    let state=this.getUrlKey("state");
		this.moubeilappid=this.getUrlKey("appid");
	    // this.getWeixin(code,state);
		this.getuserInfo(code)//页面一打开就获取用户扫码的信息
	 },
	 methods: {
	     // 公共方法
	 	 getUrlKey (name) {
	          return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
	     },
	     // 通过code获取微信信息
	   //   getWeixin(code,state){
			 // let newurl = "https://api.weixin.qq.com/sns/oauth2/access_token?appid=wx799c2495cc0d2a30&secret=0f17610d91b201443ce4e99acaa760ba&code=" + code + "&grant_type=authorization_code"
			 // this.$axios.get(newurl)
			 // .then((res) => {
				// 	console.log(res)
			 // })
			 // .catch((err) => {
				// console.log(err)
			 // })
	   //   },
	   // 获取微信个人信息，存入缓存中
	   getuserInfo(code){
		   var that = this
		   //判断是不是在微信浏览器打开，是的话，就展示微信端的跳转提示
		   var ua = navigator.userAgent.toLowerCase();
		   if(ua.match(/MicroMessenger/i) == 'micromessenger'){
			   this.pctext = false
		   }else{
			   this.pctext = true
		   }
		   
		   
		   var newdata
		   //查看本地存储中有没有随机字符串，如果有，说明是从分享进来的
		   var newrandomstr = localStorage.getItem('randomstr');
		   
		   if(newrandomstr){
			 var randomstr = newrandomstr.split("=")[1]  
		   }
		   
		   
		   // 假如是移动端的登录，请求的参数的appid就是在缓存中，缓存中没有，就用公众平台的
		   // 如果本地有随机字符串，说明是分享进来的，那么参数中也带上这个随机字符串
			if(that.moubeilappid){
				if(randomstr){
					newdata = {
						params:{
							appid:'wx799c2495cc0d2a30',
							random_str:randomstr
						}
					}

				}else{
					newdata = {
						params:{
							appid:'wx799c2495cc0d2a30'
						}
					}
				}
			}else{
				if(randomstr){
					newdata = {
						params:{
							appid:'wxc6b84ed9b28d643f', // openappid
							random_str:randomstr
						}
					}
				}else{
					newdata = {
						params:{
							appid:'wxc6b84ed9b28d643f', // openappid
						}
					}
				}
				
			}
			
			// var backurl = localStorage.getItem('geotourl')
			// 向接口请求，获取到最新的url地址，进行跳转
			var urlcookie = document.cookie
			// if(urlcookie){
			// 	urlcookie = document.cookie.split('retrunurl=')[1]
			// }
			//获取存在本地的cookie的url，因为微信授权登录的时候，跳转后会获取不到localStorage
			// alert(urlcookie)
			
			let newurl = that.$api + "/get/AuthCode/" + code
			that.$axios.get(newurl,newdata)
			.then((res) => {
				console.log(res, "login success");
				var name = res.data.result.nickname
				var id = res.data.result.id
				var adminid = res.data.result.is_admin
				var usermessage = {
					username:name,
					userid:id,
					adminid:adminid
				}
				
				localStorage.setItem('usermessage',JSON.stringify(usermessage));
				var timer = setInterval(()=>{
					if(that.daotime>1){
						that.daotime--
					}else{
						// 如果是在微信端打开的，且缓存中有案例详情地址栏信息，就返回案例详情页
						// if(backurl){
						// 	window.location.href = backurl
						// }else 
						
						if(urlcookie){
							// window.location.href = urlcookie
							urlcookie = document.cookie.split('retrunurl=')[1]
							var jumpurl = urlcookie.split('.com')[1]
							that.$router.replace({path: jumpurl})
						}else{
							// 如果不是微信端打开，就跳入首页
							that.$router.replace({path: "/"})
						}
						
						clearInterval(timer)
					}
				},1000)
			})
			.catch((err) => {
				    console.log(err)
			})

	   },
	 }
	}
</script>

<style>
	.loginWrap{
		width: 100%;
		height: 600px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.loginWrap div{
		font-size: 18px;
		line-height: 30px;
	}
</style>
