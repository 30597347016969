<template>
	<div class="all-wrap">
		<!-- 留言按钮 -->
		<Comment class="commentWrap"/>
		<!-- 置顶组件 -->
		<gotop></gotop>
		
		<div class="videolist-banner">
			<!-- 顶部导航栏 -->
			<Headernew/> 
			
			<div class="wow fadeInUp bannertop-title">
				<div>用品质面对挑战，用艺术重塑企业</div>
				<div>——后天科技</div>
			</div>
			<!-- 搜索框 -->
			<div class="wow fadeInUp searchWrap">
				<input class="searchInput" type="text" placeholder="请输入要搜索的内容" v-model="searchinput"/>
				<div>|</div>
				<div class="searchBtn" @click="sendInput">搜索</div>
			</div>
		</div>
		
		<!-- 滚动文字 -->
		<div class="wow fadeInUp grolltext-wrap" >
			<div class="grolltext-center">
				
				<div class="grolltext-list1 grolltext-border">
					<div class="grolltext-top">Area</div>
					<div class="grolltext-num">
						<div>
							<countTo :startVal='0' :endVal='35' :duration='2000'></countTo>
						</div>
						<span class="iconfont icon-add secondspan"></span>
					</div>
					<div class="grolltext-word">服务合作区域</div>
				</div>
				
				<div class="grolltext-list1 grolltext-border">
					<div class="grolltext-top">Year</div>
					<div class="grolltext-num">
						<div>
							<countTo :startVal='0' :endVal='19' :duration='2000'></countTo>
						</div>
						<span class="iconfont icon-add secondspan"></span>
					</div>
					<div class="grolltext-word">年企业科技服务经验</div>
				</div>
				
				<div class="grolltext-list1 grolltext-border">
					<div class="grolltext-top">Check</div>
					<div class="grolltext-num zuihounum">
						<div>
							<countTo :startVal='0' :endVal='15' :duration='2000'></countTo>
						</div>
						<span class="iconfont icon-add secondspan"></span>
					</div>
					<div class="grolltext-word">项验收标准</div>
				</div>
			
				<div class="grolltext-list1">
					<div class="grolltext-top">Company</div>
					<div class="zuihou-num">
						<div>
							<countTo :startVal='0' :endVal='825' :duration='2000'></countTo>
						</div>
						<span class="iconfont icon-add grolltext-numtext"></span>
					</div>
					<div class="grolltext-word">家企业选择与我们合作</div>
				</div>
			</div>
		</div>
		
		<!-- 中间位置固定尺寸 -->
		<div class="fixedwidth">
			
			<!-- 搜索结果区域标题 -->
				<div class="englishtext" v-if="searchList.length>0">
					<div class="englishtext-center">
						<div class="huienglishi">SEARCH RESULTS</div>
						<div class="englishtext-word">
							<span>搜索结果</span>
							<span>后天科技热忱服务好每一位客户 满怀信心创作新品</span>
						</div>
					</div>
				</div>
				
				
				<!-- 搜索结果区域 -->
				<div class="searchlist-Wrap" v-if="searchList.length>0">
					
					<div class="searchlist-middle">
						<waterfall :col="col" :data="searchList">
						<template>
						<div class="searchlist-zhong" v-for="(item,index) in searchList">
							<!-- 这是横屏的搜索内容 -->
							<div class="wow fadeInUp searchli" v-if="item.code_style==1" @click="remourl">
								<div class="box">
									<div class="img slow">
										<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class='preview'>
										<img :src="item.simg + '?x-oss-process=style/avatar_375'" />
										<span class='red-bg'></span>
										<span class='line line_1'></span>
										<span class='line line_2'></span>
										</router-link>
									</div>
										<div class="con">
											<h5>
												<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="title" >
												<span>{{item.main}}</span>
												<i><img src="../assets/img/listlian.png"></i>
												</router-link>
											</h5>
											<span class="hengxian"></span>
											<span></span>
											<div>
												<router-link class="conh6" target="_blank" :to="{path:'/casecontent',query:{id:item.id}}">项目时间</router-link>
												 ： {{item.time | showDate}}
											</div>
										</div>
								</div>
							</div>
									
							<!-- 竖屏视频 -->
							<div class="shusearchli" v-if="item.code_style==2" @click="remourl">
								<div class="searchcentertab-listtop">
									<router-link :to="{path:'/casecontent',query:{id:item.id}}" target="_blank" class="newcentertab-listtopl">
										<img :src="item.simg + '?x-oss-process=style/avatar_375'">
										
										<div class="newcentertab-listline">
											<span class='line line_1'></span>
											<span class='line line_2'></span>
										</div>
									</router-link>
								</div>
								<div class="searchcentertab-listword">
									<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="newcentertab-listwordl">
										<div class="newcentertab-listname">{{item.main}}</div>
										<div class="newcentertab-time">{{item.time | showDate}}</div>
									</router-link>
								</div>
							</div>
						</div>
						</template>
						</waterfall>
					</div>

						<!-- 加载中和没有更多的图标 -->
						<div class="loadicon">
							<div class="jiazaidone" v-if="showload == 1">
								<span class="iconfont icon-dengdai"></span>
								<div>加载中...</div>
							</div>
							<div class="jiazaidone" v-if="showload == 2">
								<span class="iconfont icon-xiaolian"></span>
								<div>没有更多了!</div>
							</div>
							
						</div>
						
				</div>
				
				
				<!-- 精心打造 -->
				<div class="wow fadeInUp englishtext" v-if="searchList==0">
					<div class="englishtext-center">
						<div class="huienglishi">TEAM PROJECT</div>
						<div class="englishtext-word">
							<span>后天科技从面到点服务到每一个角落</span>
							<span>我们用理性的思维、创意的视角、科技的整合，让营销变得更有力量</span>
						</div>
					</div>
				</div>
				
				<!-- 小标题区 -->
				<div class="wow fadeInUp littleword" v-if="searchList==0">
					<div class="littlelist" v-for="(item,i) in secondvideolist" @click="jumplaction(item.id)">
						<span class="iconfont icon-shoushimima" v-if="item.name == '智能交互'"></span>
						<span class="iconfont icon-Dmoxingx" v-if="item.name == '3D漫游动画'"></span>
						<span class="iconfont icon-dianying" v-if="item.name == '数字影片'"></span>
						<span class="iconfont icon-shipinbofangyingpian" v-if="item.name!='智能交互' && item.name!='3D漫游动画' && item.name !='数字影片'"></span>
						<div>{{item.name}}</div>
					</div>
				</div>
				
				<!-- 列表区 -->
				<div class="wow fadeInUp videolist-wrap" v-for="(very,es) in videolist" :id="very.code_id" v-if="searchList==0">
					<div class="videolist-h1">
						<div class="videolist-h1title">{{very.code_name}} |</div>
					</div>
					
					<ul v-if="very.style==1">
					   <li class="wow fadeInUp " v-for="(item,i) in very.videos" style="visibility: visible; animation-name: fadeInUp;" @click="remourl">
							<div class="box">
								<div class="img slow">
									<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class='preview'>
									<img :src="item.simg + '?x-oss-process=style/avatar_375'" />
									
									<i class="videolist-tips" v-if="item.code_name">{{item.code_name}}</i>
									<span class='red-bg'></span>
									<span class='line line_1'></span>
									<span class='line line_2'></span>
									</router-link>
								</div>
									<div class="con">
										<h5>
											<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="title" >
											<span>{{item.main}}</span>
											<i><img src="../assets/img/listlian.png"></i>
											</router-link>
										</h5>
										<!-- <hr> -->
										<span class="hengxian"></span>
										<div>
											<router-link class="conh6" target="_blank" :to="{path:'/casecontent',query:{id:item.id}}">项目时间</router-link>
											 ： {{item.time | showDate}}
										</div>
									</div>
							</div>
						</li>
						
		<!-- 			   <li class="wow fadeInUp " style="visibility: visible; animation-name: fadeInUp;">
							<div class="box">
								<div class="img slow">
									<router-link to="/casecontent" class='preview'>
									<img src='../assets/img/videolist1.png' />
									<span class='red-bg'></span>
									<span class='line line_1'></span>
									<span class='line line_2'></span>
									</router-link>
								</div>
									<div class="con">
										<h5>
											<router-link to="/casecontent" class="title" title="大湾区国际金科城">
											<span>大湾区国际金科城</span>
											<i><img src="../assets/img/listlian.png" title=""></i>
											</router-link to="/casecontent">
										</h5>
										<hr>
										<h6>
											<router-link to="/casecontent">政府机构</router-link>
											 / 响应式官网制作  定制开发
										</h6>
									</div>
							</div>
						</li> -->
						
					
					</ul>
					
					
					<!-- 竖屏视频 -->
					<div class="newcentertab-right" style="width: 89%;margin: 0 auto;" v-if="very.style==2" @click="remourl">
						<div class="newcentertab-list" v-for="(item,i) in very.videos">
							<div class="newcentertab-listtop">
								<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="newcentertab-listtopl">
									<img :src="item.simg + '?x-oss-process=style/avatar_375'">
									
									<div class="newcentertab-listline">
										<!-- <span class='red-bg'></span> -->
										<span class='line line_1'></span>
										<span class='line line_2'></span>
									</div>
								</router-link>
							</div>
							<div class="newcentertab-listword">
								<router-link target="_blank" :to="{path:'/casecontent',query:{id:item.id}}" class="newcentertab-listwordl">
									<div class="newcentertab-listname">{{item.main}}</div>
									<div class="newcentertab-time">{{item.time | showDate}}</div>
								</router-link>
							</div>
						</div>
					</div>
					
				</div>
				
				
				
				<!-- 新增轮播分类区 -->
				<!-- <div class="newcentertab">
					<div class="newcentertab-center">
						<div class="videolist-h1">
							<div class="videolist-h1title">竖版视频 |</div>
						</div>
						
						<div class="newcentertab-right">
							<div class="newcentertab-list">
								<div class="newcentertab-listtop">
									<img src="https://tanpanxia.tanpanxia.xjaikj.com/storage/xjaikj/public/2021-03-02/K3AhnYZlqYSXXgWiVXAoDFRIyNF91mLFlsvN0BOK.jpeg">
									
									<div class="newcentertab-listline">
										<div class="newcentertab-black"></div>
									</div>
								</div>
								<div class="newcentertab-listword">
									<div class="newcentertab-listname">长城灯具动画样片</div>
									<div class="newcentertab-time">2021-03-15</div>
								</div>
							</div>
							
							<div class="newcentertab-list">
								<div class="newcentertab-listtop">
									<img src="https://tanpanxia.tanpanxia.xjaikj.com/storage/xjaikj/public/2021-03-02/K3AhnYZlqYSXXgWiVXAoDFRIyNF91mLFlsvN0BOK.jpeg">
									
									<div class="newcentertab-listline">
										<div class="newcentertab-black"></div>
									</div>
								</div>
								<div class="newcentertab-listword">
									<div class="newcentertab-listname">长城灯具动画样片</div>
									<div class="newcentertab-time">2021-03-15</div>
								</div>
							</div>
		
							<div class="newcentertab-list">
								<div class="newcentertab-listtop">
									<img src="https://tanpanxia.tanpanxia.xjaikj.com/storage/xjaikj/public/2021-03-02/K3AhnYZlqYSXXgWiVXAoDFRIyNF91mLFlsvN0BOK.jpeg">
									
									<div class="newcentertab-listline">
										<div class="newcentertab-black"></div>
									</div>
								</div>
								<div class="newcentertab-listword">
									<div class="newcentertab-listname">长城灯具动画样片</div>
									<div class="newcentertab-time">2021-03-15</div>
								</div>
							</div>
							
							<div class="newcentertab-list">
								<div class="newcentertab-listtop">
									<img src="https://tanpanxia.tanpanxia.xjaikj.com/storage/xjaikj/public/2021-03-02/K3AhnYZlqYSXXgWiVXAoDFRIyNF91mLFlsvN0BOK.jpeg">
									
									<div class="newcentertab-listline">
										<div class="newcentertab-black"></div>
									</div>
								</div>
								<div class="newcentertab-listword">
									<div class="newcentertab-listname">长城灯具动画样片</div>
									<div class="newcentertab-time">2021-03-15</div>
								</div>
							</div>
							
							<div class="newcentertab-list">
								<div class="newcentertab-listtop">
									<img src="https://tanpanxia.tanpanxia.xjaikj.com/storage/xjaikj/public/2021-03-02/K3AhnYZlqYSXXgWiVXAoDFRIyNF91mLFlsvN0BOK.jpeg">
									
									<div class="newcentertab-listline">
										<div class="newcentertab-black"></div>
									</div>
								</div>
								<div class="newcentertab-listword">
									<div class="newcentertab-listname">长城灯具动画样片</div>
									<div class="newcentertab-time">2021-03-15</div>
								</div>
							</div>
							
						</div>
					</div>
				</div> -->
			
		</div>
		
		
		<!-- 底部品牌区 -->
		<div class="wow fadeInUp brand-wrap" v-if="searchList==0">
			<div class="brand-top">
				<img src="../assets/img/containertitle.png" />
				<div class="brand-text">一路走来，感谢信任、感激支持、感恩有您陪伴！</div>
			</div>
			
			<!-- 底部轮播图 -->
			<div class="swiper-container">
			  <div class="swiper-wrapper">
			    <div class="swiper-slide">
					<div class="lunbolisttop">
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist15.jpg"></div>
							<div class="lunbolisttext">精工汽车</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist16.jpg"></div>
							<div class="lunbolisttext">红星美凯龙</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist17.jpg"></div>
							<div class="lunbolisttext">保定供水</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist18.jpg"></div>
							<div class="lunbolisttext">观璟臺</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/zhuozheng.jpg"></div>
							<div class="lunbolisttext">卓正地产</div>
						</div>
					</div>
					<div class="lunbolisttop">
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist19.jpg"></div>
							<div class="lunbolisttext">安诚房产</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist20.jpg"></div>
							<div class="lunbolisttext">河北保百集团</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist21.jpg"></div>
							<div class="lunbolisttext">民生地产</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist22.jpg"></div>
							<div class="lunbolisttext">茂业百货</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist23.jpg"></div>
							<div class="lunbolisttext">曼德热系统</div>
						</div>
					</div>
				</div>
				<div class="swiper-slide">
					<div class="lunbolisttop">
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist24.jpg"></div>
							<div class="lunbolisttext">诚信.丽景蓝湾</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist25.jpg"></div>
							<div class="lunbolisttext">绿都.皇城</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist26.jpg"></div>
							<div class="lunbolisttext">隆基泰和</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist27.jpg"></div>
							<div class="lunbolisttext">药都制药</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist28.jpg"></div>
							<div class="lunbolisttext">蔺氏盛泰</div>
						</div>
					</div>
					<div class="lunbolisttop">
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist29.jpg"></div>
							<div class="lunbolisttext">鑫丰集团</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist30.jpg"></div>
							<div class="lunbolisttext">同美地产</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist31.jpg"></div>
							<div class="lunbolisttext">天威集团</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist32.jpg"></div>
							<div class="lunbolisttext">中国移动</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist33.jpg"></div>
							<div class="lunbolisttext">荣盛.锦绣书院</div>
						</div>
					</div>
				</div>
				<div class="swiper-slide">
					<div class="lunbolisttop">
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist34.jpg"></div>
							<div class="lunbolisttext">中国电信</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist35.jpg"></div>
							<div class="lunbolisttext">中国银行</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/yingli.jpg"></div>
							<div class="lunbolisttext">英利集团</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist14.jpg"></div>
							<div class="lunbolisttext">华中地产</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist11.jpg"></div>
							<div class="lunbolisttext">庞大集团</div>
						</div>
					</div>
					<div class="lunbolisttop">
							<div class="lunbolist">
								<div class="lunbolistimg"><img src="../assets/img/caselist/caselist1.jpg"></div>
								<div class="lunbolisttext">北国商城</div>
							</div>
							<div class="lunbolist">
								<div class="lunbolistimg"><img src="../assets/img/caselist/caselist2.jpg"></div>
								<div class="lunbolisttext">北斗星</div>
							</div>
							<div class="lunbolist">
								<div class="lunbolistimg"><img src="../assets/img/caselist/caselist3.jpg"></div>
								<div class="lunbolisttext">北国先天下</div>
							</div>
							<div class="lunbolist">
								<div class="lunbolistimg"><img src="../assets/img/caselist/caselist4.jpg"></div>
								<div class="lunbolisttext">保定学院</div>
							</div>
							<div class="lunbolist">
								<div class="lunbolistimg"><img src="../assets/img/caselist/caselist5.jpg"></div>
								<div class="lunbolisttext">保定频道</div>
							</div>
						</div>
				</div>
				<div class="swiper-slide">
					<div class="lunbolisttop">
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist6.jpg"></div>
							<div class="lunbolisttext">百悦.梧桐中心</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist7.jpg"></div>
							<div class="lunbolisttext">百年集团</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist8.jpg"></div>
							<div class="lunbolisttext">国美电器</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist9.jpg"></div>
							<div class="lunbolisttext">紫御美墅</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist10.jpg"></div>
							<div class="lunbolisttext">中国石油</div>
						</div>
					</div>
					<div class="lunbolisttop">
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist12.jpg"></div>
							<div class="lunbolisttext">河北农村信用社</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist13.jpg"></div>
							<div class="lunbolisttext">捞悦.百汇火锅</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist8.jpg"></div>
							<div class="lunbolisttext">国美电器</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist9.jpg"></div>
							<div class="lunbolisttext">紫御美墅</div>
						</div>
						<div class="lunbolist">
							<div class="lunbolistimg"><img src="../assets/img/caselist/caselist10.jpg"></div>
							<div class="lunbolisttext">中国石油</div>
						</div>
					</div>
					
				</div>

			  </div>
			  <!-- Add Pagination -->
			  <div class="swiper-pagination"></div>
			</div>
		</div>
		<Footernew ref="getheight"/>
	</div>
</template>

<script>
	import Swiper from "swiper"
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import countTo from 'vue-count-to';
	import Comment from '@/components/comment.vue'
	import { formatDate } from '../quote/utils.js' //导入时间戳处理
	import gotop from '@/components/gotop.vue'
	
	import {WOW} from 'wowjs'  //导入wow
	export default {
		name: '',
		data() {
			return {
				col:4,//waterfall瀑布流一行展示的数量
				screenWidth: document.body.clientWidth,// 屏幕宽
				videolist:[],//视频列表
				secondvideolist:[],//第二个视频列表
				searchList:[],//搜索结果的视频列表
				showload:'',//控制上拉刷新的图标样式
				page:1,//上滑刷新的页数
				limit:4,//每页显示的数据数量
				footerheight:'',//计算底部footer的高度，上滑刷新用
				searchinput:'',//获取搜索框内容
			}
		},
		components: {
		  Headernew,
		  Footernew,
		  countTo,
		  Comment,
		  gotop
		},
		watch:{
			// 检测屏幕宽度变化
			 screenWidth(val) {
					this.screenWidth = val;
					console.log(this.screenWidth)
					if(this.screenWidth > 320 && this.screenWidth <= 800){
						this.navshow = false
					}
			   }
			
		},
		mounted() {
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
				// 页面加载完成后，计算出footer的高度，防止错误出现
				this.footerheight= this.$refs.getheight.$el.offsetHeight;
			})
			
			// 每次进入页面，都让页面置顶
			document.body.scrollTop = document.documentElement.scrollTop= 0
			
			var that = this
			// 这里是用来获取屏幕宽度的，当时移动端的时候，就让瀑布流的行数发生变化
			window.onresize= () => {
			    return (() => {
			        window.screenWidth = document.body.clientWidth;
			        that.screenWidth = window.screenWidth;
			    })();
			}
			that.changelunbo() //执行判断是不是移动端函数
			
		    var mySwiper = new Swiper(".swiper-container", {
		      loop: true, // 循环模式选项
			  speed:1000,
			  autoplay: {
				  delay: 3000,
			  },
		      observer:true,
		      // 如果需要分页器
		      pagination: {
		        el: ".swiper-pagination"
		      },
		    });
			
			this.requestvideolist()
			
			setTimeout(function(){
				//创建时执行跳转锚点位置
				that.$nextTick(() => that.getlocal())
				// 执行跳转id函数
				that.$nextTick(function(){
					window.addEventListener('scroll',that.handleScroll)
				})
			},300)
			
			// 执行上滑加载更多函数
			window.addEventListener('scroll',this.sildeupmore)
		  },
		destroyed(){
			//离开页面的时候对本地缓存中id销毁，避免其他入口进来的锚点
			localStorage.setItem('id','')
		},
		  filters: {
			  // 评论时间格式化
			  showDate(value){
				let date = new Date(value * 1000)
				return formatDate(date, 'yyyy-MM-dd')
			  }
			},

		 methods:{
			 // 根据屏幕宽度，控制瀑布流一行展示的数量
			 changelunbo:function(){
			 	var that = this
			 	if(this.screenWidth > 320 && this.screenWidth <= 500){
			 		this.col = 2
			 	}else if(this.screenWidth > 500 && this.screenWidth < 800){
			 		this.col = 3
			 	}else if(this.screenWidth > 1000 && this.screenWidth < 1300){
					this.col = 3
				}else{
			 		this.col = 4
			 	}
			 	
			 },
			 // 如果用户在微信浏览器中点击了视频列表，那么就检测一下本地是不是有url缓存，有就删掉
			 remourl:function(){
				 var ua = navigator.userAgent.toLowerCase();
				 if(ua.match(/MicroMessenger/i) == 'micromessenger'){
					 var backurl = localStorage.getItem('geotourl')
					 if(backurl){
						 localStorage.removeItem('geotourl')
					 }
				 }
			 },
			 // 获取搜索框的内容，并进行列表搜索
			 sendInput:function(){
				 // var searchtext = this.$refs.searchtext.value
				 this.page=1
				 var searchtext = this.searchinput
				 let url = this.$api + "/get/getVideoNewByName?name=" + searchtext
				 this.$axios.get(url, {
					 params: {
						 on_show: 1,
						 limit:this.limit,
						 page:this.page
					 }
				 })
				 .then((res) => {
					 console.log(searchtext,res)
					 if(res.data.result.data.length==0){
						alert("没有搜索到相关内容，请重试")
					 }else{
						 this.searchList = res.data.result.data
					 }
				 })
				 .catch((err) => {
				 	console.log(err)
				 })
			 },
			 // 上滑加载更多
			 sildeupmore:function(){
				 var searchList = this.searchList
				 // 滚动条滚动高度
				 let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
				 //windowHeight是可视区的高度
			     var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
			     //scrollHeight是滚动条的总高度
			     var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
				 // 获取footer的高度
				 let height= this.footerheight;
				 //滚动条到底部的条件
				 if(scrollTop+windowHeight >= scrollHeight){
					 // 只有当搜索到了一次列表内容后，才可以执行上滑刷新
					 if(this.searchList.length>0){
						 var searchtext = this.searchinput
						 // 滚动到底部后，请求数据，并且每次触底，都让page+1
						 this.page+=1
						 this.showload=1
						 let url = this.$api + "/get/getVideoNewByName?name=" + searchtext
						 this.$axios.get(url, {
						 	 params: {
						 		 on_show: 1,
						 		 limit:this.limit,
						 		 page:this.page
						 	 }
						 })
						 .then((res) => {
						 	if(res.data.result.data.length==0){
						 		this.showload=2
						 	}else{
						 		this.searchList.push(res.data.result.data)
						 	}
						 	
						 })
						 .catch((err) => {
						 	console.log(err)
						 })
					 }
					 
					
				 } 
				 
			 },
			 
			 
			 // 请求视频列表
			 requestvideolist:function(){
				 //带参数的get请求
				 // let url = "https://htkja.htkjbd.com/api/get/video"
				 //https://htkja.htkjbd.com/api/get/getVideoNew?on_show=1
				 let newurl = this.$api + "/get/getVideoNew"
				 console.log(newurl)
				 this.$axios.get(newurl, {
					 params: {
						 on_show: 1
					 }
				 })
				 .then((res) => {
					var newres = res.data.result
					console.log(Object.values(newres)[1])
					// 视频列表
					this.videolist = Object.values(newres)[1]
					// 小标题列表
					this.secondvideolist = Object.values(newres)[0]
				 })
				 .catch((err) => {
					 console.log(err) //错误信息
				 })
			 },
			 
			 // 执行跳锚链接
			   jumplaction(e) {  //counter1是绑定的点击事件名称
			   console.log(1)
				 const returnEle = document.getElementById(e);  //productId是将要跳转区域的id
				 if (returnEle) {
				   returnEle.scrollIntoView(true); // true 是默认的
				 }
			   },
			   
			   // 从本地查找id，用于页面跳转
			   getlocal(){
				   var select = localStorage.getItem('id')
				   var elem = document.getElementById(select)
				   if(select){
					   elem.scrollIntoView(true);
				   }
			   },
			
		 }
	}
</script>

<style scoped="scoped">
	ul,li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	/* 评论组件 */
	.commentWrap{
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 100;
	 }
	/* 顶部banner区 */
	.videolist-banner{
		width: 100%;
		height: 700px;
		/* background-image: url(../assets/img/videolistbanner.jpg); */
		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/mediabanner.jpg);
		background-size: 100% 100%;
		position: relative;
		
	}
	.bannertop-title{
		width: 100%;
		position: absolute;
		left: 0;
		top: 30%;
	}
	.bannertop-title div:nth-of-type(1){
		width: 80%;
		margin: 0 auto;
		font-size: 61px;
		color: #fff;
		font-weight: bold;
	}
	.bannertop-title div:nth-of-type(2){
		width: 48%;
		margin: 35px auto 0;
		font-size: 31px;
		color: #fff;
		text-align: right;
		letter-spacing: 2px;
	}
	/* 搜索框 */
	.searchWrap{
		width: 30%;
		height: 50px;
		position: absolute;
		left: 50%;
		margin-left: -15%;
		bottom: 200px;
		background-color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 15px;
		border-radius: 5px;
		/* border-radius: 25px; */
	}
	.searchInput{
		width: 90%;
		height: 80%;
		border: none;
		outline: none;
		font-size: 15px;
		letter-spacing: 2px;
	}
	.searchBtn:hover{
		cursor: pointer;
	}
	
	
	/* 滚动文字区 */
	.grolltext-wrap{
		width: 100%;
		height: 150px;
		background-color: #f7f7f7;
	}
	.grolltext-center{
		width: 60%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.grolltext-list1{
		width: 18%;
		margin-left: 40px;
	}
	.grolltext-border{
		border-right: #eae9e9 2px solid;
		
	}
	.grolltext-top{
		width: 110px;
		font-size: 20px;
		color: #888895;
		text-align: left;
	}
	.grolltext-num{
		width: 110px;
		position: relative;
		text-align: left;
	}
	.zuihou-num{
		width: 110px;
		position: relative;
		text-align: left;
	}
	.grolltext-num div,.zuihou-num div{
		font-size: 55px;
		display: block;
		color: #444444;
		line-height: 70px;
	}
	.grolltext-numtext{
		position: absolute;
		right: 0px;
		top: 0px;
	}
	.secondspan{
		position: absolute;
		top: 0px;
		right: 27px !important;
	}
	.grolltext-word{
		width: 180px;
		text-align: left;
	}
	
	/* 外层固定宽度 */
	.fixedwidth{
		width: 1650px;
		margin: 0 auto;
	}
	
	/* 精心打造 */
	.englishtext{
		width: 100%;
		height: 150px;
	}
	.englishtext-center{
		width: 60%;
		margin: 0 auto;
	}
	.huienglishi{
		width: 100%;
		text-align: center;
		margin-top: 50px;
		height: 70px;
		font-size: 90px;
		line-height: 70px;
		text-transform: uppercase;
		color: #f7f7f7;
		font-weight: bold;
	}
	.englishtext-word{
		width: 100%;
		text-align: center;
	}
	.englishtext-word span:nth-of-type(1){
		display: block;
		font-size: 30px;
		margin-top: -30px;
	}
	.englishtext-word span:nth-of-type(2){
		font-size: 20px;
		color: #888;
		margin-top: 10px;
		line-height: 30px;
	}
	/* 小标题区 */
	.littleword{
		width: 88%;
		margin: 30px auto 70px;
		display: flex;
	}
	.littlelist{
		/* width: 150px; */
		height: 40px;
		padding: 0 15px;
		box-sizing: border-box;
		background-color: #fbfbfb;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 100px;
	}
	.littlelist:hover{
		cursor: pointer;
		background-color: #f26e33;
		color: #fff !important;
	}
	.littlelist span{
		font-size: 26px;
		font-weight: bold;
	}
	.littlelist div{
		font-size: 18px;
		margin-left: 10px;
		font-weight: bold;
	}
	/* 列表标题 */
	.videolist-h1{
		width: 88%;
		height: 50px;
		margin: 50px auto 10px;
	}
	.videolist-h1title{
		text-align: left;
		font-size: 20px;
		font-weight: bold;
	}
	/* 搜索列表区 */
	.searchlist-Wrap{
		width: 90%;
		margin: 0 auto;
		overflow: hidden;
		/* column-count: 4;
		column-gap: 10px; */
		/* display: flex;
		flex-wrap: wrap; */
		
	}
	.searchlist-middle{
		width: 100%;
		/* column-count: 4;
		column-gap: 0; */
		margin-bottom: 30px;
	}
	.searchlist-zhong{
		margin-bottom: 2.4375rem;
	}
	.searchli{
		/* width: 25%; */
		height: 200px;
		padding: 0 1.09375rem;
		/* margin-bottom: 2.4375rem; */
		box-sizing: border-box;
		break-inside: avoid;
	}
	.searchli .box{
		height: 100%;
		background: #f5f5f5;
	}
	.shusearchli{
		/* width: 25%; */
		height: 550px;
		padding: 0 1.09375rem;
		box-sizing: border-box;
		/* margin-bottom: 2.4375rem; */
		break-inside: avoid;
		/* margin-bottom: 20px; */
	}
	.shusearchli:hover{
		cursor: pointer;
	}
	.searchcentertab-listtop{
		width: 100%;
		height: 87%;
		overflow: hidden;
		position: relative;
		overflow: hidden;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.searchcentertab-listword{
		width: 100%;
		height: 9%;
		margin: 0 auto;
		padding-top: 4px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f5f5f5;
	}
	/* 加载中图标 */
	.loadicon{
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 10px 0 50px 0;
	}
	.jiazaidone{
		display: flex;
		align-items: center;
	}
	.icon-xiaolian{
		font-size: 22px;
		color: #999;
	}
	.icon-dengdai{
		font-size: 22px;
		color: #999;
	}
	.jiazaidone div{
		font-size: 18px;
		color: #999;
		margin-left: 10px;
	}
	
	/* 列表区 */
	.videolist-wrap{
		width: 100%;
	}
	.videolist-wrap ul{
		width: 90%;
		margin: 0 auto;
		display: flex;
		/* justify-content: space-between; */
		flex-wrap: wrap;
	}
	.videolist-wrap ul li{
		width: 25%;
		padding: 0 1.09375rem;
		margin-bottom: 2rem;
		box-sizing: border-box;
	}
	.fadeInUp {
	    -webkit-animation-name: fadeInUp;
	    animation-name: fadeInUp;
	}
	.animated {
	    -webkit-animation-duration: 1s;
	    animation-duration: 1s;
	    -webkit-animation-fill-mode: both;
	    animation-fill-mode: both;
	}
	.img{
		overflow: hidden;
		width: 100%;
		height: 168px;
		position: relative;
	}
	a{
		color: #333;
		text-decoration: none;
	}
	.img img{
		overflow: hidden;
		width: 100%;
		position: relative;
		height: auto;
		vertical-align: bottom;
	}
	.videolist-tips{
		padding: 4px;
		background-color: #EF590F;
		font-size: 12px !important;
		font-style: normal;
		color: #fff !important;
		text-align: center;
		position: absolute;
		right: 10px;
		top: 10px;
		border-radius: 4px;
	}
	.red-bg{
		background: rgba(226, 52, 51, 0.8);
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transition: 300ms;
		-webkit-transition: 300ms;
	}
	.img:hover .red-bg {
	    opacity: 1;
	    visibility: visible;
	}
	.box .img:hover .line {
	    transform: translate(0);
	    -webkit-transform: translate(0);
	    opacity: 1;
	    visibility: visible;
	}
	.img .line_1 {
	    width: 52px;
	    height: 1px;
	    margin: -1px 0px 0px -26px;
		transform: translateX(-55px);
		-webkit-transform: translateX(-55px);
	}
	.img .line_2 {
	    width: 1px;
	    height: 52px;
	    margin: -26px 0px 0px -1px;
	    transform: translateY(55px);
	    -webkit-transform: translateY(55px);
	}
	.img .line {
	    display: block;
	    background: #fff;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    opacity: 0;
	    visibility: hidden;
	    transition: 400ms;
	    -webkit-transition: 400ms;
	}
	.con {
	    background: #f5f5f5;
	    padding: 0px 15px;
	}
	.con h5{
	    font-size:18px;
	    height: 30px;
	    line-height: 30px;
	    color: #4d4c51;
		margin: 0 !important;
	}
	/* .con h5 a {
	    font-size: 1.375rem;
	    height: 1.5rem;
	    line-height: 1.5rem;
	    color: #4d4c51;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	} */
	.con h5 a span {
	    display: inline-block;
	    max-width: 85%;
	    font-weight: bold;
	    font-size: 16px;
	    height: 30px;
	    line-height: 30px;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}
	.con h5 i {
	    float: right;
	}
	.con h5 i img {
	    max-height: 1.5rem;
	}
	.con div {
	    font-size: 14px !important;
	    color: #4d4c51;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
		margin: 0 !important;
		line-height: 20px;
	}
	.hengxian{
		width: 100%;
		display: block;
		border-top: #999 1px solid;
	}
	.conh6{
		font-size: 14px !important;
	}
	/* 轮播分类区 */
	.newcentertab{
		width: 89%;
		margin: 0 auto 100px;
	}
	.newcentertab-center{
		width: 100%;
		height: 100%;
	}
	.newcentertab-center .videolist-h1{
		width: 88% !important;
		padding-left: 0.5%;
		margin: 0 !important;
	}
	.newcentertab-right{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}
	
	.newcentertab-list{
		width: 20%;
		height: 560px;
		padding: 0 10px 0px 10px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}
	.newcentertab-list:hover{
		cursor: pointer;
	}
	.newcentertab-list:hover .newcentertab-listline{
		display: block;
	}
	.newcentertab-listline{
		display: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		background-color: rgba(0,0,0,0.5);
	}
	.newcentertab-listline:hover .line {
	    transform: translate(0);
	    -webkit-transform: translate(0);
	    opacity: 1;
	    visibility: visible;
	}
	.newcentertab-listline .line_1 {
	    width: 52px;
	    height: 1px;
	    margin: -1px 0px 0px -26px;
		transform: translateX(-55px);
		-webkit-transform: translateX(-55px);
	}
	.newcentertab-listline .line_2 {
	    width: 1px;
	    height: 52px;
	    margin: -26px 0px 0px -1px;
	    transform: translateY(55px);
	    -webkit-transform: translateY(55px);
	}
	.newcentertab-listline .line {
	    display: block;
	    background: #fff;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    opacity: 0;
	    visibility: hidden;
	    transition: 400ms;
	    -webkit-transition: 400ms;
	}
	
	.newcentertab-listtop{
		width: 100%;
		height: 85%;
		overflow: hidden;
		position: relative;
		overflow: hidden;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.newcentertab-listtopl{
		overflow: hidden;
	}
	.newcentertab-listtopl img{
		width: 100%;
		height: auto;
		z-index: 1;
		vertical-align: top;
	}
	
	.newcentertab-listword{
		width: 100%;
		height: 8%;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
		margin: 0 auto;
		padding-top: 4px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f5f5f5;
	}
	.newcentertab-listwordl{
		width: 95%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.newcentertab-listname{
		width: 65%;
		font-size: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: bold;
		text-align: left;
		padding-left: 3px;
		box-sizing: border-box;
	}
	.newcentertab-time{
		width: 35%;
		font-size: 14px;
		color: #9E9B9B;
		font-weight: bold;
		text-align: right;
	}
	
	/* 品牌区 */
	.brand-wrap{
		width: 100%;
		height: 700px;
		background-image: url(../assets/img/containerbg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 30px 0;
		box-sizing: border-box;
	}
	.brand-top{
		width: 20%;
		margin:  0 auto;
	}
	.brand-top img{
		width: 300px;
		height: 80px;
	}
	.brand-text{
		font-size: 16px;
		line-height: 40px;
	}
	/* 底部轮播 */
	.swiper-container {
	  width: 60%;
	  height: 400px;
	  margin: 50px auto 0;
	}
	.swiper-wrapper{
		 width: 60%;
	}
	.swiper-slide {
	   width: 60%;
	}
	.lunbolisttop{
		width: 100%;
		text-align: center;
		font-size: 18px;
		display: flex;
		justify-content: space-between;
	}
	.lunbolist{
		width: 150px;
		height: 180px;
		text-align: center;
	}
	.lunbolistimg{
		width: 150px;
		height: 150px;
		background-image: url(../assets/img/swiperbg.png);
		background-size: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lunbolistimg img{
		width: 85px;
		height: 50px;
	}
	.lunbolisttext{
		font-size: 15px;
		line-height: 22px;
	}
	
	
	
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		/* 顶部banner区 */
			.videolist-banner{
				width: 100%;
				height: 250px;
			}
			.bannertop-title{
				width: 100%;
				position: absolute;
				left: 0;
				top: 35%;
			}
			.bannertop-title div:nth-of-type(1){
				width: 90%;
				margin: 0 auto;
				font-size: 22px;
				color: #fff;
				font-weight: bold;
			}
			.bannertop-title div:nth-of-type(2){
				width: 80%;
				margin: 15px auto 0;
				font-size: 16px;
				color: #fff;
				text-align: right;
				letter-spacing: 2px;
			}
			/* 搜索框 */
			.searchWrap{
				width: 70%;
				height: 35px;
				position: absolute;
				left: 13%;
				margin-left: 0;
				bottom: 30px;
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 10px;
				border-radius: 5px;
			}
			.searchInput{
				width: 80%;
				height: 80%;
				border: none;
				outline: none;
				font-size: 12px;
				letter-spacing: 2px;
			}
			.searchWrap div:nth-of-type(1){
				font-size: 12px;
				line-height: 35px;
			}
			.searchWrap div:nth-of-type(2){
				font-size: 14px;
				line-height: 35px;
			}
			.searchBtn:hover{
				cursor: pointer;
			}
			/* 滚动文字区 */
			.grolltext-wrap{
				width: 100%;
				height: 200px;
				background-color: #f7f7f7;
			}
			.grolltext-center{
				width: 100%;
				height: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
			}
			.grolltext-list1{
				width: 50%;
				margin-left: 0px;
			}
			.grolltext-border{
				border-right: #eae9e9 1px solid;
				box-sizing: border-box;
			}
			.grolltext-top{
				width: 100%;
				font-size: 16px;
				color: #888895;
				text-align: center;
			}
			.grolltext-num{
				width: 100%;
				position: relative;
				text-align: center;
			}
			.zuihou-num{
				width: 100%;
				position: relative;
				text-align: center;
			}
			.grolltext-num div,.zuihou-num div{
				font-size: 24px;
				display: block;
				color: #444444;
				line-height: 40px;
			}
			.grolltext-numtext{
				position: absolute;
				right: 45px;
				top: 2px;
			}
			.secondspan{
				position: absolute;
				top: 2px;
				right: 45px !important;
			}
			.grolltext-word{
				width: 100%;
				font-size: 12px;
				text-align: center;
				letter-spacing: 1px;
			}
			/* 搜索列表区 */
			.searchlist-Wrap{
				width: 93%;
				margin: 0 auto;
				/* column-count: 4;
				column-gap: 10px; */
				/* display: flex;
				flex-wrap: wrap; */
			}
			.searchlist-middle{
				width: 100%;
				/* column-count: 2;
				column-gap: 0; */
			}
			.searchlist-zhong{
				margin-bottom: 1.5rem;
			}
			.searchli{
				/* width: 50%; */
				height: 120px;
				padding: 0 0.5rem;
				box-sizing: border-box;
			}
			.searchli .box{
				height: 100%;
				background: #f5f5f5;
			}
			.shusearchli{
				/* width: 50%; */
				height: 310px;
				padding: 0 0.5rem;
				box-sizing: border-box;
				/* margin-bottom: 20px; */
			}
			.shusearchli:hover{
				cursor: pointer;
			}
			.shusearchli:hover .newcentertab-black{
				width: 90%;
				height: 90%;
				border: #E1DDDD 0.5px solid;
				opacity: 0.3;
			}
			.shusearchli:hover img{
				transform: scale(1.1);
				transition: 0.5s;
			}
			
			.searchcentertab-listtop{
				width: 100%;
				height: 87%;
				overflow: hidden;
				position: relative;
				overflow: hidden;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}
			.searchcentertab-listword{
				width: 100%;
				height: 13%;
				margin: 0 auto;
				padding-top: 4px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: #f5f5f5;
			}
			/* 加载中图标 */
			.loadicon{
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 10px 0 50px 0;
			}
			.jiazaidone{
				display: flex;
				align-items: center;
			}
			.icon-xiaolian{
				font-size: 16px;
				color: #999;
			}
			.icon-dengdai{
				font-size: 16px;
				color: #999;
			}
			.jiazaidone div{
				font-size: 14px;
				color: #999;
				margin-left: 10px;
			}
			/* 外层固定宽度 */
			.fixedwidth{
				width: 100%;
				margin: 0 auto;
			}
			
			/* 精心打造 */
			.englishtext{
				width: 100%;
				height: 100px;
			}
			.englishtext-center{
				width: 93%;
				margin: 0 auto;
			}
			.huienglishi{
				width: 100%;
				text-align: center;
				margin-top: 20px;
				height: 50px;
				font-size: 40px;
				line-height: 50px;
				text-transform: uppercase;
				color: #f7f7f7;
				font-weight: bold;
			}
			.englishtext-word{
				width: 100%;
				text-align: center;
			}
			.englishtext-word span:nth-of-type(1){
				display: block;
				width: 100%;
				text-align: center;
				font-size: 22px;
				margin-top: -30px;
			}
			.englishtext-word span:nth-of-type(2){
				display: block;
				text-align: left;
				font-size: 14px;
				color: #888;
				margin-top: 10px;
				line-height: 20px;
			}
			/* 小标题区 */
			.littleword{
				width: 92%;
				margin: 25px auto 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			.littlelist{
				width: 45%;
				height: 40px;
				background-color: #fbfbfb;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 0px;
				margin-bottom: 15px;
			}
			.littlelist span{
				font-size: 20px;
				font-weight: bold;
			}
			.littlelist div{
				font-size: 14px;
				margin-left: 10px;
				font-weight: bold;
			}
			
			/* 列表区 */
			.videolist-wrap{
				width: 100%;
			}
			.videolist-h1{
				width: 92%;
				height: 40px;
				margin: 20px auto 0px;
			}
			.videolist-h1title{
				text-align: left;
				font-size: 16px;
				font-weight: bold;
			}
			.videolist-wrap ul{
				width: 93%;
				margin: 0 auto;
				display: flex;
				/* justify-content: space-between; */
				flex-wrap: wrap;
			}
			.videolist-wrap ul li{
				width: 50%;
				padding: 0 0.2rem;
				margin-bottom: 1rem;
				box-sizing: border-box;
			}
			.fadeInUp {
			    -webkit-animation-name: fadeInUp;
			    animation-name: fadeInUp;
			}
			.animated {
			    -webkit-animation-duration: 1s;
			    animation-duration: 1s;
			    -webkit-animation-fill-mode: both;
			    animation-fill-mode: both;
			}
			.img{
				overflow: hidden;
				width: 100%;
				height: 94px;
				position: relative;
			}
			a{
				color: #333;
				text-decoration: none;
			}
			.img img{
				overflow: hidden;
				width: 100%;
				position: relative;
				height: auto;
				vertical-align: bottom;
			}
			.videolist-tips{
				padding: 2px;
				background-color: #EF590F;
				font-size: 8px !important;
				font-style: normal;
				color: #fff !important;
				text-align: center;
				position: absolute;
				right: 5px;
				top: 5px;
				border-radius: 2px;
			}
			.red-bg{
				background: rgba(226, 52, 51, 0.8);
				position: absolute;
				left: 0px;
				top: 0px;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transition: 300ms;
				-webkit-transition: 300ms;
			}
			.img:hover .red-bg {
			    opacity: 1;
			    visibility: visible;
			}
			.box .img:hover .line {
			    transform: translate(0);
			    -webkit-transform: translate(0);
			    opacity: 1;
			    visibility: visible;
			}
			.img .line_1 {
			    width: 52px;
			    height: 1px;
			    margin: -1px 0px 0px -26px;
				transform: translateX(-55px);
				-webkit-transform: translateX(-55px);
			}
			.img .line_2 {
			    width: 1px;
			    height: 40px;
			    margin: -26px 0px 0px -1px;
			    transform: translateY(55px);
			    -webkit-transform: translateY(55px);
			}
			.img .line {
			    display: block;
			    background: #fff;
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    opacity: 0;
			    visibility: hidden;
			    transition: 400ms;
			    -webkit-transition: 400ms;
			}
			.con {
			    background: #f5f5f5;
			    padding: 0px 5px;
			}
			.con h5{
			    font-size:20px;
			    height: 25px;
			    line-height: 25px;
			    color: #4d4c51;
				margin: 0 !important;
			}
			.con h5 a {
			    font-size: 0.7rem;
			    height: 1rem;
			    line-height: 1rem;
			    color: #4d4c51;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.con h5 a span {
			    display: inline-block;
			    max-width: 85%;
			    font-weight: bold;
			    font-size: 14px;
			    height: 1rem;
			    line-height: 1rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			}
			.con h5 i {
			    float: right;
				height: 22px;
			}
			.con h5 i img {
			    max-height: 1.5rem;
			}
			.con div {
			    font-size: 11px;
			    color: #4d4c51;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
				margin: 0 !important;
			}
			.conh6{
				font-size: 11px !important;
			}
			/* 轮播分类区 */
/* 			.newcentertab{
				width: 90%;
				height: 500px;
				margin: 0 auto 100px;
			}
			.newcentertab-center{
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
			}
			.newcentertab-left{
				width: 45%;
				height: 500px;
			}
			.newcentertab-left img{
				width: 100%;
				height: 100%;
			} */
			.newcentertab-right{
				width: 100%;
				height: 710px;
				display: flex;
				flex-wrap: wrap;
				align-content: flex-start;
				justify-content: space-between;
				overflow: hidden;
			}

			.newcentertab-list{
				width: 49%;
				height: 350px;
				padding: 0 0px 0px 0px;
				box-sizing: border-box;
				margin-bottom: 10px;
			}
			.newcentertab-list:hover{
				cursor: pointer;
			}
			.newcentertab-list:hover .newcentertab-black{
				width: 90%;
				height: 80%;
				border: #E1DDDD 0.5px solid;
				opacity: 0.3;
			}
			.newcentertab-listtop{
				width: 100%;
				height: 85%;
				position: relative;
				overflow: hidden;
			}
			.newcentertab-listtop img{
				width: 100%;
				height: auto;
				z-index: 1;
				vertical-align: top;
			}
			.newcentertab-listline{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				background-color: rgba(0,0,0,0.5);
				display: flex;
				justify-content: center;
				align-items: center;
			}
		/* 	.newcentertab-black{
				width: 90%;
				height: 90%;
				border: #E1DDDD 0.5px solid;
				opacity: 0.3;
			} */
			
			.newcentertab-listword{
				width: 100%;
				height: 10%;
				margin: 0px auto;
				display: flex;
				justify-content: space-between;
			}
			.newcentertab-listname{
				width: 50%;
				font-size: 12px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: bold;
				text-align: left;
			}
			.newcentertab-time{
				width: 50%;
				font-size: 10px;
				color: #9E9B9B;
				font-weight: bold;
			}
			
			/* 品牌区 */
			.brand-wrap{
				width: 100%;
				height: 400px;
				background-image: url(../assets/img/containerbg.jpg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				padding: 20px 0;
				box-sizing: border-box;
			}
			.brand-top{
				width: 93%;
				margin:  0 auto;
			}
			.brand-top img{
				width: 50%;
				height: auto;
			}
			.brand-text{
				font-size: 14px;
				line-height: 40px;
			}
			/* 底部轮播 */
			.swiper-container {
			  width: 90%;
			  height: 230px;
			  margin: 20px auto 0;
			}
			.swiper-wrapper{
				 width: 90%;
			}
			.swiper-slide {
			   width: 90%;
			}
			.lunbolisttop{
				width: 100%;
				text-align: center;
				font-size: 18px;
				display: flex;
				justify-content: space-between;
			}
			.lunbolist{
				width: 18%;
				height: 100px;
				text-align: center;
			}
			.lunbolistimg{
				width: 100%;
				height: 70px;
				background-image: url(../assets/img/swiperbg.png);
				background-size: 100% 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.lunbolistimg img{
				width: 50%;
				height: 30px;
			}
			.lunbolisttext{
				width: 100%;
				font-size: 10px;
				line-height: 18px;
				overflow: hidden;
				  text-overflow: ellipsis;
				  white-space:nowrap; 
			}
	}
	
	
	/* pad端 */
		@media (min-width: 500px) and (max-width: 800px){
			/* 顶部banner区 */
				.videolist-banner{
					width: 100%;
					height: 380px;
				}
				.bannertop-title{
					width: 100%;
					position: absolute;
					left: 0;
					top: 40%;
				}
				.bannertop-title div:nth-of-type(1){
					width: 80%;
					margin: 0 auto;
					font-size: 30px;
					color: #fff;
					font-weight: bold;
				}
				.bannertop-title div:nth-of-type(2){
					width: 59%;
					margin: 20px auto 0;
					font-size: 20px;
					color: #fff;
					text-align: right;
					letter-spacing: 2px;
				}
				/* 搜索框 */
				.searchWrap{
					width: 50%;
					height: 35px;
					position: absolute;
					left: 24%;
					margin-left: 0;
					bottom: 50px;
					background-color: #fff;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 10px;
					border-radius: 5px;
				}
				.searchInput{
					width: 88%;
					height: 80%;
					border: none;
					outline: none;
					font-size: 12px;
					letter-spacing: 2px;
				}
				.searchWrap div:nth-of-type(1){
					font-size: 12px;
					line-height: 35px;
				}
				.searchWrap div:nth-of-type(2){
					font-size: 14px;
					line-height: 35px;
				}
				.searchBtn:hover{
					cursor: pointer;
				}
				/* 滚动文字区 */
				.grolltext-wrap{
					width: 100%;
					height: 120px;
					background-color: #f7f7f7;
				}
				.grolltext-center{
					width: 100%;
					height: 100%;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
				}
				.grolltext-list1{
					width: 25%;
					margin-left: 0px;
				}
				.grolltext-border{
					border-right: #eae9e9 1px solid;
					box-sizing: border-box;
				}
				.grolltext-top{
					width: 100%;
					font-size: 18px;
					color: #888895;
					text-align: center;
				}
				.grolltext-num{
					width: 100%;
					position: relative;
					text-align: center;
				}
				.zuihou-num{
					width: 100%;
					position: relative;
					text-align: center;
				}
				.grolltext-num div,.zuihou-num div{
					font-size: 26px;
					display: block;
					color: #444444;
					line-height: 40px;
				}
				.grolltext-numtext{
					position: absolute;
					right: 50px;
					top: 2px;
				}
				.secondspan{
					position: absolute;
					top: 2px;
					right: 50px !important;
				}
				.grolltext-word{
					width: 100%;
					font-size: 14px;
					text-align: center;
					letter-spacing: 1px;
				}
				/* 搜索列表区 */
				.searchlist-Wrap{
					width: 93%;
					margin: 0 auto;
					/* column-count: 4;
					column-gap: 10px; */
					display: flex;
					flex-wrap: wrap;
				}
				.searchlist-middle{
					width: 100%;
					/* column-count: 3;
					column-gap: 0; */
				}
				.searchlist-zhong{
					margin-bottom: 1.5rem;
				}
				.searchli{
					/* width: 33.3333%; */
					height: 148px;
					padding: 0 0.5rem;
					/* margin-bottom: 1rem; */
					box-sizing: border-box;
				}
				.searchli .box{
					height: 100%;
					background: #f5f5f5;
				}
				.shusearchli{
					/* width: 33.3333%; */
					height: 370px;
					padding: 0 0.5rem;
					/* margin-bottom: 1rem; */
					box-sizing: border-box;
				}
				.shusearchli:hover{
					cursor: pointer;
				}
				.shusearchli:hover .newcentertab-black{
					width: 90%;
					height: 90%;
					border: #E1DDDD 0.5px solid;
					opacity: 0.3;
				}
				.shusearchli:hover img{
					transform: scale(1.1);
					transition: 0.5s;
				}
				
				.searchcentertab-listtop{
					width: 100%;
					height: 87%;
					overflow: hidden;
					position: relative;
					overflow: hidden;
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
				}
				.searchcentertab-listword{
					width: 100%;
					height: 13%;
					margin: 0 auto;
					padding-top: 4px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: #f5f5f5;
				}
				/* 外层固定宽度 */
				.fixedwidth{
					width: 100%;
					margin: 0 auto;
				}
				/* 精心打造 */
				.englishtext{
					width: 100%;
					height: 100px;
				}
				.englishtext-center{
					width: 93%;
					margin: 0 auto;
				}
				.huienglishi{
					width: 100%;
					text-align: center;
					margin-top: 20px;
					height: 50px;
					font-size: 40px;
					line-height: 50px;
					text-transform: uppercase;
					color: #f7f7f7;
					font-weight: bold;
				}
				.englishtext-word{
					width: 100%;
					text-align: center;
				}
				.englishtext-word span:nth-of-type(1){
					display: block;
					font-size: 22px;
					margin-top: -30px;
				}
				.englishtext-word span:nth-of-type(2){
					font-size: 14px;
					color: #888;
					margin-top: 10px;
					line-height: 30px;
				}
				/* 小标题区 */
				.littleword{
					width: 92%;
					margin: 15px auto 20px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
				.littlelist{
					width: 30%;
					height: 40px;
					background-color: #fbfbfb;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 0px;
					margin-bottom: 15px;
				}
				.littlelist span{
					font-size: 20px;
					font-weight: bold;
				}
				.littlelist div{
					font-size: 14px;
					margin-left: 10px;
					font-weight: bold;
				}
				
				/* 列表区 */
				.videolist-wrap{
					width: 100%;
				}
				.videolist-h1{
					width: 92%;
					height: 40px;
					margin: 20px auto 0px;
				}
				.videolist-h1title{
					text-align: left;
					font-size: 16px;
					font-weight: bold;
				}
				.videolist-wrap ul{
					width: 93%;
					margin: 0 auto;
					display: flex;
					/* justify-content: space-between; */
					flex-wrap: wrap;
				}
				.videolist-wrap ul li{
					width: 32%;
					padding: 0 0.2rem;
					margin-bottom: 1rem;
					box-sizing: border-box;
					margin-right: 0.5rem;
				}
				.fadeInUp {
				    -webkit-animation-name: fadeInUp;
				    animation-name: fadeInUp;
				}
				.animated {
				    -webkit-animation-duration: 1s;
				    animation-duration: 1s;
				    -webkit-animation-fill-mode: both;
				    animation-fill-mode: both;
				}
				.img{
					overflow: hidden;
					width: 100%;
					height: 92px;
					position: relative;
				}
				a{
					color: #333;
					text-decoration: none;
				}
				.img img{
					overflow: hidden;
					width: 100%;
					position: relative;
					height: auto;
					vertical-align: bottom;
				}
				.videolist-tips{
					padding: 2px;
					background-color: #EF590F;
					font-size: 8px !important;
					font-style: normal;
					color: #fff !important;
					text-align: center;
					position: absolute;
					right: 5px;
					top: 5px;
					border-radius: 2px;
				}
				.red-bg{
					background: rgba(226, 52, 51, 0.8);
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					opacity: 0;
					visibility: hidden;
					transition: 300ms;
					-webkit-transition: 300ms;
				}
				.img:hover .red-bg {
				    opacity: 1;
				    visibility: visible;
				}
				.box .img:hover .line {
				    transform: translate(0);
				    -webkit-transform: translate(0);
				    opacity: 1;
				    visibility: visible;
				}
				.img .line_1 {
				    width: 52px;
				    height: 1px;
				    margin: -1px 0px 0px -26px;
					transform: translateX(-55px);
					-webkit-transform: translateX(-55px);
				}
				.img .line_2 {
				    width: 1px;
				    height: 40px;
				    margin: -26px 0px 0px -1px;
				    transform: translateY(55px);
				    -webkit-transform: translateY(55px);
				}
				.img .line {
				    display: block;
				    background: #fff;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    opacity: 0;
				    visibility: hidden;
				    transition: 400ms;
				    -webkit-transition: 400ms;
				}
				.con {
				    background: #f5f5f5;
				    padding: 0px 5px;
				}
				.con h5{
				    font-size:22px;
				    height: 30px;
				    line-height: 30px;
				    color: #4d4c51;
					margin: 0 !important;
				}
				.con h5 a {
				    font-size: 0.8rem;
				    height: 1rem;
				    line-height: 1rem;
				    color: #4d4c51;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.con h5 a span {
				    display: inline-block;
				    max-width: 85%;
				    font-weight: bold;
				    font-size: 16px;
				    height: 1rem;
				    line-height: 1rem;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				}
				.con h5 i {
				    float: right;
				}
				.con h5 i img {
				    max-height: 1.5rem;
				}
				.con div {
				    font-size: 13px;
				    color: #4d4c51;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
					margin: 0 !important;
				}
				.conh6{
					font-size: 11px !important;
				}
				/* 轮播分类区 */
	/* 			.newcentertab{
					width: 90%;
					height: 500px;
					margin: 0 auto 100px;
				}
				.newcentertab-center{
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: space-between;
				}
				.newcentertab-left{
					width: 45%;
					height: 500px;
				}
				.newcentertab-left img{
					width: 100%;
					height: 100%;
				} */
				.newcentertab-right{
					width: 100%;
					height: auto;
					display: flex;
					flex-wrap: wrap;
					align-content: flex-start;
					/* justify-content: space-between; */
				}
	
				.newcentertab-list{
					width: 33.33%;
					height: 440px;
					/* padding-bottom: 1%; */
					padding: 0 5px 0px 5px;
					box-sizing: border-box;
					margin-bottom: 20px;
				}
				.newcentertab-list:hover{
					cursor: pointer;
				}
				.newcentertab-list:hover .newcentertab-black{
					width: 90%;
					height: 80%;
					border: #E1DDDD 0.5px solid;
					opacity: 0.3;
				}
				.newcentertab-listtop{
					width: 100%;
					height: 85%;
					position: relative;
					overflow: hidden;
				}
				.newcentertab-listtop img{
					width: 100%;
					height: auto;
					z-index: 1;
					vertical-align: top;
				}
				.newcentertab-listline{
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 2;
					background-color: rgba(0,0,0,0.5);
					display: flex;
					justify-content: center;
					align-items: center;
				}
			/* 	.newcentertab-black{
					width: 90%;
					height: 90%;
					border: #E1DDDD 0.5px solid;
					opacity: 0.3;
				} */
				
				.newcentertab-listword{
					width: 100%;
					height: 10%;
					margin: 0px auto;
					display: flex;
					justify-content: space-between;
				}
				.newcentertab-listname{
					width: 50%;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-weight: bold;
					text-align: left;
				}
				.newcentertab-time{
					width: 50%;
					font-size: 12px;
					color: #9E9B9B;
					font-weight: bold;
				}
				
				/* 品牌区 */
				.brand-wrap{
					width: 100%;
					height: 430px;
					background-image: url(../assets/img/containerbg.jpg);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					padding: 20px 0;
					box-sizing: border-box;
				}
				.brand-top{
					width: 93%;
					margin:  0 auto;
				}
				.brand-top img{
					width: 20%;
					height: auto;
				}
				.brand-text{
					font-size: 16px;
					line-height: 30px;
				}
				/* 底部轮播 */
				.swiper-container {
				  width: 90%;
				  height: 260px;
				  margin: 20px auto 0;
				}
				.swiper-wrapper{
					 width: 90%;
				}
				.swiper-slide {
				   width: 90%;
				}
				.lunbolisttop{
					width: 100%;
					text-align: center;
					font-size: 18px;
					display: flex;
					justify-content: space-between;
				}
				.lunbolist{
					width: 18%;
					height: 120px;
					text-align: center;
				}
				.lunbolistimg{
					width: 95%;
					height: 100px;
					background-image: url(../assets/img/swiperbg.png);
					background-size: 100% 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.lunbolistimg img{
					width: 50%;
					height: 30px;
				}
				.lunbolisttext{
					width: 100%;
					font-size: 10px;
					line-height: 18px;
					overflow: hidden;
					  text-overflow: ellipsis;
					  white-space:nowrap; 
				}
		}
		
		/* 老板仓定制机的样式 */
		@media (min-width: 1000px) and (max-width: 1300px){
			/* 评论组件 */
			.commentWrap{
				 position: fixed;
				 left: 0;
				 top: 0;
				 z-index: 100;
			 }
			/* 顶部banner区 */
			.videolist-banner{
				width: 100%;
				height: 450px;
				/* background-image: url(../assets/img/videolistbanner.jpg); */
				background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/mediabanner.jpg);
				background-size: 100% 100%;
				position: relative;
				
			}
			.bannertop-title{
				width: 100%;
				position: absolute;
				left: 0;
				top: 35%;
			}
			.bannertop-title div:nth-of-type(1){
				width: 80%;
				margin: 0 auto;
				font-size: 40px;
				color: #fff;
				font-weight: bold;
			}
			.bannertop-title div:nth-of-type(2){
				width: 60%;
				margin: 20px auto 0;
				font-size: 20px;
				color: #fff;
				text-align: right;
				letter-spacing: 2px;
			}
			/* 搜索框 */
			.searchWrap{
				width: 30%;
				height: 50px;
				position: absolute;
				left: 50%;
				margin-left: -15%;
				bottom: 70px;
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 15px;
				border-radius: 5px;
				/* border-radius: 25px; */
			}
			.searchInput{
				width: 85%;
				height: 80%;
				border: none;
				outline: none;
				font-size: 15px;
				letter-spacing: 2px;
			}
			.searchBtn:hover{
				cursor: pointer;
			}
			
			
			/* 滚动文字区 */
			.grolltext-wrap{
				width: 100%;
				height: 150px;
				background-color: #f7f7f7;
			}
			.grolltext-center{
				width: 75%;
				height: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.grolltext-list1{
				width: 20%;
				margin-left: 0px;
			}
			.grolltext-border{
				border-right: #eae9e9 2px solid;
				
			}
			.grolltext-top{
				width: 110px;
				font-size: 20px;
				color: #888895;
				text-align: left;
			}
			.grolltext-num{
				width: 110px;
				position: relative;
				text-align: left;
			}
			.zuihou-num{
				width: 110px;
				position: relative;
				text-align: left;
			}
			.grolltext-num div,.zuihou-num div{
				font-size: 40px;
				display: block;
				color: #444444;
				line-height: 70px;
			}
			.grolltext-numtext{
				position: absolute;
				right: 0px;
				top: 0px;
			}
			.secondspan{
				position: absolute;
				top: 0px;
				right: 27px !important;
			}
			.grolltext-word{
				width: 180px;
				font-size: 14px;
				text-align: left;
			}
			
			/* 外层固定宽度 */
			.fixedwidth{
				width: 100%;
				margin: 0 auto;
			}
			
			/* 精心打造 */
			.englishtext{
				width: 100%;
				height: 150px;
			}
			.englishtext-center{
				width: 60%;
				margin: 0 auto;
			}
			.huienglishi{
				width: 100%;
				text-align: center;
				margin-top: 50px;
				height: 70px;
				font-size: 70px;
				line-height: 70px;
				text-transform: uppercase;
				color: #f7f7f7;
				font-weight: bold;
			}
			.englishtext-word{
				width: 100%;
				text-align: center;
			}
			.englishtext-word span:nth-of-type(1){
				display: block;
				font-size: 25px;
				margin-top: -40px;
			}
			.englishtext-word span:nth-of-type(2){
				font-size: 18px;
				color: #888;
				margin-top: 10px;
				line-height: 30px;
			}
			/* 小标题区 */
			.littleword{
				width: 88%;
				margin: 20px auto 60px;
				display: flex;
				flex-wrap: wrap;
			}
			.littlelist{
				/* width: 150px; */
				height: 40px;
				padding: 0 15px;
				box-sizing: border-box;
				background-color: #fbfbfb;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 50px;
			}
			.littlelist:hover{
				cursor: pointer;
				background-color: #f26e33;
				color: #fff !important;
			}
			.littlelist span{
				font-size: 26px;
				font-weight: bold;
			}
			.littlelist div{
				font-size: 18px;
				margin-left: 10px;
				font-weight: bold;
			}
			/* 列表标题 */
			.videolist-h1{
				width: 88%;
				height: 50px;
				margin: 50px auto 10px;
			}
			.videolist-h1title{
				text-align: left;
				font-size: 20px;
				font-weight: bold;
			}
			/* 搜索列表区 */
			.searchlist-Wrap{
				width: 90%;
				margin: 0 auto;
				overflow: hidden;
				/* column-count: 4;
				column-gap: 10px; */
				/* display: flex;
				flex-wrap: wrap; */
				
			}
			.searchlist-middle{
				width: 100%;
				/* column-count: 4;
				column-gap: 0; */
				margin-bottom: 30px;
			}
			.searchlist-zhong{
				margin-bottom: 2.4375rem;
			}
			.searchli{
				/* width: 25%; */
				height: 200px;
				padding: 0 1.09375rem;
				/* margin-bottom: 2.4375rem; */
				box-sizing: border-box;
				break-inside: avoid;
			}
			.searchli .box{
				height: 100%;
				background: #f5f5f5;
			}
			.shusearchli{
				/* width: 25%; */
				height: 550px;
				padding: 0 1.09375rem;
				box-sizing: border-box;
				/* margin-bottom: 2.4375rem; */
				break-inside: avoid;
				/* margin-bottom: 20px; */
			}
			.shusearchli:hover{
				cursor: pointer;
			}
			.searchcentertab-listtop{
				width: 100%;
				height: 87%;
				overflow: hidden;
				position: relative;
				overflow: hidden;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}
			.searchcentertab-listword{
				width: 100%;
				height: 9%;
				margin: 0 auto;
				padding-top: 4px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: #f5f5f5;
			}
			/* 加载中图标 */
			.loadicon{
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 10px 0 50px 0;
			}
			.jiazaidone{
				display: flex;
				align-items: center;
			}
			.icon-xiaolian{
				font-size: 22px;
				color: #999;
			}
			.icon-dengdai{
				font-size: 22px;
				color: #999;
			}
			.jiazaidone div{
				font-size: 18px;
				color: #999;
				margin-left: 10px;
			}
			
			/* 列表区 */
			.videolist-wrap{
				width: 100%;
			}
			.videolist-wrap ul{
				width: 90%;
				margin: 0 auto;
				display: flex;
				/* justify-content: space-between; */
				flex-wrap: wrap;
			}
			.videolist-wrap ul li{
				width: 33.333%;
				padding: 0 1.09375rem;
				margin-bottom: 2rem;
				box-sizing: border-box;
			}
			.fadeInUp {
			    -webkit-animation-name: fadeInUp;
			    animation-name: fadeInUp;
			}
			.animated {
			    -webkit-animation-duration: 1s;
			    animation-duration: 1s;
			    -webkit-animation-fill-mode: both;
			    animation-fill-mode: both;
			}
			.img{
				overflow: hidden;
				width: 100%;
				height: 150px;
				position: relative;
			}
			a{
				color: #333;
				text-decoration: none;
			}
			.img img{
				overflow: hidden;
				width: 100%;
				position: relative;
				height: auto;
				vertical-align: bottom;
			}
			.videolist-tips{
				padding: 4px;
				background-color: #EF590F;
				font-size: 12px !important;
				font-style: normal;
				color: #fff !important;
				text-align: center;
				position: absolute;
				right: 10px;
				top: 10px;
				border-radius: 4px;
			}
			.red-bg{
				background: rgba(226, 52, 51, 0.8);
				position: absolute;
				left: 0px;
				top: 0px;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transition: 300ms;
				-webkit-transition: 300ms;
			}
			.img:hover .red-bg {
			    opacity: 1;
			    visibility: visible;
			}
			.box .img:hover .line {
			    transform: translate(0);
			    -webkit-transform: translate(0);
			    opacity: 1;
			    visibility: visible;
			}
			.img .line_1 {
			    width: 52px;
			    height: 1px;
			    margin: -1px 0px 0px -26px;
				transform: translateX(-55px);
				-webkit-transform: translateX(-55px);
			}
			.img .line_2 {
			    width: 1px;
			    height: 52px;
			    margin: -26px 0px 0px -1px;
			    transform: translateY(55px);
			    -webkit-transform: translateY(55px);
			}
			.img .line {
			    display: block;
			    background: #fff;
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    opacity: 0;
			    visibility: hidden;
			    transition: 400ms;
			    -webkit-transition: 400ms;
			}
			.con {
			    background: #f5f5f5;
			    padding: 0px 15px;
			}
			.con h5{
			    font-size:18px;
			    height: 30px;
			    line-height: 30px;
			    color: #4d4c51;
				margin: 0 !important;
			}
			/* .con h5 a {
			    font-size: 1.375rem;
			    height: 1.5rem;
			    line-height: 1.5rem;
			    color: #4d4c51;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			} */
			.con h5 a span {
			    display: inline-block;
			    max-width: 85%;
			    font-weight: bold;
			    font-size: 16px;
			    height: 30px;
			    line-height: 30px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			}
			.con h5 i {
			    float: right;
			}
			.con h5 i img {
			    max-height: 1.5rem;
			}
			.con div {
			    font-size: 14px !important;
			    color: #4d4c51;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
				margin: 0 !important;
				line-height: 20px;
			}
			.hengxian{
				width: 100%;
				display: block;
				border-top: #999 1px solid;
			}
			.conh6{
				font-size: 14px !important;
			}
			/* 轮播分类区 */
			.newcentertab{
				width: 89%;
				margin: 0 auto 100px;
			}
			.newcentertab-center{
				width: 100%;
				height: 100%;
			}
			.newcentertab-center .videolist-h1{
				width: 88% !important;
				padding-left: 0.5%;
				margin: 0 !important;
			}
			.newcentertab-right{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-content: flex-start;
			}
			
			.newcentertab-list{
				width: 33.333%;
				height: 560px;
				padding: 0 10px 0px 10px;
				box-sizing: border-box;
				margin-bottom: 20px;
			}
			.newcentertab-list:hover{
				cursor: pointer;
			}
			.newcentertab-list:hover .newcentertab-listline{
				display: block;
			}
			.newcentertab-listline{
				display: none;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				background-color: rgba(0,0,0,0.5);
			}
			.newcentertab-listline:hover .line {
			    transform: translate(0);
			    -webkit-transform: translate(0);
			    opacity: 1;
			    visibility: visible;
			}
			.newcentertab-listline .line_1 {
			    width: 52px;
			    height: 1px;
			    margin: -1px 0px 0px -26px;
				transform: translateX(-55px);
				-webkit-transform: translateX(-55px);
			}
			.newcentertab-listline .line_2 {
			    width: 1px;
			    height: 52px;
			    margin: -26px 0px 0px -1px;
			    transform: translateY(55px);
			    -webkit-transform: translateY(55px);
			}
			.newcentertab-listline .line {
			    display: block;
			    background: #fff;
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    opacity: 0;
			    visibility: hidden;
			    transition: 400ms;
			    -webkit-transition: 400ms;
			}
			
			.newcentertab-listtop{
				width: 100%;
				height: 85%;
				overflow: hidden;
				position: relative;
				overflow: hidden;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}
			.newcentertab-listtopl{
				overflow: hidden;
			}
			.newcentertab-listtopl img{
				width: 100%;
				height: auto;
				z-index: 1;
				vertical-align: top;
			}
			
			.newcentertab-listword{
				width: 100%;
				height: 8%;
				border-bottom-right-radius: 5px;
				border-bottom-left-radius: 5px;
				margin: 0 auto;
				padding-top: 4px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: #f5f5f5;
			}
			.newcentertab-listwordl{
				width: 95%;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.newcentertab-listname{
				width: 65%;
				font-size: 16px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: bold;
				text-align: left;
				padding-left: 3px;
				box-sizing: border-box;
			}
			.newcentertab-time{
				width: 35%;
				font-size: 14px;
				color: #9E9B9B;
				font-weight: bold;
				text-align: right;
			}
			
			/* 品牌区 */
			.brand-wrap{
				width: 100%;
				height: 650px;
				background-image: url(../assets/img/containerbg.jpg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				padding: 30px 0;
				box-sizing: border-box;
			}
			.brand-top{
				width: 50%;
				margin:  0 auto;
			}
			.brand-top img{
				width: 300px;
				height: 80px;
			}
			.brand-text{
				font-size: 16px;
				line-height: 40px;
			}
			/* 底部轮播 */
			.swiper-container {
			  width: 80%;
			  height: 400px;
			  margin: 50px auto 0;
			}
			.swiper-wrapper{
				 width: 60%;
			}
			.swiper-slide {
			   width: 60%;
			}
			.lunbolisttop{
				width: 100%;
				text-align: center;
				font-size: 18px;
				display: flex;
				justify-content: space-between;
			}
			.lunbolist{
				width: 150px;
				height: 180px;
				text-align: center;
			}
			.lunbolistimg{
				width: 150px;
				height: 150px;
				background-image: url(../assets/img/swiperbg.png);
				background-size: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.lunbolistimg img{
				width: 85px;
				height: 50px;
			}
			.lunbolisttext{
				font-size: 15px;
				line-height: 22px;
			}
		}
</style>
