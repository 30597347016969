<template>
	<div class="all-wrap">
		
		<!-- 先判断是否是在微信中打开，如果不是出遮罩，提示进入微信打开 -->
		<!-- <div class="wechatnologin" v-show="weixinopen" style="z-index: 999;">
			<div class="nomessage">请用微信打开本页面！</div>
		</div> -->
		
		
		<!-- 如果用户没有登录信息，且在微信中打开，那么显示一个白色遮罩 -->
		<!-- <div class="wechatnologin" v-show="wechatnologin" style="z-index: 900;">
			<div class="nomessage">您还没有登录，请登录后查看！</div>
			<div class="gotologBtn" @click="gologin">授权微信登录</div>
		</div> -->
		
		<!-- 返回区域 -->
		<div class="goback" @click="gojumpback"><span class="el-icon-arrow-left"></span></div>
		
		<!-- 签收单内容区 -->
		<div class="signwrap">

			<div class="signtop">
				<div class="signtopleft">
					<img src="../../assets/img/logo2.png" />
				</div>
				<router-link to="/" class="signtopright">https://xjaikj.htkjbd.com/</router-link>
			</div>
			
			<div class="signcenter">
				<div class="signtitle">服务验收单</div>
			    <ul class="signul">
			    	<!-- 表头 -->
			    	<li class="lione">
			    		<div class="lionediv1">项目名称</div>
			    		<div class="litwodiv1" v-show="!textinp">{{datamessage.project_name}}</div>
						<!-- 这是新增 -->
						<div class="litwodiv1" v-show="textinp"><input v-model="addname" class="projectname" type="text" placeholder="请输入名称"></div>
						<div class="lionediv2">项目编号</div>
						<div class="litwodiv2" v-show="!textinp">{{datamessage.number}}</div>
						<!-- 这是新增 -->
						<div class="litwodiv2" v-show="textinp"><input v-model="addnum" class="projectname" type="text" placeholder="请输入编号"></div>
			    	</li>
					<!-- 项目情况 -->
					<li class="lione2">
						<div class="lionediv">项目情况</div>
						<div class="litwodiv shoujida" v-show="!textinp">{{datamessage.content}}</div>
						<!-- 这是新增 -->
						<div class="litwodiv" v-show="textinp">
							<!-- <input v-model="addcontent" class="projectcont" type="text" placeholder="请输入项目情况"> -->
							<textarea v-model="addcontent" class="projectcont qingkuang" placeholder="请输入项目情况"></textarea>
						</div>
					</li>
					<!-- 序号头部 -->
					<li class="litcont">
						<div class="litcontonediv1" style="font-weight: bold;" v-show="!textinp">序号</div>
						<div @click="addformlist" class="litcontonediv1" v-show="textinp" style="font-weight: bold;">序号<i class="el-icon-circle-plus-outline"></i></div>
						<div class="litcontonediv2" style="font-weight: bold;">验收内容</div>
						<div class="litcontonediv3" style="font-weight: bold;">规格</div>
						<div class="litcontonediv4" style="font-weight: bold;">说明</div>
					</li>
					<!-- 验收内容列表-原始 -->
					<li class="litcont" v-for="(item,index) in datamessage.array" v-show="!textinp">
						<div class="litcontonediv1" style="font-weight: bold;">{{index+1}}</div>
						<div class="litcontonediv2 litcontneirong" style="color: #8A8888;">{{item.content}}</div>
						<div class="litcontonediv3 litcontneirong" style="color: #8A8888;">{{item.number}}</div>
						<div class="litcontonediv4 litcontneirong" style="color: #8A8888;">{{item.remark}}</div>
					</li>
					<!-- 验收内容列表-新增 -->
					<li class="litcont" v-for="(item,index) in datamessage.array" v-show="textinp">
						<div class="litcontonediv1" style="font-weight: bold;" @click="jianformlist(index)">{{index+1}}<i class="el-icon-remove-outline"></i></div>
						<div class="litcontonediv2" style="color: #8A8888;">
							<!-- <input @input="inpcont" ref="addyan1" class="projectcont" type="text" :value="item.content"> -->
							<textarea @input="inpcont" ref="addyan1" class="projectcont" :value="item.content"></textarea>
						</div>
						<div class="litcontonediv3" style="color: #8A8888;">
							<!-- <input @input="inpcont" ref="addyan2" class="projectcont" type="text" :value="item.number"> -->
							<textarea @input="inpcont" ref="addyan2" class="projectcont" :value="item.number"></textarea>
						</div>
						<div class="litcontonediv4" style="color: #8A8888;">
							<!-- <input @input="inpcont" ref="addyan3" class="projectname" type="text" :value="item.remark"> -->
							<textarea @input="inpcont" ref="addyan3" class="projectname" :value="item.remark"></textarea>
						</div>
					</li>
					<!-- 备注 -->
					<li class="lione2">
						<div class="lionediv">备注：</div>
						<div class="litwodiv shoujida" v-show="!textinp">{{datamessage.remarks}}</div>
						<div class="litwodiv" v-show="textinp">
							<!-- <input v-model="addmark" class="projectcont" type="text" placeholder="请输入备注内容"> -->
							<textarea v-model="addmark" class="projectcont qingkuang" placeholder="请输入备注内容"></textarea>
						</div>
					</li>
					<!-- 项目验收 -->
					<li class="lione2 mobilelione">
						<div class="lionediv">项目验收</div>
						<div class="litwodiv" style="justify-content: center;">
							<el-button :disabled="editing" :size="btnsize" v-show="!check" class="litcontbutton" type="primary" @click='suresign'>点击验收</el-button>
							<!-- <el-button :size="btnsize" type="danger" plain disabled v-show="userid">已验收</el-button> -->
							<!-- 已签收印章 -->
							<div class="yanshoudivs" v-show="check">
								<img  class="yiqianshou" src="../../assets/img/yiqian.png" />
							</div>
						</div>
					</li>
					<!-- 负责人 -->
					<li class="lione3 mobilelione">
						<div class="lionediv">负责人</div>
						<div class="litwodiv">
							<div class="yanshoudiv" v-if="check">
								<div class="wechart">
									<img :src="datamessage.check_user.headimgurl" />
									<span>{{datamessage.check_user.nickname}}</span>
								</div>
								<div class="yanshotext">已验收</div>
							</div>
						</div>
					</li>
			    	<!-- 同意  -->
			    	<!-- <li class="litcont">
			    		<div class="litcontonediv">甲方审核意见</div>
			    		<div class="litconttwodiv">
			    			<el-button class="litcontbutton" v-show="!userid" type="primary" @click='openwindow'>点击验收</el-button>
			    			<div class="yanshoudiv" v-show="userid">
			    				<div class="wechart">
			    					<img :src="datamessage.check_headimgurl" />
			    					<span>{{datamessage.check_name}}</span>
			    				</div>
			    				<div class="yanshotext">已验收</div>
			    			</div>
			    			
			    		</div>
			    	</li> -->
			    	
			    </ul>
				
				<div class="botomdes">
					<div class="botomdesleft">北京后天世纪文化传媒有限公司保定分公司</div>
					<div class="botomdesright" v-show="!textinp">{{datamessage.date}}</div>
					
					<el-date-picker
					      v-show="textinp"
					      v-model="value1"
					      type="date"
						  class="timercheck"
					      placeholder="选择日期">
					</el-date-picker>
				</div>
				
				<!-- 这是新增 -->
				<div class="botomdesright timerchecks" style="margin-top: 10px;" v-show="textinp">
					<el-button @click="postnewdetail" type="danger">确认提交</el-button>
				</div>
				
			</div>
		</div>
		
		<!-- 弹出框 -->
		<div class="windowtip" v-show="windowshow">
			<!-- 提示是否确认签收 -->
			<div class="windowcenter">
				<div class="windowtop">验收通过</div>
				<div class="windowbtn">
					<el-button @click='closewindow' class="windowbtn1" type="primary" plain>取消</el-button>
					<el-button @click='suresign' class="windowbtn2" type="primary">确认</el-button>
				</div>
			</div>
			<div class="windowtipyin"></div>
		</div>
		
		<!-- 提交成功后的弹窗 -->
		<div class="uploadtip" v-show="windowtip">
			<div class="uploadyin"></div>
			<div class="uploadcenter">
				<div>提交成功，点击确定返回列表页！</div>
				<el-button :size="btnsize" type="primary" @click="jumppage">确定</el-button>
			</div>
		</div>
		
		
		<!-- <Footernew/> -->
	</div>
</template>

<script>
	import $ from "jquery"
	import wx from 'weixin-js-sdk'
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	
	export default {
	  name: 'signdetailadd',
	  components: {
	    Headernew,
	    Footernew,
	  },
	  data() {
		return {
			user_id:'',//用户id
			check:'',//用户是否签收过
			uid:'',//方案id
			windowshow:false,//控制弹窗的显示和隐藏
			signend:false,//控制签收头像，签收图片的显示
			datamessage:'',//签收单数据
			userid:'',//是否签收，根据user_id字段判断，有就是有，没有就是null
			wechatnologin:true,//控制微信打开页面时，未登录，显示的遮罩
			weixinopen:true,//控制，用微信打开本页面的提示显示和隐藏
			btnsize:'medium',//按钮尺寸
			screenWidth: document.body.clientWidth,     // 屏幕宽
			textinp:true, //新增输入框的显示和隐藏
			editing:true,//编辑或新增状态下，不能点击签收
			addname:'',//新增项目名
			addnum:'',//新增项目编号
			addcontent:'',//新增项目情况
			addmark:'',//新增备注
			liuyan1:'',//验收内容
			liuyan2:'',//验收规格
			liuyan3:'',//验收说明
			windowtip:false,//修改成功的弹窗
			value1:'',//选择时间
		}
	  },
	  mounted() {
		  var that = this
		  // 这里是用来获取屏幕宽度的
		  window.onresize= () => {
		      return (() => {
		          window.screenWidth = document.body.clientWidth;
		          that.screenWidth = window.screenWidth;
		      })();
		  }
		  
		  this.gologin() //执行微信登录和判断
		  this.getdata() //获取数据
		  // this.share() //微信转发分享 这里为了避免异步问题，将微信转发放在getdata中
	  },
	  methods:{
		  
		  // 先判断是否在微信浏览器中，是的话就授权登录，不是就不能打开
		  gologin:function(){
			  var that = this
			  var url = window.location.href
			  // 只要是判断此时是在微信浏览器打开的这个页面，就执行登录
			   var ua = navigator.userAgent.toLowerCase();
			   var usersmessage = localStorage.getItem('usermessage');
			   // alert(usersmessage)
			   if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				   that.weixinopen = false
				   //说明用户是在微信中打开的
				   if(usersmessage){
					   var userid = JSON.parse(usersmessage).userid 
					   that.user_id = userid
					   
					   // 已经登录
					   that.wechatnologin = false
					   
					   // 向后台提交浏览记录
					   var newdata = {
							user_id:userid,
							check_id:that.$route.query.id //方案id
					    }
					    this.$axios.post(this.$api + "/checksBrows",newdata)
					   .then(function (res) {
							//console.log(res)
					    }).catch(function (err) {
							console.log(err)
					    })  
				   }else{
					   //还没有登录
					   that.wechatnologin = true
					   // localStorage.setItem('geotourl',url)
					   document.cookie = 'retrunurl' +"="+ url
					   // 移动端用的是公众号的appid，pc端用的是公众平台的appid
					   var appid = 'wx799c2495cc0d2a30'
					   var urlx = 'http%3A%2F%2Fxjaikj.htkjbd.com%2Floginsucess%3Fappid%3Dwx799c2495cc0d2a30'
					   window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlx}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
					   // 跳转到loginsucess.vue页面，在域名上得到code

				   }
				   
			   }else{
				   // 提示请用微信打开
				   that.weixinopen = true
			   }
		  },
		  // 打开弹窗
		  openwindow:function(){
		  	 this.windowshow = true
		  },
		  // 关闭弹窗
		  closewindow:function(){
			  this.windowshow = false
		  },
		  // 确认签收
		  suresign:function(){
			  this.windowshow = false
			  this.signend = true
			  
			  var that = this
			  var usersmessage = localStorage.getItem('usermessage');
			  var userid = JSON.parse(usersmessage).userid 
			  let url = this.$api + "/saveCheck/" + that.uid
			  this.$axios.post(url,{
			  	user_id:userid
			  })
			  .then((res) => {
				  // console.log(res)
				  that.check = true
				  that.datamessage.check_user = res.data.result
				  
				  // console.log(that.datamessage)
				  // that.userid = res.data.result.user_id
			  })
			  .catch((err) => {
			  	console.log(err)
			  })
		  },
		  getdata:function(){
			  // let uid = this.$route.params.id;
			  if(this.screenWidth > 300 && this.screenWidth < 800){
				  this.btnsize = 'mini'
			  }else{
				  this.btnsize = 'medium'
			  }
			  
			  var that = this
			  
			  // 说明是正常点击进入的
			  if(that.$route.query.id){
				  
				  // 让输入框隐藏，让文本框显示
				  that.textinp = false
				  that.editing = false
				  
				  
				  this.uid =this.$route.query.id
				  let uid =this.$route.query.id
				  let url = this.$api + "/getCheckById/" + uid
				  this.$axios.get(url,{
						params:{
							user_id:that.user_id
						}
				  })
				  .then((res) => {
						  console.log(res.data.result)
						  that.datamessage = res.data.result
						  that.check = res.data.result.on_check
						  that.userid = res.data.result.user_id
						  
						  // 微信转发分享
						  // 这里是为了避免异步问题，所以将微信转发分享放在这个位置上
						  that.share(res.data.result.project_name)
				  })
				  .catch((err) => {
				  	console.log(err)
				  })
			  }else if(that.$route.query.newadd){
				  // 说明是点击添加进入的
				  // 让输入框隐藏，让文本框显示
				  that.textinp = true
				  that.editing = true
			  }
			  
		  },
		  // 微信分享功能
		  share:function(newname){
		        var that=this
				// console.log(that.uid)
		  		var shareUrl = window.location.href
		  		this.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
		  		.then(function (res) {
		  		   wx.config({
		  			 debug: false,// true开启调试模式,pc端会console.log手机端会alert
		  			 appId: res.data.result.appId,// 必填，公众号的唯一标识，填自己的！
		  			 timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
		  			 nonceStr: res.data.result.nonceStr,   // 必填，生成签名的随机串
		  			 signature: res.data.result.signature, // 必填，签名，见附录1
		  			 jsApiList: [
		  			   'onMenuShareTimeline',
		  			   'onMenuShareAppMessage'
		  			 ]
		  		   })
		  		
		  		   //分享配置
		  		   wx.ready(function () {
		  			   //分享到朋友圈
		  			   wx.onMenuShareTimeline({
		  				 // title: that.datamessage.content+'验收单',
						 title: '服务验收单',
		  				 link: 'https://xjaikj.htkjbd.com/signdetail?id=' + that.uid,
		  				 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
		  				 success: function () {
		  					  
		  				 },
		  				 cancel: function () {
		  				   // alert("取消分享");
		  				 }
		  			   });
		  			   //分享给朋友
		  			   wx.onMenuShareAppMessage({
		  				 // title: that.datamessage.content+'验收单',
						 title: '服务验收单',
		  				 desc: newname, 
		  				 link: 'https://xjaikj.htkjbd.com/signdetail?id=' + that.uid,
		  				 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
		  				 success: function () {
		  					 // alert('分享成功')
		  				 },
		  				 cancel: function (err) {
		  				   // alert("取消分享");
		  				   alert(err)
		  				 }
		  			   });
		  		   })
		  		
		  		}).catch(function (err) {
		  												// alert(err)
		  								//        console.log(err)
		  		})  
		  		
		      },
		  // 新增验收内容列表
		  addformlist:function(){
			  var that = this

			  var newarry={
				  content:'',
				  number:'',
				  remark:''
			  }
			  if(!that.datamessage.array){
				  // 说明是点击的增加进来的，没有datamessage.array，所以不能直接改
				  that.datamessage={
				  		'array':[]
				  }
			  }else{
				  // 说明之前填入过数据，把之前的数据渲染出来
				  var dataarr = that.datamessage.array
				  that.$refs.addyan1.map((item,index)=>{
				  		dataarr[index].content = item.value
				  })
				  that.$refs.addyan2.map((item,index)=>{
				  	  dataarr[index].number = item.value
				  })
				  that.$refs.addyan3.map((item,index)=>{
				  	  dataarr[index].remark = item.value
				  })
				  
				  // console.log(dataarr)
			  }
			  that.datamessage.array.push(newarry)
		  },
		  // 当验收内容中的输入框，任意一个在输入的话，就渲染一次列表
		  // 防止出现删除第二个，第四个的数值消失的情况
		  inpcont:function(){
			  var that = this
			  var dataarr = that.datamessage.array
			  that.$refs.addyan1.map((item,index)=>{
			  		dataarr[index].content = item.value
			  })
			  that.$refs.addyan2.map((item,index)=>{
			  	  dataarr[index].number = item.value
			  })
			  that.$refs.addyan3.map((item,index)=>{
			  	  dataarr[index].remark = item.value
			  })
		  },
		  // 删掉一行
		  jianformlist:function(num){
			  var that = this
			  var arr = that.datamessage.array
			  // arr.splice(num,1)
			  var newarr = arr.filter((item,index)=>{
				  return index!=num
			  })
			  that.datamessage.array = newarr
		  },
		  // 提交新详情
		  postnewdetail:function(){
			  var that = this
			  var addname = that.addname
			  var addnum = that.addnum
			  var addcontent = that.addcontent
			  var addmark = that.addmark
			  var addarr = JSON.stringify(that.datamessage.array)
			  
			  var date = new Date();
			  var years = date .getFullYear(); //获取完整的年份(4位)
			  var month = date .getMonth()+1; //获取当前月份(0-11,0代表1月)
			  var day = date .getDate(); //获取当前日(1-31)
			  
			  
			var newdate
			
			// 选了时间就要选择的时间，没选时间就用当前时间
			if(that.value1==""){
				newdate = years+'-'+month+'-'+day
			}else{
			   var newtime = that.value1
			   var newyears = newtime.getFullYear();
			   var newmonth = newtime.getMonth()+1;
			   var newday = newtime.getDate();
			   newdate = newyears+'-'+newmonth+'-'+newday
			   console.log(newdate)
			}
			  
			  if(addname!='' && addnum!='' && addcontent!='' && addmark!=''){
				  var newarry = {}
				  if(that.$route.query.newadd){
					   // 说明是新增，此时是没有id的
					   newarry.project_name = addname
					   newarry.content = addcontent
					   newarry.number = addnum
					   newarry.date = newdate
					   newarry.remarks = addmark
					   newarry.array = addarr
					   newarry.user_id = that.user_id
				  }else{
					   //说明是编辑进来的
					   newarry.id = that.$route.query.id
					   newarry.project_name = addname
					   newarry.content = addcontent
					   newarry.number = addnum
					   newarry.date = newdate
					   newarry.remarks = addmark
					   newarry.array = addarr
					   newarry.user_id = that.user_id
				  }
				  let url = this.$api + "/addCheck"
				  this.$axios.post(url,newarry)
				  .then((res) => {
					   that.windowtip = true
					   console.log(res)
				  })
				  .catch((err) => {
				  		console.log(err)
				  })
			  }else{
				   alert('请将内容补充完整！！')
			   }
			  
			  
		  },
		  // 返回列表页
		  jumppage:function(){
		  		this.$router.push({path: '/signlist'})
		  },
		  // 点击返回上一页
		  gojumpback:function(){
		  		this.$router.go(-1)
		  }
	  }
	}
	
</script>

<style scoped="scoped">
	router-link,a{
		text-decoration: none;
	}
	ul,li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	/* 顶部返回按钮 */
	.goback{
		display: none;
	}
	/* 微信中打开，没有登录的话，显示的遮罩 */
	.wechatnologin{
		width: 100vw;
		height: 100vh;
		background-color: #fff;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.gotologBtn{
		width: 130px;
		height: 40px;
		color: #fff;
		line-height: 40px;
		background-color: #007400;
		margin-top: 20px;
		border-radius: 5px;
	}
	.gotologBtn:hover{
		cursor: pointer;
	}
	
	.navtop{
		width: 100%;
		height: 90px;
		background-color: #000;
	}
	.signwrap{
		width: 1200px;
		height: 800px;
		margin: 80px auto 0;
	}
	/* 顶部横线 */
	.signtop{
		width: 100%;
		height: 50px;
		/* border-bottom: #9A9999 1px solid; */
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	.signtopleft img{
		width: 130px;
		height: auto;
	}
	.signtopright{
		font-size: 14px;
		color: #949494;
		line-height: 23px;
	}
	/* 表格 */
	.signcenter{
		width: 100%;
		height: auto;
		margin-top: 20px;
		padding-bottom: 150px;
		position: relative;
	}
	.signtitle{
		width: 100%;
		font-size: 26px;
		font-weight: bold;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		border-bottom: none;
		height: 80px;
		line-height: 80px;
	}
	.signul{
		width: 100%;
		margin-top: 0;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		/* border-radius: 5px; */
	}
	/* 项目名称 */
	.lione{
		display: flex;
		height: 70px;
		align-items: center;
		/* background-color: #ECEBEB; */
	}
	.lionediv1{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.projectname{
		width: 90%;
		height: 80%;
		font-size: 16px;
		border: none;
		outline: none;
	}
	.projectcont{
		width: 99%;
		height: 90%;
		border: none;
		outline: none;
		font-size: 16px;
	}
	.lionediv2{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-left: #9A9999 3px solid;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.litwodiv1{
		width: 40%;
		height: 100%;
		text-align: center;
		line-height: 25px;
		display: flex;
		align-items: center;
		text-align: left;
		padding: 0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	.litwodiv2{
		width: 30%;
		height: 100%;
		text-align: center;
		line-height: 70px;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	/* 项目情况 */
	.lione2{
		display: flex;
		height: 130px;
		align-items: center;
	}
	.lione3{
		display: flex;
		height: 100px;
		align-items: center;
	}
	.lionediv{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top: #9A9999 3px solid;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.litwodiv{
		width: 85%;
		height: 100%;
		text-align: center;
		border-top: #9A9999 3px solid;
		line-height: 25px;
		display: flex;
		align-items: center;
		text-align: left;
		padding: 0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	.shoujida{
		/* height: auto !important; */
		display: block;
		overflow: auto !important;
		padding: 5px 10px !important;
		border-bottom: #fff 5px solid;
		box-sizing: border-box;
	}
	.qingkuang::-webkit-input-placeholder{
	  color:#757575 !important;
	  font-size: 16px !important;
	  letter-spacing: 0 !important;
	  font-family: '微软雅黑';
	}
	/* 序号表头 */
	.litcont{
		display: flex;
		height: 70px;
		align-items: center;
		border-top: #9A9999 3px solid;
		box-sizing: border-box;
	}
	.litcontonediv1{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		/* line-height: 90px; */
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-size: 16px;
		/* font-weight: bold; */
	}
	.el-icon-circle-plus-outline,.el-icon-remove-outline{
		margin-left: 2px;
		font-size: 18px;
	}
	.litcontonediv2{
		width: 35%;
		height: 100%;
		text-align: center;
		line-height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		box-sizing: border-box;
		border-right: #9A9999 3px solid;
		font-size: 16px;
		/* font-weight: bold; */
	}
	.litcontonediv3{
		width: 30%;
		height: 100%;
		text-align: center;
		line-height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		box-sizing: border-box;
		border-right: #9A9999 3px solid;
		font-size: 16px;
		/* font-weight: bold; */
	}
	.litcontonediv4{
		width: 20%;
		height: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		/* line-height: 90px; */
		font-size: 16px;
		/* font-weight: bold; */
	}
	.litcontneirong{
	/* 		display: block !important; */
			align-items: flex-start;
			text-align: left !important;
			overflow: auto !important;
			padding: 10px !important;
			box-sizing: border-box !important;
			border-bottom: #fff 10px solid !important;
		}

	/* 已签收印章 */
	.yanshoudivs{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.yiqianshou{
		width: 260px;
		height: auto;
		/* position: absolute;
		right: 420px;
		bottom: 190px; */
		transform:rotate(-25deg)
	}
	/* 已验收信息 */
	.yanshoudiv{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.wechart{
		display: flex;
		align-items: center;
	}
	.wechart img{
		width: 25px;
		border-radius: 50%;
	}
	.wechart span{
		font-size: 16px;
		font-weight: bold;
		margin-left: 5px;
		color: orange;
	}
	.yanshotext{
		font-size: 16px;
		font-weight: bold;
		margin-left: 10px;
	}
	/* 弹出框 */
	.windowtip{
		width: 100%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.windowtipyin{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0,0,0,0.6);
	}
	.windowcenter{
		width: 400px;
		height: 200px;
		background-color: #fff;
		z-index: 3;
		border-radius: 10px;
	}
	.windowtop{
		margin-top: 40px;
		font-size: 16px;
		font-weight: bold;
	}
	.windowbtn{
		margin-top: 60px;
	}
	.windowbtn1,.windowbtn2{
		width: 150px;
	}
	
	/* 底部公司名称 */
	.botomdes{
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
	}
	.botomdesleft,.botomdesright{
		font-size: 18px;
		font-weight: bold;
	}
	/* 提交成功后的弹窗 */
	.uploadtip{
		width: 100%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.uploadyin{
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6);
		z-index: 1;
		position: absolute;
		left: 0;
		top: 0;
	}
	.uploadcenter{
		width: 400px;
		height: 200px;
		background-color: #fff;
		z-index: 2;
		border-radius: 5px;
	}
	.uploadcenter div{
		width: 100%;
		height: 60%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		letter-spacing: 1px;
	}
	.botbutton{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}
	.timerchecks{
		display: flex;
		justify-content: flex-end;
		margin-top: 20px !important;
		margin-bottom: 15px !important;
	}
	
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		/* 顶部返回按钮 */
		.topnav{
			display: none;
		}
		/* 顶部返回按钮 */
		.goback{
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			margin: 0 auto 30px;
			display: flex;
			justify-content: flex-start;
			background-color: black;
		}
		.goback span{
			font-size: 18px;
			font-weight: bold;
			margin-left: 10px;
			color: #fff;
		}
		.timerchecks{
			display: flex;
			justify-content: center;
			margin-top: 15px !important;
			margin-bottom: 15px !important;
		}
		/* 时间选择 */
		.timercheck{
			margin-top: 15px;
		}
		.navtop{
			width: 100%;
			height: 90px;
			background-color: #000;
		}
		.signwrap{
			width: 93%;
			height: auto;
			margin: 20px auto 0;
		}
		/* 顶部横线 */
		.signtop{
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.signtopleft img{
			width: 100px;
			height: auto;
		}
		.signtopright{
			font-size: 13px;
			line-height: 20px;
			color: #949494;
		}
		/* 表格 */
		.signcenter{
			width: 100%;
			margin-top: 10px;
			padding-bottom: 50px;
			position: relative;
		}
		.signtitle{
			font-size: 17px;
			font-weight: bold;
			width: 100%;
			border: #9A9999 2px solid;
			border-bottom: none;
			box-sizing: border-box;
			height: 60px;
			line-height: 60px;
		}
		.signul{
			width: 100%;
			margin-top: 0px;
			border: #9A9999 2px solid;
			box-sizing: border-box;
			/* border-radius: 5px; */
		}
		/* 项目名称 */
		.lione{
			display: flex;
			height: 90px;
			align-items: center;
			/* background-color: #ECEBEB; */
		}
		.lionediv1{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-right: #9A9999 2px solid;
			box-sizing: border-box;
			font-weight: bold;
		}
		.lionediv2{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: left;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			box-sizing: border-box;
			font-weight: bold;
		}
		.litwodiv1{
			width: 41%;
			font-size: 13px;
			height: 100%;
			text-align: left;
			line-height: 0px;
			display: flex;
			justify-content: center;
			line-height: 20px;
			align-items: center;
			padding: 0 5px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		.projectname{
			font-size: 13px;
		}
		.projectcont{
			width: 99%;
			height: 90%;
			border: none;
			outline: none;
			font-size: 13px;
		}
		.litwodiv2{
			width: 21%;
			font-size: 13px;
			height: 100%;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
			box-sizing: border-box;
		}
		/* 项目情况 */
		.lione2{
			display: flex;
			height: 120px;
			align-items: center;
		}

		.mobilelione{
			height: 80px;
		}
		.lionediv{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
			box-sizing: border-box;
		}
		.litwodiv{
			width: 81%;
			font-size: 13px;
			height: 100%;
			text-align: left;
			line-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
			padding: 0 10px;
			border-top: #9A9999 2px solid;
			box-sizing: border-box;
		}
		.qingkuang::-webkit-input-placeholder{
		  color:#757575 !important;
		  font-size: 13px !important;
		  letter-spacing: 0 !important;
		  font-family: '微软雅黑';
		}
		/* 序号表头 */
		.litcont{
			display: flex;
			height: 80px;
			align-items: center;
			border-top: #9A9999 2px solid;
		}
		.litcontonediv1{
			width: 19%;
			height: 100%;
			text-align: center;
			line-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 13px;
			border-right: #9A9999 2px solid;
			/* font-weight: bold; */
			box-sizing: border-box;
		}
		.litcontonediv2{
			width: 33%;
			height: 100%;
			text-align: center;
			line-height: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 13px;
			border-right: #9A9999 2px solid;
			/* font-weight: bold; */
			box-sizing: border-box;
			padding: 0 10px;
			box-sizing: border-box;
		}
		.litcontonediv3{
			width: 29%;
			height: 100%;
			text-align: center;
			line-height: 18px;
			display: flex;
			font-size: 13px;
			justify-content: center;
			align-items: center;
			border-right: #9A9999 2px solid;
			/* font-weight: bold; */
			padding: 0 10px;
			box-sizing: border-box;
		}
		.litcontonediv4{
			width: 19%;
			height: 100%;
			text-align: center;
			line-height: 20px;
			display: flex;
			font-size: 13px;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
			box-sizing: border-box;
		}
		.litcontneirong{
			display: block !important;
			text-align: left !important;
			overflow: auto !important;
			padding: 5px !important;
			box-sizing: border-box !important;
			border-bottom: #fff 3px solid !important;
		}

		/* 已签收印章 */
		.yiqianshou{
			/* width: 100px;
			height: auto;
			position: absolute;
			right: 0;
			top: 0px; */
			width: 170px;
			height: auto;
			/* position: absolute;
			right: 90px;
			bottom: 130px; */
			transform:rotate(-25deg)
		}
		/* 已验收信息 */
		.yanshoudiv{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.wechart{
			display: flex;
			align-items: center;
		}
		.wechart img{
			width: 20px;
		}
		.wechart span{
			font-size: 14px;
			font-weight: bold;
			margin-left: 5px;
			color: orange;
		}
		.yanshotext{
			font-size: 14px;
			font-weight: bold;
			margin-left: 10px;
		}
		/* 弹出框 */
		.windowtip{
			width: 100%;
			height: 100vh;
			position: fixed;
			left: 0;
			top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.windowtipyin{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			background-color: rgba(0,0,0,0.6);
		}
		.windowcenter{
			width: 80%;
			height: 150px;
			background-color: #fff;
			z-index: 3;
			border-radius: 8px;
		}
		.windowtop{
			margin-top: 20px;
			font-size: 16px;
			font-weight: bold;
		}
		.windowbtn{
			margin-top: 40px;
		}
		.windowbtn1,.windowbtn2{
			width: 120px;
		}
		
		/* 底部公司名称 */
		.botomdes{
			width: 100%;
			display: block;
			margin-top: 20px;
		}
		.botomdesleft,.botomdesright{
			font-size: 14px;
			font-weight: bold;
		}
		.botomdesright{
			margin-top: 5px;
		}
	}
	
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		.topnav{
			display: none;
		}
		/* 顶部返回按钮 */
		.goback{
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			margin: 0 auto 30px;
			display: flex;
			justify-content: flex-start;
			background-color: black;
		}
		.goback span{
			font-size: 18px;
			font-weight: bold;
			margin-left: 10px;
			color: #fff;
		}
		/* 微信中打开，没有登录的话，显示的遮罩 */
		.wechatnologin{
			width: 100vw;
			height: 100vh;
			background-color: #fff;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 1000;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.gotologBtn{
			width: 130px;
			height: 40px;
			color: #fff;
			line-height: 40px;
			background-color: #007400;
			margin-top: 20px;
			border-radius: 5px;
		}
		.gotologBtn:hover{
			cursor: pointer;
		}
		
		.navtop{
			width: 100%;
			height: 90px;
			background-color: #000;
		}
		.signwrap{
			width: 97%;
			height: auto;
			margin: 30px auto 0;
		}
		/* 顶部横线 */
		.signtop{
			width: 100%;
			height: 50px;
			/* border-bottom: #9A9999 1px solid; */
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.signtopleft img{
			width: 130px;
			height: auto;
		}
		.signtopright{
			font-size: 14px;
			color: #949494;
			line-height: 23px;
		}
		/* 表格 */
		.signcenter{
			width: 100%;
			margin-top: 15px;
			padding-bottom: 100px;
			position: relative;
		}
		.signtitle{
			width: 100%;
			font-size: 20px;
			font-weight: bold;
			border: #9A9999 2px solid;
			border-bottom: none;
			height: 90px;
			line-height: 90px;
		}
		.signul{
			width: 100%;
			margin-top: 0;
			border: #9A9999 2px solid;
			/* border-radius: 5px; */
		}
		/* 项目名称 */
		.lione{
			display: flex;
			height: 90px;
			align-items: center;
			/* background-color: #ECEBEB; */
		}
		.lionediv1{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.lionediv2{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			border-left: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.litwodiv1{
			width: 40%;
			height: 100%;
			line-height: 22px;
			display: flex;
			text-align: left;
			align-items: center;
			padding: 0 10px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		.litwodiv2{
			width: 30%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			/* font-weight: bold; */
		}
		/* 项目情况 */
		.lione2{
			display: flex;
			height: 160px;
			align-items: center;
		}
		.mobilelione{
			height: 100px;
		}
		.lionediv{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.litwodiv{
			width: 85%;
			height: 100%;
			text-align: left;
			border-top: #9A9999 2px solid;
			line-height: 22px;
			display: flex;
			align-items: center;
			padding: 0 10px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		/* 序号表头 */
		.litcont{
			display: flex;
			height: 110px;
			align-items: center;
			border-top: #9A9999 2px solid
		}
		.litcontonediv1{
			width: 15%;
			height: 100%;
			font-size: 16px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 90px;
			border-right: #9A9999 2px solid;
			/* font-weight: bold; */
		}
		.litcontonediv2{
			width: 35%;
			height: 100%;
			text-align: center;
			line-height: 20px;
			display: flex;
			font-size: 16px;
			justify-content: center;
			align-items: center;
			padding: 0 10px;
			box-sizing: border-box;
			border-right: #9A9999 2px solid;
			/* font-weight: bold; */
		}
		.litcontonediv3{
			width: 30%;
			height: 100%;
			font-size: 16px;
			text-align: center;
			line-height: 90px;
			border-right: #9A9999 2px solid;
			/* font-weight: bold; */
		}
		.litcontonediv4{
			width: 20%;
			height: 100%;
			font-size: 16px;
			text-align: center;
			line-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
		}
		
		/* 已签收印章 */
		.yiqianshou{
			width: 210px;
			height: auto;
			/* position: absolute;
			right: 240px;
			bottom: 150px; */
			transform:rotate(-25deg)
		}
		/* 已验收信息 */
		.yanshoudiv{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.wechart{
			display: flex;
			align-items: center;
		}
		.wechart img{
			width: 25px;
			border-radius: 50%;
		}
		.wechart span{
			font-size: 16px;
			font-weight: bold;
			margin-left: 5px;
			color: orange;
		}
		.yanshotext{
			font-size: 16px;
			font-weight: bold;
			margin-left: 10px;
		}
		/* 弹出框 */
		.windowtip{
			width: 100%;
			height: 100vh;
			position: fixed;
			left: 0;
			top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.windowtipyin{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			background-color: rgba(0,0,0,0.6);
		}
		.windowcenter{
			width: 400px;
			height: 200px;
			background-color: #fff;
			z-index: 3;
			border-radius: 10px;
		}
		.windowtop{
			margin-top: 40px;
			font-size: 16px;
			font-weight: bold;
		}
		.windowbtn{
			margin-top: 60px;
		}
		.windowbtn1,.windowbtn2{
			width: 150px;
		}
		
		/* 底部公司名称 */
		.botomdes{
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 40px;
		}
		.botomdesleft,.botomdesright{
			font-size: 18px;
			font-weight: bold;
		}
	}
</style>
