<template>
	<div class="wrap">
		<div class="topnav">
			<Headernew/>
		</div>
		
		<!-- 返回区域 -->
		<div class="goback" @click="gojumpback"><span class="el-icon-arrow-left"></span></div>
		
		<div class="signwrap">
			<div class="signtop">
				<div class="signtopleft">
					<img src="../../assets/img/logo2.png" />
				</div>
				<router-link to="/" class="signtopright">https://xjaikj.htkjbd.com/</router-link>
			</div>
			
			<div class="signcenter">
				<div class="signtitle">项目签收单</div>
			    <ul class="signul">
			    	<!-- 表头 -->
			    	<li class="lione">
			    		<div class="lionediv1">项目名称</div>
			    		<div class="litwodiv1">{{datamessage.project_name}}</div>
						<div class="lionediv2">项目编号</div>
						<div class="litwodiv2">{{datamessage.number}}</div>
			    	</li>
					<!-- 项目情况 -->
					<li class="lione2">
						<div class="lionediv">项目情况</div>
						<div class="litwodiv">{{datamessage.content}}</div>
					</li>
					<!-- 项目验收 -->
					<li class="lione2 mobilelione">
						<div class="lionediv">项目验收</div>
						<div class="litwodiv" style="justify-content: center;">
							<el-button :size="btnsize" v-show="!check" class="litcontbutton" type="primary">点击签收</el-button>
							<!-- <el-button :size="btnsize" type="danger" plain disabled v-show="userid">已验收</el-button> -->
							<!-- 已签收印章 -->
							<!--  -->
							<div class="yanshoudivs" v-show="check">
								<img class="yiqianshou" src="../../assets/img/qianshou.png" />
							</div>
						</div>
					</li>
					<!-- 负责人 -->
					<li class="lione3 mobilelione">
						<div class="lionediv">负责人</div>
						<div class="litwodiv">
							<div class="yanshoudiv" v-if="check">
								<div class="wechart">
									<img :src="datamessage.check_user.headimgurl" />
									<span>{{datamessage.check_user.nickname}}</span>
								</div>
								<div class="yanshotext">已签收</div>
							</div>
						</div>
					</li>
				</ul>
				
				<div class="botomdes">
					<div class="botomdesleft">北京后天世纪文化传媒有限公司保定分公司</div>
					<div class="botomdesright">2021-9-29</div>
				</div>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import $ from "jquery"
	import wx from 'weixin-js-sdk'
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	
	export default {
	  name: 'checkdetail',
	  components: {
	    Headernew,
	    Footernew,
	  },
	  data() {
		return {
			user_id:'',//用户id
			userid:'',//是否签收，根据user_id字段判断，有就是有，没有就是null
			check:'',//用户是否签收过
			uid:'',//方案id
			signend:false,//控制签收头像，签收图片的显示
			datamessage:'',//签收单数据
			btnsize:'medium',//按钮尺寸
			wechatnologin:true,//控制微信打开页面时，未登录，显示的遮罩
			weixinopen:true,//控制，用微信打开本页面的提示显示和隐藏
			screenWidth: document.body.clientWidth,     // 屏幕宽
		}
	  },
	  mounted() {
		  var that = this
		  // 这里是用来获取屏幕宽度的
		  window.onresize= () => {
		      return (() => {
		          window.screenWidth = document.body.clientWidth;
		          that.screenWidth = window.screenWidth;
		      })();
		  }

		  this.getdata() //获取数据
		  //this.share() //微信转发分享
	  },
	  methods:{

		  getdata:function(){
			  var that = this
			  if(this.screenWidth > 300 && this.screenWidth < 800){
				  this.btnsize = 'mini'
			  }else{
				  this.btnsize = 'medium'
			  }
			  
			  // 从地址上获取项目userid和checkid
			  // https://xjaikj.htkjbd.com/printcheck?userid=5&checkid=39
			  let checkid = this.$route.query.checkid
			  let userid = this.$route.query.userid
			  console.log(checkid,userid)
			  
			  let url = this.$api + "/getConfirmById/" + checkid
			  this.$axios.get(url,{
					params:{
						user_id:userid
					}
			  })
			  .then((res) => {
				  // console.log(res)
				  that.datamessage = res.data.result
				  that.check = res.data.result.on_check
				  that.userid = res.data.result.user_id
				  
				  // 微信转发分享
				  // 这里是为了避免异步问题，所以将微信转发分享放在这个位置上
				  that.share(res.data.result.project_name) //微信转发分享
			  })
			  .catch((err) => {
			  	console.log(err)
			  })
		  },
		  // 确认签收
		  suresign:function(){
			  this.windowshow = false
			  this.signend = true
			  
			  var that = this
			  var usersmessage = localStorage.getItem('usermessage');
			  var userid = JSON.parse(usersmessage).userid 
			  let url = this.$api + "/saveConfirm/" + that.uid
			  this.$axios.post(url,{
				user_id:userid
			  })
			  .then((res) => {
				  // console.log(res)
				  that.check = true
				  that.datamessage.check_user = res.data.result
				  // console.log(that.datamessage)
				  // that.userid = res.data.result.user_id
			  })
			  .catch((err) => {
				console.log(err)
			  })
		  },
		  // 微信分享功能
		  share:function(newname){
		        var that=this
				let uid = this.$route.query.checkid
				let userid = this.$route.query.userid
		  				// console.log(that.uid)
		  		var shareUrl = window.location.href
		  		this.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
		  		.then(function (res) {
		  		   wx.config({
		  			 debug: false,// true开启调试模式,pc端会console.log手机端会alert
		  			 appId: res.data.result.appId,// 必填，公众号的唯一标识，填自己的！
		  			 timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
		  			 nonceStr: res.data.result.nonceStr,   // 必填，生成签名的随机串
		  			 signature: res.data.result.signature, // 必填，签名，见附录1
		  			 jsApiList: [
		  			   'onMenuShareTimeline',
		  			   'onMenuShareAppMessage'
		  			 ]
		  		   })
		  		
		  		   //分享配置
		  		   wx.ready(function () {
		  			   //分享到朋友圈
		  			   wx.onMenuShareTimeline({
		  				 // title: that.datamessage.content+'验收单',
		  				 title: '项目签收单打印单',
		  				 link: 'https://xjaikj.htkjbd.com/printcheck?userid=' + userid + '&checkid=' + uid,
		  				 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
		  				 success: function () {
		  					  
		  				 },
		  				 cancel: function () {
		  				   // alert("取消分享");
		  				 }
		  			   });
		  			   //分享给朋友
		  			   wx.onMenuShareAppMessage({
		  				 // title: that.datamessage.content+'验收单',
		  				 title: '项目签收单打印单',
		  				 desc: newname, 
		  				 link: 'https://xjaikj.htkjbd.com/printcheck?userid=' + userid + '&checkid=' + uid,
		  				 imgUrl: 'https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/logo.png',
		  				 success: function () {
		  					 // alert('分享成功')
		  				 },
		  				 cancel: function (err) {
		  				   // alert("取消分享");
		  				   alert(err)
		  				 }
		  			   });
		  		   })
		  		
		  		}).catch(function (err) {
		  												// alert(err)
		  								//        console.log(err)
		  		})  
		  		
		   },
		   // 点击返回上一页
		   gojumpback:function(){
		   		this.$router.go(-1)
		   },
	  }
	}
	
</script>

<style scoped="scoped">
	router-link,a{
		text-decoration: none;
	}
	ul,li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	iframe {
		width: 1200px;
		height: 700px;
		border: none !important;
		margin: 0 !important;
	}
	/* 顶部返回按钮 */
	.goback{
		display: none;
	}
	.topnav{
		width: 100%;
		height: 96px;
		background-color: #000000;
	}
	/* 微信中打开，没有登录的话，显示的遮罩 */
	.wechatnologin{
		width: 100vw;
		height: 100vh;
		background-color: #fff;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.gotologBtn{
		width: 130px;
		height: 40px;
		color: #fff;
		line-height: 40px;
		background-color: #007400;
		margin-top: 20px;
		border-radius: 5px;
	}
	.gotologBtn:hover{
		cursor: pointer;
	}
	
	.navtop{
		width: 100%;
		height: 90px;
		background-color: #000;
	}
	.signwrap{
		width: 1200px;
		height: 300px;
		margin: 80px auto 0;
	}
	/* 顶部横线 */
	.signtop{
		width: 100%;
		height: 50px;
		/* border-bottom: #9A9999 1px solid; */
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	.signtopleft img{
		width: 130px;
		height: auto;
	}
	.signtopright{
		font-size: 14px;
		color: #949494;
		line-height: 23px;
	}
	/* 表格 */
	.signcenter{
		width: 100%;
		height: auto;
		margin-top: 20px;
		padding-bottom: 150px;
		position: relative;
	}
	.signtitle{
		width: 100%;
		font-size: 26px;
		font-weight: bold;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		border-bottom: none;
		height: 80px;
		line-height: 80px;
	}
	.signul{
		width: 100%;
		margin-top: 0;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		/* border-radius: 5px; */
	}
	
	/* 表格 */
	.signcenter{
		width: 100%;
		height: auto;
		margin-top: 20px;
		padding-bottom: 150px;
		position: relative;
	}
	.signtitle{
		width: 100%;
		font-size: 26px;
		font-weight: bold;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		border-bottom: none;
		height: 80px;
		line-height: 80px;
	}
	.signul{
		width: 100%;
		margin-top: 0;
		border: #9A9999 3px solid;
		box-sizing: border-box;
		/* border-radius: 5px; */
	}
	/* 项目名称 */
	.lione{
		display: flex;
		height: 70px;
		align-items: center;
		/* background-color: #ECEBEB; */
	}
	.lionediv1{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.lionediv2{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-left: #9A9999 3px solid;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.litwodiv1{
		width: 40%;
		height: 100%;
		text-align: center;
		line-height: 25px;
		display: flex;
		align-items: center;
		text-align: left;
		padding: 0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	.litwodiv2{
		width: 30%;
		height: 100%;
		text-align: center;
		line-height: 70px;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	/* 项目情况 */
	.lione2{
		display: flex;
		height: 130px;
		align-items: center;
	}
	.lione3{
		display: flex;
		height: 100px;
		align-items: center;
	}
	.lionediv{
		width: 15%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top: #9A9999 3px solid;
		border-right: #9A9999 3px solid;
		box-sizing: border-box;
		font-weight: bold;
	}
	.litwodiv{
		width: 85%;
		height: 100%;
		text-align: center;
		border-top: #9A9999 3px solid;
		line-height: 25px;
		display: flex;
		align-items: center;
		text-align: left;
		padding: 0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #8A8888;
		/* font-weight: bold; */
	}
	/* 已签收印章 */
	.yanshoudivs{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.yiqianshou{
		width: 260px;
		height: auto;
		/* position: absolute;
		right: 420px;
		bottom: 190px; */
		transform:rotate(-25deg);
		z-index: 999;
	}
	/* 已验收信息 */
	.yanshoudiv{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.wechart{
		display: flex;
		align-items: center;
	}
	.wechart img{
		width: 25px;
		border-radius: 50%;
	}
	.wechart span{
		font-size: 16px;
		font-weight: bold;
		margin-left: 5px;
		color: orange;
	}
	.yanshotext{
		font-size: 16px;
		font-weight: bold;
		margin-left: 10px;
	}
	/* 底部公司名称 */
	.botomdes{
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
	}
	.botomdesleft,.botomdesright{
		font-size: 18px;
		font-weight: bold;
	}
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		.topnav{
			display: none;
		}
		/* 顶部返回按钮 */
		.goback{
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			margin: 0 auto 30px;
			display: flex;
			justify-content: flex-start;
			background-color: black;
		}
		.goback span{
			font-size: 18px;
			font-weight: bold;
			margin-left: 10px;
			color: #fff;
		}
		/* 微信中打开，没有登录的话，显示的遮罩 */
		.wechatnologin{
			width: 100vw;
			height: 100vh;
			background-color: #fff;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 1000;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.gotologBtn{
			width: 130px;
			height: 40px;
			color: #fff;
			line-height: 40px;
			background-color: #007400;
			margin-top: 20px;
			border-radius: 5px;
		}
		.gotologBtn:hover{
			cursor: pointer;
		}
		.navtop{
			width: 100%;
			height: 90px;
			background-color: #000;
		}
		.signwrap{
			width: 93%;
			height: auto;
			margin: 20px auto 0;
		}
		/* 顶部横线 */
		.signtop{
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.signtopleft img{
			width: 100px;
			height: auto;
		}
		.signtopright{
			font-size: 13px;
			line-height: 20px;
			color: #949494;
		}
		/* 表格 */
		.signcenter{
			width: 100%;
			margin-top: 10px;
			padding-bottom: 50px;
			position: relative;
		}
		.signtitle{
			font-size: 17px;
			font-weight: bold;
			width: 100%;
			border: #9A9999 2px solid;
			border-bottom: none;
			box-sizing: border-box;
			height: 60px;
			line-height: 60px;
		}
		.signul{
			width: 100%;
			margin-top: 0px;
			border: #9A9999 2px solid;
			box-sizing: border-box;
			/* border-radius: 5px; */
		}
		/* 项目名称 */
		.lione{
			display: flex;
			height: 90px;
			align-items: center;
			/* background-color: #ECEBEB; */
		}
		.lionediv1{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-right: #9A9999 2px solid;
			box-sizing: border-box;
			font-weight: bold;
		}
		.lionediv2{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: left;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			box-sizing: border-box;
			font-weight: bold;
		}
		.litwodiv1{
			width: 41%;
			font-size: 13px;
			height: 100%;
			text-align: left;
			line-height: 0px;
			display: flex;
			justify-content: center;
			line-height: 20px;
			align-items: center;
			padding: 0 5px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		.litwodiv2{
			width: 21%;
			font-size: 13px;
			height: 100%;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
			box-sizing: border-box;
		}
		/* 项目情况 */
		.lione2{
			display: flex;
			height: 120px;
			align-items: center;
		}
		
		.mobilelione{
			height: 80px;
		}
		.lionediv{
			width: 19%;
			height: 100%;
			font-size: 14px;
			text-align: center;
			line-height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
			box-sizing: border-box;
		}
		.litwodiv{
			width: 81%;
			font-size: 13px;
			height: 100%;
			text-align: left;
			line-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			/* font-weight: bold; */
			padding: 0 10px;
			border-top: #9A9999 2px solid;
			box-sizing: border-box;
		}
		/* 已签收印章 */
		.yiqianshou{
			/* width: 100px;
			height: auto;
			position: absolute;
			right: 0;
			top: 0px; */
			width: 170px;
			height: auto;
			/* position: absolute;
			right: 90px;
			bottom: 130px; */
			transform:rotate(-25deg)
		}
		/* 已验收信息 */
		.yanshoudiv{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.wechart{
			display: flex;
			align-items: center;
		}
		.wechart img{
			width: 20px;
		}
		.wechart span{
			font-size: 14px;
			font-weight: bold;
			margin-left: 5px;
			color: orange;
		}
		.yanshotext{
			font-size: 14px;
			font-weight: bold;
			margin-left: 10px;
		}
		/* 底部公司名称 */
		.botomdes{
			width: 100%;
			display: block;
			margin-top: 20px;
		}
		.botomdesleft,.botomdesright{
			font-size: 14px;
			font-weight: bold;
		}
		.botomdesright{
			margin-top: 5px;
		}
	}
	
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		.topnav{
			display: none;
		}
		/* 顶部返回按钮 */
		.goback{
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			margin: 0 auto 30px;
			display: flex;
			justify-content: flex-start;
			background-color: black;
		}
		.goback span{
			font-size: 18px;
			font-weight: bold;
			margin-left: 10px;
			color: #fff;
		}
		.navtop{
			width: 100%;
			height: 90px;
			background-color: #000;
		}
		.signwrap{
			width: 97%;
			height: auto;
			margin: 30px auto 0;
		}
		/* 顶部横线 */
		.signtop{
			width: 100%;
			height: 50px;
			/* border-bottom: #9A9999 1px solid; */
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.signtopleft img{
			width: 130px;
			height: auto;
		}
		.signtopright{
			font-size: 14px;
			color: #949494;
			line-height: 23px;
		}
		/* 表格 */
		.signcenter{
			width: 100%;
			margin-top: 15px;
			padding-bottom: 100px;
			position: relative;
		}
		.signtitle{
			width: 100%;
			font-size: 20px;
			font-weight: bold;
			border: #9A9999 2px solid;
			border-bottom: none;
			height: 90px;
			line-height: 90px;
		}
		.signul{
			width: 100%;
			margin-top: 0;
			border: #9A9999 2px solid;
			/* border-radius: 5px; */
		}
		/* 项目名称 */
		.lione{
			display: flex;
			height: 90px;
			align-items: center;
			/* background-color: #ECEBEB; */
		}
		.lionediv1{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.lionediv2{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			border-left: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.litwodiv1{
			width: 40%;
			height: 100%;
			line-height: 22px;
			display: flex;
			text-align: left;
			align-items: center;
			padding: 0 10px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		.litwodiv2{
			width: 30%;
			height: 100%;
			text-align: center;
			line-height: 90px;
			/* font-weight: bold; */
		}
		/* 项目情况 */
		.lione2{
			display: flex;
			height: 160px;
			align-items: center;
		}
		.mobilelione{
			height: 100px;
		}
		.lionediv{
			width: 15%;
			height: 100%;
			text-align: center;
			line-height: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: #9A9999 2px solid;
			border-right: #9A9999 2px solid;
			font-weight: bold;
		}
		.litwodiv{
			width: 85%;
			height: 100%;
			text-align: left;
			border-top: #9A9999 2px solid;
			line-height: 22px;
			display: flex;
			align-items: center;
			padding: 0 10px;
			box-sizing: border-box;
			/* font-weight: bold; */
		}
		/* 已签收印章 */
		.yiqianshou{
			width: 210px;
			height: auto;
			/* position: absolute;
			right: 240px;
			bottom: 150px; */
			transform:rotate(-25deg)
		}
		/* 已验收信息 */
		.yanshoudiv{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.wechart{
			display: flex;
			align-items: center;
		}
		.wechart img{
			width: 25px;
			border-radius: 50%;
		}
		.wechart span{
			font-size: 16px;
			font-weight: bold;
			margin-left: 5px;
			color: orange;
		}
		.yanshotext{
			font-size: 16px;
			font-weight: bold;
			margin-left: 10px;
		}
		/* 底部公司名称 */
		.botomdes{
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 40px;
		}
		.botomdesleft,.botomdesright{
			font-size: 18px;
			font-weight: bold;
		}
	}
</style>
