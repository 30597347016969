<template>
	<div class="gotop" v-if="gotopshow" @click="gototop">
		<span class="iconfont icon-stack"></span>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
	  props: {
	  },
	  data() {
		return {
			gotopshow:true,
		}
	  },
	  mounted() {
	
	  },
	  methods:{
		  gototop:function(){
			  // 点击跳到顶部
			  // 滚动条滚动高度
			  document.body.scrollTop = document.documentElement.scrollTop = 0;
		  }
	  },
	}
</script>

<style>
	@import '../assets/css/alibaba.css';
	.gotop{
		width: 50px;
		height: 70px;
		position: fixed;
		right: 18px;
		top: 690px;
		z-index: 110;
	}
	.gotop:hover{
		cursor: pointer;
	}
	.gotop span{
		color: #01AEEC;
		font-size: 45px;
	}
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		.gotop{
			width: 50px;
			height: 70px;
			position: fixed;
			right: 7px;
			top: 570px;
			z-index: 110;
		}
		.gotop span{
			color: #01AEEC;
			font-size: 30px;
		}
	}
	
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		
	}
	
	/* 老板仓定制机的样式 */
	@media (min-width: 1000px) and (max-width: 1300px){
		/* 置顶 */
		.gotop{
			width: 40px;
			height: 70px;
			position: fixed;
			right: 22px;
			/* top: 690px; */
			top: auto;
			bottom: 70px;
			z-index: 110;
		}
		.gotop:hover{
			cursor: pointer;
		}
		.gotop span{
			color: #01AEEC;
			font-size: 35px;
		}
		.box1,.section{
			height: inherit;
		}
	}
</style>
