<template>
	<div class="caseview">
		<div class="casecontent" @click="toCases">
			<!-- <span class="iconfont icon-bianpinghuatubiaosheji-"></span> -->
			<img src="../assets/img/alhj_btn.png" />
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		name: 'Cases',
		props: {
			type: Number
		},
		data() {
			return {
				
			}
		},
		mounted() {
	
		},
		methods:{
			toCases() {
				if (this.type == 2) {
					this.$router.push("/casecontent?id=522&random_str=4949130485691201&parent_id=10492")
				} else {
					this.$router.push("/casedetail");
				}
			}
		}
	}
</script>

<style>
	@import '../assets/css/alibaba.css';
	.caseview{
		position: relative;
	}
	.casecontent{
		width: 70px;
		height: 70px;
		position: fixed;
		right: 10px;
		top: 500px;
		z-index: 110;
	}
	.casecontent:hover{
		cursor: pointer;
	}
	.casecontent div{
		width: 100%;
		font-size: 11px;
		color: #fff;
		background-color: #01AEEC;
		border-radius: 4px;
		padding: 1px 0;
	}
	.casecontent img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		/* border: 1px solid red; */
	}
	
	/* 手机端样式 */
	@media (min-width: 300px) and (max-width: 500px){
		.caseview{
			position: relative;
		}
		.casecontent{
			width: 55px;
			height: 70px;
			position: fixed;
			right: 5px;
			top: 410px;
			z-index: 110;
		}
		.casecontent:hover{
			cursor: pointer;
		}
		.casecontent div{
			font-size: 10px;
			color: #fff;
			border-radius: 2px;
			padding: 1px 0;
		}
		.casecontent img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	/* pad端样式 */
	@media (min-width: 500px) and (max-width: 800px){
		.caseview{
			position: relative;
		}
		.casecontent{
			width: 60px;
			height: 70px;
			position: fixed;
			right: 15px;
			top: 500px;
			z-index: 110;
		}
		.casecontent:hover{
			cursor: pointer;
		}
		.casecontent div{
			font-size: 11px;
			color: #fff;
			border-radius: 2px;
			padding: 2px 0;
		}
		.casecontent img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	/* 老板仓定制机的样式 */
	@media (min-width: 1000px) and (max-width: 1300px){
		.casecontent{
			top: auto;
			width: 65px;
			right: 10px;
			z-index: 110;
			height: 65px;
			bottom: 150px;
			position: fixed;
			transform: translateY(-80px);
		}
		.casecontent div{
			width: 100%;
			font-size: 11px;
			color: #fff;
			background-color: #01AEEC;
			border-radius: 4px;
			padding: 1px 0;
		}
		.casecontent img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		
	}
</style>
