<template>
	<div class="all-wrap">
		<div class="topnav">
			<Headernew/>
		</div>
		
		<div class="formzuiwaiechart">
			<!-- 表格区域 -->
			<div class="formwrap22echart">
				  <div class="formtitle">转发浏览记录总表</div>
				  <div class="explainechart">
					  <div class="timeselectechart">
						  <el-date-picker
								@change="changetime"
								v-model="timechose"
								style="width: 100%;"
								type="date"
								placeholder="选择日期">
						   </el-date-picker>
					  </div>
					   
					   <div class="projectchoseechart">
						   <!-- <el-select v-model="projectchoseechart" filterable  placeholder="请选择项目">
							   <el-option
								 style="width: 100%;"
								 v-for="item in options"
								 :key="item.value"
								 :label="item.label"
								 :value="item.value">
							   </el-option>
							 </el-select> -->
							 <input class="searchinputechart" ref="search" type="text" placeholder="请输入项目名称" />
							 <img src="../assets/img/search.png" @click="tosearch" />
					   </div>
				  </div>
				  <div class="echart">
					  <el-table
						  :data="tableData"
						  height="100%"
						  border
						  style="width: 100%;"
						  row-key="id"
						  default-expand-all
						  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
						  :header-cell-style='{background: "#f4f4f4"}'
						  >
						  <el-table-column
							prop="date"
							label="日期"
							fixed="left"
							:width="timewidth"
							sortable
							column-key="date"
							>
						  </el-table-column>
						  <el-table-column
							prop="time"
							:width='timewidth'
							label="时间">
						  </el-table-column>
						  <el-table-column
							prop="main"
							label="项目名"
							:width='tablewidth'
							>
						  </el-table-column>
						  <el-table-column
							prop="name"
							label="浏览人"
							:width='tablewidth'
							>
						  </el-table-column>
						  <el-table-column
							prop="browse"
							label="浏览量">
						  </el-table-column>
						  <!-- <el-table-column
							prop="forward"
							label="转发量">
						  </el-table-column> -->
						  <el-table-column
							:width='tablewidth'
							label="关系图">
							<template slot-scope="scope">
							  <el-button
								size="mini"
								type="primary"
								:disabled="scope.row.parent_id>0"
								@click="jumppage(scope.$index, scope.row)"
								>查看关系图</el-button>
							</template>
								
						  </el-table-column>
						</el-table>
					</div>
					
					
					<!-- 分页 -->
					<el-pagination
					  background
					  class="fenyeqi"
					  layout="prev, pager, next"
					  @current-change='changepage'
					  :total="countnum">
					</el-pagination>
					
			</div>
			
		</div>
		
		<!-- 表格结束 -->
		<Footernew/>
		
	</div>
</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import {WOW} from 'wowjs'  //导入wow
	
	export default {
		name: '',
		data() {
			return {
				tablewidth:'125',//表格尺寸，根据浏览器宽度不同，表格尺寸改变
				timewidth:'120',//日期和时间表格框，根据不同设备变宽度
				fullWidth:document.documentElement.clientWidth,//浏览器可视区宽度
				timechose: '', //选择的时间
				searchtext:'',//搜索项目名
				pagenum:1,//当前页数，默认第一页
				limit:12,//每页显示多少
				countnum:1,//数据总数
				//筛选时间 
				tableData: [{
					id: 1,
				    date: '2021-09-02',
					time: '09:23',
					project:'卓正地产项目',
				    name: '张先生',
				    browse: '12', //浏览量
					forward: '5',//转发量
					children: [{
						  id: 33,
						  date: '2021-08-31',
						  time: '09:52',
						  project:'卓正地产项目',
						  name: '郑经理',
						  browse: '18',//浏览量
						  forward: '8',//转发量
						}, {
						  id: 34,
						  date: '2021-09-01',
						  time: '09:40',
						  project:'卓正地产项目',
						  name: '王经理',
						  browse: '13',//浏览量
						  forward: '3',//转发量
					  }]
				}, {
				  id: 2,
				  date: '2021-09-02',
				  time: '11:23',
				  project:'石药展厅项目',
				  name: '李先生',
				  browse: '15',//浏览量
				  forward: '7',//转发量
				}, {
				  id: 3,
				  date: '2021-09-01',
				  time: '10:23',
				  project:'明德市场项目',
				  name: '刘先生',
				  browse: '17',//浏览量
				  forward: '15',//转发量
				}, {
				  id: 4,
				  date: '2021-08-30',
				  time: '13:43',
				  project:'泽龙地产项目',
				  name: '詹经理',
				  browse: '30',//浏览量
				  forward: '10',//转发量
				  children: [{
						  id: 31,
						  date: '2021-08-31',
						  time: '16:23',
						  project:'泽龙地产项目',
						  name: '赵先生',
						  browse: '18',//浏览量
						  forward: '8',//转发量
						}, {
						  id: 32,
						  date: '2021-09-01',
						  time: '15:23',
						  project:'泽龙地产项目',
						  name: '孙经理',
						  browse: '13',
						  forward: '3',//转发量
					  }]
				},{
				  id: 5,
				  date: '2021-09-04',
				  time: '10:23',
				  project:'鲁班会',
				  name: '刘先生',
				  browse: '17',//浏览量
				  forward: '15',//转发量
				},
				{
				  id: 6,
				  date: '2021-09-05',
				  time: '11:23',
				  project:'水岸项目',
				  name: '邓先生',
				  browse: '17',//浏览量
				  forward: '15',//转发量
				},
				{
				  id: 7,
				  date: '2021-09-03',
				  time: '12:20',
				  project:'后天项目',
				  name: '李经理',
				  browse: '17',//浏览量
				  forward: '15',//转发量
				},
				{
				  id: 8,
				  date: '2021-09-03',
				  time: '12:23',
				  project:'直隶烧锅',
				  name: '陈经理',
				  browse: '17',//浏览量
				  forward: '15',//转发量
				},
				{
				  id: 9,
				  date: '2021-09-03',
				  time: '12:24',
				  project:'风华天诚',
				  name: '刘经理',
				  browse: '17',//浏览量
				  forward: '15',//转发量
				},]
			}	
		},
		components: {
		  Headernew,
		  Footernew,
		},
		async mounted() {
			// 页面打开的时候滚到顶部
			document.documentElement.scrollTop = document.body.scrollTop = 0
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
			})
			
			this.handleResize() //获取计算器宽度
			//this.loopdata() //循环日期
			//this.getday() //获取年月日
			this.getdata() //获取数据
			
		},
		methods: {
			// 计算浏览器宽度
			 handleResize (event) {
				 var that = this
			      that.fullWidth = document.documentElement.clientWidth
				 if(that.fullWidth>1200){
					 // 说明是电脑端
					 that.tablewidth = 300
					 that.timewidth = 200
				 }else if(that.fullWidth>300 && that.fullWidth<500){
					 // 说明是在移动端
					 that.tablewidth = 140
					 that.timewidth = 125
				 }
			 },
			 // 获取今天的日期
			 getday:function(){
				 var date = new Date();
				 var years = date .getFullYear(); //获取完整的年份(4位)
				 var month = date .getMonth()+1; //获取当前月份(0-11,0代表1月)
				 var day = date .getDate(); //获取当前日(1-31)
				 
				 this.timechose = years+'-'+month+'-'+day
			 },
			 filterHandler(value, row, column) {
				   const property = column['property'];
				   return row[property] === value;
			 },
			 // 在el-table中添加这段:row-class-name="tableRowClassName"
			 tableRowClassName({row, rowIndex}) {
				   if (row.children && row.children.length>0) {
					 return 'warning-row';
				   }
				   return '';
			 },
			 // 点击跳入关系图页面
			 jumppage(index, row) {
			     // console.log(index, row);
				   var id = row.id //获取到点击的这一行的id
				   var date = row.date //获取到点击的这一行的时间
				   // this.$router.push({
					  //  name:'echartdetail',
					  //  params:{"id":id}
				   // })
				   this.$router.push({path: '/echartdetail?id='+id+'&&time='+date})
				   // console.log(row)
				   // if(row.children && row.children.length>0){
					  //  this.$router.push({
						 //   name:'echartdetail',
						 //   params:{"id":id}
					  //  })
				   // }
			 },
			 //循环数据，拿到所有数据的date，放入filtertime中
			 loopdata:function(){
				   var that = this
				   var tableData = that.tableData
				   var newdata = []
				   var lastdata = []
				   
				   tableData.map((item,index)=>{
					   newdata.push(item.date)
					   return newdata
				   })
				   // 数组去重
				   newdata = Array.from(new Set(newdata)) 
				   newdata.sort((a, b) => b.localeCompare(a))
				   
				   newdata.map(item=>{
					   var newobj = {}
					   newobj.text = item
					   newobj.value = item
					   return lastdata.push(newobj)
				   })
				   
				   that.filtertime = lastdata   
			 },
			 getdata:function(){
				 var that = this
				 
				 let newurl = 'https://htkja.htkjbd.com/api/browsTable'
				 this.$axios.get(newurl,{
					 params:{
						 date:that.timechose,
						 project_name:that.searchtext,
						 page:that.pagenum,
						 limit:that.limit
					 }
				 })
				 .then((res) => {
					 that.tableData = res.data.result.data
					 that.countnum = res.data.result.count
					 // console.log(res.data.result.data)
				 })
				 .catch((err) => {
				 	console.log(err) //错误信息
				 })
			 },
			 // 更改日期的时候
			 changetime:function(){
				 var that = this
				 var date = that.timechose
				 var years = date .getFullYear(); //获取完整的年份(4位)
				 var month = date .getMonth()+1; //获取当前月份(0-11,0代表1月)
				 var day = date .getDate(); //获取当前日(1-31)
				 this.timechose = years+'-'+month+'-'+day
				 this.getdata()
			 },
			 // 点击搜索的时候
			 tosearch:function(){
				 var that = this
				 that.searchtext = that.$refs.search.value
				 // console.log(that.$refs.search.value)
				 this.getdata()
			 },
			 // 切换分页
			 changepage:function(num){
				 this.pagenum = num
				 this.getdata()
			 }
			 
		}, 
	}
</script>

<style>
	.topnav{
		width: 100%;
		height: 96px;
		background-color: #000000;
	}
	
	.formzuiwaiechart{
		width: 100%;
		height: 1100px;
		position: relative;
	}
	.formwrap22echart{
		width: 1400px;
		height: 700px;
		position: absolute;
		left: 50%;
		top: 38%;
		margin-left: -37%;
		margin-top: -19%;
		/* box-shadow:0 0 10px rgba(153, 153,153, 0.3); */
		
	}
	.formtitle{
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 2px;
	}
	.explainechart{
		width: 100%;
		display: flex;
		margin-top: 25px;
		margin-bottom: 20px;
	}
	.projectchoseechart{
		width: 17%;
		margin-left: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: #dcdfe6 1px solid;
		border-radius: 5px;
		padding: 0 8px;
		box-sizing: border-box;
	}
	.searchinputechart{
		width: 70%;
		height: 80%;
		border: none;
		outline: none;
		color: #000;
	}
	.projectchoseechart img{
		width: 9%;
	}
	.echart{
		height: 720px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}
	.el-table--border:after,.el-table--group:after,.el-table:before {
	    /* background-color: black; */
	}
	.el-table--border,.el-table--group {
	    /* border-color: black;	 */
	}
	.el-table th > .cell {
		  text-align: center;
		}
	.el-table .cell {
		  text-align: center;
	}
	.el-table .warning-row {
	    background: oldlace;
	  }
	.el-table .success-row {
	    background: #f0f9eb;
	}
	/* 分页器 */
	@media (min-width: 1100px) {
		.fenyeqi{
			margin-top: 40px;
		}
		.el-pager li {
			height: 40px !important;
			line-height: 40px !important;
		}
		.number{
			width: 40px;
		}
		.btn-next,.btn-prev,.el-icon{
			width: 40px;
			height: 40px !important;
			line-height: 40px !important;
		}
	}
	
	
	
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		body{
			background-color: #fff;
		}
		.topnav{
			width: 100%;
			height: 60px;
			background-color: #000000;
		}
		
		.formzuiwaiechart{
			width: 97%;
			margin: 0 auto;
			height: 113vh;
			position: relative;
		}
		.formwrap22echart{
			width: 100%;
			height: 98vh;
			position: relative;
			left: 0;
			top: 0;
			z-index: 1 !important;
			margin: 0 auto !important;
			/* box-shadow:0 0 10px rgba(153, 153,153, 0.3); */
			/* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
			border-radius: 5px;
			/* transform: rotate(90deg); */
		}
		.formtitle{
			font-size: 18px;
			margin-top: 20px;
			font-weight: bold;
			letter-spacing: 2px;
		}
		.explainechart{
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.timeselectechart{
			width: 49%;
		}
		.projectchoseechart{
			width: 49%;
			margin-left: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: #dcdfe6 1px solid;
			border-radius: 5px;
			padding: 0 8px;
			box-sizing: border-box;
		}
		.searchinputechart{
			width: 70%;
			height: 80%;
			border: none;
			outline: none;
			color: #dcdfe6;
		}
		input::-webkit-input-placeholder{
			font-size: 14px;
		}
		.projectchoseechart img{
			width: 12%;
		}
		
		.echart{
			height: 80vh;
			/* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
			border-radius: 5px;
		}
		/* 分页器 */
		.fenyeqi{
			margin-top: 30px;
			margin-left:-3px;
		}
		
	}
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.topnav{
			width: 100%;
			height: 96px;
			background-color: #000000;
		}
		
		.formzuiwaiechart{
			width: 100%;
			height: 980px;
			position: relative;
		}
		.formwrap22echart{
			width: 98%;
			height: 700px;
			position: absolute;
			left: 1%;
			top: 5%;
			margin-left: 0%;
			margin-top: 0%;
			z-index: 1 !important;
			/* box-shadow:0 0 10px rgba(153, 153,153, 0.3); */
		}
		.formtitle{
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 2px;
		}
		.explainechart{
			width: 100%;
			display: flex;
			margin-top: 25px;
			margin-bottom: 20px;
		}
		.projectchoseechart{
			width: 30%;
			margin-left: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: #dcdfe6 1px solid;
			border-radius: 5px;
			padding: 0 8px;
			box-sizing: border-box;
		}
		.searchinputechart{
			width: 70%;
			height: 80%;
			border: none;
			outline: none;
			color: #000;
		}
		.projectchoseechart img{
			width: 9%;
		}
		.echart{
			height: 700px;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}
		/* 分页器 */
		.fenyeqi{
			margin-top: 30px;
			/* margin-left:-3px; */
		}
	}
</style>
