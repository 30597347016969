<template>
	<div>
		<!-- 留言按钮 -->
		<Comment class="commentWrap"/>
		<!-- 置顶组件 -->
		<gotop></gotop>
		
		<div class="bannertop">
			<!-- 顶部导航栏 -->
			<Headernew/> 
			<div class="bannertop-black">
				<div class="wow fadeInUp bannertop-title" >
					<!-- <div>{{articlecont.name}}</div> -->
					<!-- <div>{{articlecont.describe}}</div> -->
				</div>
			</div>
		</div>
		
		<!-- 富文本信息 -->
		<div class="maincontent" v-html="contentmessage">
			{{contentmessage}}
		</div>
		
		<Footernew/>
	</div>

</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import Comment from '@/components/comment.vue'
	import gotop from '@/components/gotop.vue'
	export default {
		components: {
		  Headernew,
		  Footernew,
		  Comment,
		  gotop
		},
	    data () {
	      return {
			  articlecont:"",
			  contentmessage:"",
			}
	    },
	    created(){
	      this.getParams()
	    },
	    methods:{
	      getParams() {
	        // 取到路由带过来的参数
	        const routerid = this.$route.query.id;
			console.log(routerid)
				let newurl = this.$api + "/get/Articles"
				this.$axios.get(newurl)
				.then((res) => {
					var articles = res.data.result.data
					var newarticlecont = articles.filter(item=>{
						return item.id==routerid
					})
					this.articlecont = newarticlecont[0]
					this.contentmessage = newarticlecont[0].content
				})
				.catch((err) => {
					console.log(err)
				})
	      }
	    }
	  }
</script>

<style scoped="scoped">
	body{
		margin: 0;
		padding: 0;
	}
	/* 评论组件 */
	.commentWrap{
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 100;
	 }
	.bannertop{
		width: 100%;
		height: 800px !important;
		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/banner-144.jpg) !important;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.bannertop-black{
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.3);
		position: relative;
	}
	.bannertop-title{
		width: 100%;
		position: absolute;
		left: 0;
		top: 40%;
	}
	.bannertop-title div:nth-of-type(1){
		font-size: 61px;
		color: #fff;
		font-weight: bold;
	}
	.bannertop-title div:nth-of-type(2){
		font-size: 31px;
		color: #fff;
		margin-top: 35px;
		letter-spacing: 2px;
	}
	/* 富文本信息 */
	.maincontent{
		width: 90%;
		margin: 16px auto 40px;
		/* overflow: hidden; */
	}
	/* .maincontent img{
		width: 100% !important;
		height: auto !important;
	} */
	p{
		text-align: left;
	}
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		.bannertop{
			width: 100%;
			height: 250px !important;
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/banner-144.jpg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
		.bannertop-title{
			width: 90%;
			position: absolute;
			left: 5%;
			top: 40%;
		}
		.bannertop-title div:nth-of-type(1){
			font-size: 20px;
			color: #fff;
			font-weight: bold;
		}
		.bannertop-title div:nth-of-type(2){
			font-size: 14px;
			color: #fff;
			margin-top: 10px;
			letter-spacing: 2px;
		}
		/* 富文本信息 */
		.maincontent{
			width: 90%;
			margin: 0px auto 40px;
			/* overflow: hidden; */
		}
	}
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.bannertop{
			width: 100%;
			height: 400px;
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/banner-144.jpg);
		}
		.bannertop-title{
			width: 80%;
			position: absolute;
			left: 10%;
			top: 40%;
		}
		.bannertop-title div:nth-of-type(1){
			font-size: 24px;
			color: #fff;
			font-weight: bold;
		}
		.bannertop-title div:nth-of-type(2){
			font-size: 16px;
			color: #fff;
			margin-top: 20px;
			letter-spacing: 2px;
		}
		/* 富文本信息 */
		.maincontent{
			width: 90%;
			margin: 0px auto 40px;
			/* overflow: hidden; */
		}
	}
</style>
