<template>
	<div class="codewrap">
		<div class="codewrap-text">请用微信扫码登录</div>
		<div class="lcationcode" id="login_container"></div>
	</div>
</template>

<script>
	import $ from "jquery"
	// import wx from 'weixin-js-sdk'
	export default {
	  data() {
		return {
		}
	  },
	  created(){
		  
	  },
	  mounted() {
		  this.startlogin()
	  },
	  methods:{
		  startlogin(){
			  // 页面打开就执行微信登录
		   var obj = new WxLogin({
		  
		          id:"login_container", 
				  // 移动端用的是公众号的appid，pc端用的是公众平台的appid
		          appid: 'wxc6b84ed9b28d643f', // openappid
		  
		          scope: "snsapi_login",
		  
		          redirect_uri: "https%3A%2F%2Fxjaikj.htkjbd.com%2Floginsucess",
		  
		          state: '',
		  
		          style: "black",
		  
		          href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUgewogYm9yZGVyOiBub25lOwogd2lkdGg6IDI1MHB4OwogaGVpZ2h0OiAyNTBweDsKfQouaW1wb3dlckJveCAuc3RhdHVzewogZGlzcGxheTogbm9uZQp9Cg=='
		  
		      })
		  }
	  },
	}
</script>

<style>
	.codewrap{
		width: 100%;
		height: 100vh;
		background-color: #000000 !important;
		padding-top: 150px;
		box-sizing: border-box;
	}
	#login_container iframe{
		height: 340px !important;
		margin-top: 20px;
	}
	.codewrap-text{
		color: #fff;
		font-size: 20px;
		letter-spacing: 1px;
	}
</style>
