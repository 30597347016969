<template>
	<div class="root">
		<div class="top"></div>
		<div class="middle">
			<div class="videoView">
				<video class="video" controls :src="url" poster="../assets/poster.png" controlslist="nodownload"></video>
			</div>
		</div>
		<div class="bottom"></div>
	</div>
</template>

<script>
	
	//在需要使用的路由里进行引用   也可以进行全局引用
	import wx from 'weixin-js-sdk'
	
	export default {
		data() {
			return {
				isPhone: false,
				isWx: false,
				url: "https://houtiankeji.oss-cn-beijing.aliyuncs.com/%E6%95%B0%E5%AD%97%E5%8C%96%E5%85%83%E5%AE%87%E5%AE%99%E8%87%AA%E5%AE%A3-%E5%B0%8F%E6%A0%BC%E5%BC%8F.mp4"
			}
		},
		created() {
			let ua = navigator.userAgent.toLowerCase();
			this.isPhone = ua.match(/iphone/i) == 'iphone' || ua.match(/android/i) == 'android';
			this.isWx = ua.match(/MicroMessenger/i) == 'micromessenger';
			if (this.isPhone && this.isWx) {
				
			} else {
				alert("请在手机微信端打开");
				this.$router.replace("/");
			}
		},
		mounted() {
			let that = this;
			this.$nextTick(() => {
				that.share();
				document.getElementsByTagName('title')[0].innerText = "案例合集";
			});
		},
		methods:{
			toCases() {
				this.$router.push("/casedetail");
			},
			// 微信分享功能
			share: function() {
				var that = this;
				var shareUrl = window.location.href;
				that.$axios.get('https://htkja.htkjbd.com/api/get/jssdk?path=' + encodeURIComponent(shareUrl))
					.then(function(res) {
						wx.config({
							debug: false, // true开启调试模式,pc端会console.log手机端会alert
							appId: res.data.result.appId, // 必填，公众号的唯一标识，填自己的！
							timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
							nonceStr: res.data.result.nonceStr, // 必填，生成签名的随机串
							signature: res.data.result.signature, // 必填，签名，见附录1
							jsApiList: [
								'onMenuShareTimeline',
								'onMenuShareAppMessage'
							]
						})
			
						//分享配置
						wx.ready(function() {
							//分享到朋友圈
							wx.onMenuShareTimeline({
								// title: that.videomessage.main,
								title: "案例合集",
								link: shareUrl,
								imgUrl: "https://xjaikj.htkjbd.com/collecticon.png",
								success: function() {
			
								},
								cancel: function() {
									// alert("取消分享");
								}
							});
							//分享给朋友
							wx.onMenuShareAppMessage({
								// title: that.videomessage.main,
								// desc: that.videomessage.main,
								title: "案例合集",
								desc: "数字化一站式解决方案服务商",
								link: shareUrl,
								imgUrl: "https://xjaikj.htkjbd.com/collecticon.png",
								success: function() {
			
								},
								cancel: function() {
									// alert("取消分享");
								}
							});
						})
			
					}).catch(function(err) {
						// alert(err)
						//        console.log(err)
					})
			},
			
			
			// 禁止右键和F12
			prohibitClick: function() {
			
				//禁用右键
				document.oncontextmenu = function() {
					return false;
				};
				//禁用开发者工具F12
				document.onkeydown = document.onkeyup = document.onkeypress = function(event) {
					let e = event || window.event || arguments.callee.caller.arguments[0];
					if (e && e.keyCode == 123) {
						e.returnValue = false;
						return false;
					}
				}
			},
		}
	}
</script>

<style scoped>
	.root {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		display: flex;
		position: absolute;
		box-sizing: border-box;
		flex-direction: column;
		justify-content: space-between;
		background-color: #1B3F7E;
		/* background-image: url("../assets/img/albg.png"); */
	}
	
	.top {
		height: 40vw;
		background-size: 100%;
		background-image: url("../assets/img/albg.png");
	}
	
	.middle {
		display: flex;
		height: 53.5vw;
		align-items: center;
		background-size: 100%;
		justify-content: center;
		background-position: 0 -41.5vw;
		background-image: url("../assets/img/albg.png");
	}
	
	.bottom {
		height: 79.8vw;
		background-size: 100%;
		background-position: 0 -97vw;
		background-repeat: no-repeat;
		background-image: url("../assets/img/albg.png");
	}
	
	.videoView {
		width: 87vw;
		height: 50vw;
	}
	
	.video {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
</style>