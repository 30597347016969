<template>
	<div class="all-wrap">
		<!-- 留言按钮 -->
		<Comment class="commentWrap"/>
		<!-- 置顶组件 -->
		<gotop></gotop>
		
		<div class="concat-top">
			<!-- 顶部导航栏和顶部图片 -->
			<Headernew/> 
			<div class="concat-topyin">
				<div class="wow fadeInUp concat-topyincenter">
					<div class="concat-topeng">CONCAT US</div>
					<div class="concat-toptext">联系我们</div>
					<div class="concat-topword">值得信赖的合作伙伴，为您提供最优质的技术与服务</div>
				</div>
			</div>
		</div>
		
		<!-- 公司介绍 -->
		<div class="wow fadeInUp company-wrap">
			<div class="company-top">
				<div class="company-eng">COMPANY INTRODUCTION</div>
				<div class="company-title">公司介绍</div>
			</div>
			<div class="company-bottom">
				<div class="company-left">
					<p>
					北京后天世纪影视传媒有限公司创办于2002年，2004年设立保定分公司，自创办以来专业从事
					宣传片制作、企业形象包装设计、微电影以及大型庆典活动的策划与执行，本公司拥有一支强
					大的专业从事三维动画制作、影视拍摄、影视剪辑与特效包装的技术团队，人数常年保持在30人以上。
					</p>
					<p>
					经过十余年的发展，已经成为一家具有国际水平标准，以4A级管理及录制流程为标准的保定市最大的影视制作公司。
					</p>
					<p>
					2017年随着市场的不断累积与扩散成立相关科技公司，在原有宣传片的业务覆盖下，取得软件
					程序方向的突破。且近2年来一直在为企业及地产开发商提供专业、完善的一体化运营平台和整体解决方案。
					今后，后天科技将一直秉持着“以品质面对挑战、用艺术重塑企业、传播精彩、共享未来”的经营理念，以新锐的创意、精湛的技术与专业的服务，竭诚为您服务！
					</p>
				</div>
				<div class="company-right">
				</div>
			</div>
		</div>

		<!-- 企业文化 -->
		<div class="wow bounceInUp corporate">
			<div class="company-top">
				<div class="company-eng">CORPORATE CULTURE</div>
				<div class="company-title">企业文化</div>
			</div>
			
			<div class="corporate-bottom">
				<div class="corporate-list">
					<img src="../assets/img/companyicon.png" />
					<div>公司使命</div>
					<div>以客户的要求为第一追求</div>
				</div>
				<div class="corporate-list">
					<img src="../assets/img/fuwuicon.png" />
					<div>服务理念</div>
					<div>用最贴合企业的方式发声，让营销更有意义</div>
				</div>
				<div class="corporate-list">
					<img src="../assets/img/zuanshiicon.png" />
					<div>价值观</div>
					<div>用品质面对挑战，用艺术重塑企业</div>
				</div>
			</div>
		</div>

		<!-- 联系我们 -->
		<div class="wow bounceInUp concatmessage">
			<div class="company-top">
				<div class="company-eng">CONCAT INFORMATION</div>
				<div class="company-title">联系方式</div>
			</div>
			
			<div class="concatmessage-bottom">
				<div class="concatmessage-left">
					<div class="concatmessage-title">联系我们</div>
					<div class="concatmessage-span">
						<div><span class="iconfont icon-phone"></span></div>
						<div><span class="iconfont icon-youxiang"></span></div>
						<div><span class="iconfont icon-location"></span></div>
					</div>
					<div class="concatmessage-loca">
						<div>电话 | 0312-3051117   </div>
						<div>手机 | 13903126240 | 15933970970</div>
						<!-- <div>地址 | 河北省保定市莲池区秀兰锦观城底商李庄街772号后天科技</div> -->
						<div>地址 | 河北省保定市电谷科技中心3号楼C座305室后天科技</div>
					</div>
				</div>
				<div class="concatmessage-right">
					<img src="../assets/img/concatmap.jpg" />
					<!-- <div id="map-container" style="width:100%;height:100%;"></div> -->
				</div>
			</div>
			
		</div>
		
		
		<!-- 地图 -->
		<!-- <div class="mapwrap"></div>-->
		
		<Footernew/>
	</div>
</template>

<script>
	import $ from "jquery"
	import Headernew from '@/components/Headernew.vue'
	import Footernew from '@/components/Footernew.vue'
	import {WOW} from 'wowjs'  // 导入 wow
	import loadBMap from '../quote/loadBMap.js'
	import Comment from '@/components/comment.vue'
	import gotop from '@/components/gotop.vue'
	export default {
		name: '',
		data() {
			return {
				 form: { 
					 address: '', //详细地址 
					 addrPoint: { //详细地址经纬度 
						 lng: 0, 
						 lat: 0    
					 } }, 
					 map: '', //地图实例 
					 mk: '' //Marker实例
			}	
		},
		components: {
		  Headernew,
		  Footernew,
		  Comment,
		  gotop
		},
		async mounted() {
			// 在项目加载完成之后初始化wow
			this.$nextTick(() => {
				let wow = new WOW({
					live:false
				})
				wow.init()
			})
			
			//这里运用async/await 进行异步处理，保证BMap加载进来后才执行后面的操作
			// await loadBMap('6u9kGeBlGCBo0XomI0u7pVPbFihEioPD') //加载引入BMap
			// this.initMap()
		},
		methods: {
			// initMap() { 
			// 	var that = this; 
			// 	this.map = new BMap.Map("map-container", {enableMapClick:false}) 
			// 	//新建地图实例，enableMapClick:false ：禁用地图默认点击弹框 
			// 	var point = new BMap.Point(115.509543,38.907392); 
			// 	this.map.centerAndZoom(point,19)
			// 	// 创建点标记
			// 	var marker1 = new BMap.Marker(new BMap.Point(115.509543,38.907392));
			// 	// 在地图上添加点标记
			// 	this.map.addOverlay(marker1);
			// 	// 创建缩放
			// 	this.map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
			// 	var scaleCtrl = new BMap.ScaleControl();  // 添加比例尺控件
			// 	this.map.addControl(scaleCtrl);
			// },
		
		}, 
	}
</script>

<style scoped="scoped">
	body{
		background-color: #fff !important;
		padding: 0;
	}
	ul,li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	/* 评论组件 */
	.commentWrap{
		 position: fixed;
		 left: 0;
		 top: 0;
		 z-index: 100;
	 }
	.all-wrap{
		background-color: #fff !important;
	}
	.concat-top{
		width: 100%;
		height: 700px;
		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus.jpg);
	}
	.concat-topyin{
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.4);
		display: flex;
		justify-content: center;
	}
	.concat-topyincenter{
		margin-top: 250px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.concat-topeng{
		font-size: 30px;
		color: #fff;
		line-height: 40px;
		font-weight: bold;
	}
	.concat-toptext{
		width: 200px;
		height: 70px;
		background-image: url(../assets/img/concaticon.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		text-align: center;
		line-height: 70px;
		font-size: 34px;
		color: #fff;
		font-weight: bold;
		letter-spacing: 2px;
		margin-top: 20px;
	}
	.concat-topword{
		line-height: 70px;
		font-size: 28px;
		color: #fff;
		letter-spacing: 2px;
		font-weight: bold;
		margin-top: 20px;
	}
	/* 公司介绍 */
	.company-wrap{
		width: 80%;
		height: 500px;
		background-color: #fff;
		margin: 100px auto 0;
	}
	.company-eng{
		text-align: center;
		line-height: 50px;
		font-size: 18px;
		color: #545454;
		font-weight: bold;
	}
	.company-title{
		text-align: center;
		font-size: 18px;
		color: #545454;
		font-weight: bold;
	}
	.company-bottom{
		display: flex;
		justify-content: center;
		margin-top: 30px;
		/* position: relative; */
	}
	.company-left{
		width: 650px;
		height: 400px;
		text-align: left;
		font-size: 16px;
		line-height: 25px;
	}
	.company-right{
		width: 750px;
		height: 400px;
		margin-left: 40px;
		overflow: hidden;
		/* background-image: url(../assets/img/concatscroll.jpg); */
		background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus1.jpg);
		background-repeat:no-repeat; 
		/* background-attachment:fixed; */
		background-size: 100%;
		/* background-position: 150% 30%; */
	}
	
	/* 企业文化 */
	.corporate{
		width: 100%;
		height: 350px;
		margin-top: 50px;
		background-color: #f7f7f7;
		padding-top: 80px;
	}
	.corporate-bottom{
		display: flex;
		justify-content: center;
		width: 80%;
		margin: 70px auto 0;
	}
	.corporate-list{
		width: 30%;
	}
	.corporate-list img{
		width: 50px;
		height: 50px;
	}
	.corporate-list div:nth-of-type(1){
		font-size: 16px;
		line-height: 30px;
		margin-top: 15px;
	}
	.corporate-list div:nth-of-type(2){
		font-size: 15px;
		letter-spacing: 1px;
		color: #b0b1b1;
	}
	/* 联系我们 */
	.concatmessage{
		width: 80%;
		height: 550px;
		margin: 70px auto 80px;
	}
	.concatmessage-bottom{
		width: 100%;
		height: 350px;
		margin-top: 80px;
		display: flex;
		justify-content: center;
	}
	.concatmessage-left{
		width: 35%;
		height: 350px;
	}
	.concatmessage-title{
		width: 100%;
		text-align: left;
		font-size: 18px;
		font-weight: bold;
	}
	.concatmessage-span{
		display: flex;
		width: 100%;
		margin-top: 30px;
	}
	.concatmessage-span div{
		width: 50px;
		height: 50px;
		border-radius: 50% 50%;
		background-color: #ff4133;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
	}
	.concatmessage-span div span{
		color: #fff;
		font-size: 25px;
	}
	.concatmessage-loca{
		text-align: left;
		margin-top: 30px;
	}
	.concatmessage-loca div{
		color: #b2b2b2;
		margin-top: 10px;
	}
	.concatmessage-right{
		width: 50%;
		height: 350px;
	}
	.concatmessage-right img{
		width: 100%;
		height: 350px;
	}
	/* 地图 */
/* 	.mapwrap{
		width: 80%;
		height: 400px;
		margin: 0px auto 40px;
		border: #000000 1px solid;
	} */
	
	/* 手机端自适应 */
	@media (min-width: 300px) and (max-width: 500px){
		.concat-top{
			width: 100%;
			height: 250px;
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus.jpg);
			background-size: 100% 100%;
		}
		.concat-topyincenter{
			width: 60%;
			margin-top: 80px;
		}
		.concat-topeng{
			font-size: 16px;
			line-height: 30px;
		}
		.concat-toptext{
			width: 120px;
			height: 40px;
			line-height: 40px;
			font-size: 16px;
		}
		.concat-topword{
			line-height: 20px;
			font-size: 14px;
			letter-spacing: 2px;
			margin-top: 10px;
		}
		/* 公司介绍 */
		.company-wrap{
			width: 93%;
			height: auto;
			background-color: #fff;
			margin: 20px auto 0;
		}
		.company-eng{
			text-align: center;
			line-height: 30px;
			font-size: 16px;
			color: #545454;
			font-weight: bold;
		}
		.company-title{
			text-align: center;
			font-size: 16px;
			color: #545454;
			font-weight: bold;
		}
		.company-bottom{
			display: block;
			margin-top: 20px;
			position: relative;
		}
		.company-left{
			width: 100%;
			height: 300px;
			text-align: left;
			font-size: 12px;
			line-height: 20px;
			padding-top: 210px;
		}
		.company-right{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 200px;
			margin-left: 0px;
			overflow: hidden;
			/* background-image: url(../assets/img/concatscroll.jpg); */
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus1.jpg);
			background-repeat:no-repeat; 
			background-attachment: scroll;
			background-size: 100% 100%;
			background-position: 10% 30%;
		}
		
		/* 企业文化 */
		.corporate{
			width: 100%;
			height: auto;
			margin-top: 20px;
			background-color: #f7f7f7;
			padding-top: 20px;
			padding-bottom: 30px;
		}
		.corporate-bottom{
			display: flex;
			justify-content: space-between;
			width: 90%;
			margin: 30px auto 0;
		}
		.corporate-list{
			width: 30%;
		}
		.corporate-list img{
			width: 40px;
			height: 40px;
		}
		.corporate-list div:nth-of-type(1){
			font-size: 14px;
			line-height: 20px;
			margin-top: 5px;
		}
		.corporate-list div:nth-of-type(2){
			font-size: 12px;
			letter-spacing: 1px;
			color: #b0b1b1;
		}
		/* 联系我们 */
		.concatmessage{
			width: 93%;
			height: auto;
			margin: 20px auto 0;
		}
		.concatmessage-bottom{
			width: 90%;
			height: auto;
			margin: 30px auto 0;
			display: block;
			position: relative;
		}
		.concatmessage-left{
			width: 100%;
			height: 200px;
			padding-top: 230px;
		}
		.concatmessage-title{
			width: 100%;
			text-align: left;
			font-size: 16px;
			font-weight: bold;
		}
		.concatmessage-span{
			display: flex;
			width: 100%;
			margin-top: 10px;
		}
		.concatmessage-span div{
			width: 30px;
			height: 30px;
			border-radius: 50% 50%;
			background-color: #ff4133;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 10px;
		}
		.concatmessage-span div span{
			color: #fff;
			font-size: 16px;
		}
		.concatmessage-loca{
			text-align: left;
			margin-top: 10px;
		}
		.concatmessage-loca div{
			color: #b2b2b2;
			margin-top: 5px;
			font-size: 16px;
		}
		.concatmessage-right{
			width: 100%;
			height: 200px;
			position: absolute;
			left: 0;
			top: 0;
		}
		.concatmessage-right img{
			width: 100%;
			height: 200px;
		}
	}
	
	
	/* pad端 */
	@media (min-width: 500px) and (max-width: 800px){
		.concat-top{
			width: 100%;
			height: 350px;
			background-size: 100% 100%;
		}
		.concat-topyincenter{
			width: 60%;
			margin-top: 150px;
		}
		.concat-topeng{
			font-size: 16px;
			line-height: 30px;
		}
		.concat-toptext{
			width: 120px;
			height: 40px;
			line-height: 40px;
			font-size: 16px;
		}
		.concat-topword{
			line-height: 20px;
			font-size: 14px;
			letter-spacing: 2px;
			margin-top: 10px;
		}
		/* 公司介绍 */
		.company-wrap{
			width: 93%;
			height: auto;
			background-color: #fff;
			margin: 20px auto 0;
		}
		.company-eng{
			text-align: center;
			line-height: 30px;
			font-size: 16px;
			color: #545454;
			font-weight: bold;
		}
		.company-title{
			text-align: center;
			font-size: 16px;
			color: #545454;
			font-weight: bold;
		}
		.company-bottom{
			display: flex;
			margin-top: 10px;
			position: relative;
			justify-content: space-between;
		}
		.company-left{
			width: 49%;
			height: auto;
			text-align: left;
			font-size: 12px;
			line-height: 20px;
			padding-top: 0px;
		}
		.company-right{
/* 			position: absolute;
			left: 0;
			top: 0; */
			width: 49%;
			height: 230px;
			margin-left: 0px;
			overflow: hidden;
			/* background-image: url(../assets/img/concatscroll.jpg); */
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus1.jpg);
			background-repeat:no-repeat; 
			background-attachment: scroll;
			background-size: 100% auto;
			background-position: 10% 30%;
		}
		
		/* 企业文化 */
		.corporate{
			width: 100%;
			height: auto;
			margin-top: 20px;
			background-color: #f7f7f7;
			padding-top: 20px;
			padding-bottom: 30px;
		}
		.corporate-bottom{
			display: flex;
			justify-content: space-between;
			width: 90%;
			margin: 30px auto 0;
		}
		.corporate-list{
			width: 30%;
		}
		.corporate-list img{
			width: 40px;
			height: 40px;
		}
		.corporate-list div:nth-of-type(1){
			font-size: 14px;
			line-height: 20px;
			margin-top: 5px;
		}
		.corporate-list div:nth-of-type(2){
			font-size: 12px;
			letter-spacing: 1px;
			color: #b0b1b1;
		}
		/* 联系我们 */
		.concatmessage{
			width: 93%;
			height: auto;
			margin: 20px auto 0;
		}
		.concatmessage-bottom{
			width: 90%;
			height: auto;
			margin: 30px auto 80px;
			display: flex;
			justify-content: space-between;
			position: relative;
		}
		.concatmessage-left{
			width: 47%;
			height: 200px;
			padding-top: 0px;
		}
		.concatmessage-title{
			width: 100%;
			text-align: left;
			font-size: 16px;
			font-weight: bold;
		}
		.concatmessage-span{
			display: flex;
			width: 100%;
			margin-top: 10px;
		}
		.concatmessage-span div{
			width: 30px;
			height: 30px;
			border-radius: 50% 50%;
			background-color: #ff4133;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 10px;
		}
		.concatmessage-span div span{
			color: #fff;
			font-size: 16px;
		}
		.concatmessage-loca{
			text-align: left;
			margin-top: 10px;
		}
		.concatmessage-loca div{
			color: #b2b2b2;
			margin-top: 5px;
			font-size: 16px;
		}
		.concatmessage-right{
			width: 49%;
			height: 200px;
/* 			position: absolute;
			left: 0;
			top: 0; */
		}
		.concatmessage-right img{
			width: 100%;
			height: 200px;
		}
	}
	
	/* 老板仓定制机的样式 */
	@media (min-width: 1000px) and (max-width: 1300px){
		/* 评论组件 */
		.commentWrap{
			 position: fixed;
			 left: 0;
			 top: 0;
			 z-index: 100;
		 }
		.all-wrap{
			background-color: #fff !important;
		}
		.concat-top{
			width: 100%;
			height: 500px;
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus.jpg);
		}
		.concat-topyin{
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.4);
			display: flex;
			justify-content: center;
		}
		.concat-topyincenter{
			margin-top: 200px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.concat-topeng{
			font-size: 22px;
			color: #fff;
			line-height: 40px;
			font-weight: bold;
		}
		.concat-toptext{
			width: 150px;
			height: 50px;
			background-image: url(../assets/img/concaticon.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			text-align: center;
			line-height: 50px;
			font-size: 25px;
			color: #fff;
			font-weight: bold;
			letter-spacing: 2px;
			margin-top: 10px;
		}
		.concat-topword{
			line-height: 30px;
			font-size: 22px;
			color: #fff;
			letter-spacing: 2px;
			font-weight: bold;
			margin-top: 15px;
		}
		/* 公司介绍 */
		.company-wrap{
			width: 80%;
			height: 360px;
			background-color: #fff;
			margin: 40px auto 0;
		}
		.company-eng{
			text-align: center;
			line-height: 50px;
			font-size: 18px;
			color: #545454;
			font-weight: bold;
		}
		.company-title{
			text-align: center;
			font-size: 18px;
			color: #545454;
			font-weight: bold;
		}
		.company-bottom{
			display: flex;
			justify-content: center;
			margin-top: 30px;
			/* position: relative; */
		}
		.company-left{
			width: 650px;
			height: 260px;
			overflow: hidden;
			text-align: left;
			font-size: 16px;
			line-height: 25px;
		}
		.company-right{
			width: 750px;
			height: 260px;
			margin-left: 40px;
			overflow: hidden;
			/* background-image: url(../assets/img/concatscroll.jpg); */
			background-image: url(https://lubanhui1.oss-cn-beijing.aliyuncs.com/htkj/imgs/concatus1.jpg);
			background-repeat:no-repeat; 
			/* background-attachment:fixed; */
			background-size: 100%;
			/* background-position: 150% 30%; */
		}
		
		/* 企业文化 */
		.corporate{
			width: 100%;
			height: 260px;
			margin-top: 50px;
			background-color: #f7f7f7;
			padding-top: 20px;
		}
		.corporate-bottom{
			display: flex;
			justify-content: center;
			width: 100%;
			margin: 30px auto 0;
		}
		.corporate-list{
			width: 30%;
		}
		.corporate-list img{
			width: 50px;
			height: 50px;
		}
		.corporate-list div:nth-of-type(1){
			font-size: 16px;
			line-height: 30px;
			margin-top: 5px;
		}
		.corporate-list div:nth-of-type(2){
			width: 100%;
			margin: 0 auto;
			font-size: 13px;
			letter-spacing: 1px;
			color: #b0b1b1;
		}
		/* 联系我们 */
		.concatmessage{
			width: 80%;
			height: 350px;
			margin: 40px auto 80px;
		}
		.concatmessage-bottom{
			width: 100%;
			height: 350px;
			margin-top: 40px;
			display: flex;
			justify-content: space-between;
		}
		.concatmessage-left{
			width: 35%;
			height: 350px;
		}
		.concatmessage-title{
			width: 100%;
			text-align: left;
			font-size: 18px;
			font-weight: bold;
		}
		.concatmessage-span{
			display: flex;
			width: 100%;
			margin-top: 30px;
		}
		.concatmessage-span div{
			width: 50px;
			height: 50px;
			border-radius: 50% 50%;
			background-color: #ff4133;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 10px;
		}
		.concatmessage-span div span{
			color: #fff;
			font-size: 25px;
		}
		.concatmessage-loca{
			text-align: left;
			margin-top: 30px;
		}
		.concatmessage-loca div{
			color: #b2b2b2;
			margin-top: 10px;
		}
		.concatmessage-right{
			width: 60%;
			height: 350px;
		}
		.concatmessage-right img{
			width: 100%;
			height: 250px;
		}
	}
</style>
